import React, { Component } from 'react';
import {
  reduxForm,
  Form,
  Field,
} from 'redux-form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import momentLocaliser from 'react-widgets/lib/localizers/moment'
import 'react-widgets/dist/css/react-widgets.css';
import CircularProgress from '@material-ui/core/CircularProgress';
//Component
//Lib
import { required } from '../../../libs/validation';
//Reducer
import { apiCallRequest } from '../../../reducers/api';
import { getProvider } from '../../../reducers/setting/api';
import { formProvider } from '../../../reducers/ui/mapForm';
import {
  settingOpenProvider,
  settingProviderSelect,
} from '../../../reducers/setting';

//Config
import Configs from '../../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: '200px',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  center: {
    position: 'relative',
    left: '20px',
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);


class ProviderDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentWillMount() {}
  componentWillReceiveProps(nextProps) {
    if (this.props.providerForm !== nextProps.providerForm) {
      this.props.initialize({ ...nextProps.providerForm });
    }
  }
  handleSave = (form) => {
    //debugger
    this.props.settingOpenProvider(false);
    let formData = { ...form };

    if (formData.id) {
      delete formData.password;
      let id = formData.id;
      delete formData.id;
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + '/gpsProvider/' + id,
        form: { ...formData },
        method: 'PUT',
        callback: this.handleCancel,
      });
    } else {
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + '/gpsProvider',
        form: { ...formData },
        method: 'POST',
        callback: this.handleCancel,
      });
    }
  };
  handleCancel = () => {
    //if(data.errorList.length==0){
    this.props.reset();
    this.props.getProvider({
      url: Configs[env].BACKEND_HOST + '/gpsProvider',
      filter: {},
    });
    this.props.formProvider(null);
    this.props.settingProviderSelect(null);
    this.props.settingOpenProvider(false);
    //}
  };
  render() {
    const { handleSubmit, classes } = this.props;

    return (
      <div style={{ padding: '10px' }}>
        {/*this.props.isLoading && <Loading />*/}

        <Dialog
          open={this.props.provider_dialog}
          //TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCancel}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {this.props.providerForm && this.props.providerForm.id
              ? 'EDIT PROVIDER'
              : 'ADD PROVIDER'}
          </DialogTitle>
          <DialogContent>
            <Form
              //style={{ overflowY:"scroll"}}
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.handleSave.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name='name'
                    component={renderInput}
                    className={styles.textField}
                    label='Provider'
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <DialogActions>
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      aria-label='Save'
                      className={styles.button}
                      disabled={this.props.isLoading}
                      type='submit'
                    >
                      {this.props.isLoading && (
                        <CircularProgress
                          className={classes.center}
                          size={25}
                          thickness={2}
                        />
                      )}
                      SAVE
                    </Button>
                    <Button
                      variant='outlined'
                      size='small'
                      color='secondary'
                      aria-label='Close'
                      disabled={this.props.isLoading}
                      className={classNames(styles.button, styles.cssGreen)}
                      onClick={this.handleCancel}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    isLoading: state.ui.isLoading,
    provider_selecting: state.setting.provider_selecting,
    provider_dialog: state.setting.provider_dialog,
    providerForm: state.mapForm.providerForm,
  };
};
const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  settingOpenProvider,
  settingProviderSelect,
  getProvider,
  formProvider,
};

const enhance = compose(
  reduxForm({
    form: 'providerForm',
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(ProviderDialog);
