import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withStyles } from "@material-ui/core/styles";


const styles = theme => ({
    center:{
      position: 'relative',
      left: '20px'
    },
  });

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DeleteDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    handleConfirm = () => {
        this.props.handleComfirmDelete();
    }
    handleCancel = () => {
        this.props.handleComfirmCancel();
    };
    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={true}
                //TransitionComponent={Transition}
                keepMounted
                onClose={this.handleCancel}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                >
                <DialogTitle id="alert-dialog-slide-title">
                    Delete {this.props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    { this.props.detail }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleConfirm} disabled={this.props.isLoading} color="primary">
                    {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                        Confirm
                    </Button>
                    <Button onClick={this.handleCancel} disabled={this.props.isLoading} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps = function(state,ownProps) {
  return {
    isLoading: state.ui.isLoading,
  }
}
const mapActionsToProps = {
}

DeleteDialog.propTypes = {
};

export default connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(DeleteDialog));