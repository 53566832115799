import React, { useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getDistance } from 'utils/math';
import { AREA_TYPES } from '../libs/constants';
import getStyleOptions from '../libs/getStyleOptions';
import DeleteButton from './DeleteButton';

function DrawPolygon(props) {
  const { options, map, onCallback, disposeFunctions, polygon, isDraw } = props;

  if (!window.H || !window.H.map || !map) {
    throw new Error('HMap has to be initialized before adding Map Objects');
  }

  let markerGroup = useRef();

  let mainGroup = useRef();

  let isOnDown = useRef(false);
  let polyline = useRef();
  let _polygon = useRef();
  let marker = useRef();
  let areaMarker = useRef();
  let polygonLine = useRef();
  let polylineLine = useRef();

  let startPoint = useRef(null);
  const areaType = AREA_TYPES.ALLOWED;
  const pointsLimit = 100;
  const MIN_DISTANCE = 10;
  const MIN_POLYGON_POINTS = 3;
  const styleOptions = getStyleOptions(areaType);

  const getPoint = useCallback(
    (e) => {
      const pointer = e.currentPointer;
      return map.screenToGeo(pointer.viewportX, pointer.viewportY);
    },
    [map]
  );

  const getIndex = useCallback(
    () => markerGroup.current.getObjects().length,
    []
  );

  const transformLatLng = (points) => {
    const latLng = [];
    points.forEach((item) => {
      latLng.push(item.lat, item.lng, 0);
    });
    return latLng;
  };

  const getMarker = useCallback(
    (point, icon) => {
      const index = getIndex();
      const vertice = new window.H.map.Marker(
        { lat: point.lat, lng: point.lng },
        { icon, volatility: true }
      );

      vertice.draggable = true;
      vertice.setData({ index });
      markerGroup.current.addObject(vertice);
      return vertice;
    },
    [getIndex]
  );

  const resetMarkers = useCallback(() => {
    const markers = markerGroup.current.getObjects();
    markers.forEach((item) => item.setIcon(styleOptions.icon));
  }, [styleOptions]);

  const createDragPolygon = useCallback(
    (point) => {
      const latLngAlt = polylineLine.current.getLatLngAltArray();
      polygonLine.current = new window.H.geo.LineString([
        ...latLngAlt,
        point.lat,
        point.lng,
        0,
      ]);

      marker.current = getMarker(point, styleOptions.addIcon);

      const geoPolygon = new window.H.geo.Polygon(polygonLine.current);

      let fillColor =
        options && options.style && options.style.fillColor
          ? options.style.fillColor
          : styleOptions.fillColor;

      _polygon.current = new window.H.map.Polygon(geoPolygon, {
        style: {
          fillColor: fillColor,
          lineWidth: 0,
        },
        volatility: true,
      });

      _polygon.current.draggable = true;
      mainGroup.current.addObject(_polygon.current);
    },
    [getMarker, options, styleOptions]
  );

  const createDragPolyline = useCallback(
    (point) => {
      map.getViewPort().element.classList.add('dark');
      polylineLine.current = new window.H.geo.LineString(
        transformLatLng([point, point])
      );
      let strokeColor =
        options && options.style && options.style.strokeColor
          ? options.style.strokeColor
          : styleOptions.strokeColor;
      polyline.current = new window.H.map.Polyline(polylineLine.current, {
        style: {
          strokeColor: strokeColor,
          lineWidth: 2,
        },
        volatility: true,
      });

      mainGroup.current.addObject(polyline.current);
      polyline.current.draggable = true;

      areaMarker.current = getMarker(point, styleOptions.enterIcon);
      marker.current = getMarker(point, styleOptions.addIcon);
    },
    [getMarker, map, options, styleOptions]
  );

  const dispatchGeoJSON = useCallback(() => {
    if (!_polygon.current) return;
    if (onCallback) {
      onCallback(_polygon.current.toGeoJSON());
    }
  }, [onCallback]);

  const mapPointerMoveHandler = useCallback(
    (e) => {
      if (marker.current) {
        const point = getPoint(e);

        marker.current.setGeometry(point);

        if (polylineLine.current) {
          polylineLine.current.removePoint(marker.current?.data?.index);
          polylineLine.current.insertPoint(marker.current?.data?.index, point);
        }

        if (polygonLine.current) {
          polygonLine.current.removePoint(marker.current?.data?.index);
          polygonLine.current.insertPoint(marker.current?.data?.index, point);
        }
        if (polyline.current) {
          polyline.current.setGeometry(polylineLine.current);
        }
        if (_polygon.current)
          _polygon.current.setGeometry(
            new window.H.geo.Polygon(polygonLine.current)
          );

        e.stopPropagation();
      }
    },
    [getPoint]
  );

  const mapPointerDownHandler = useCallback(() => {
    isOnDown.current = true;
  }, []);

  const mapDragHandler = useCallback((e) => {
    isOnDown.current = false;
  }, []);

  const markerDragHandler = useCallback(
    (e) => {
      const lineString = _polygon.current.getGeometry().getExterior();
      const geoPoint = getPoint(e);
      const { index } = e.target.getData();
      e.target.setGeometry(geoPoint);

      lineString.removePoint(index);
      lineString.insertPoint(index, geoPoint);

      _polygon.current.setGeometry(new window.H.geo.Polygon(lineString));

      e.stopPropagation();
    },
    [getPoint]
  );

  const markerDragEndHandler = useCallback(() => {
    dispatchGeoJSON();
  }, [dispatchGeoJSON]);

  const subscribeToMarkerEvents = useCallback(() => {
    if (!markerGroup.current) return;
    if (!isDraw) return;
    markerGroup.current.addEventListener('drag', markerDragHandler, false);
    markerGroup.current.addEventListener(
      'dragend',
      markerDragEndHandler,
      false
    );
  }, [isDraw, markerDragEndHandler, markerDragHandler]);

  const unsubscribeToMarkerEvents = useCallback(() => {
    if (!markerGroup.current) return;
    if (!isDraw) return;

    markerGroup.current.removeEventListener('drag', markerDragHandler, false);
    markerGroup.current.removeEventListener(
      'dragend',
      markerDragEndHandler,
      false
    );
  }, [isDraw, markerDragEndHandler, markerDragHandler]);

  const mainDragStartHandler = useCallback(
    (e) => {
      const target = e.target;
      if (target instanceof window.H.map.Polygon) {
        var pointer = e.currentPointer,
          object = e.target;

        // store the starting geo position
        object.setData({
          startCoord: map.screenToGeo(pointer.viewportX, pointer.viewportY),
        });

        e.stopPropagation();
      }
    },
    [map]
  );

  const mainDragHandler = useCallback(
    (e) => {
      const target = e.target;
      if (target instanceof window.H.map.Polygon) {
        var pointer = e.currentPointer,
          object = e.target,
          startCoord = object.getData()['startCoord'],
          newCoord = map.screenToGeo(pointer.viewportX, pointer.viewportY),
          outOfMapView = false;

        if (!newCoord.equals(startCoord)) {
          var currentLineString = object.getGeometry().getExterior(),
            newLineString = new window.H.geo.LineString();

          // create new LineString with updated coordinates
          currentLineString.eachLatLngAlt(function (lat, lng, alt) {
            var diffLat = lat - startCoord.lat,
              diffLng = lng - startCoord.lng,
              newLat = newCoord.lat + diffLat,
              newLng = newCoord.lng + diffLng;

            // prevent dragging to latitude over 90 or -90 degrees to prevent loosing altitude values
            if (newLat >= 90 || newLat <= -90) {
              outOfMapView = true;
              return;
            }

            newLineString.pushLatLngAlt(newLat, newLng, 0);
          });

          if (!outOfMapView) {
            object.setGeometry(new window.H.geo.Polygon(newLineString));
            object.setData({
              startCoord: newCoord,
            });

            if (!markerGroup.current) return;

            // Recreate vertice marker
            markerGroup.current.removeAll();
            object
              .getGeometry()
              .getExterior()
              .eachLatLngAlt(function (lat, lng, alt, index) {
                getMarker({ lat, lng }, styleOptions.addIcon);
              });
            markerGroup.current.setVisibility(false);
          }
        }
        e.stopPropagation();
      }
    },
    [getMarker, map, styleOptions.addIcon]
  );

  const mainPointerUpHandler = useCallback(
    (e) => {
      if (!markerGroup.current) return;
      markerGroup.current.setVisibility(true);
      dispatchGeoJSON();
    },
    [dispatchGeoJSON]
  );

  // Move polygon by dragging after draw completion
  const subscribeToPolygonEvents = useCallback(() => {
    if (!mainGroup.current) return;
    if (!isDraw) return;
    mainGroup.current.addEventListener(
      'dragstart',
      mainDragStartHandler,
      false
    );
    mainGroup.current.addEventListener('drag', mainDragHandler, false);
    mainGroup.current.addEventListener('dragend', mainPointerUpHandler, false);
  }, [isDraw, mainDragHandler, mainDragStartHandler, mainPointerUpHandler]);

  const unsubscribeToPolygonEvents = useCallback(() => {
    if (!mainGroup.current) return;
    if (!isDraw) return;

    mainGroup.current.removeEventListener(
      'dragstart',
      mainDragStartHandler,
      false
    );
    mainGroup.current.removeEventListener('drag', mainDragHandler, false);
    mainGroup.current.removeEventListener(
      'dragend',
      mainPointerUpHandler,
      false
    );
  }, [isDraw, mainDragHandler, mainDragStartHandler, mainPointerUpHandler]);

  const mapPointerUpHandler = useCallback(
    (e) => {
      if (!isOnDown.current) return;
      if (!markerGroup.current) return;

      const point = getPoint(e);
      const markerCount = getIndex();
      const markers = markerGroup.current.getObjects()?.length;
      const markerIndex = e.target?.data?.index;
      let distance;

      if (startPoint.current) {
        const projection = new window.H.geo.PixelProjection();
        projection.rescale(map.getZoom());

        const pointPixels = projection.geoToPixel(point);
        const startPointPixels = projection.geoToPixel(startPoint.current);
        distance = getDistance(
          { latitude: pointPixels.x, longitude: pointPixels.y },
          { latitude: startPointPixels.x, longitude: startPointPixels.y }
        );
      }

      // Complete polygon, callback result to parent
      if (distance <= MIN_DISTANCE && markerCount >= MIN_POLYGON_POINTS) {
        // const points = polylineLine.current.getLatLngAltArray();
        // const firstPoint = new window.H.geo.Point(points[0], points[1], 0);

        markerGroup.current.removeObject(areaMarker.current);
        // marker.current.setGeometry(firstPoint);
        // marker.current.setData({ index: 0 });
        // marker.current = null;

        mainGroup.current.removeObject(polyline.current);

        let fillColor =
          options && options.style && options.style.fillColor
            ? options.style.fillColor
            : styleOptions.fillColor;
        let strokeColor =
          options && options.style && options.style.strokeColor
            ? options.style.strokeColor
            : styleOptions.strokeColor;
        _polygon.current.setStyle(
          new window.H.map.SpatialStyle({
            fillColor: fillColor,
            strokeColor: strokeColor,
            lineWidth: 2,
          })
        );

        polygonLine.current.removePoint(
          polygonLine.current.getPointCount() - 1
        );
        _polygon.current.setGeometry(
          new window.H.geo.Polygon(polygonLine.current)
        );

        markerGroup.current.addEventListener(
          'pointerdown',
          (e) => {
            if (!marker.current) {
              resetMarkers();
              e.target.setIcon(styleOptions.dragIcon);
            }
          },
          false
        );

        startPoint.current = null;

        dispatchGeoJSON();

        subscribeToMarkerEvents();

        map.removeEventListener('pointerup', mapPointerUpHandler, false);
        map.removeEventListener('pointermove', mapPointerMoveHandler, false);
        map.removeEventListener('pointerdown', mapPointerDownHandler, false);
        map.removeEventListener('drag', mapDragHandler, false);

        subscribeToPolygonEvents();
        return;
      }

      if (markerIndex === undefined) {
        startPoint.current = point;
      }

      if (markers > pointsLimit - 1) {
        // console.log('Point Limit Exceeds');
        return;
      }

      if (markerIndex === 0) return;

      if (!polyline.current && !_polygon.current) {
        createDragPolyline(point);
        return;
      }

      if (markerCount === 2) {
        createDragPolygon(point);
        return;
      }

      if (marker.current) {
        marker.current = getMarker(point, styleOptions.addIcon);
      }

      if (polylineLine.current) {
        polylineLine.current.pushPoint(point);
        polygonLine.current.pushPoint(point);
      }

      e.stopPropagation();
    },
    [
      createDragPolygon,
      createDragPolyline,
      dispatchGeoJSON,
      getIndex,
      getMarker,
      getPoint,
      map,
      mapDragHandler,
      mapPointerDownHandler,
      mapPointerMoveHandler,
      options,
      resetMarkers,
      styleOptions,
      subscribeToMarkerEvents,
      subscribeToPolygonEvents,
    ]
  );

  const subscribeToMapEvents = useCallback(() => {
    map.addEventListener('pointerup', mapPointerUpHandler, false);
    map.addEventListener('pointermove', mapPointerMoveHandler, false);
    map.addEventListener('pointerdown', mapPointerDownHandler, false);
    map.addEventListener('drag', mapDragHandler, false);
  }, [
    map,
    mapPointerUpHandler,
    mapPointerMoveHandler,
    mapPointerDownHandler,
    mapDragHandler,
  ]);

  const unsubscribeToMapEvents = useCallback(() => {
    map.removeEventListener('pointerup', mapPointerUpHandler, false);
    map.removeEventListener('pointermove', mapPointerMoveHandler, false);
    map.removeEventListener('pointerdown', mapPointerDownHandler, false);
    map.removeEventListener('drag', mapDragHandler, false);
  }, [
    map,
    mapPointerUpHandler,
    mapPointerMoveHandler,
    mapPointerDownHandler,
    mapDragHandler,
  ]);

  const createPolygon = useCallback(
    (points) => {
      if (!points) return;
      if (points.length === 0) return;

      let copyPoints = [...points];

      // Convert [{lat,lng}] to [[lng,lat]]
      if (copyPoints[0].lat && copyPoints[0].lng) {
        copyPoints = copyPoints.map((p) => [p.lng, p.lat]);
      }
      // Remove last index if first and last value (lat,lng) are the same
      if (
        copyPoints[0][0] === copyPoints[copyPoints.length - 1][0] &&
        copyPoints[0][1] === copyPoints[copyPoints.length - 1][1]
      ) {
        copyPoints.splice(-1);
      }
      let lineString = new window.H.geo.LineString();
      // Create markers
      copyPoints.forEach((point, index) => {
        if (isDraw) {
          const vertice = new window.H.map.Marker(
            { lat: point[1], lng: point[0] },
            { icon: styleOptions.addIcon, volatility: true }
          );

          vertice.draggable = true;
          vertice.setData({ index });
          markerGroup.current.addObject(vertice);
        }

        lineString.pushPoint({ lat: point[1], lng: point[0] });
      });

      if (isDraw) {
        markerGroup.current.addEventListener(
          'pointerdown',
          (e) => {
            resetMarkers();
            e.target.setIcon(styleOptions.dragIcon);
          },
          false
        );
      }

      // Create polygon
      let fillColor =
        options && options.style && options.style.fillColor
          ? options.style.fillColor
          : styleOptions.fillColor;
      let strokeColor =
        options && options.style && options.style.strokeColor
          ? options.style.strokeColor
          : styleOptions.strokeColor;
      _polygon.current = new window.H.map.Polygon(lineString, {
        style: {
          fillColor: fillColor,
          strokeColor: strokeColor,
          lineWidth: 2,
        },
        volatility: true,
      });
      _polygon.current.draggable = true;
      mainGroup.current.addObject(_polygon.current);

      // Events
      subscribeToMarkerEvents();
      subscribeToPolygonEvents();
    },
    [
      isDraw,
      options,
      resetMarkers,
      styleOptions,
      subscribeToMarkerEvents,
      subscribeToPolygonEvents,
    ]
  );

  // Unsubscribe event
  useEffect(() => {
    if (!markerGroup.current && !mainGroup.current) {
      markerGroup.current = new window.H.map.Group({
        visibility: true,
      });
      mainGroup.current = new window.H.map.Group({ volatility: true });
      mainGroup.current.addObject(markerGroup.current);

      map.addObject(mainGroup.current);

      if (polygon && polygon.length >= MIN_POLYGON_POINTS) {
        createPolygon(polygon);
      }
      if (isDraw) {
        subscribeToMapEvents();
      }
    }

    const dispose = () => {
      unsubscribeToMarkerEvents();
      unsubscribeToPolygonEvents();

      polylineLine.current = null;
      polygonLine.current = null;
      _polygon.current = null;
      polyline.current = null;
      marker.current = null;
      startPoint.current = null;
      areaMarker.current = null;
      markerGroup.current = null;
      mainGroup.current = null;

      unsubscribeToMapEvents();
    };
    disposeFunctions.current.push(dispose);
  }, [
    map,
    polygon,
    disposeFunctions,
    subscribeToMapEvents,
    unsubscribeToMapEvents,
    unsubscribeToMarkerEvents,
    unsubscribeToPolygonEvents,
    styleOptions,
    subscribeToPolygonEvents,
    subscribeToMarkerEvents,
    createPolygon,
    isDraw,
  ]);

  const clear = () => {
    polylineLine.current = null;
    polygonLine.current = null;
    _polygon.current = null;
    polyline.current = null;
    marker.current = null;
    startPoint.current = null;
    areaMarker.current = null;

    markerGroup.current = null;
    mainGroup.current = null;

    subscribeToMapEvents();
    unsubscribeToMarkerEvents();
    unsubscribeToPolygonEvents();

    if (onCallback) {
      onCallback({});
    }
  };

  if (!markerGroup.current || !isDraw) {
    return <div style={{ display: 'none' }} />;
  } else {
    return <DeleteButton onDeleteClick={clear} />;
  }
}

export default DrawPolygon;
