import React, { useEffect, useState } from 'react';
import Configs from 'config/config';
import { useSelector } from 'react-redux';
import Mapview, {
  HMapPlaceSearch,
  HMapDrawCircle,
  HMapDrawMarker,
  HMapPolyline,
  HMapInfoWindow,
  HMapDrawPolygon,
  HMapRoute,
} from 'Components/HereMap';
import {
  markerPinNumberSvgIcon,
  truckIcon,
  hazardIcon,
} from 'Components/Common/MarkerIconRenderer';

import { getBbox } from 'Components/HereMap/libs/geoLib';
import Loading from 'Components/HereMap/Components/Loading';
import { encode } from '@liberty-rider/flexpolyline';
import ImgVdoPreview from '../HazardPoints/ImgVdoPreview';

const env = process.env.NODE_ENV;

const RouteSuggestionMap = (props) => {
  const { mapRef } = props;
  const [selectedHazardId, setSelectedHazardId] = useState(null);
  const [selectedPolylines, setSelectedPolylines] = useState([]);
  const [bbox, setBbox] = useState(null);

  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });
  const harzardPoints = useSelector((state) => {
    return state.map.markerList;
  });

  const hazardCircleList = useSelector((state) => {
    return state.map.circleList;
  });

  const hazardPolygonList = useSelector((state) => {
    return state.map.polygonList;
  });

  const suggestionPolylineList = useSelector((state) => {
    return state.map.suggestionPolylineList;
  });

  const markerOrigin = useSelector((state) => {
    return state.map.marker_origin;
  });

  const markerDesc = useSelector((state) => {
    return state.map.marker_desc;
  });

  useEffect(() => {
    if (!suggestionPolylineList || suggestionPolylineList.length === 0) {
      setSelectedPolylines([]);
      return;
    }

    setSelectedPolylines(suggestionPolylineList);
  }, [suggestionPolylineList]);

  useEffect(() => {
    let bounds = [];
    if (!suggestionPolylineList || suggestionPolylineList.length === 0) {
      return;
    }

    let selectedPolyline = suggestionPolylineList[0];
    if (
      selectedPolyline &&
      selectedPolyline.path &&
      selectedPolyline.path.length > 0
    ) {
      bounds.push([selectedPolyline.path[0][0], selectedPolyline.path[0][1]]);

      if (selectedPolyline.path.length > 1) {
        bounds.push([
          selectedPolyline.path[selectedPolyline.path.length - 1][0],
          selectedPolyline.path[selectedPolyline.path.length - 1][1],
        ]);
      }
    }

    if (bounds.length > 0) {
      setBbox(getBbox(bounds));
    }
  }, [suggestionPolylineList]);

  const renderHazardPolygon = (p) => {
    if (!p.id) return null;
    return (
      <HMapDrawPolygon
        polygon={p.path}
        options={{
          style: {
            fillColor: 'rgba(193, 36, 0, 0.4)',
            strokeColor: '#e06666',
          },
        }}
      />
    );
  };
  const renderHazardCircle = (c, idx) => {
    return (
      <HMapDrawCircle
        key={`hazard-circle-${idx}`}
        circle={{
          center: { lat: c.center.lat, lng: c.center.lng },
          radius: c.radius,
        }}
        options={{
          style: { fillColor: 'rgba(193, 36, 0, 0.4)', strokeColor: '#e06666' },
        }}
      />
    );
  };
  const renderHazardPoint = (m, idx) => {
    let isOpen = selectedHazardId === m.id;
    let video = null;
    let photo = [];

    if (isOpen) {
      if (m && m.mediaUrl) {
        m.mediaUrl.forEach(function (row) {
          if (row !== null) {
            let files = row.split('.');
            if (files[files.length - 1].toLowerCase().includes('mp4'))
              video = row;
            else photo.push(row);
          }
        });
      }
    }
    const selectedMarkerFunction = (d) => {
      return {
        pointerdown: (_) => {
          setSelectedHazardId(d.id);
        },
      };
    };
    return (
      <HMapDrawMarker
        key={`hazard-${idx}`}
        marker={{ lat: m.latitude, lng: m.longitude }}
        options={{
          zIndex: 100,
          icon: hazardIcon(),
          size: { w: 40, h: 40 },
        }}
        objectEvents={selectedMarkerFunction(m)}
      >
        <HMapInfoWindow
          size={
            video || photo.length > 0
              ? { width: 430, height: 320 }
              : { width: 380, height: 260 }
          }
          isOpen={isOpen}
          onClose={() => {
            setSelectedHazardId(null);
          }}
        >
          <ImgVdoPreview
            inputPhotos={photo}
            inputVdo={video}
            info={{
              name: m?.warning || '',
              description: m?.detail || '',
            }}
          />
        </HMapInfoWindow>
      </HMapDrawMarker>
    );
  };
  return (
    <>
      {map_loading && <Loading />}
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={bbox}
      >
        {selectedPolylines &&
          selectedPolylines.map((p, idx) => {
            if (p.path.length === 0) {
              return null;
            }
            let polyline = encode({
              polyline: p.path,
            });

            return [<HMapPolyline key={`leg-${idx}`} polyline={polyline} />];
          })}

        {harzardPoints.map((p, idx) => {
          return renderHazardPoint(p, idx);
        })}
        {hazardCircleList.map((c, idx) => {
          return renderHazardCircle(c, idx);
        })}
        {hazardPolygonList.map((p, idx) => {
          return renderHazardPolygon(p, idx);
        })}

        {markerOrigin && markerOrigin.lat && markerOrigin.lng && (
          <HMapDrawMarker
            marker={markerOrigin}
            options={{
              icon: markerPinNumberSvgIcon('A', 30, 40, '#4c00b0', '#ffffff'),
            }}
          />
        )}
        {markerDesc && markerDesc.lat && markerDesc.lng && (
          <HMapDrawMarker
            marker={markerDesc}
            options={{
              icon: markerPinNumberSvgIcon('B', 30, 40, '#ff0000', '#ffffff'),
            }}
          />
        )}
        <HMapPlaceSearch />
      </Mapview>
    </>
  );
};
export default RouteSuggestionMap;
