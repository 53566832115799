import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getRouteCode,
  getRunSeq,
  GET_IMPACT_HAZARDS,
  GET_SELECTED_HAZARDS,
  GET_IMPACT_ROUTES,
} from '../reducers/map/api';
import {
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons,
  mapSetBounds,
  mapSetImpactHazard,
  mapSetImpactRoute,
  mapSetHazardSelectList,
  mapSetRouteCode,
  mapSetRunSeq,
  SET_MAP_LOADING,
} from '../reducers/map';

import { SET_SNACKBAR } from '../reducers/ui';

import axios from 'axios';
import Configs from '../config/config';
const env = process.env.NODE_ENV;
const gUtils = require('@googlemaps/google-maps-services-js/dist/util');

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  // yield takeLatest(getHistoryLogs, hazardHistoryLogWorkerSaga);
  yield takeLatest(GET_IMPACT_HAZARDS, impactHazardWorkerSaga);
  yield takeLatest(GET_IMPACT_ROUTES, impactHazardWorkerSaga);
  yield takeLatest(GET_SELECTED_HAZARDS, impactHazardWorkerSaga);
  yield takeLatest(getRouteCode, dataListWorkerSaga);
  yield takeLatest(getRunSeq, dataListWorkerSaga);
}

function paramsUrl(data) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function fetchDataGet(params) {
  //http://mapssh.leafte.ch/managementTest
  let url = '';
  if (params.payload.filter === null) url = params.payload.url;
  else url = params.payload.url + '?' + paramsUrl(params.payload.filter);
  // let callback = params.payload.callback;
  // let callback_error = params.payload.callback_error;
  // let callback_errorList = params.payload.callback_errorList;
  let headers = {
    Accept: 'application/json',
    // 'Accept-Encoding' : 'gzip',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem('token'),
  };
  // console.log('#fetchDataGet url',url);
  return axios
    .get(url, { headers: headers, timeout: 60000 })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return { catchError: error };
    });
}
// worker saga: makes the api call when watcher saga sees the action
function* dataListWorkerSaga(action) {
  try {
    const response = yield call(fetchDataGet, action);
    // console.log('#dataListWorkerSaga response',response);
    if (action.type === getRouteCode.toString()) {
      yield put({ type: mapSetRouteCode, payload: response.data.list[0] });
    } else if (action.type === getRunSeq.toString()) {
      yield put({ type: mapSetRunSeq, payload: response.data.list[0] });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    //yield put({ type: mapAction.authLoginError, error });
  }
}

function* impactHazardWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });
    const response = yield call(fetchDataGet, action);
    // console.log('#impactHazardWorkerSaga response',response);
    let params = action;
    let callback = params.payload.callback;
    let callback_error = params.payload.callback_error;

    yield put({ type: SET_MAP_LOADING, payload: false });

    if (response.hasOwnProperty('catchError')) {
      if (callback_error) callback_error(response.catchError.toString());
      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: response.catchError.toString(),
          snackbarDuration: 20000,
        },
      });
      return false;
    }

    if (parseInt(response.status) < 200 || parseInt(response.status) > 200) {
      if (callback_error)
        callback_error('HTTP ERROR : status code = ' + response.status);
      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: 'HTTP ERROR : status code = ' + response.status,
          snackbarDuration: 20000,
        },
      });
      return false;
    }

    if (response.data.hasOwnProperty('errorList')) {
      let errorShow = '';
      errorShow = response.data.errorList[0].errorMessage;
      console.info('errorDetail', response.data.errorList[0].errorDetail);

      if (callback_error) callback_error(errorShow);
      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: errorShow,
          snackbarDuration: 20000,
        },
      });

      return false;
    }

    if (
      response.data.hasOwnProperty('list') ||
      response.data.hasOwnProperty('result')
    ) {
      if (callback) callback(response.data);

      // let polylines = setPolylines(response.data.list,false);
      let markers = setMarkers_array(response.data.list, false);
      let circles = setCircles_array(response.data.list, false);
      let polygons = setPolygons_array(response.data.list, false);
      let bounds = setBounds_array(response.data.list);

      if (action.type === GET_IMPACT_HAZARDS.toString()) {
        yield put({ type: mapSetImpactHazard, payload: response.data.list });
        yield put({ type: mapSetBounds, payload: bounds });
      } else if (action.type === GET_IMPACT_ROUTES.toString()) {
        yield put({ type: mapSetImpactRoute, payload: response.data.list });
        yield put({ type: mapSetMarkers, payload: markers });
        yield put({ type: mapSetCircles, payload: circles });
        yield put({ type: mapSetPolygons, payload: polygons });
      } else if (action.type === GET_SELECTED_HAZARDS.toString()) {
        yield put({ type: mapSetMarkers, payload: markers });
        yield put({
          type: mapSetHazardSelectList,
          payload: response.data.list,
        });
        yield put({ type: mapSetCircles, payload: circles });
        yield put({ type: mapSetPolygons, payload: polygons });
      }

      return true;
    }

    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'API error',
        snackbarDuration: 20000,
      },
    });

    return false;
  } catch (error) {
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

function setMarkers_array(data, editable) {
  let markerList = [];
  let marker = {};

  // routeCode: "KC09"
  // routes: null
  // runSeq: "01"

  data.forEach((route, i) => {
    if (route.hazard) {
      route.hazard.forEach((point) => {
        //Marker
        marker = {
          ...point,
          routeCode: route.routeCode,
          hid: point.id,
          id: i + '_' + point.id,
          position: { lat: point.latitude, lng: point.longitude },
          info: {
            name: point.location,
            description: point.detail,
            logisticsPoint: point.category,
          },
          isGroup: false,
          category: point.category,
          editable: editable,
        };
        //markerList.push(marker);
        markerList.push(marker);
      });
    }
  });
  return markerList;
}

function setCircles_array(data, editable) {
  let circleList = [];
  let circle = {};
  data.forEach((route, i) => {
    if (route.hazard) {
      route.hazard.forEach((point) => {
        //Circle
        if (point.type === 'circle') {
          circle = {
            id: i + '_' + point.id,
            center: { lat: point.circleLatitude, lng: point.circleLongitude },
            radius: point.radius,
            info: {
              name: point.location,
              description: point.detail,
              logisticsPoint: point.category,
            },
            isGroup: false,
            editable: editable,
          };
          circleList.push(circle);
        }
      });
    }
  });

  return circleList;
}

function setBounds_array(data) {
  let bounds = [];
  data.forEach((route) => {
    if (route.hazard) {
      route.hazard.forEach((point) => {
        bounds.push({ lat: point.latitude, lng: point.longitude });
        if (point.type === 'circle') {
          bounds.push({
            lat: point.circleLatitude,
            lng: point.circleLongitude,
          });
        } else if (point.type === 'polygon') {
          var decodePath = gUtils.decodePath(point.polygon);
          decodePath.forEach(function (p) {
            bounds.push({ lat: p.lat(), lng: p.lng() });
          });
        }
      });
    }
    // if(route.routes){
    //     var decodePath = gUtils.decodePath(decodeURIComponent(route.routes));
    //     decodePath.forEach(function(p) {
    //       bounds.push({ lat: p.lat(), lng: p.lng() });
    //     });
    // }
  });
  return bounds;
}

function setPolygons_array(data, editable) {
  let polygonList = [];
  let polygon = {};
  data.forEach((route) => {
    if (route.hazard) {
      route.hazard.forEach((point) => {
        var decodePath = gUtils.decodePath(point.polygon);
        var paths = [];
        decodePath.forEach(function (p) {
          paths.push({ lat: p.lat(), lng: p.lng() });
        });
        polygon = {
          id: point.routeCode,
          path: decodePath,
          // info : {
          //   name : point.location,
          //   description : point.detail,
          //   logisticsPoint : point.category ,
          // },
          isGroup: false,
          editable: editable,
        };
        //polygonList.push(polygon);
        polygonList.push(polygon);
      });
    }
  });
  return polygonList;
}
