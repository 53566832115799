import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import RouteForm from './RouteForm';
import HistoryLogForm from './HistoryLogForm';
import HazardForm from './HazardForm';
import SelectedHazardForm from './SelectedHazardForm';
//Reducer
import { mapResetMap } from '../../reducers/map';
import { searchHazardRoute } from '../../reducers/search';
import { selectImpactHazard } from '../../reducers/ui/selectList';
import { uiActiveTabImpact } from '../../reducers/ui';
import {
  getHistoryLogs,
  getRouteCode,
  GET_IMPACT_HAZARDS,
  GET_IMPACT_ROUTES,
} from '../../reducers/map/api';
//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

function TabContainer({ children, dir }) {
  return (
    <Typography component='div' dir={dir} style={{ padding: 0 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
  },
});

class MenuTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.firstOpen ? 1 : 0,
      firstOpen: props.firstOpen,
    };
  }
  componentDidMount() {}
  componentWillReceiveProps(nextProps, props) {}

  renderMenuImpact = () => {
    //return (<SelectedHazardForm/>);
    if (this.props.selected_impact && this.state.value === 0) {
      return <SelectedHazardForm />;
    } else {
      this.props.selectImpactHazard(null);
      return <HazardForm />;
    }
  };
  handleChangeTabs = (event, value) => {
    this.props.mapResetMap(true);

    this.setState({ value });
    this.props.uiActiveTabImpact(value);
    //console.log('#handleChangeTabs value',value);
    if (value === 1) {
      this.setState({ firstOpen: 1 });
      this.props.selectImpactHazard(null);
      //Load Route Code
      this.props.getRouteCode({
        url: Configs[env].BACKEND_HOST + '/impactHazardFindRoute',
        filter: {},
      });
      //Load Route
      /*let filter = this.props.search_hazard_route;
      this.props.GET_IMPACT_ROUTES({
        url: Configs[env].BACKEND_HOST + "/impactHazardRoute",
        filter: filter
      });*/
    } else if (value === 2) {
      this.props.selectImpactHazard(null);

      let filter = { ...this.props.search_hazard_logs };
      if (filter.category && filter.category === 'all') delete filter.category;
      this.props.getHistoryLogs({
        url: Configs[env].BACKEND_HOST + '/impactHazardHistoryCond',
        filter: filter,
      });
    } else {
      let filter = { ...this.props.search_hazard_impact };
      if (filter.category && filter.category === 'all') delete filter.category;
      this.props.GET_IMPACT_HAZARDS({
        url: Configs[env].BACKEND_HOST + '/impactHazardCond',
        filter: filter,
      });
    }
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };
  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position='static' color='default'>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTabs}
            indicatorColor='primary'
            textColor='primary'
          >
            <Tab label='Hazard' />
            <Tab label='Route' />
            <Tab label='History Logs' />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.state.value}
          // index={2}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>
            {this.renderMenuImpact()}
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <RouteForm firstOpen={this.state.firstOpen} />
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <HistoryLogForm />
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

MenuTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  formManage: state.ui.formManage,
  search_hazard_logs: state.search.search_hazard_logs,
  search_hazard_impact: state.search.search_hazard_impact,
  search_hazard_route: state.search.search_hazard_route,
  selected_impact: state.selection.selected_impact,
});

const mapActionsToProps = {
  mapResetMap,
  GET_IMPACT_HAZARDS,
  GET_IMPACT_ROUTES,
  getHistoryLogs,
  getRouteCode,
  searchHazardRoute,
  selectImpactHazard,
  uiActiveTabImpact,
};
export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles, { withTheme: true })
)(MenuTabs);
