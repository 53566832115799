import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

// import EditIcon from '@material-ui/icons/Create';
//Reducer
import { LOAD_MODIFIED_ROUTE_SUCCESS } from '../../../reducers/map/';
import { uiSwitchForm } from '../../../reducers/ui';
import { apiCallRequest } from '../../../reducers/api';
import { getMasterRoutes } from '../../../reducers/map/api';
import { formMasterRoute } from '../../../reducers/ui/mapForm';
import { MAP_SELECT_CANDIDATE } from 'reducers/map';

//Config
import Configs from '../../../config/config';
const env = process.env.NODE_ENV;

const styles = () => ({
  gridButton: {
    padding: '0px 0px',
    minWidth: '50px',
    fontSize: '0.8em',
    minHeight: '20px',
  },
  iconStyle: {
    padding: 0,
  },
  center: {
    position: 'relative',
    left: '20px',
  },
});

class MasterRouteRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      name: this.props.data.name,
    };
    this.invokeParentMethodDelete = this.invokeParentMethodDelete.bind(this);
    this.invokeParentMethodEdit = this.invokeParentMethodEdit.bind(this);
  }

  i;
  handleConfirm() {
    var id = this.props.data.id;
    this.props.API_REQUEST({
      url: Configs[env].BACKEND_HOST + '/route/' + id,
      method: 'DELETE',
      filter: {},
      callback: this.handleComplete,
    });
  }
  handleComplete = () => {
    this.props.GET_MASTER_ROUTE({
      url: Configs[env].BACKEND_HOST + '/routeCond',
      filter: {
        logisticsRouteId: this.props.logisticRouteForm.logisticsRouteId,
      },
    });
    this.props.MAP_SELECT_CANDIDATE(-1);

    this.handleCancel();
  };
  handleCancel = () => {
    this.setState({ open: false });
  };
  invokeParentMethodDelete() {
    this.setState({ open: true });
  }

  setLegs(data, clickable) {
    let candidatePolylineList = [];
    let polyline = {};
    if (data) {
      data.forEach((item) => {
        //if(item.type=='polyline'){
        var decodePath = window.google.maps.geometry.encoding.decodePath(
          decodeURIComponent(item.Route)
        );
        var paths = [];
        decodePath.forEach(function (p) {
          paths.push({ lat: p.lat(), lng: p.lng() });
        });
        polyline = {
          id: item.Seq,
          path: decodePath,
          info: {
            name: item.Seq,
            description: '',
            logisticsPoint: '',
            clickable: clickable,
          },
        };
        //candidatePolylineList.push(polyline);
        candidatePolylineList.push(polyline);
        //}
      });
      return candidatePolylineList;
    }
  }

  invokeParentMethodEdit() {
    this.props.FORM_ROUTEMASTER(this.props.data);
    this.props.LOAD_MODIFIED_ROUTE_SUCCESS({
      modified_route: this.props.data.routes,
      legs: this.setLegs(this.props.data.routes.Legs),
      TollUsage: this.props.data.routes.TollUsage,
    });
    this.props.SWITCH_FORM({
      page: 'master_route',
      mode: 'edit',
      id: this.props.data.id,
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <span>
        <IconButton
          className={classes.iconStyle}
          onClick={this.invokeParentMethodEdit}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          className={classes.iconStyle}
          onClick={this.invokeParentMethodDelete}
        >
          <DeleteIcon />
        </IconButton>
        <Dialog
          open={this.state.open}
          //TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            Delete Route Master
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Confirm to delete {this.state.name}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleConfirm.bind(this)}
              disabled={this.props.isLoading}
              variant='contained'
              color='secondary'
            >
              {this.props.isLoading && (
                <CircularProgress
                  className={classes.center}
                  size={25}
                  thickness={2}
                />
              )}
              Confirm
            </Button>
            <Button
              onClick={this.handleCancel.bind(this)}
              disabled={this.props.isLoading}
              color='primary'
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
    isLoading: state.ui.isLoading,
    logisticRouteForm: state.mapForm.logisticRouteForm,
  };
};

const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  SWITCH_FORM: uiSwitchForm,
  FORM_ROUTEMASTER: formMasterRoute,
  GET_MASTER_ROUTE: getMasterRoutes,
  MAP_SELECT_CANDIDATE,
  LOAD_MODIFIED_ROUTE_SUCCESS,
};
const enhance = compose(
  connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true }),
  withStyles(styles)
);

export default enhance(MasterRouteRenderer);
