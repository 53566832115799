/* eslint-disable */

import point from 'assets/icons/point.svg';
import pointRestricted from 'assets/icons/point-restricted.svg';
import pointSpeeding from 'assets/icons/point-speeding.svg';
import enterPoint from 'assets/icons/enter-point.svg';
import enterPointRestricted from 'assets/icons/enter-point-restricted.svg';
import enterPointSpeeding from 'assets/icons/enter-point-speeding.svg';
import dragPoint from 'assets/icons/drag-point.svg';
import dragPointRestricted from 'assets/icons/drag-point-restricted.svg';
import dragPointSpeeding from 'assets/icons/drag-point-speeding.svg';
import addPoint from 'assets/icons/add-point.svg';
import addPointRestricted from 'assets/icons/add-point-restricted.svg';
import addPointSpeeding from 'assets/icons/add-point-speeding.svg';
import { AREA_TYPES } from './constants';

const ICON_OPTIONS = { anchor: { x: 12, y: 12 }, size: { w: 24, h: 24 } };
const ENTER_ICON_OPTIONS = { anchor: { x: 19, y: 19 }, size: { w: 38, h: 38 } };

export const getStyleColors = (areaType) => {
  switch (areaType) {
    case AREA_TYPES.SPEED_LIMIT:
      return {
        strokeColor: 'rgb(121, 170, 240)',
        fillColor: 'rgba(121, 170, 240, 0.3)',
      };
    case AREA_TYPES.RESTRICTED:
      return {
        strokeColor: 'rgb(195, 38, 65)',
        fillColor: 'rgba(195, 38, 65, 0.3)',
      };
    default:
      return {
        strokeColor: 'rgb(0, 172, 114)',
        fillColor: 'rgba(0, 172, 114, 0.3)',
      };
  }
};

const getStyleOptions = (areaType) => {
  const { H } = window;

  switch (areaType) {
    case AREA_TYPES.SPEED_LIMIT:
      return {
        strokeColor: 'rgb(121, 170, 240)',
        fillColor: 'rgba(121, 170, 240, 0.3)',
        icon: new H.map.Icon(pointSpeeding, ICON_OPTIONS),
        enterIcon: new H.map.Icon(enterPointSpeeding, ENTER_ICON_OPTIONS),
        dragIcon: new H.map.Icon(dragPointSpeeding, ICON_OPTIONS),
        addIcon: new H.map.Icon(addPointSpeeding, ICON_OPTIONS),
      };
    case AREA_TYPES.RESTRICTED:
      return {
        strokeColor: 'rgb(195, 38, 65)',
        fillColor: 'rgba(195, 38, 65, 0.3)',
        icon: new H.map.Icon(pointRestricted, ICON_OPTIONS),
        enterIcon: new H.map.Icon(enterPointRestricted, ENTER_ICON_OPTIONS),
        dragIcon: new H.map.Icon(dragPointRestricted, ICON_OPTIONS),
        addIcon: new H.map.Icon(addPointRestricted, ICON_OPTIONS),
      };
    default:
      return {
        strokeColor: 'rgb(0, 172, 114)',
        fillColor: 'rgba(0, 172, 114, 0.3)',
        icon: new H.map.Icon(point, ICON_OPTIONS),
        enterIcon: new H.map.Icon(enterPoint, ENTER_ICON_OPTIONS),
        dragIcon: new H.map.Icon(dragPoint, ICON_OPTIONS),
        addIcon: new H.map.Icon(addPoint, ICON_OPTIONS),
      };
  }
};

export default getStyleOptions;
