import React, { Component } from 'react';
import {
  compose,
  withProps,
  withState,
  withHandlers,
  withStateHandlers,
} from 'recompose';
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  Circle,
  Polyline,
  Polygon,
  InfoWindow,
} from 'react-google-maps';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
//Component
import LocationMaster from './LocationMaster';
import HazardPoints from './HazardPoints';
import HistoryPoint from './ImpactHazard/HistoryPoint';
import RouteMaster from './RouteMaster';
import RouteSuggestMap from './RouteSuggestMap';
import DrawingMap from './DrawingMap';
import SearchPlace from './SearchPlace';
import MapLoadingBox from './MapLoadingBox';

import uuidv4 from 'uuid/v4';
import {
  CLEAR_SELECTING,
  mapZoomChanged,
  mapCenterChanged,
} from '../../reducers/map';

import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const {
  SearchBox,
} = require('react-google-maps/lib/components/places/SearchBox');

class BaseMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      center: this.props.center,
      searchbox: null,
      position: {
        lat: 13.95,
        lng: 101.33,
      },
    };
  }

  setBound = (bounds) => {
    try {
      const latLngBounds = new window.google.maps.LatLngBounds();
      let countBounds = 0;
      bounds.forEach((bound, i) => {
        if (bound.lat && bound.lng) {
          latLngBounds.extend(
            new window.google.maps.LatLng(bound.lat, bound.lng)
          );
          countBounds++;
        }
      });
      if (countBounds > 0) this.map.fitBounds(latLngBounds);
    } catch (e) {}
  };

  componentDidMount() {
    if (this.props.bounds && this.props.bounds.length > 0) {
      this.setBound(this.props.bounds);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.bounds && prevProps.bounds !== this.props.bounds) {
      this.setBound(this.props.bounds);
    }
    if (this.props.center && prevProps.center !== this.props.center) {
      this.map.panTo(this.props.center);
    }
    // if(prevProps.zoom!=this.props.zoom){
    //   console.log("Kai PanTo",this.props.center)
    // }
  }

  // componentWillReceiveProps(nextProps, props) {
  //   if(nextProps.bounds!=this.props.bounds){
  //     this.setBound(nextProps.bounds)
  //   }
  // }
  onMapClick = (e) => {
    this.props.CLEAR_SELECTING();
  };

  onBoundsChanged = (e) => {
    if (this.props.boundsIsSet && this.map.getZoom() > 17) {
      this.props.mapZoomChanged(this.map.getZoom());
      this.props.mapZoomChanged(18);
    }
  };

  // onZoomChanged = (e) => {
  //   setTimeout(()=>{
  //     if(this.map.getZoom() !== this.props.zoom)
  //       this.props.mapZoomChanged(this.map.getZoom())
  //   },1000)

  // }

  render() {
    return (
      <GoogleMap
        options={{ gestureHandling: 'greedy' }}
        // defaultZoom={5}
        zoom={this.props.zoom ? this.props.zoom : 11}
        center={this.props.center}
        // onCenterChanged={this.onCenterChanged}
        // onZoomChanged={this.onZoomChanged}
        ref={(input) => (this.map = input)}
        onClick={this.onMapClick}
        onBoundsChanged={this.onBoundsChanged}
      >
        {window.location.href.search('/location_master') > 0 && (
          <LocationMaster key={uuidv4()} />
        )}
        {window.location.href.search('/hazard_point') > 0 && (
          <HazardPoints key={uuidv4()} />
        )}
        {window.location.href.search('/route_master') > 0 && (
          <RouteMaster key={uuidv4()} />
        )}
        {(window.location.href.search('/route_suggestion') > 0 ||
          window.location.href.search('/tracking') > 0) && (
          <RouteSuggestMap key={uuidv4()} />
        )}
        {window.location.href.search('/impact_hazard') > 0 && (
          <HistoryPoint key={uuidv4()} />
        )}
        {
          window.location.href.search('/location_master') > 0 &&
            this.props.drawingMode && <DrawingMap key={uuidv4()} />
          //this.props.link_path=='/location_master'
        }
        {
          window.location.href.search('/hazard_point') > 0 &&
            this.props.drawingMode && <DrawingMap key={uuidv4()} />
          //this.props.link_path=='/location_master'
        }
        {/* <SearchPlace key={uuidv4()}/> */}

        {this.props.map_loading && <MapLoadingBox />}
      </GoogleMap>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    drawingMode: state.map.drawingMode,
    center: state.map.center,
    bounds: state.map.bounds,
    boundsIsSet: state.map.boundsIsSet,
    zoom: state.map.zoom,
    link_path: state.ui.activeMenuListItem.subLink,
    map_loading: state.map.map_loading,
  };
};

const mapActionsToProps = {
  CLEAR_SELECTING: CLEAR_SELECTING,
  mapZoomChanged: mapZoomChanged,
  mapCenterChanged,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(
  compose(
    //connect(mapStateToProps),
    withProps({
      googleMapURL:
        'https://maps.googleapis.com/maps/api/js?key=' +
        Configs[env].MAP_KEY +
        '&v=3&libraries=geometry,drawing,places',
      loadingElement: <div style={{ height: '100%' }} />,
      containerElement: <div style={{ height: '100%' }} />,
      mapElement: <div style={{ height: '100%' }} />,
    }),
    withScriptjs,
    withGoogleMap
  )(BaseMap)
);
