import React, { useEffect, useMemo, useState } from 'react';
import Configs from 'config/config';
import { useSelector, useDispatch } from 'react-redux';
import Mapview, { HMapPlaceSearch, HMapDrawMarker } from 'Components/HereMap';
import { mapSetMarkers } from 'reducers/map';
import { markerPinSvgIcon } from 'Components/Common/MarkerIconRenderer';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import Loading from 'Components/HereMap/Components/Loading';

const env = process.env.NODE_ENV;

const LocationMasterMap = (props) => {
  const { mapRef } = props;
  const [bbox, setBbox] = useState(null);
  const [isSetBound, setIsSetBound] = useState(true);
  const dispatch = useDispatch();

  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });

  const markerList = useSelector((state) => {
    return state.map.markerList;
  });

  let marker = useMemo(() => {
    if (
      markerList &&
      markerList.length > 0 &&
      markerList[0].position &&
      markerList[0].position.lat &&
      markerList[0].position.lng
    ) {
      return markerList[0].position;
    } else {
      return null;
    }
  }, [markerList]);

  useEffect(() => {
    if (marker) {
      setBbox(getBbox([[marker.lat, marker.lng]]));
    }
  }, [marker]);

  const mapPageGroup = () => {
    return (
      <HMapDrawMarker
        marker={marker}
        isDraw={true}
        draggable={true}
        options={{
          icon: markerPinSvgIcon(30, 40, '#019875', '#ffffff'),
        }}
        onCallback={(d) => {
          const markerList = [
            {
              position: d,
              isGroup: true,
            },
          ];
          dispatch(mapSetMarkers(markerList));

          setIsSetBound(false);
        }}
      />
    );
  };
  return (
    <>
      {map_loading && <Loading />}
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={isSetBound && bbox}
      >
        {mapPageGroup()}

        <HMapPlaceSearch />
      </Mapview>
    </>
  );
};
export default LocationMasterMap;
