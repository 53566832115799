import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import { Field } from 'redux-form';
import { required } from '../../../libs/validation';
import { selectCategory } from '../../../reducers/ui/selectList';
import {
  searchHazardLogs,
  searchHazardImpact,
  searchHazardRoute,
} from '../../../reducers/search';
const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});
const renderSelect = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  value,
  defaultValue,
  ...props
}) => (
  <Select
    error={!!(touched && error)}
    helperText={touched && error}
    defaultValue={defaultValue}
    value={value}
    {...inputProps}
    {...props}
  />
);
class CategorySelect extends Component {
  constructor(props) {
    super(props);
    this.menuItem = [
      {
        value: 'share',
        path: '/img/_hz_share.png',
        icon: '🚫 ',
        text: 'Share Information',
      },
      {
        value: 'warning',
        path: '/img/_hz_warning.png',
        icon: '❗',
        text: 'Warning',
      },
      {
        value: 'prohibit',
        path: '/img/_hz_prohibit.png',
        icon: '⚠️ ',
        text: 'Prohibit Area',
      },
    ];
    if (props.isSearch !== undefined && props.isSearch === true) {
      this.menuItem = [
        { value: 'all', path: '', icon: '', text: 'All Category' },
        {
          value: 'share',
          path: '/img/_hz_share.png',
          icon: '🚫 ',
          text: 'Share Information',
        },
        {
          value: 'warning',
          path: '/img/_hz_warning.png',
          icon: '❗',
          text: 'Warning',
        },
        {
          value: 'prohibit',
          path: '/img/_hz_prohibit.png',
          icon: '⚠️ ',
          text: 'Prohibit Area',
        },
      ];
    }
  }
  handleChange = (event) => {
    //this.props.selectCategory({text:"",value:event.target.value})
    // console.log('#handleChange formImpact',this.props.formImpact);
    if (this.props.formImpact === 'history') {
      this.props.searchHazardLogs({
        ...this.props.search_hazard_logs,
        category: event.target.value,
      });
    } else if (this.props.formImpact === 'impact') {
      this.props.searchHazardImpact({
        ...this.props.search_hazard_impact,
        category: event.target.value,
      });
    }
  };
  renderMenuItem() {
    return this.menuItem.map(function (data, index) {
      return (
        <MenuItem key={index} value={data.value}>
          {data.path != '' ? (
            <img
              alt={data.text}
              src={data.path}
              style={{ width: 20, height: 20 }}
            />
          ) : (
            ''
          )}{' '}
          {data.text}
        </MenuItem>
      );
    });
  }
  render() {
    return (
      <FormControl required fullWidth>
        <InputLabel shrink htmlFor='category-required'>
          CATEGORY
        </InputLabel>
        <Field
          defaultValue='all'
          onChange={this.handleChange}
          name='category'
          component={renderSelect}
          label='CATEGORY'
          InputLabelProps={{
            shrink: true,
            id: 'category-field',
          }}
          margin='dense'
          className={styles.textField}
          validate={[required]}
          fullWidth
        >
          {this.renderMenuItem()}
        </Field>
      </FormControl>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    category: state.selection.category,
    search_hazard_logs: state.search.search_hazard_logs,
    search_hazard_impact: state.search.search_hazard_impact,
    search_hazard_route: state.search.search_hazard_route,
    ...ownProps,
  };
};
const mapActionsToProps = {
  searchHazardLogs,
  searchHazardImpact,
  searchHazardRoute,
  selectCategory,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(CategorySelect));
