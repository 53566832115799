import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputAdornment, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import { connect } from 'react-redux';
import { searchOperation } from '../../reducers/search';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: 200
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  padding5px: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    margin: '10px',
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
  },
  selectStyles: {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  },
  txtBlack: {
    color: '#000000',
  },
  iconButton: { marginRight: '20px', padding: '2px' },
});

class PlantSelect extends Component {
  state = {
    value: [],
  };
  renderMenuItem = () => {
    let self = this;
    if (this.props.dashboard_plant) {
      var menuItem = [...this.props.dashboard_plant];
      menuItem.sort();
      return menuItem.map(function (value, index) {
        return (
          <MenuItem key={value} value={value}>
            {/*<Checkbox checked={self.state.value.indexOf(value) > -1} />*/}
            <Checkbox
              checked={
                self.props.search_operation.plant &&
                self.props.search_operation.plant.indexOf(value) > -1
                  ? true
                  : false
              }
            />
            <ListItemText primary={value} />
          </MenuItem>
        );
      });
    } else return null;
  };
  onClearSelect = () => {
    this.setState({ value: [] });
    this.props.searchOperation({
      ...this.props.search_operation,
      plant: [],
    });
  };
  handleChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.searchOperation({
      ...this.props.search_operation,
      plant: event.target.value,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <FormControl required fullWidth className={classes.textField}>
        <InputLabel
          shrink
          htmlFor='plant-required'
          className={classes.txtBlack}
        >
          PLANT
        </InputLabel>
        <Select
          multiple
          testing='select-plant'
          name='plant'
          value={
            this.props.search_operation.plant
              ? this.props.search_operation.plant
              : []
          }
          onChange={this.handleChange}
          renderValue={(selected) => selected.join(', ')}
          inputProps={{
            id: 'plant-required',
          }}
          endAdornment={
            this.state.value &&
            this.state.value.length > 0 && (
              <InputAdornment position='end'>
                <IconButton
                  className={classes.iconButton}
                  aria-label='clear'
                  onClick={this.onClearSelect}
                >
                  <CloseIcon fontSize='small' />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          {this.renderMenuItem()}
        </Select>
      </FormControl>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    dashboard_plant: state.dashboard.dashboard_plant,
    search_operation: state.search.search_operation,
  };
};
const mapActionsToProps = {
  searchOperation,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(PlantSelect));
