import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uiAlertOpen, uiAlertReset } from '../../reducers/ui/index';

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

const AlertDialogSlide = (props) => {
  const { alert } = props;
  const handleOk = () => {
    uiAlertReset();
  };
  const handleCancel = () => {
    uiAlertReset();
  };

  return (
    <div>
      <Dialog
        fullWidth={false}
        maxWidth={'md'}
        open={alert.open}
       // //TransitionComponent={Transition}
        keepMounted
        onClose={handleCancel}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {alert.title || 'Notification'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            {alert.message}
          </DialogContentText>
        </DialogContent>
        {alert.type !== undefined && alert.type === 'confirm' && (
          <DialogActions>
            <Button onClick={handleCancel} color='primary'>
              Cancel
            </Button>
            <Button onClick={handleOk} color='primary'>
              Ok
            </Button>
          </DialogActions>
        )}
        {alert.type !== undefined && alert.type === 'warning' && (
          <DialogActions>
            <Button onClick={handleOk} color='primary'>
              Ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
const mapStateToProps = function (state, ownProps) {
  return {
    alert: state.ui.alert,
  };
};
const mapActionsToProps = {
  uiAlertOpen,
  uiAlertReset,
};

AlertDialogSlide.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  message: PropTypes.string,
  timeout: PropTypes.number,
};
const Alert = connect(mapStateToProps, mapActionsToProps)(AlertDialogSlide);

export default Alert;
