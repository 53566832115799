const { lineString, point } = require('@turf/helpers');
const length = require('@turf/length').default;
const along = require('@turf/along').default;
const distance = require('@turf/distance').default;
const bbox = require('@turf/bbox').default;

export const pointsAlongLine = (coordinates, intervalKm = 1) => {
  if (coordinates && coordinates.length) {
    var jsonLine = lineString(coordinates);
    var dist = length(jsonLine);
    let result = [];
    for (let step = 1; step * intervalKm < dist; step++) {
      let pt = along(jsonLine, step * intervalKm);
      result.push([pt.geometry.coordinates[1], pt.geometry.coordinates[0]]);
    }
    return result;
  } else {
    return [];
  }
};

export const lineDistance = (coordinates) => {
  if (coordinates && coordinates.length) {
    var jsonLine = lineString(coordinates);
    return length(jsonLine);
  } else {
    return -1;
  }
};

export const waypointsByDistanceCond = (coordinates) => {
  let interval = -1;
  if (coordinates && coordinates.length > 2) {
    var jsonLine = lineString(coordinates);
    let d = length(jsonLine);
    if (d < 10) {
      interval = 1;
    } else if (d >= 10 && d < 30) {
      interval = 2;
    } else {
      interval = d / 30;
    }
  }
  if (interval > 0) {
    return pointsAlongLine(coordinates, interval);
  } else {
    return [];
  }
};

const angleBetweenTwoVector = (center, pt1, pt2) => {
  //find vector components
  var dAx = pt1[1] - center[1];
  var dAy = pt1[0] - center[0];
  var dBx = pt2[1] - center[1];
  var dBy = pt2[0] - center[0];
  var angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
  if (angle < 0) {
    angle = angle * -1;
  }
  return angle * (180 / Math.PI);
};

export const waypointsByBearingCond = (coordinates, threshold = 45) => {
  let result = [];
  if (coordinates.length < 3) return result;

  coordinates.forEach((d, idx) => {
    if (idx !== 0 && idx !== coordinates.length - 1) {
      var dAx = coordinates[idx][1] - coordinates[idx - 1][1];
      var dAy = coordinates[idx][0] - coordinates[idx - 1][0];
      var dBx = coordinates[idx + 1][1] - coordinates[idx][1];
      var dBy = coordinates[idx + 1][0] - coordinates[idx][0];
      var angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
      if (angle < 0) {
        angle = angle * -1;
      }
      let degree_angle = angle * (180 / Math.PI);

      if (degree_angle > threshold) {
        if (result.length) {
          let dist = distance(result[result.length - 1], [
            coordinates[idx][1],
            coordinates[idx][0],
          ]);
          // Distance between Waypoint must higher than 300m
          if (dist > 0.3) {
            result.push([coordinates[idx][1], coordinates[idx][0]]);
          }
        } else {
          result.push([coordinates[idx][1], coordinates[idx][0]]);
        }
      }
    }
  });
  return result;
};

export const addWaypointWithClosestIndex = (waypoints, pt) => {
  if (waypoints.length < 3) {
    let copyArray = [...waypoints];
    copyArray.splice(0, 1);
    copyArray.splice(-1, 1);
    copyArray.push(pt);
    return copyArray;
  }
  let waypointLength = waypoints.length;
  let retIndex = 0;
  let leastDist = null;
  let tPt = point([pt.lng, pt.lat]);
  waypoints.forEach((d, idx) => {
    let calDist = distance(
      point([waypoints[idx].lng, waypoints[idx].lat]),
      tPt
    );
    if (leastDist === null || calDist < leastDist) {
      retIndex = idx;
      leastDist = calDist;
    }
  });

  let copyArray = [...waypoints];
  if (retIndex === 0) {
    copyArray.splice(0, 1);
    copyArray.splice(-1, 1);
    copyArray.unshift(pt);
    return copyArray;
  }
  if (retIndex === waypointLength - 1) {
    copyArray.splice(0, 1);
    copyArray.splice(-1, 1);
    copyArray.push(pt);
    return copyArray;
  }
  let degree_angle = angleBetweenTwoVector(
    [waypoints[retIndex].lng, waypoints[retIndex].lat],
    [pt.lng, pt.lat],
    [waypoints[retIndex + 1].lng, waypoints[retIndex + 1].lat]
  );
  if (degree_angle > 90 && degree_angle < 270) {
    copyArray.splice(retIndex, 0, pt);
  } else {
    copyArray.splice(retIndex + 1, 0, pt);
  }

  copyArray.splice(0, 1);
  copyArray.splice(-1, 1);
  return copyArray;
};

export const getBbox = (array) => {
  if (!array) return [];
  if (array.length === 1) return array;
  var line = lineString(array);
  return bbox(line);
};
