import React, { useRef, useEffect, useCallback, useState } from 'react';
import {
  markerPinNumberSvgIcon,
  waypointPinNumberSvgIcon,
} from 'Components/Common/MarkerIconRenderer';
import { useDispatch, useSelector } from 'react-redux';
import { hmapRouting } from 'reducers/hmap/api';
import { hmapSelector } from 'reducers/hmap';
import Loading from './Loading';
import { showError } from 'libs/errorHandler';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';
import InfoWindowDelete from './InfoWindowDelete';
import AutoWaypoints from './AutoWaypoints';
import { addWaypointWithClosestIndex } from '../libs/geoLib';
import DeleteButton from './DeleteButton';

const WAYPOINT_LIMIT = 80;

function Route(props) {
  const {
    map,
    interaction,
    builderOptions,
    enqueueSnackbar,
    disposeFunctions,
    onCallback,
    route,
    options,
  } = props;
  const dispatch = useDispatch();
  const [infoWindowContent, setInfoWindowContent] = useState({});
  const [lineJson, setLineJson] = useState();
  if (!window.H || !window.H.map || !map) {
    throw new Error('HMap has to be initialized before adding Map Objects');
  }
  let showWaypointTimeout = useRef();
  let startMarker = useRef();
  let endMarker = useRef();
  let markerGroup = useRef();
  let waypointGroup = useRef();
  let polylineGroup = useRef();
  let flexiblePolyline = useRef();
  let routeSection = useRef();
  let isShowWaypointDelete = useRef(null);

  const dispatchCallback = useCallback(
    (routeData) => {
      if (onCallback) {
        onCallback({ ...routeData, section: routeSection.current });
      }
    },
    [onCallback]
  );

  const markerTapHandler = useCallback(
    (ev) => {
      let data = ev.target.getData();
      var targetPosition = map.geoToScreen(ev.target.getGeometry());
      setInfoWindowContent({
        title: data.isStart ? 'Start Point' : 'End Point',
        type: 'od',
        left: targetPosition.x,
        top: targetPosition.y,
        data,
      });
    },
    [map]
  );

  const { isRouteLoading, routeError, routeResult } = useSelector(hmapSelector);

  const calculateRoute = useCallback(
    (routeId) => {
      if (routeId && routeId !== route.id) {
        return;
      }
      if (!startMarker.current || !endMarker.current) {
        return;
      }
      let startGeometry = startMarker.current.getGeometry();
      let endGeometry = endMarker.current.getGeometry();
      let waypoints = waypointGroup.current.getObjects().map((w) => {
        let geo = w.getGeometry();
        return [geo.lat, geo.lng];
      });

      dispatch(
        hmapRouting({
          id: route.id,
          transportMode: 'truck',
          origin: `${startGeometry.lat},${startGeometry.lng}`,
          destination: `${endGeometry.lat},${endGeometry.lng}`,
          waypoints,
        })
      );
    },
    [dispatch, route.id]
  );

  const pointerEnterPolylineHandler = useCallback(
    (ev, data) => {
      if (options?.isWaypointOnEnter) {
        clearTimeout(showWaypointTimeout.current);

        if (waypointGroup.current) {
          let wpData = waypointGroup.current.getData();
          if (!wpData?.id || !data?.groupId || wpData?.id === data?.groupId) {
            waypointGroup.current.setVisibility(true);
          }
        }
        let polylineData = polylineGroup.current.getData();
        if (
          !polylineData?.id ||
          !data?.groupId ||
          polylineData?.id === data?.groupId
        ) {
          polylineGroup.current.getObjects().forEach((p) => {
            let curStyle = p.getStyle();
            if (curStyle.fillColor !== 'white') {
              p.setStyle({
                ...curStyle,
                strokeColor: 'rgba(255, 125, 0, 0.9)',
              });
            }
          });
        }
      }
    },
    [options?.isWaypointOnEnter]
  );

  const pointerLeavePolylineHandler = useCallback(
    (ev, data) => {
      if (options?.isWaypointOnEnter) {
        // Do nothing when popup delete is shown
        if (isShowWaypointDelete.current) {
          return;
        }
        if (waypointGroup.current) {
          polylineGroup.current.getObjects().forEach((p) => {
            let curStyle = p.getStyle();
            if (curStyle.fillColor !== 'white') {
              p.setStyle({
                ...curStyle,
                strokeColor: 'rgba(0, 128, 255, 0.9)',
              });
            }
          });
          showWaypointTimeout.current = setTimeout(() => {
            waypointGroup.current.setVisibility(false);
            setInfoWindowContent({});
          }, 200);
        }
      }
    },
    [options?.isWaypointOnEnter]
  );

  const waypointTapHandler = useCallback(
    (ev) => {
      let data = ev.target.getData();
      var targetPosition = map.geoToScreen(ev.target.getGeometry());
      // Clear previous focus polyline
      if (isShowWaypointDelete.current) {
        let wpData = { ...isShowWaypointDelete.current };
        isShowWaypointDelete.current = null;
        pointerLeavePolylineHandler(null, wpData);
      }
      isShowWaypointDelete.current = data;
      setInfoWindowContent({
        title: `Waypoint ${data.index}`,
        type: 'waypoint',
        left: targetPosition.x,
        top: targetPosition.y,
        data,
      });
    },
    [map, pointerLeavePolylineHandler]
  );

  const pointerDownPolylineHandler = useCallback(
    (ev) => {
      const pointer = ev.currentPointer;
      let geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);

      let tmpWaypoints = [];
      waypointGroup.current
        .getObjects()
        .forEach((w) => tmpWaypoints.push(w.getGeometry()));
      tmpWaypoints.unshift(startMarker.current.getGeometry());
      tmpWaypoints.push(endMarker.current.getGeometry());
      let newWaypoints = addWaypointWithClosestIndex(tmpWaypoints, geoPoint);

      waypointGroup.current.removeAll();
      newWaypoints.forEach((wp, idx) => {
        let waypointMarker = new window.H.map.Marker(wp, {
          icon: new window.H.map.Icon(
            waypointPinNumberSvgIcon(idx + 1, 30, 40, '#013234', '#ffffff')
          ),
          volatility: true,
        });
        waypointMarker.draggable = true;
        waypointMarker.setData({ groupId: route.id, index: idx + 1 });

        waypointGroup.current.addObject(waypointMarker);
      });

      if (!waypointGroup.current) return;
      // Callback result
      let waypoints = waypointGroup.current.getObjects().map((w) => {
        let geo = w.getGeometry();
        return [geo.lat, geo.lng];
      });
      dispatchCallback({
        id: route.id,
        waypoints: waypoints,
        polyline: flexiblePolyline.current,
      });
    },
    [dispatchCallback, map, route.id]
  );

  useEffect(() => {
    if (routeError) {
      showError(enqueueSnackbar, routeError);
    }
  }, [routeError, enqueueSnackbar]);

  useEffect(() => {
    if (routeResult) {
      if (routeResult.id && routeResult.id !== route.id) {
        return;
      }
      if (routeResult.routes.length) {
        if (polylineGroup.current) {
          polylineGroup.current.removeAll();
          polylineGroup.current.removeEventListener(
            'pointerdown',
            pointerDownPolylineHandler,
            false
          );
          polylineGroup.current.removeEventListener(
            'pointerenter',
            pointerEnterPolylineHandler,
            true
          );
          polylineGroup.current.removeEventListener(
            'pointerleave',
            pointerLeavePolylineHandler,
            true
          );
        }

        routeResult?.routes[0]?.sections.forEach((section) => {
          // Create a linestring to use as a point source for the route line
          let linestring = window.H.geo.LineString.fromFlexiblePolyline(
            section.polyline
          );
          // Keep for further used
          // Assume have only one section
          routeSection.current = section;
          flexiblePolyline.current = section.polyline;

          // For using in auto waypoints
          setLineJson(linestring.toGeoJSON());

          // Create a polyline to display the route:
          var routeOutline = new window.H.map.Polyline(linestring, {
            style: {
              lineWidth: 8,
              strokeColor: 'rgba(0, 128, 255, 0.9)',
              lineTailCap: 'arrow-tail',
              lineHeadCap: 'arrow-head',
            },
          });
          // Create a patterned polyline:
          var routeArrows = new window.H.map.Polyline(linestring, {
            style: {
              lineWidth: 8,
              fillColor: 'white',
              strokeColor: 'rgba(255, 255, 255, 1)',
              lineDash: [0, 2],
              lineTailCap: 'arrow-tail',
              lineHeadCap: 'arrow-head',
            },
          });

          if (polylineGroup.current) {
            // Add the route polyline and the two markers to the map:
            polylineGroup.current.addObjects([routeOutline, routeArrows]);

            polylineGroup.current.addEventListener(
              'pointerdown',
              pointerDownPolylineHandler,
              false
            );
            polylineGroup.current.addEventListener(
              'pointerenter',
              pointerEnterPolylineHandler,
              true
            );
            polylineGroup.current.addEventListener(
              'pointerleave',
              pointerLeavePolylineHandler,
              true
            );
          }

          if (!waypointGroup.current) return;
          // Callback result
          let waypoints = waypointGroup.current.getObjects().map((w) => {
            let geo = w.getGeometry();
            return [geo.lat, geo.lng];
          });
          dispatchCallback({
            id: route.id,
            waypoints: waypoints,
            polyline: section.polyline,
          });
        });
      }
    }
  }, [
    map,
    onCallback,
    routeResult,
    pointerDownPolylineHandler,
    pointerEnterPolylineHandler,
    pointerLeavePolylineHandler,
    dispatchCallback,
    route.id,
  ]);

  const mapPointerDownHandler = useCallback(
    (e) => {
      if (isShowWaypointDelete.current) {
        let wpData = { ...isShowWaypointDelete.current };
        isShowWaypointDelete.current = null;
        pointerLeavePolylineHandler(null, wpData);
      }
      setInfoWindowContent({});
      var target = e.target;
      if (target instanceof window.H.map.Marker) return;

      const pointer = e.currentPointer;
      let geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
      if (!startMarker.current && options?.isStartDraggable) {
        startMarker.current = new window.H.map.Marker(geoPoint, {
          icon: new window.H.map.Icon(
            markerPinNumberSvgIcon(
              options?.startMarkerLabel || 'A',
              30,
              40,
              options?.startMarkerColor || '#4c00b0',
              '#ffffff'
            )
          ),
          volatility: true,
        });
        let index = markerGroup.current.getObjects().length;
        startMarker.current.setData({ index, isStart: true });
        startMarker.current.draggable = true;
        markerGroup.current.addObject(startMarker.current);
        calculateRoute();
        return;
      }
      if (!endMarker.current && options?.isEndDraggable) {
        endMarker.current = new window.H.map.Marker(geoPoint, {
          icon: new window.H.map.Icon(
            markerPinNumberSvgIcon(
              options?.endMarkerLabel || 'B',
              30,
              40,
              options?.endMarkerColor || '#ff0000',
              '#ffffff'
            )
          ),

          volatility: true,
        });
        let index = markerGroup.current.getObjects().length;
        endMarker.current.setData({ index, isEnd: true });
        endMarker.current.draggable = true;
        markerGroup.current.addObject(endMarker.current);
        calculateRoute();
        return;
      }
    },
    [
      map,
      options?.isStartDraggable,
      options?.isEndDraggable,
      calculateRoute,
      options?.startMarkerLabel,
      options?.endMarkerLabel,
      options?.startMarkerColor,
      options?.endMarkerColor,
      pointerLeavePolylineHandler,
    ]
  );

  const mapDragStartHandler = useCallback(
    (ev) => {
      var target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof window.H.map.Marker) {
        pointerEnterPolylineHandler(ev, target.getData());

        var targetPosition = map.geoToScreen(target.getGeometry());
        target['offset'] = new window.H.math.Point(
          pointer.viewportX - targetPosition.x,
          pointer.viewportY - targetPosition.y
        );
        interaction.disable();
        setInfoWindowContent({});
        ev.stopPropagation();
      }
    },
    [map, interaction, pointerEnterPolylineHandler]
  );

  const mapDragEndHandler = useCallback(
    (ev) => {
      var target = ev.target;
      if (target instanceof window.H.map.Marker) {
        pointerLeavePolylineHandler(ev, target.getData());
        let waypointData = target.getData();
        interaction.enable();
        calculateRoute(waypointData?.groupId);
        ev.stopPropagation();
      }
    },
    [interaction, calculateRoute, pointerLeavePolylineHandler]
  );

  const mapDragHandler = useCallback(
    (ev) => {
      var target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof window.H.map.Marker) {
        target.setGeometry(
          map.screenToGeo(
            pointer.viewportX - target['offset'].x,
            pointer.viewportY - target['offset'].y
          )
        );
      }
    },
    [map]
  );

  const subscribeToMapEvents = useCallback(() => {
    map.addEventListener('pointerdown', mapPointerDownHandler, false);
    map.addEventListener('dragstart', mapDragStartHandler, false);
    map.addEventListener('dragend', mapDragEndHandler, false);
    map.addEventListener('drag', mapDragHandler, false);
  }, [
    map,
    mapDragEndHandler,
    mapDragHandler,
    mapDragStartHandler,
    mapPointerDownHandler,
  ]);

  const unsubscribeToMapEvents = useCallback(() => {
    map.removeEventListener('pointerdown', mapPointerDownHandler, false);
    map.removeEventListener('dragstart', mapDragStartHandler, false);
    map.removeEventListener('dragend', mapDragEndHandler, false);
    map.removeEventListener('drag', mapDragHandler, false);
  }, [
    map,
    mapPointerDownHandler,
    mapDragStartHandler,
    mapDragEndHandler,
    mapDragHandler,
  ]);

  // Unsubscribe event
  useEffect(() => {
    if (!polylineGroup.current) {
      polylineGroup.current = new window.H.map.Group({
        visibility: true,
      });
      polylineGroup.current.setData({ id: route.id });
    }

    if (!markerGroup.current) {
      markerGroup.current = new window.H.map.Group({
        visibility:
          typeof options?.isShowStartEndMarker !== 'undefined'
            ? options?.isShowStartEndMarker
            : true,
      });
    }

    if (!waypointGroup.current) {
      waypointGroup.current = new window.H.map.Group({
        visibility: options?.isWaypointOnEnter ? false : true,
      });
      waypointGroup.current.setData({ id: route.id });
    }

    markerGroup.current.addEventListener('tap', markerTapHandler, false);
    waypointGroup.current.addEventListener('tap', waypointTapHandler, false);
    waypointGroup.current.addEventListener(
      'pointerenter',
      pointerEnterPolylineHandler,
      true
    );
    waypointGroup.current.addEventListener(
      'pointerleave',
      pointerLeavePolylineHandler,
      true
    );

    map.addObjects([
      polylineGroup.current,
      markerGroup.current,
      waypointGroup.current,
    ]);

    unsubscribeToMapEvents();
    subscribeToMapEvents();

    if (route) {
      if (route.polyline) {
        // Create a linestring to use as a point source for the route line
        let linestring = window.H.geo.LineString.fromFlexiblePolyline(
          route.polyline
        );

        // Keep for further used
        routeSection.current = route.section;
        flexiblePolyline.current = route.polyline;

        let points = linestring.getLatLngAltArray();
        const firstPoint = new window.H.geo.Point(points[0], points[1], 0);
        const lastPoint = new window.H.geo.Point(
          points[points.length - 3],
          points[points.length - 2],
          0
        );
        if (!startMarker.current) {
          startMarker.current = new window.H.map.Marker(firstPoint, {
            icon: new window.H.map.Icon(
              markerPinNumberSvgIcon(
                options?.startMarkerLabel || 'A',
                30,
                40,
                options?.startMarkerColor || '#4c00b0',
                '#ffffff'
              )
            ),
            volatility: true,
          });
          let index = markerGroup.current.getObjects().length;
          startMarker.current.setData({ index, isStart: true });
          if (options?.isStartDraggable) {
            startMarker.current.draggable = true;
          }
          markerGroup.current.addObject(startMarker.current);
        }
        if (!endMarker.current) {
          endMarker.current = new window.H.map.Marker(lastPoint, {
            icon: new window.H.map.Icon(
              markerPinNumberSvgIcon(
                options?.endMarkerLabel || 'B',
                30,
                40,
                options?.endMarkerColor || '#ff0000',
                '#ffffff'
              )
            ),

            volatility: true,
          });
          let index = markerGroup.current.getObjects().length;
          endMarker.current.setData({ index, isEnd: true });
          if (options?.isEndDraggable) {
            endMarker.current.draggable = true;
          }
          markerGroup.current.addObject(endMarker.current);
        }

        // For using in auto waypoints
        setLineJson(linestring.toGeoJSON());

        // Create a polyline to display the route:
        var routeOutline = new window.H.map.Polyline(linestring, {
          style: {
            lineWidth: 8,
            strokeColor: 'rgba(0, 128, 255, 0.9)',
            lineTailCap: 'arrow-tail',
            lineHeadCap: 'arrow-head',
          },
        });
        // Create a patterned polyline:
        var routeArrows = new window.H.map.Polyline(linestring, {
          style: {
            lineWidth: 8,
            fillColor: 'white',
            strokeColor: 'rgba(255, 255, 255, 1)',
            lineDash: [0, 2],
            lineTailCap: 'arrow-tail',
            lineHeadCap: 'arrow-head',
          },
        });

        if (polylineGroup.current) {
          // Add the route polyline and the two markers to the map:
          polylineGroup.current.addObjects([routeOutline, routeArrows]);

          polylineGroup.current.addEventListener(
            'pointerdown',
            pointerDownPolylineHandler,
            false
          );
          polylineGroup.current.addEventListener(
            'pointerenter',
            pointerEnterPolylineHandler,
            true
          );
          polylineGroup.current.addEventListener(
            'pointerleave',
            pointerLeavePolylineHandler,
            true
          );
        }
      }

      if (route.waypoints) {
        waypointGroup.current.removeAll();
        for (let pt of route.waypoints) {
          let waypointIdx = waypointGroup.current.getObjects().length + 1;
          let waypointMarker = new window.H.map.Marker(
            { lat: pt[0], lng: pt[1] },
            {
              icon: new window.H.map.Icon(
                waypointPinNumberSvgIcon(
                  waypointIdx,
                  30,
                  40,
                  '#013234',
                  '#ffffff'
                )
              ),
              volatility: true,
            }
          );
          waypointMarker.draggable = true;
          waypointMarker.setData({ groupId: route.id, index: waypointIdx });
          waypointGroup.current.addObject(waypointMarker);
        }
      }
    }

    const dispose = () => {
      if (polylineGroup.current) {
        polylineGroup.current.removeAll();
        polylineGroup.current.removeEventListener(
          'pointerdown',
          pointerDownPolylineHandler,
          false
        );
        polylineGroup.current.removeEventListener(
          'pointerenter',
          pointerEnterPolylineHandler,
          true
        );
        polylineGroup.current.removeEventListener(
          'pointerleave',
          pointerLeavePolylineHandler,
          true
        );
      }
      if (markerGroup.current) {
        markerGroup.current.removeAll();
        markerGroup.current.removeEventListener('tap', markerTapHandler, false);
      }
      if (waypointGroup.current) {
        waypointGroup.current.removeAll();
        waypointGroup.current.removeEventListener(
          'tap',
          waypointTapHandler,
          false
        );
      }
      startMarker.current = null;
      endMarker.current = null;

      polylineGroup.current = null;
      markerGroup.current = null;
      waypointGroup.current = null;

      unsubscribeToMapEvents();
    };
    disposeFunctions.current.push(dispose);
  }, [
    map,
    unsubscribeToMapEvents,
    waypointTapHandler,
    markerTapHandler,
    pointerDownPolylineHandler,
    pointerEnterPolylineHandler,
    pointerLeavePolylineHandler,
    disposeFunctions,
    subscribeToMapEvents,
    route,
    options?.isStartDraggable,
    options?.isEndDraggable,
    options?.isWaypointOnEnter,
    options?.isShowStartEndMarker,
    options?.endMarkerLabel,
    options?.endMarkerColor,
    options?.startMarkerLabel,
    options?.startMarkerColor,
  ]);

  const clear = () => {
    if (polylineGroup.current) {
      polylineGroup.current.removeAll();
      polylineGroup.current.removeEventListener(
        'pointerdown',
        pointerDownPolylineHandler,
        false
      );
      polylineGroup.current.removeEventListener(
        'pointerenter',
        pointerEnterPolylineHandler,
        true
      );
      polylineGroup.current.removeEventListener(
        'pointerleave',
        pointerLeavePolylineHandler,
        true
      );
    }
    if (markerGroup.current) {
      markerGroup.current.removeAll();
      markerGroup.current.removeEventListener('tap', markerTapHandler, false);
    }
    if (waypointGroup.current) {
      waypointGroup.current.removeAll();
      waypointGroup.current.removeEventListener(
        'tap',
        waypointTapHandler,
        false
      );
      waypointGroup.current.removeEventListener(
        'pointerenter',
        pointerEnterPolylineHandler,
        true
      );
      waypointGroup.current.removeEventListener(
        'pointerleave',
        pointerLeavePolylineHandler,
        true
      );
    }
    startMarker.current = null;
    endMarker.current = null;

    polylineGroup.current = null;
    markerGroup.current = null;
    waypointGroup.current = null;

    unsubscribeToMapEvents();

    dispatchCallback({});
  };

  if (isRouteLoading) {
    return <Loading />;
  }

  if (!startMarker.current || !endMarker.current) {
    return <div style={{ display: 'none' }} />;
  }

  return (
    <>
      {options?.enableDelete && <DeleteButton onDeleteClick={clear} />}
      {options?.enableAutoWaypoints && (
        <AutoWaypoints
          lineJson={lineJson}
          onDeleteCallback={() => {
            if (waypointGroup.current) {
              if (!waypointGroup.current.getObjects().length) return;
              waypointGroup.current.removeAll();
              if (route) {
                dispatchCallback({
                  id: route.id,
                  waypoints: [],
                  polyline: flexiblePolyline.current,
                });
              } else {
                calculateRoute();
              }
            }
          }}
          onCallback={(points) => {
            if (points.length > 0) {
              let pointLength = points.length;
              if (pointLength > WAYPOINT_LIMIT) {
                points.splice(0, pointLength - WAYPOINT_LIMIT);
                showError(
                  enqueueSnackbar,
                  `Waypoints size is over limit (${WAYPOINT_LIMIT}, out of lengh waypoints are skipped)`
                );
              }
              waypointGroup.current.removeAll();
              for (let pt of points) {
                let waypointIdx = waypointGroup.current.getObjects().length + 1;
                let waypointMarker = new window.H.map.Marker(
                  { lat: pt[0], lng: pt[1] },
                  {
                    icon: new window.H.map.Icon(
                      waypointPinNumberSvgIcon(
                        waypointIdx,
                        30,
                        40,
                        '#013234',
                        '#ffffff'
                      )
                    ),
                    volatility: true,
                  }
                );
                waypointMarker.draggable = true;
                waypointMarker.setData({
                  groupId: route.id,
                  index: waypointIdx,
                });
                waypointGroup.current.addObject(waypointMarker);
              }

              if (route) {
                dispatchCallback({
                  id: route.id,
                  waypoints: points,
                  polyline: flexiblePolyline.current,
                });
              }
            }
          }}
        />
      )}
      <InfoWindowDelete
        content={infoWindowContent}
        disabled={
          infoWindowContent?.type === 'od' &&
          !(options?.isStartDeletable || options?.isEndDeletable)
        }
        onClose={() => {
          let wpData = { ...isShowWaypointDelete.current };
          isShowWaypointDelete.current = null;
          pointerLeavePolylineHandler(null, wpData);
        }}
        onDeleteClick={(d) => {
          if (
            d.type === 'od' &&
            (options?.isStartDeletable || options?.isEndDeletable)
          ) {
            let selectedMarker = markerGroup.current
              .getObjects()
              .filter((m) => {
                let mData = m.getData();
                return mData.index === d.data.index;
              });
            markerGroup.current.removeObjects(selectedMarker);
            if (d.data.isStart && options?.isStartDeletable) {
              startMarker.current = null;
            }
            if (d.data.isEnd && options?.isEndDeletable) {
              endMarker.current = null;
            }
            markerGroup.current.getObjects().forEach((m, idx) => {
              let curData = m.getData();
              m.setData({ ...curData, index: idx });
            });
            polylineGroup.current.removeAll();
            waypointGroup.current.removeAll();
          }

          if (d.type === 'waypoint') {
            if (isShowWaypointDelete.current) {
              let wpData = { ...isShowWaypointDelete.current };
              isShowWaypointDelete.current = null;
              pointerLeavePolylineHandler(null, wpData);
            }
            let selectedMarker = waypointGroup.current
              .getObjects()
              .filter((m) => {
                let mData = m.getData();
                return mData.index === d.data.index;
              });
            waypointGroup.current.removeObjects(selectedMarker);

            waypointGroup.current.getObjects().forEach((m, idx) => {
              let curData = m.getData();
              m.setData({ ...curData, index: idx + 1 });
              m.setIcon(
                new window.H.map.Icon(
                  waypointPinNumberSvgIcon(
                    idx + 1,
                    30,
                    40,
                    '#013234',
                    '#ffffff'
                  )
                )
              );
            });
            if (waypointGroup.current.getObjects().length > 0) {
              calculateRoute();
            } else {
              if (route) {
                dispatchCallback({
                  id: route.id,
                  waypoints: [],
                  polyline: flexiblePolyline.current,
                });
              } else {
                calculateRoute();
              }
            }
          }
          setInfoWindowContent({});
        }}
      />
    </>
  );
}

export default compose(withSnackbar)(Route);
