import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';

const styles = {
  avatar: {
    margin: 5,
    width: 20,
    height: 20,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
};

class DestinationPointRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  render() {
    const { classes } = this.props;
    return '>>' + this.state.value;
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
    activeMenuListItem: state.ui.activeMenuListItem,
  };
};

const mapActionsToProps = {};
const enhance = compose(
  connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true }),
  withStyles(styles)
);

export default enhance(DestinationPointRenderer);
