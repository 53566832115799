import React, { Component } from 'react';
import {
  reduxForm,
  Form,
  Field,
} from 'redux-form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import momentLocaliser from 'react-widgets/lib/localizers/moment'
import 'react-widgets/dist/css/react-widgets.css';
import CircularProgress from '@material-ui/core/CircularProgress';
//Component
//Lib
import { required } from '../../../libs/validation';
//Reducer
import { apiCallRequest } from '../../../reducers/api';
import { getCompanies, getGroupCompanies } from '../../../reducers/setting/api';
import { formCompany } from '../../../reducers/ui/mapForm';
import {
  settingOpenCompany,
  settingCompanySelect,
} from '../../../reducers/setting';

//Config
import Configs from '../../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: '200px',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  center: {
    position: 'relative',
    left: '20px',
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderSelect = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <Select
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

class CompanyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentWillMount() {}
  componentWillReceiveProps(nextProps) {
    this.renderGroupCompanyItem();
    if (this.props.companyForm !== nextProps.companyForm) {
      //console.log('#componentWillReceiveProps group_selecting',nextProps.group_selecting);
      if (nextProps.group_selecting.id)
        this.props.initialize({
          ...nextProps.companyForm,
          groupId: nextProps.group_selecting.id,
        });
      else this.props.initialize({ ...nextProps.companyForm });
    }
  }
  //Group Company
  getGroupCompanyItem() {
    this.props.getGroupCompanies({
      url: Configs[env].BACKEND_HOST + '/companyGroup',
      method: 'GET',
      filter: {},
    });
  }
  renderGroupCompanyItem() {
    if (this.props.setting_groupcompanies) {
      var menuItem = this.props.setting_groupcompanies;
      return menuItem.map(function (data, index) {
        return (
          <MenuItem key={index} value={data.id} detail={data.name}>
            {data.name}
          </MenuItem>
        );
      });
    } else return null;
  }
  handleSave = (form) => {
    //debugger
    this.props.settingOpenCompany(false);
    let formData = { ...form };
    // console.log('#handleSave formData',formData);

    if (formData.id) {
      delete formData.password;
      let id = formData.id;
      delete formData.id;
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + '/company/' + id,
        form: { ...formData },
        method: 'PUT',
        callback: this.handleCancel,
      });
    } else {
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + '/company',
        form: { ...formData },
        method: 'POST',
        callback: this.handleCancel,
      });
    }
  };
  handleChange = (event) => {
    if (event.target.name === 'groupId') {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  handleCancel = () => {
    // console.log('#handleCancel groupId',this.props.group_selecting)
    if (this.props.group_selecting.id) {
      this.props.getCompanies({
        url: Configs[env].BACKEND_HOST + '/companyCond',
        filter: {
          groupId: this.props.group_selecting.id,
        },
      });
    }
    this.props.reset();
    this.props.formCompany(null);
    this.props.settingCompanySelect(null);
    this.props.settingOpenCompany(false);
  };
  render() {
    const { handleSubmit, classes } = this.props;

    return (
      <div style={{ padding: '10px' }}>
        {/*this.props.isLoading && <Loading />*/}

        <Dialog
          open={this.props.company_dialog}
          //TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCancel}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {this.props.companyForm && this.props.companyForm.id
              ? 'EDIT COMPANY'
              : 'ADD COMPANY'}
          </DialogTitle>
          <DialogContent>
            <Form
              //style={{ overflowY:"scroll"}}
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.handleSave.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth className={styles.formControl}>
                    <InputLabel shrink htmlFor='groupcompany-field'>
                      GROUP COMPANY
                    </InputLabel>
                    <Field
                      onChange={this.handleChange}
                      name='groupId'
                      fullWidth
                      component={renderSelect}
                      label='GROUP COMPANY'
                      InputLabelProps={{
                        shrink: true,
                        id: 'groupcompany-field',
                      }}
                      margin='dense'
                      disabled={true}
                      className={styles.textField}
                    >
                      {this.renderGroupCompanyItem()}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name='name'
                    component={renderInput}
                    className={styles.textField}
                    label='Company'
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <DialogActions>
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      aria-label='Save'
                      className={styles.button}
                      disabled={this.props.isLoading}
                      type='submit'
                    >
                      {this.props.isLoading && (
                        <CircularProgress
                          className={classes.center}
                          size={25}
                          thickness={2}
                        />
                      )}
                      SAVE
                    </Button>
                    <Button
                      variant='outlined'
                      size='small'
                      color='secondary'
                      aria-label='Close'
                      disabled={this.props.isLoading}
                      className={classNames(styles.button, styles.cssGreen)}
                      onClick={this.handleCancel}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    isLoading: state.ui.isLoading,
    company_selecting: state.setting.company_selecting,
    company_dialog: state.setting.company_dialog,
    companyForm: state.mapForm.companyForm,
    setting_groupcompanies: state.setting.setting_groupcompanies,
    group_selecting: state.setting.group_selecting,
  };
};
const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  settingOpenCompany,
  settingCompanySelect,
  getCompanies,
  getGroupCompanies,
  formCompany,
};

const enhance = compose(
  reduxForm({
    form: 'companyForm',
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(CompanyDialog);
