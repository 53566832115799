import React, { Component } from 'react';
import { setSidebarVisibility } from '../../reducers/ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ConfirmBtnRenderer from './Renderer/ConfirmBtnRenderer';
import DateTimeFieldRenderer from './Renderer/DateTimeFieldRenderer';
import PlanETAFieldRenderer from './Renderer/PlanETAFieldRenderer';
import ProbActionFieldRenderer from './Renderer/ProbActionFieldRenderer';
import DateTimeDialog from './Dialog/DateTimeDialog';
import ProblemDialog from './Dialog/ProblemDialog';
import ActionDialog from './Dialog/ActionDialog';

import DateTimeEditor from './Renderer/DateTimeEditor';
import ManageBtnRenderer from './Renderer/ManageBtnRenderer';
import RouteRenderer from './Renderer/RouteRenderer';
import TrackingRenderer from './Renderer/TrackingRenderer';
import IconRouteRenderer from './Renderer/IconRouteRenderer';
import StatusOperationRenderer from '../Ui/Renderer/StatusOperationRenderer';
import SelectRenderer from '../Ui/Renderer/SelectRenderer';
import Avatar from '@material-ui/core/Avatar';
import $ from 'jquery';
import { getDataOperation } from '../../reducers/dashboard/operation';
import { dashboardSelected } from '../../reducers/dashboard';
import moment from 'moment';
import store from 'reducers/rootReducer';

import Configs from '../../config/config';
import './grid.css';

const env = process.env.NODE_ENV;
const MIN_HEIGHT = 25;

const styles = (theme) => ({
  avatar: {
    margin: 5,
    width: 20,
    height: 20,
  },
});

class SubFrameGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowSelecting: true,
      blinkColor: 0,
      columnDefs: [
        {
          headerName: 'Part Usage',
          field: 'partUsage',
          headerClass: 'multiline',
          //cellRenderer: "planETAFieldRenderer",
          //cellRenderer: "dateTimeFieldRenderer",
          width: 90,
          /*cellStyle: function(params) {
              return {color:'black',textAlign:'center',backgroundColor: params.data.partUsage.color === "#FFFFFF" ? "transparent" : params.data.partUsage.color};
          },
          cellClass: function(params) { 
            return (params.data.partUsage.blink===1?'':''); 
          }*/
        },
        {
          headerName: 'Vin No. / PDS',
          field: 'partInfo',
          headerClass: 'multiline',
          width: 270,
          /*cellStyle: function(params) {
              return {color:'black',backgroundColor: params.data.partInfo.color === "#FFFFFF" ? "transparent" : params.data.partInfo.color};
          },
          cellClass: function(params) { 
            return (params.data.partInfo.blink===1?'':''); 
          }*/
        },
      ],

      rowClassRules: {
        // "status-ontime": function(params) {
        //   var status = params.data.status;
        //   return status === "0";
        // },
        'status-delay': function (params) {
          var status = params.data.status;
          return status === '1';
        },
        'status-critical-delay': function (params) {
          var status = params.data.status;
          return status === '2';
        },
      },
      defaultColDef: { editable: false, sortable: true, resizable: true },
      context: { componentParent: this },
      frameworkComponents: {
        confirmBtnRenderer: ConfirmBtnRenderer,
        dateTimeFieldRenderer: DateTimeFieldRenderer,
        planETAFieldRenderer: PlanETAFieldRenderer,
        probActionFieldRenderer: ProbActionFieldRenderer,
        //statusOperationRenderer : StatusOperationRenderer,
        //selectRenderer : SelectRenderer,
        manageBtnRenderer: ManageBtnRenderer,
        routeRenderer: RouteRenderer,
        trackingRenderer: TrackingRenderer,
        iconRouteRenderer: IconRouteRenderer,
        //datePicker: getDatePicker()
      },
      headerHeight: 60,
      rowData: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    //console.log('#componentWillReceiveProps dashboard_subframe',nextProps.dashboard_subframe);
    //getDataOperation({});
    // if(this.props.blinkColor != nextProps.blinkColor){
    //   this.renderColumns(nextProps);
    // }
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    setSidebarVisibility(false);
    //this.autoSizeAll();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.dashboard_subframe != this.props.dashboard_subframe) {
      //console.log('#componentDidUpdate dashboard_subframe',this.props.dashboard_subframe);
      //this.restoreSelect(prevProps);
    }
    //this.autoSizeAll();
  }
  componentWillUnmount() {}
  rowData() {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function (column, index) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  autosizeHeaders(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        '#jobDataGrid .ag-header-cell-label'
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }

  methodFromParent(cell) {
    alert('Parent Component Method from ' + cell + '!');
  }
  renderData = () => {
    if (Array.isArray(this.props.dashboard_subframe)) {
      return this.props.dashboard_subframe;
    } else return [];
  };

  render() {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <div
          id='jobDataGrid'
          style={{
            boxSizing: 'border-box',
            height: '98%',
            width: '100%',
          }}
          className='ag-theme-balham'
        >
          <AgGridReact
            reactNext={true}
            reduxStore={store}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.props.dashboard_subframe || []}
            rowSelection='single'
            onGridReady={this.autosizeHeaders.bind(this)}
            frameworkComponents={this.state.frameworkComponents}
          />

          <DateTimeDialog title={this.state.title} />
          <ProblemDialog title={this.state.title} />
          <ActionDialog title={this.state.title} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dashboard_selected: state.dashboard.dashboard_selected,
  dashboard_subframe: state.dashboard.dashboard_subframe,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    dashboardSelected: (payload) => dispatch(dashboardSelected(payload)),
    dispatch,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SubFrameGrid);
