import * as d3 from 'd3';

const shadeColor = (color, percent) => {
  color = color.substr(1);
  let num = parseInt(color, 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

function pinPathCubicBezier(width, height) {
  const offset = 2;
  const deltaX = width * Math.sqrt(3) - offset;
  const deltaY = (height * 4) / 3 - offset;
  return `M 0,0 C ${-deltaX},${-deltaY} 
      ${deltaX},${-deltaY} 0,0 z`;
}
function pinPath(height, radius) {
  const dyAC = height - radius;
  const alpha = Math.acos(radius / dyAC);
  const deltaX = radius * Math.sin(alpha);
  const deltaY = (height * (height - radius * 2)) / dyAC;
  return `M 0,0 
    L ${-deltaX},${-deltaY}
    A ${radius} ${radius} 1 1 1 ${deltaX},${-deltaY} 
    L 0,0 z`;
}
export const createMarkerCube = (width, height, color) => {
  let canvas, ctx;

  const ww = width * 2;
  const hh = height * 2.2;
  canvas = document.createElement('canvas');
  canvas.width = ww;
  canvas.height = hh;

  const x = width / 2;
  const y = height / 2;

  const wx = width;
  const wy = height;
  const h = height * 1.2;

  ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, ww, hh);

  // Fill background for testing
  //ctx.fillStyle = 'rgba(255, 255, 255, 0.6)';
  //ctx.fillRect(0, 0, ww, hh);

  // Move to bottom center
  ctx.translate(x, hh - y);

  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x - wx, y - wx * 0.5);
  ctx.lineTo(x - wx, y - h - wx * 0.5);
  ctx.lineTo(x, y - h * 1);
  ctx.closePath();
  //ctx.fillStyle = shadeColor(color, -10);
  //ctx.strokeStyle = color;
  ctx.fillStyle = color;
  ctx.strokeStyle = 'white';
  ctx.stroke();
  ctx.fill();

  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x + wy, y - wy * 0.5);
  ctx.lineTo(x + wy, y - h - wy * 0.5);
  ctx.lineTo(x, y - h * 1);
  ctx.closePath();
  // ctx.fillStyle = shadeColor(color, 10);
  // ctx.strokeStyle = shadeColor(color, 50);
  ctx.fillStyle = color;
  ctx.strokeStyle = 'white';

  ctx.stroke();
  ctx.fill();

  ctx.beginPath();
  ctx.moveTo(x, y - h);
  ctx.lineTo(x - wx, y - h - wx * 0.5);
  ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
  ctx.lineTo(x + wy, y - h - wy * 0.5);
  ctx.closePath();
  // ctx.fillStyle = shadeColor(color, 20);
  // ctx.strokeStyle = shadeColor(color, 60);
  ctx.fillStyle = color;
  ctx.strokeStyle = 'white';
  ctx.stroke();
  ctx.fill();

  return canvas.toDataURL();
};

export const createMarkerNumber = (width, height, color, text) => {
  let canvas, ctx;

  canvas = document.createElement('canvas');
  canvas.width = width * 1;
  canvas.height = height * 1;

  const x = width / 2;
  const y = height / 2;

  ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, width, height);

  // Fill background for debugging
  // ctx.fillStyle = 'rgba(255, 255, 155, 0.6)';
  // ctx.fillRect(0, 0, width , height );

  // Move origin from top one pixel to avoid clipping
  ctx.translate(0, 1);

  ctx.beginPath();
  // Top arc
  ctx.arc(x, height / 3, (width - 2) / 2, Math.PI, 0, false); // (width -2 ) add -2 to avoid left/right clipping

  // Right bend
  ctx.bezierCurveTo(
    width - 1,
    height / 3 + height / 4,
    x + width / 3,
    y,
    x,
    height - 1
  );

  // Left bend
  ctx.moveTo(1, height / 3);
  ctx.bezierCurveTo(
    1,
    height / 3 + height / 4,
    x - width / 3,
    y,
    x,
    height - 1
  );

  ctx.fillStyle = 'white';
  ctx.fill();
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.stroke();

  let offset = text.length * 3;
  if (text.length <= 2) {
    ctx.font = 'bold 12px Arial';
    ctx.translate(width / 2 - offset, height / 2);
  } else if (text.length === 3) {
    ctx.font = 'bold 11px Arial';
    ctx.translate(width / 2 - offset, height / 2);
  } else {
    ctx.translate(width / 2 - offset + 2, height / 2);
    ctx.font = 'bold 9px Arial';
  }
  ctx.fillStyle = 'black';
  ctx.fillText(text, 0, 0);

  return canvas.toDataURL();
};

export const createMarkerUnserved = (width, height) => {
  let canvas, ctx;

  canvas = document.createElement('canvas');
  canvas.width = width * 1;
  canvas.height = height * 1;

  const centerX = width / 2;
  const centerY = height / 2;

  ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, width, height);
  // Fill background for debugging
  //ctx.fillStyle = 'rgba(255, 255, 155, 0.6)';
  //ctx.fillRect(0, 0, width , height );

  // Draw circle
  ctx.fillStyle = 'red';
  ctx.strokeStyle = 'white';
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.arc(centerX, centerY, width / 2 - 2, 0, Math.PI * 2);
  ctx.fill();
  ctx.stroke();

  // Add text
  ctx.translate(centerX - 4, centerY + centerY / 2 - 2);
  ctx.fillStyle = 'white';
  ctx.font = 'bold 12px Arial';
  ctx.fillText('?', 0, 0);

  return canvas.toDataURL();
};

export const createMarkerAlert = (width, height, color) => {
  let innerBorder = 1;
  let padding = 2;
  // A little math to make life easier
  let phi = Math.tan(width / 2 / height);
  let x = innerBorder / Math.cos(phi);
  let y = x / Math.tan(phi);
  let gamma = Math.sqrt(Math.abs(innerBorder * innerBorder - x * x));

  let canvas, ctx;

  canvas = document.createElement('canvas');
  canvas.width = width * 1;
  canvas.height = height * 1;

  const centerX = width / 2;
  const centerY = height / 2;

  ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, width, height);

  // Fill background for debugging
  // ctx.fillStyle = 'rgba(255, 255, 155, 0.6)';
  // ctx.fillRect(0, 0, width , height );

  ctx.fillStyle = color;
  ctx.strokeStyle = 'white';
  ctx.lineWidth = 2;
  ctx.beginPath();

  ctx.moveTo(centerX - x, padding);
  ctx.quadraticCurveTo(centerX, padding - y, centerX + x, padding);
  //Right corner
  ctx.lineTo(width - padding + gamma, height - gamma - padding);
  ctx.quadraticCurveTo(
    width - padding + y,
    height - padding + innerBorder,
    width - padding,
    height - padding + innerBorder
  );
  // Left Corner
  ctx.lineTo(padding, height - padding + innerBorder);
  ctx.quadraticCurveTo(
    padding - y,
    height - padding + innerBorder,
    padding - gamma,
    height - padding - gamma + innerBorder
  );
  ctx.lineTo(centerX - x, padding);
  ctx.closePath();

  ctx.stroke();
  ctx.fillStyle = color;
  ctx.fill();

  ctx.translate(centerX - 1, centerY + 7);
  ctx.fillStyle = 'white';
  ctx.font = 'bold 12px Arial';
  ctx.fillText('!', 0, 0);

  return canvas.toDataURL();
};

export const markerPinSvgIcon = (width, height, background, border) => {
  const path = pinPathCubicBezier(width, height);
  let radius = Math.min(width, height) / 2;

  let svg = d3.create('svg');
  svg
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + height + ')')
    .append('path')
    .attr('d', path)
    .style('stroke', border)
    .style('fill', background);
  let svgString = new XMLSerializer().serializeToString(svg.node());

  let svgUrl = `data:image/svg+xml,` + encodeURIComponent(svgString);

  return svgUrl;
};

export const markerPinNumberSvgIcon = (
  text,
  width,
  height,
  background,
  border,
  fontSize = '12px'
) => {
  const path = pinPathCubicBezier(width, height);
  let radius = Math.min(width, height) / 2;

  let svg = d3.create('svg');
  svg
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + height + ')')
    .append('path')
    .attr('d', path)
    .style('stroke', border)
    // .style('stroke-width','2%')
    .style('fill', background);

  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', radius - 5)
    .attr('fill', '#ffffff')
    .style('stroke', border);

  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('text')
    .attr('dy', `3px`)
    .style('text-anchor', 'middle')
    .attr('class', 'inner-circle')
    .attr('font-size', fontSize)
    .attr('font-family', 'sans-serif')
    .attr('font-weight', 'bold')
    .attr('fill', 'black')
    .text(() => text);

  let svgString = new XMLSerializer().serializeToString(svg.node());

  let svgUrl = `data:image/svg+xml,` + encodeURIComponent(svgString);

  return svgUrl;
};

export const waypointPinNumberSvgIcon = (
  text,
  width,
  height,
  background,
  border,
  fontSize = '12px'
) => {
  let radius = Math.min(width, height) / 2;

  let svg = d3.create('svg');
  // Leg border
  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('line')
    .attr('x1', 0)
    .attr('y1', 0)
    .attr('x2', 0)
    .attr('y2', height)
    .style('stroke', 'white')
    .style('stroke-width', '7px');
  // Leg inner
  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('line')
    .attr('x1', 0)
    .attr('y1', 0)
    .attr('x2', 0)
    .attr('y2', height)
    .style('stroke', background)
    .style('stroke-width', '5px');

  // Shadow
  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + height + ')')
    .append('ellipse')
    .attr('x1', 0)
    .attr('y1', 0)
    .attr('rx', radius / 3)
    .attr('ry', radius / 8)
    .style('fill', background)
    .style('stroke', 'white');

  // Circle
  svg
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', radius)
    .attr('fill', background)
    .style('stroke', border);

  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', radius - 5)
    .attr('fill', '#ffffff')
    .style('stroke', border);

  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('text')
    .attr('dy', `3px`)
    .style('text-anchor', 'middle')
    .attr('class', 'inner-circle')
    .attr('font-size', fontSize)
    .attr('font-family', 'sans-serif')
    .attr('font-weight', 'bold')
    .attr('fill', 'black')
    .text(() => text);

  let svgString = new XMLSerializer().serializeToString(svg.node());

  let svgUrl = `data:image/svg+xml,` + encodeURIComponent(svgString);

  return svgUrl;
};

export const markerCircleSvgIcon = (width, height, fillColor) => {
  let radius = Math.min(width, height) / 2;

  let svg = d3.create('svg');
  svg.attr('width', width).attr('height', height);

  svg
    .append('g')
    .append('circle')
    .attr('cx', radius)
    .attr('cy', radius)
    .attr('r', radius)
    .attr('fill', fillColor)
    .attr('stroke-width', 2)
    .attr('stroke', '#ffffff');

  let svgString = new XMLSerializer().serializeToString(svg.node());

  let svgUrl = `data:image/svg+xml,` + encodeURIComponent(svgString);

  return svgUrl;
};

export const truckIcon = () => {
  return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="512" height="512">
    <g id="Layer_4" data-name="Layer 4">
        <path d="M56,27A24.001,24.001,0,1,0,15.02942,43.97058c-.118.0173,11.37831,12.10207,15.51281,16.47426a1.99064,1.99064,0,0,0,2.899,0C37.40646,56.2456,48.1698,44.98931,47.93781,44.93781A23.93638,23.93638,0,0,0,56,27Z" style="fill:#42a5f5"/>
        <circle cx="32" cy="27" r="19" style="fill:#eee"/>
    </g>
    <g id="Maps">
        <path d="M28,22V35H20a2.00591,2.00591,0,0,1-2-2V28.47a1.91123,1.91123,0,0,1,.21-.89l2.24-4.47A2.00006,2.00006,0,0,1,22.24,22Z" style="fill:#424242"/>
        <path d="M46,21V33a2,2,0,0,1-2,2H28V21a2,2,0,0,1,2-2H44A2,2,0,0,1,46,21Z" style="fill:#795548"/>
        <circle cx="23" cy="35" r="2" style="fill:#616161"/>
        <path d="M25,22v6H18.05a2.40923,2.40923,0,0,1,.16-.42l2.24-4.47A2.00006,2.00006,0,0,1,22.24,22Z" style="fill:#42a5f5"/>
        <circle cx="37" cy="35" r="2" style="fill:#616161"/>
    </g>
    <g id="Icon">
        <path d="M31.99219,62.07129a3.00826,3.00826,0,0,1-2.17676-.93945C25.4023,56.4503,18.06874,48.81008,14.32227,44.67773a24.99946,24.99946,0,0,1,16.04931-42.626C44.40672.81247,57.26255,12.9709,57,27a25.0285,25.0285,0,0,1-8.39746,18.68506c.09734.17242-9.39543,10.116-14.43408,15.44629A2.96707,2.96707,0,0,1,31.99219,62.07129ZM15.8667,43.42432c.64453.76611,15.40185,16.33349,15.40185,16.33349a.9908.9908,0,0,0,1.44581.00049c3.77062-4.0191,13.102-13.69183,14.55908-15.56738A23.02977,23.02977,0,0,0,55,27C55.285,14.06483,43.41141,2.93032,30.49854,4.04785A23.02171,23.02171,0,0,0,15.8667,43.42432ZM32,47A20,20,0,1,1,52,27,20.02292,20.02292,0,0,1,32,47ZM32,9A18,18,0,1,0,50,27,18.02031,18.02031,0,0,0,32,9Zm12,9H30a3,3,0,0,0-3,3H22.23584a2.9998,2.9998,0,0,0-2.68311,1.65826l-2.23608,4.47211A2.99911,2.99911,0,0,0,17,28.47168V33a3.01347,3.01347,0,0,0,3.18433,3,2.982,2.982,0,0,0,5.6314,0h8.3686a2.982,2.982,0,0,0,5.6314,0H44a3,3,0,0,0,3-3V21A3,3,0,0,0,44,18ZM21.3418,23.55273A.99993.99993,0,0,1,22.23615,23H24v4H19.61816ZM23,36a1,1,0,1,1,1-1A1.001,1.001,0,0,1,23,36Zm4-2H25.81573a2.98208,2.98208,0,0,0-5.63146,0A1.03,1.03,0,0,1,19,33h1a1,1,0,0,0,0-2H19V29h6a.99974.99974,0,0,0,1-1V23h1Zm10,2a1,1,0,1,1,1-1A1.001,1.001,0,0,1,37,36Zm8-3a1,1,0,0,1-1,1H39.81573a2.98208,2.98208,0,0,0-5.63146,0H29V21a1,1,0,0,1,1-1H44a1,1,0,0,1,1,1Z"/>
    </g>
</svg>`;
};
export const hazardIcon = () => {
  return `<svg height="512" viewBox="0 0 60 60" width="512"
  xmlns="http://www.w3.org/2000/svg">
  <g id="Colour">
      <path d="m55 26a25.014 25.014 0 0 1 -15.47 23.12l-8.02 9.19a2 2 0 0 1 -3.02 0l-8.02-9.19a25 25 0 1 1 34.53-23.12z" fill="#d95f3d"/>
      <circle cx="30" cy="26" fill="#f9f9f9" r="20"/>
  </g>
  <g id="Outlines">
      <path d="m40.123 36h-20.246a3.943 3.943 0 0 1 -3.316-5.976l10.123-17.116a3.836 3.836 0 0 1 6.632 0l10.123 17.116a3.943 3.943 0 0 1 -3.316 5.976z" fill="#e69138"/>
  </g>
  <g id="Lines" fill="#fff">
      <path d="m30 27a1 1 0 0 1 -1-1v-9a1 1 0 0 1 2 0v9a1 1 0 0 1 -1 1z"/>
      <path d="m30 32a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1z"/>
  </g>
</svg>`;
};
