import React, { useEffect, useState } from 'react';
import Configs from 'config/config';
import { useSelector, useDispatch } from 'react-redux';
import Mapview, {
  HMapDrawCircle,
  HMapDrawMarker,
  HMapDrawPolygon,
  HMapInfoWindow,
} from 'Components/HereMap';
import { markerPinSvgIcon } from 'Components/Common/MarkerIconRenderer';
import { mapSelectedMarker } from 'reducers/map';
import ImgVdoPreview from './ImgVdoPreview';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import Loading from 'Components/HereMap/Components/Loading';

const gUtils = require('@googlemaps/google-maps-services-js/dist/util');
const env = process.env.NODE_ENV;

const HazardPointListMap = (props) => {
  const { mapRef } = props;
  const [bbox, setBbox] = useState(null);
  const dispatch = useDispatch();
  const hazard_points = useSelector((state) => {
    return state.map.hazard_points;
  });

  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });

  const markerSelecting = useSelector((state) => {
    return state.map.marker_selecting;
  });

  useEffect(() => {
    let bounds = [];

    if (hazard_points && hazard_points.length > 0) {
      hazard_points.forEach((p) => {
        if (p) {
          bounds.push([p.latitude, p.longitude]);
        }
      });
    }

    if (bounds.length > 0) {
      setBbox(getBbox(bounds));
    }
  }, [hazard_points]);

  const renderCircle = (c) => {
    return (
      <>
        {renderMarkerPoint(c)}
        <HMapDrawCircle
          circle={{
            center: { lat: c.circleLatitude, lng: c.circleLongitude },
            radius: c.radius,
          }}
          options={{ style: { fillColor: 'rgba(0, 85, 170, 0.4)' } }}
        />
      </>
    );
  };
  const renderMarkerPoint = (m) => {
    let isOpen = markerSelecting?.id === m.id;
    let video = null;
    let photo = [];

    if (isOpen) {
      if (m && m.mediaUrl) {
        m.mediaUrl.forEach(function (row) {
          if (row !== null) {
            let files = row.split('.');
            if (files[files.length - 1].toLowerCase().includes('mp4'))
              video = row;
            else photo.push(row);
          }
        });
      }
    }
    const selectedMarkerFunction = (d) => {
      return {
        pointerdown: (_) => {
          dispatch(mapSelectedMarker(d));
        },
      };
    };
    return (
      <HMapDrawMarker
        marker={{ lat: m.latitude, lng: m.longitude }}
        options={{
          icon: markerPinSvgIcon(30, 40, '#3B538B', '#ffffff'),
        }}
        objectEvents={selectedMarkerFunction(m)}
      >
        <HMapInfoWindow
          size={
            video || photo.length > 0
              ? { width: 430, height: 320 }
              : { width: 380, height: 260 }
          }
          isOpen={isOpen}
          onClose={() => {
            dispatch(mapSelectedMarker(null));
          }}
        >
          <ImgVdoPreview
            inputPhotos={photo}
            inputVdo={video}
            info={{
              name: m?.warning || '',
              description: m?.detail || '',
            }}
          />
        </HMapInfoWindow>
      </HMapDrawMarker>
    );
  };
  const renderPolygon = (p) => {
    let paths = gUtils.decodePath(p.polygon);
    return (
      <>
        {renderMarkerPoint(p)}
        <HMapDrawPolygon
          polygon={paths}
          options={{
            style: {
              fillColor: 'rgba(0, 85, 170, 0.4)',
              strokeColor: 'rgba(0, 85, 170, 0.6)',
            },
          }}
        />
      </>
    );
  };
  return (
    <>
      {map_loading && <Loading />}
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={bbox}
      >
        {hazard_points.map((p) => {
          if (p.type === 'circle') {
            return renderCircle(p);
          }
          if (p.type === 'polygon') {
            return renderPolygon(p);
          }
          return <div />;
        })}
      </Mapview>
    </>
  );
};
export default HazardPointListMap;
