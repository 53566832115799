import { Check } from "@material-ui/icons";
import { createAction, handleActions } from "redux-actions";

export const dashboardManagement = createAction("DASHBOARD_MANAGEMENT");
export const dashboardOperation = createAction("DASHBOARD_OPERATION");
export const dashboardFrame = createAction("DASHBOARD_FRAME");
export const dashboardSubFrame = createAction("DASHBOARD_SUBFRAME");
export const dashboardRecovery = createAction("DASHBOARD_RECOVERY");

export const dashboardPlant = createAction("DASHBOARD_PLANT");
export const dashboardRouteSelect = createAction("DASHBOARD_ROUTE_SELECT");
export const dashboardRoute = createAction("DASHBOARD_ROUTE");
export const dashboardOpenTimeDialog = createAction("DASHBOARD_TIME_DIALOG");
export const dashboardOpenProblemDialog = createAction(
  "DASHBOARD_PROBLEM_DIALOG"
);
export const dashboardOpenProblemRemarkDialog = createAction(
  "DASHBOARD_PROBLEM_REMARK_DIALOG"
);
export const dashboardOpenActionDialog = createAction(
  "DASHBOARD_ACTION_DIALOG"
);

export const dashboardSelected = createAction("DASHBOARD_SELECTED");
export const operDashboardSelected = createAction("OPER_DASHBOARD_SELECTED");
export const dashboardFrameSelected = createAction("DASHBOARD_FRAME_SELECTED");
export const dashboardSubFrameOpen = createAction("DASHBOARD_SUBFRAME_OPEN");

const initialState = {
  dashboard_management: null,
  dashboard_operation: null,
  dashboard_frame: null,
  dashboard_subframe: null,
  dashboard_plant: null,
  // dashboard_yard: null,
  dashboard_route_select:null,
  dashboard_route: null,
  dashboard_yard_parser: null,
  dashboard_time_dialog: null,
  dashboard_problem_dialog: null,
  dashboard_problem_remark_dialog: null,
  dashboard_action_dialog: null,
  dashboard_selected: null,
  oper_dashboard_selected: null,
  dashboard_recovery: null,
  dashboard_frame_selected: null,
  dashboard_subframe_open: false,
};
function parser(data) {
  let dataValue = [];
  data.forEach((element) => {
    let object = {};
    Object.keys(element).forEach((key) => {
      if (key === "version" || key === "planAccessSeq")
        object[key] = element[key];
      else object[key] = element[key].value;
    });
    dataValue.push(object);
  });
  return dataValue;
}
// REDUCERS
export default handleActions(
  {
    [dashboardManagement](state, { payload }) {
      return { ...state, dashboard_management: payload };
    },
    [dashboardOperation](state, { payload }) {
      return {
        ...state,
        dashboard_operation: payload,
        dashboard_operation_parser: parser(payload),
      };
    },
    [dashboardFrame](state, { payload }) {
      return {
        ...state,
        dashboard_frame: payload,
        dashboard_frame_parser: parser(payload),
      };
    },
    [dashboardSubFrame](state, { payload }) {
      return {
        ...state,
        dashboard_subframe: payload,
        dashboard_subframe_parser: parser(payload),
      };
    },
    [dashboardRecovery](state, { payload }) {
      return { ...state, dashboard_recovery: payload };
    },

    [dashboardSelected](state, { payload }) {
      return { ...state, dashboard_selected: payload };
    },
    [operDashboardSelected](state, { payload }) {
      return { ...state, oper_dashboard_selected: payload };
    },
    [dashboardFrameSelected](state, { payload }) {
      return { ...state, dashboard_frame_selected: payload };
    },
    [dashboardSubFrameOpen](state, { payload }) {
      return { ...state, dashboard_subframe_open: payload };
    },

    //Plant && Yard
    [dashboardPlant](state, { payload }) {
      return { ...state, dashboard_plant: payload };
    },
    // [dashboardYard](state, { payload }) {
    //   return { ...state, dashboard_yard: payload };
    // },
     [dashboardRouteSelect](state, { payload }) {
      return { ...state, dashboard_route_select: payload };
    },
    [dashboardRoute](state, { payload }) {
      return { ...state, dashboard_route: payload };
    },

    //Dialog
    [dashboardOpenTimeDialog](state, { payload }) {
      return { ...state, dashboard_time_dialog: payload };
    },
    [dashboardOpenProblemDialog](state, { payload }) {
      return { ...state, dashboard_problem_dialog: payload };
    },
    [dashboardOpenProblemRemarkDialog](state, { payload }) {
      return { ...state, dashboard_problem_remark_dialog: payload };
    },
    [dashboardOpenActionDialog](state, { payload }) {
      return { ...state, dashboard_action_dialog: payload };
    },
  },
  initialState
);
