import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import store, { history } from 'reducers/rootReducer';
import Login from './Components/Login';
import { Route, Switch } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { loadLiterals } from './reducers/literals';
import loadLang from './i18n';
import Cookies from 'js-cookie';
import Configs from './config/config';
import { SnackbarProvider } from 'notistack';

import './index.css';

const lang = loadLang();
const env = process.env.NODE_ENV;

// Cookie Timer
var clearCookieTimer = null;

const clearCookieFunction = () => {
  Cookies.remove('user_sid');
};

// clearCookieTimer = setTimeout(clearCookieFunction, Configs[env].IDLE_TIMEOUT);
// const eventHandler = (_) => {
//   if (clearCookieTimer) {
//     clearTimeout(clearCookieTimer);
//     clearCookieTimer = null;
//     clearCookieTimer = setTimeout(
//       clearCookieFunction,
//       Configs[env].IDLE_TIMEOUT
//     );
//   }
// };
// // Listen window events and reset clear cookie timer
// const listenerEvents = ['mousemove', 'click', 'keypress'];
// for (let n = 0; n < listenerEvents.length; n++) {
//   window.addEventListener(listenerEvents[n], eventHandler);
// }

store.dispatch(loadLiterals(lang));

const Root = () => {
  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <SnackbarProvider
          maxSnack={3}
          ref={notistackRef}
          action={(key) => (
            <Button
              variant='text'
              style={{ color: 'white' }}
              onClick={onClickDismiss(key)}
            >
              Dismiss
            </Button>
          )}
        >
          <Switch>
            <Route path='/login' component={Login} />
            <App />
          </Switch>
        </SnackbarProvider>
      </ConnectedRouter>
    </Provider>
  );
};
export default Root;
