import { handleActions } from 'redux-actions';
// ACTION CREATORS
import { createAction } from 'redux-actions';
export const selectLogisticPoint = createAction('SELECT_LOGISTIC_POINT');
export const selectLocationGroup = createAction('SELECT_LOCATION_GROUP');
export const selectCategory = createAction('SELECT_CATEGORY');
export const selectJobDashboardId = createAction('SELECT_JOB_DASHBOARD');
export const selectShiftSeq = createAction('SELECT_JOB_SHIFSEQ');
export const selectImpactHazard = createAction('SELECT_IMPACT_HAZARD');

export const selectReset = createAction('SELECT_RESET');

const initialState = {
  logisticPoint: {
    text: '',
    value: '',
  },
  locationGroup: {
    text: '',
    value: '',
    idSelect: '',
  },
  category: {
    text: '',
    value: '',
  },
  shiftSeq: null,
  jobDashboardId: null,
  selected_impact: null,
};

export default handleActions(
  {
    [selectLogisticPoint](state, { payload }) {
      return {
        ...state,
        logisticPoint: { text: payload.text, value: payload.value },
      };
    },
    [selectLocationGroup](state, { payload }) {
      let idSelect = payload.value !== '' ? JSON.parse(payload.value).id : '';
      return {
        ...state,
        locationGroup: {
          text: payload.text,
          value: payload.value,
          idSelect: idSelect,
        },
      };
    },
    [selectCategory](state, { payload }) {
      return {
        ...state,
        category: { text: payload.text, value: payload.value },
      };
    },
    [selectJobDashboardId](state, { payload }) {
      return { ...state, jobDashboardId: payload };
    },
    [selectShiftSeq](state, { payload }) {
      return { ...state, shiftSeq: payload };
    },
    [selectImpactHazard](state, { payload }) {
      return { ...state, selected_impact: payload };
    },
    [selectReset](state, { payload }) {
      return {
        ...state,
        logisticPoint: {
          text: '',
          value: '',
        },
        locationGroup: {
          text: '',
          value: '',
          idSelect: '',
        },
        category: {
          text: '',
          value: '',
        },
        jobDashboardId: null,
        selected_impact: null,
      };
    },
  },
  initialState
);
