import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//Component
import DeliveryChart from './DeliveryChart';
import ETADelayChart from './ETADelayChart';
import CriticalRoutes from './CriticalRoutes';
import PlantSelect from './PlantSelect';
import ActionBoxes from './ActionBoxes';

import { getManagement } from '../../reducers/dashboard/api';
import { setSidebarVisibility, SET_SNACKBAR } from '../../reducers/ui';
import { searchManage } from '../../reducers/search';

import TimerMachine from 'react-timer-machine';
import RouteSelectManage from './RouteSelect';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  card: {
    // maxWidth: 345,
    // margin: theme.spacing.unit,
  },
  media: {
    // height: 140,
  },
  margin: {
    // margin: theme.spacing.unit,
  },
});

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      last_updated: '',
      started: true,
      timeStart: 180,
      fetching: false,
      manualReload: false,
      isLoading: false,
      tooltip_action: <div style={{ whiteSpace: 'pre-line' }}>{''}</div>,
    };
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;

    setSidebarVisibility(false);
    this.getData(this.props.search_manage);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.search_manage != this.props.search_manage) {
      this.getData(nextProps.search_manage);
    }
    if (nextProps.dashboard_management != this.props.dashboard_management) {
      // console.log('#componentWillReceiveProps actionNow',nextProps.dashboard_management.actionNow);
      this.setTooltipActionNow(nextProps.dashboard_management.actionNow);
    }
  }

  manualReload(search_manage) {
    this.getData(search_manage);
    this.setState({ manualReload: true });
  }

  getData = (search_manage) => {
    this.setState({ fetching: true, started: false, isLoading: true });

    let filter = {};
    if (search_manage.plant) filter.plant = search_manage.plant.join(',');
    if (search_manage.route) filter.route = search_manage.route.join(',');

    this.props.getManagement({
      url: Configs[env].BACKEND_HOST + '/managementDashboard',
      // url: "https://map.leafte.ch/dashboard/management_no_bar",
      method: 'GET',
      filter: filter,
      callback: this.updateTime,
      callback_error: this.updateTime_error,
    });
  };
  setTooltipActionNow = (actionNow) => {
    let tooltip_action = '';
    for (let item of actionNow) {
      tooltip_action +=
        item.routeCode +
        ' ' +
        item.runSeq +
        ' ' +
        item.logisticsPartner +
        ' ' +
        item.truckLicense +
        '\n';
    }

    this.setState({
      tooltip_action: (
        <div
          style={{ whiteSpace: 'pre-line', fontSize: '13px', padding: '5px' }}
        >
          {tooltip_action}
        </div>
      ),
    });
  };
  updateTime = () => {
    this.setState({ last_updated: moment().format('YYYY/MM/DD HH:mm:ss') });
    this.setState({ started: true, fetching: false, isLoading: false });

    if (this.state.manualReload) {
      this.props.SET_SNACKBAR({
        snackbarOpen: true,
        snackbarVariant: 'success',
        snackbarMessage: 'Manual reload data success',
        snackbarDuration: 2000,
      });
    }

    this.setState({ manualReload: false });
  };
  updateTime_error = (error) => {
    this.setState({
      started: true,
      fetching: false,
      isLoading: false,
      manualReload: false,
    });
  };

  handleSearch = () => {
    this.getData(this.props.search_manage);
  };

  handleSnackbarClose = (event, reason) => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'calc(100vh - 75px)',
          overflow:"auto" 
        }}
      >
        <div
          style={{
            width: 'calc(100vw - 75px)',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              width: '300px',
              textAlign: 'center',
              verticalAlign: 'middle',
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
            }}
          >
            <Button
              disabled={this.state.isLoading}
              variant='contained'
              size='small'
              color='primary'
              className={classes.margin}
              onClick={() => this.manualReload(this.props.search_manage)}
            >
              Update
            </Button>
            <Button
              size='medium'
              className={classes.margin}
              disabled={this.state.isLoading}
              onClick={() => this.manualReload(this.props.search_manage)}
            >
              {this.state.last_updated &&
                moment(this.state.last_updated).format('YYYY/MM/DD HH:mm:ss')}
              &nbsp;(
              <TimerMachine
                timeStart={this.state.timeStart * 1000} // start at 10 seconds
                timeEnd={0} // end at 20 seconds
                started={this.state.started}
                paused={false}
                countdown={true} // use as stopwatch
                interval={1000} // tick every 1 second
                formatTimer={(time, ms) =>
                  moment.duration(ms, 'milliseconds').format('h:mm:ss')
                }
                // onStart={time =>
                //   console.info(`Timer started: ${JSON.stringify(time)}`)
                // }
                // onStop={time =>
                //   console.info(`Timer stopped: ${JSON.stringify(time)}`)
                // }
                // onTick={time =>
                //   console.info(`Timer ticked: ${JSON.stringify(time)}`)
                // }
                // onPause={time =>
                //   console.info(`Timer paused: ${JSON.stringify(time)}`)
                // }
                // onResume={time =>
                //   console.info(`Timer resumed: ${JSON.stringify(time)}`)
                // }
                onComplete={(time) => {
                  this.getData(this.props.search_manage);
                }}
              />
              )
            </Button>
          </div>
          <div style={{ flex: 1, marginLeft: '2em' }}>
            <PlantSelect />
          </div>
          <div style={{ flex: 1, marginLeft: '2em' }}>
            <RouteSelectManage />
          </div>
        </div>
        <div style={{ width: 'calc(100vw - 75px)', marginTop: '0.6em' }}>
          <ActionBoxes
            tooltip_action={this.state.tooltip_action}
            summary={this.props.summary}
          />
        </div>

        <div
          style={{
            width: 'calc(100vw - 75px)',
            marginTop: '0.5em',
            flex: '1',
            display: 'flex',
            height: '300px',
            flexDirection: 'column',
          }}
        >
          <Card style={{ height: '100%' }}>
            <CardContent style={{ height: '100%' }}>
              <DeliveryChart />
            </CardContent>
          </Card>
        </div>

        <div
          style={{
            width: 'calc(100vw - 75px)',
            marginTop: '0.5em',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Card
            className={classes.card}
            style={{ width: '380px', padding: '0px' }}
          >
            <CardContent style={{ padding: '0px' }}>
              <Typography
                style={{ marginTop: '1.1em' }}
                variant='subtitle2'
                component='h2'
                align='center'
              >
                ETA delay reason :
              </Typography>
              <ETADelayChart />
            </CardContent>
          </Card>

          <Card
            className={classes.card}
            style={{
              flex: '3',
              marginLeft: '0.5em',
              display: 'flex',
              paddingBottom: '16px',
            }}
          >
            <CardContent
              style={{
                width: 'calc(100vw - 75px)',
                paddingTop: '3px',
                paddingBottom: '20px',
              }}
            >
              <Typography variant='subtitle2' component='h2' align='left'>
                Critical route watch list :{' '}
                {/*(Delay from part usage time/Truck damage/Truck accident)*/}
              </Typography>
              <CriticalRoutes style={{ marginTop: '0.5em' }} />
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isReset: state.map.isReset,
  formManage: state.ui.formManage,
  dashboard_management: state.dashboard.dashboard_management,
  search_manage: state.search.search_manage,
  summary: state.dashboard.dashboard_management
    ? state.dashboard.dashboard_management.summary
    : {},
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    getManagement: (payload) => dispatch(getManagement(payload)),
    searchManage: (payload) => dispatch(searchManage(payload)),
    SET_SNACKBAR: (payload) => dispatch(SET_SNACKBAR(payload)),
    dispatch,
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Main);
