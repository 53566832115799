import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import ManagementDashboard from 'Components/ManagementDashboard';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'reducers/rootReducer';

import Users from 'Components/User';
import Setting from 'Components/Configuration/Setting';
import EventLogs from 'Components/Configuration/EventLogs';
import SystemMaster from 'Components/SystemMaster';
import OperationDashboard from 'Components/OperationDashboard';
import FrameDashboard from 'Components/FrameDashboard';
import LocationMaster from 'Components/LocationMaster';
import HazardPoints from 'Components/HazardPoints';
import ImpactHazard from 'Components/ImpactHazard';
import RouteMaster from 'Components/RouteMaster';
import RouteSuggestion from 'Components/RouteSuggestion';
import Tracking from 'Components/Tracking';
import ErrorComponent from 'Components/Common/ErrorPage';
import { setSidebarVisibility } from 'reducers/ui';

const ContentRoutes = (props) => {
  const { dispatch } = props;
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          path='/dashboard/management_dash'
          component={() => <ManagementDashboard name='ManagementDashboard' />}
        />
        <Route
          path='/dashboard/operation_dash'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <OperationDashboard name='OperationDashboard' />;
          }}
        />
        <Route
          path='/dashboard/frame_dash'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <FrameDashboard name='FrameDashboard' />;
          }}
        />
        <Route
          path='/route_suggestion/:jobDashboardId'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <RouteSuggestion name='Route Suggestion' />;
          }}
        />
        <Route
          path='/tracking/:jobDashboardId'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <Tracking name='Route Suggestion' />;
          }}
        />
        <Route
          path='/master/location_master'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <LocationMaster name='Location Master' />;
          }}
        />
        <Route
          path='/master/route_master'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <RouteMaster name='Route Master' />;
          }}
        />
        <Route
          path='/master/hazard_point'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <HazardPoints name='Hazard Points' />;
          }}
        />
        <Route
          path='/master/impact_hazard/:routeCode?/:runSeq?'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <ImpactHazard name='Impact Hazard' />;
          }}
        />
        <Route
          path='/config/users'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <Users name='Users' />;
          }}
        />
        <Route
          path='/config/settings'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <Setting name='System Configuration' />;
          }}
        />
        <Route
          path='/config/system_master'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <SystemMaster name='System Master' />;
          }}
        />
        <Route
          path='/config/event_logs'
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <EventLogs name='EventLogs' />;
          }}
        />
        <Route
          path='/'
          exact
          component={() => {
            return <Redirect to='/dashboard/operation_dash' />;
          }}
        />
        <Route
          path='*'
          component={() => {
            return (
              <ErrorComponent
                title={'Page not found :('}
                message={
                  'Maybe the page you are looking for has been removed, or you typed in the wrong URL'
                }
              />
            );
          }}
        />
      </Switch>
    </ConnectedRouter>
  );
};
export default withRouter(ContentRoutes);
