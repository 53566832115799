import React, { Component } from 'react';
import moment from 'moment';
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Loading from '../Ui/Loading';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Toolbar from '@material-ui/core/Toolbar';
import compose from 'recompose/compose';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

//import momentLocaliser from 'react-widgets/lib/localizers/moment'
import 'react-widgets/dist/css/react-widgets.css';
//Component

//Lib
import { required, number, email, match } from '../../libs/validation';
//Reducer
import { apiCallRequest } from '../../reducers/api';
import { setSidebarVisibility, uiSwitchForm } from '../../reducers/ui';
import { settingUserSelect, settingOpenNewPass } from '../../reducers/setting';
import {
  getUsers,
  getGroupCompanies,
  getCompanies,
  getLevel,
} from '../../reducers/setting/api';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: '200px',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
  },
  center: {
    position: 'relative',
    left: '20px',
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderSelect = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <Select
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

class ManageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawingMode: null,
      marker: null,
      now: new Date(),
      category: 0,
      openNewpassDialog: false,
      disableCompany: true,
      disableLevel: true,
    };
  }

  componentWillMount() {}
  componentDidMount() {
    this.getGroupCompanyItem();
    //this.getLevelItem();
    if (this.props.userForm) {
      this.props.initialize({ ...this.props.userForm });

      if (this.props.userForm.id)
        this.setState({ userId: this.props.userForm.id });
      if (this.props.userForm.groupId) {
        this.getCompanyItem(this.props.userForm.groupId);
        this.setState({ groupId: this.props.userForm.groupId });
      }
      if (this.props.userForm.companyId) {
        this.setState({ companyId: this.props.userForm.companyId });
        this.props.change('companyId', this.props.userForm.companyId);
        this.getLevelItem();
      }
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === 'groupId') {
      this.setState({
        companyId: null,
        disableCompany: true,
        levelId: null,
        disableLevel: true,
      });
      this.props.change('companyId', null);
      this.props.change('levelId', null);
      this.getCompanyItem(event.target.value);
    }
    if (event.target.name === 'companyId') {
      this.setState({ levelId: null, disableLevel: true });
      this.props.change('levelId', null);
      this.getLevelItem();
    }
  };
  handleSave = (form) => {
    if (form.companyId !== 1 && form.levelId === 1) {
      this.props.change('levelId', null);
    } else {
      let formData = { ...form };
      delete formData.groupId;
      delete formData.confirmPassword;

      if (this.props.mode.toUpperCase() === 'EDIT') {
        delete formData.password;
        let id = formData.id;
        delete formData.id;

        this.props.API_REQUEST({
          url: Configs[env].BACKEND_HOST + '/user/' + id,
          form: { ...formData },
          method: 'PUT',
          callback: this.handleReset.bind(this),
        });
      } else {
        this.props.API_REQUEST({
          url: Configs[env].BACKEND_HOST + '/user',
          form: { ...formData },
          method: 'POST',
          callback: this.handleReset.bind(this),
        });
      }
    }
  };
  //Group Company
  getGroupCompanyItem() {
    this.props.getGroupCompanies({
      url: Configs[env].BACKEND_HOST + '/companyGroup',
      method: 'GET',
      filter: {},
    });
  }
  renderGroupCompanyItem() {
    if (this.props.setting_groupcompanies) {
      var menuItem = this.props.setting_groupcompanies;
      return menuItem.map(function (data, index) {
        return (
          <MenuItem key={index} value={data.id} detail={data.name}>
            {data.name}
          </MenuItem>
        );
      });
    } else return null;
  }
  //Company
  getCompanyItem(groupId) {
    this.props.getCompanies({
      url: Configs[env].BACKEND_HOST + '/companyCond',
      method: 'GET',
      filter: { groupId: groupId },
      callback: this.checkCompany,
    });
  }
  checkCompany = (data) => {
    // console.log('#checkCompany data',data);
    if (data.list && data.list.length > 0)
      this.setState({ disableCompany: false });
  };
  renderCompanyItem() {
    if (this.props.setting_companies) {
      var menuItem = this.props.setting_companies;
      return menuItem.map(function (data, index) {
        return (
          <MenuItem key={index} value={data.id} detail={data.name}>
            {data.name}
          </MenuItem>
        );
      });
    } else return null;
  }
  //Level
  getLevelItem() {
    this.props.getLevel({
      url: Configs[env].BACKEND_HOST + '/level',
      method: 'GET',
      filter: {},
      callback: this.checkLevel,
    });
  }
  checkLevel = (data) => {
    // console.log('#checkLevel data',data);
    if (data.list && data.list.length > 0)
      this.setState({ disableLevel: false });
  };
  renderLevelItem() {
    if (this.props.setting_userlevel && this.state.companyId) {
      // console.log('#renderLevelItem setting_userlevel',this.props.setting_userlevel);
      var menuItem = this.props.setting_userlevel;
      // console.log('#renderLevelItem companyId', this.state.companyId);
      // console.log('#renderLevelItem groupId', this.state.groupId);

      if (this.state.groupId == 1 && this.state.companyId == 1) {
        delete menuItem[3];
        delete menuItem[4];
      }
      if (this.state.groupId == 1 && this.state.companyId == 2) {
        delete menuItem[0];
        delete menuItem[2];
      }
      //Supplier
      // if (this.state.groupId == 3 && this.state.companyId == 8) {
      //   delete menuItem[0];
      //   delete menuItem[1];
      //   delete menuItem[3];
      // }
      if (this.state.groupId == 2) {
        delete menuItem[0];
        delete menuItem[3];
        delete menuItem[4];
      }
      return menuItem.map(function (data, index) {
        return (
          <MenuItem key={index} value={data.id} detail={data.name}>
            {data.name}
          </MenuItem>
        );
      });
    } else return null;
  }
  handleNewPassword = (event) => {
    this.props.settingUserSelect(this.props.userForm.id);
    this.props.settingOpenNewPass(true);
  };
  handleClick = (event) => {
    this.props.OPEN_DIALOG(true);
  };
  handleReset = () => {
    this.props.reset();
    this.props.uiSwitchForm({ page: 'list', mode: '' });
    this.getUsers();
  };
  getUsers = () => {
    this.props.getUsers({
      url: Configs[env].BACKEND_HOST + '/user',
      filter: {},
    });
  };
  render() {
    const { handleSubmit, submitting, classes } = this.props;

    return (
      <div style={{ padding: '10px' }}>
        {/*this.props.isLoading && <Loading />*/}
        <Toolbar variant='dense'>
          <Typography variant='h6' gutterBottom className={styles.titlePanel}>
            {this.props.mode.toUpperCase()} USER
          </Typography>
        </Toolbar>
        <Card className={styles.card}>
          <CardContent>
            <Form
              //style={{ overflowY:"scroll"}}
              autoComplete='new-password'
              autoCorrect='off'
              spellCheck='off'
              onSubmit={handleSubmit(this.handleSave.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth className={styles.formControl}>
                    <InputLabel shrink htmlFor='groupcompany-field'>
                      GROUP COMPANY
                    </InputLabel>
                    <Field
                      onChange={this.handleChange}
                      name='groupId'
                      fullWidth
                      component={renderSelect}
                      label='GROUP COMPANY'
                      InputLabelProps={{
                        shrink: true,
                        id: 'groupcompany-field',
                      }}
                      margin='dense'
                      className={styles.textField}
                      validate={[required]}
                    >
                      {this.renderGroupCompanyItem()}
                    </Field>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth className={styles.formControl}>
                    <InputLabel shrink htmlFor='company-field'>
                      COMPANY
                    </InputLabel>
                    <Field
                      onChange={this.handleChange}
                      name='companyId'
                      fullWidth
                      component={renderSelect}
                      disabled={this.state.disableCompany}
                      label='COMPANY'
                      InputLabelProps={{
                        shrink: true,
                        id: 'company-field',
                      }}
                      margin='dense'
                      className={styles.textField}
                      validate={[required]}
                    >
                      {this.renderCompanyItem()}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    className={styles.formControl}
                    autoComplete='new-password'
                    autoCorrect='off'
                    spellCheck='off'
                  >
                    <Field
                      fullWidth
                      name='email'
                      type='email'
                      autoComplete='new-password'
                      autoCorrect='off'
                      spellCheck='off'
                      disabled={this.props.userForm.id ? true : false}
                      component={renderInput}
                      className={styles.textField}
                      label='Email'
                      validate={[required, email]}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth className={styles.formControl}>
                    <InputLabel shrink htmlFor='level-field'>
                      LEVEL
                    </InputLabel>
                    <Field
                      //onChange={this.handleChange}
                      name='levelId'
                      fullWidth
                      component={renderSelect}
                      label='LEVEL'
                      disabled={this.state.disableLevel}
                      InputLabelProps={{
                        shrink: true,
                        id: 'level-field',
                      }}
                      margin='dense'
                      className={styles.textField}
                      validate={[required]}
                    >
                      {this.renderLevelItem()}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Field
                    fullWidth
                    name='firstName'
                    autoComplete='new-password'
                    autoCorrect='off'
                    spellCheck='off'
                    component={renderInput}
                    className={styles.textField}
                    label='FirstName'
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    className={styles.formControl}
                    autoComplete='new-password'
                    autoCorrect='off'
                    spellCheck='off'
                  >
                    <Field
                      fullWidth
                      autoComplete='new-password'
                      autoCorrect='off'
                      spellCheck='off'
                      name='lastName'
                      component={renderInput}
                      className={styles.textField}
                      label='LastName'
                      validate={[required]}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  {this.props.userForm.id && (
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      aria-label='Change Password'
                      className={styles.button}
                      onClick={this.handleNewPassword}
                    >
                      CHANGE PASSWORD
                    </Button>
                  )}
                  {!this.props.userForm.id && (
                    <Field
                      fullWidth
                      autoComplete='new-password'
                      autoCorrect='off'
                      spellCheck='off'
                      name='password'
                      type='password'
                      component={renderInput}
                      className={styles.textField}
                      label='Password'
                      validate={[required]}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {!this.props.userForm.id && (
                    <Field
                      fullWidth
                      autoComplete='new-password'
                      autoCorrect='off'
                      spellCheck='off'
                      name='confirmPassword'
                      type='password'
                      component={renderInput}
                      className={styles.textField}
                      label='Confirm Password'
                      validate={[required, match]}
                    />
                  )}
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
              <Grid item xs={12}>
                <DialogActions>
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    aria-label='Save'
                    className={styles.button}
                    disabled={this.props.isLoading}
                    type='submit'
                  >
                    {this.props.isLoading && (
                      <CircularProgress
                        className={classes.center}
                        size={25}
                        thickness={2}
                      />
                    )}
                    SAVE
                  </Button>
                  <Button
                    variant='outlined'
                    size='small'
                    color='secondary'
                    aria-label='Add'
                    disabled={this.props.isLoading}
                    className={classNames(styles.button, styles.cssGreen)}
                    onClick={this.handleReset}
                  >
                    BACK
                  </Button>
                </DialogActions>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    isLoading: state.ui.isLoading,
    userForm: state.mapForm.userForm,
    setting_groupcompanies: state.setting.setting_groupcompanies,
    setting_companies: state.setting.setting_companies,
    setting_userlevel: state.setting.setting_userlevel,
    mode: state.ui.formManage.mode == 'edit' ? 'EDIT' : 'ADD',
  };
};
const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  uiSwitchForm,
  settingUserSelect,
  settingOpenNewPass,
  getGroupCompanies,
  getCompanies,
  getLevel,
  getUsers,
};

const enhance = compose(
  reduxForm({
    form: 'usersForm',
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(ManageForm);
