import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import ManageBtnRenderer from './Renderer/ManageBtnRenderer';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import store from 'reducers/rootReducer';

const styles = (theme) => ({});
class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          cellRenderer: 'manageBtnRenderer',
          width: 80,
        },
        { headerName: 'Company', field: 'company' },
        { headerName: 'Email', field: 'email' },
        { headerName: 'FirstName', field: 'firstName' },
        { headerName: 'LastName', field: 'lastName' },
        { headerName: 'Level', field: 'level', width: 100 },
      ],
      rowData: null,
      defaultColDef: { sortable: true, resizable: true },
      frameworkComponents: {
        manageBtnRenderer: ManageBtnRenderer,
      },
    };
  }
  componentDidUpdate() {
    this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {}
  renderData = () => {
    if (this.props.setting_users && this.props.setting_users.length > 0) {
      //filter text in location point
      if (this.props.textSearchUsers === '') return this.props.setting_users;
      else {
        let result = [];
        let findText = this.props.textSearchUsers;
        this.props.setting_users.forEach((user, i) => {
          if (
            user.company.toUpperCase().search(findText.toUpperCase()) > -1 ||
            user.email.toUpperCase().search(findText.toUpperCase()) > -1 ||
            user.firstName.toUpperCase().search(findText.toUpperCase()) > -1 ||
            user.lastName.toUpperCase().search(findText.toUpperCase()) > -1
          )
            result.push(user);
        });

        return result;
      }
    } else return [];
  };

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };

  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function (column, index) {
        if (index !== 0) allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };

  render() {
    return (
      <div style={{ width: '100%', height: '400px' }}>
        <div
          className='ag-theme-balham'
          style={{
            height: '98%',
            width: '100%',
          }}
        >
          <AgGridReact
            reactNext={true}
            reduxStore={store}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderData()}
            rowSelection='multiple'
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    setting_users: state.setting.setting_users,
    textSearchUsers: state.search.search_users,
  };
};
const mapActionsToProps = {};

export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
)(Users);
