import React, { Component } from 'react';
import { propTypes, reduxForm, Form, Field } from 'redux-form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import compose from 'recompose/compose';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
//Lib
import { required, number } from '../../libs/validation';
//Conponent
//Reducer
import {
  formLogisticRoute,
  formUpdaateLogisticRouteId,
} from '../../reducers/ui/mapForm';
import { uiSwitchForm } from '../../reducers/ui';
import {
  mapDrawingMode,
  SET_ROUTESVIEW,
  MAP_SELECT_CANDIDATE,
  mapSetMasterRoutes,
  mapSetLogisticRoutes,
} from '../../reducers/map';
import { clearState } from 'reducers/hmap';

import { apiCallRequest } from '../../reducers/api';
//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  center: {
    position: 'relative',
    left: '20px',
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

class LogisticRouteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawingMode: null,
      name: '',
      logisticsRouteId: null,
      marker: {
        latitude: 0,
        longitude: 0,
      },
    };
  }

  componentWillMount() {
    if (this.props.logisticRouteForm.originId) {
      this.props.initialize({ ...this.props.logisticRouteForm });
      this.props.ROUTE_FORM({ ...this.props.logisticRouteForm });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.master_routes !== nextProps.master_routes) {
      this.props.initialize({ ...nextProps.logisticRouteForm });
    }
    if (this.props.logisticRouteForm !== nextProps.logisticRouteForm) {
      this.props.initialize({ ...nextProps.logisticRouteForm });
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSave = (form) => {
    this.props.SET_ROUTESVIEW([]);
    this.props.MAP_SELECT_CANDIDATE(-1);
    this.props.clearState();

    let formData = {
      originId: this.props.logisticRouteForm.originId,
      destinationId: this.props.logisticRouteForm.destinationId,
      name: form.name,
    };

    let name = form.name;
    this.props.ROUTE_FORM({ ...this.props.logisticRouteForm, name });

    if (this.props.logisticRouteForm.logisticsRouteId) {
      this.props.API_REQUEST({
        url:
          Configs[env].BACKEND_HOST +
          '/logisticsRoute/' +
          this.props.logisticRouteForm.logisticsRouteId,
        form: { name: name },
        method: 'PUT',
        callback: this.handleComplete,
      });
    } else {
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + '/logisticsRoute',
        form: { ...formData },
        method: 'POST',
        callback: this.handleComplete,
      });
    }
  };
  handleComplete = (response) => {
    if (response.id) {
      this.props.formUpdaateLogisticRouteId(response.id);
    }
  };
  handleClick(event) {
    this.props.onDialogOpen(true);
  }
  handleClickBack(event) {
    this.props.clearState();
    this.props.SWITCH_FORM({ page: 'list' });
    this.props.MAP_SELECT_CANDIDATE(-1);
    this.props.mapSetMasterRoutes([]);
    this.props.mapSetLogisticRoutes([]);
  }
  handleDrawingMode(mode, event) {
    this.setState({ drawingMode: mode }, this.setMode);
  }
  setMode() {
    this.props.DRAWING_MODE(this.state.drawingMode);
  }
  render() {
    const { handleSubmit, classes } = this.props;

    return (
      <Form
        autoComplete={'off'}
        autoCorrect={'off'}
        spellCheck={'off'}
        onSubmit={handleSubmit(this.handleSave.bind(this))}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              label='Name'
              name='name'
              component={renderInput}
              className={styles.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin='dense'
              validate={[required]}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              disabled
              name='originName'
              component={renderInput}
              label='Origin'
              className={styles.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin='dense'
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              disabled
              name='destinationName'
              component={renderInput}
              label='Destination'
              className={styles.textField}
              InputLabelProps={{
                shrink: true,
              }}
              margin='dense'
            />
          </Grid>
          <Grid item xs={12}>
            <DialogActions>
              <Button
                variant='contained'
                size='small'
                color='primary'
                aria-label='Save'
                className={styles.button}
                type='submit'
                disabled={this.props.isLoading}
              >
                {this.props.isLoading && (
                  <CircularProgress
                    className={classes.center}
                    size={25}
                    thickness={2}
                  />
                )}
                Save
              </Button>
              <Button
                variant='outlined'
                size='small'
                color='secondary'
                aria-label='Add'
                className={classNames(styles.button, styles.cssGreen)}
                onClick={this.handleClickBack.bind(this)}
                disabled={this.props.isLoading}
              >
                BACK
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Form>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    isLoading: state.ui.isLoading,
    formManage: state.ui.formManage,
    drawingMode: state.map.drawingMode,
    marker: state.map.marker,
    circle: state.map.circle,
    polygon: state.map.polygon,
    logisticRouteForm: state.mapForm.logisticRouteForm,
    master_routes: state.map.master_routes,
    mode: state.ui.formManage.mode || ownProps.mode === 'edit' ? 'EDIT' : 'ADD',
  };
};
const mapActionsToProps = {
  SWITCH_FORM: uiSwitchForm,
  DRAWING_MODE: mapDrawingMode,
  API_REQUEST: apiCallRequest,
  ROUTE_FORM: formLogisticRoute,
  formUpdaateLogisticRouteId: formUpdaateLogisticRouteId,
  SET_ROUTESVIEW: SET_ROUTESVIEW,
  MAP_SELECT_CANDIDATE: MAP_SELECT_CANDIDATE,
  mapSetMasterRoutes: mapSetMasterRoutes,
  mapSetLogisticRoutes: mapSetLogisticRoutes,
  clearState: clearState,
};

const enhance = compose(
  reduxForm({
    form: 'logisticsRouteForm',
    // validate
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(LogisticRouteForm);
