import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import EditIcon from '@material-ui/icons/Create';
import { apiCallRequest } from '../../../reducers/api';
import { mapPropsStream } from 'recompose';
import { red } from '@material-ui/core/colors';

const styles = (theme) => ({
  gridButton: {
    padding: '0px 0px',
    minWidth: '50px',
    fontSize: '0.8em',
    minHeight: '20px',
  },
  iconStyle: {
    padding: 0,
  },
});
class StatusOperationRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      color: props.value.color, //this.parserColor(props.value),
      text: props.value.value, //this.parser(props.value),
    };
  }
  // parserColor(value){
  //     switch(value){
  //         case "3": return "red"
  //         default :  return ""
  //     }
  // }
  // parser(value){

  //     switch(value){
  //         case "0":  return "ON TIME"
  //         case "1":  return "DELAY"
  //         case "2":  return "CRITICAL DELAY"
  //         case "3":  return "DELAY2"
  //         default :  return ""
  //     }
  // }
  render() {
    const { classes } = this.props;

    return (
      <div style={{ backgroundColor: this.state.color, textAlign: 'center' }}>
        {this.state.text}
      </div>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
  };
};

const mapActionsToProps = {};
const enhance = compose(
  connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true }),
  withStyles(styles)
);

export default enhance(StatusOperationRenderer);
