import React,{Component} from 'react';
import { connect } from "react-redux";
import { propTypes, reduxForm,Form, Field } from 'redux-form';
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import DialogActions from "@material-ui/core/DialogActions";
import classNames from "classnames";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Loading from '../Ui/Loading';
import DataLoadingBox from "../Map/DataLoadingBox";
import { SET_DATA_LOADING } from "../../reducers/map";

//Reducer
import { setSidebarVisibility,uiSwitchForm } from "../../reducers/ui";
import { exportEventLogs } from "../../reducers/setting/api";
//Lib
import { required, number ,betweenDateTime} from "../../libs/validation";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({

});

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
  }) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      format="YYYY/MM/DD" 
      {...inputProps}
      {...props}
    />
  );
  
class EventLogs extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
          configs : null
      };
    }
    componentDidMount() {
        const { setSidebarVisibility } = this.props;
        setSidebarVisibility(false);
        this.props.initialize({
            dateFrom:moment().format("YYYY-MM-DD"),
            dateTo:moment().format("YYYY-MM-DD"),
        });
        this.props.SET_DATA_LOADING(false);
      }
    handleExport = form =>{
        let prepare_filter = JSON.parse(JSON.stringify(form))

        prepare_filter.dateFrom = moment(form.dateFrom,'YYYY-MM-DD').format('YYYY/MM/DD') + " 00:00"
        prepare_filter.dateTo = moment(form.dateTo,'YYYY-MM-DD').format('YYYY/MM/DD') + " 23:59"


        this.props.exportEventLogs({
            url: Configs[env].BACKEND_HOST + "/eventLog/download",
            filter: prepare_filter,
            callback : this.completeData,
        });
    }
    completeData= (response) => {
      this.props.SET_DATA_LOADING(false);
    }
    
    render() {
        
        const { handleSubmit, submitting } = this.props;
        return (
            <Card className={styles.card}>
                <CardHeader title="Event Logs"/>
                <CardContent>
                <Form
                autoComplete={"off"}
                autoCorrect={"off"}
                spellCheck={"off"}
                onSubmit={handleSubmit(this.handleExport.bind(this))}
                > 
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        
                        <Field
                        name="dateFrom"
                        label="DATE FROM"
                        type="date"
                        component={renderInput}
                        className={styles.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="dense"
                        validate={[required,betweenDateTime]}
                        /> 
                    </Grid>
                    <Grid item xs={3}>
                         <Field
                        name="dateTo"
                        label="DATE TO"
                        type="date"
                        component={renderInput}
                        className={styles.textField}
                        onChange={this.handleChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                        margin="dense"
                        validate={[required,betweenDateTime]}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    { (this.props.data_loading) &&
                      <DataLoadingBox/>
                    }</Grid>
                    <Grid item xs={3}>
                        <DialogActions>
                        
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            aria-label="Save"
                            className={styles.button}
                            type="submit"
                            disabled={!JSON.parse(localStorage.getItem('privilege')).configuration.controllable}
                        >
                            EXPORT USER EVENT LOGS
                        </Button>
                        </DialogActions>
                    </Grid>
                </Grid>
                
                
                </Form>
            </CardContent>
          </Card>
        );
    }
}


const mapStateToProps = function(state, ownProps) {
    return {
      data_loading : state.map.data_loading
    };
  };
  const mapActionsToProps = {
    
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
      exportEventLogs:payload => dispatch(exportEventLogs(payload)),
      SET_DATA_LOADING:payload => dispatch(SET_DATA_LOADING(payload)),
      dispatch
    };
  };
  
const enhance = compose(
    reduxForm({
      form: "exportEventLog",
    }),
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    withStyles(styles)
  );
  export default enhance(EventLogs);
   