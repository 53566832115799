import React, { useEffect, useState } from 'react';
import Configs from 'config/config';
import { useSelector } from 'react-redux';
import Mapview, {
  HMapPlaceSearch,
  HMapDrawMarker,
  HMapPolyline,
} from 'Components/HereMap';
import {
  markerCircleSvgIcon,
  markerPinNumberSvgIcon,
} from 'Components/Common/MarkerIconRenderer';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import { hmapSelector } from 'reducers/hmap';
import Loading from 'Components/HereMap/Components/Loading';

const env = process.env.NODE_ENV;

const RouteMasterMapList = (props) => {
  const { mapRef } = props;
  const [bbox, setBbox] = useState(null);
  const [candidatePolyline, setCandidatePolyline] = useState(null);
  const [masterPolyline, setMasterPolyline] = useState(null);

  const show_master_or_candidate = useSelector((state) => {
    return state.map.show_master_or_candidate;
  });
  const marker_origin = useSelector((state) => {
    return state.map.marker_origin;
  });
  const marker_desc = useSelector((state) => {
    return state.map.marker_desc;
  });
  const candidate_selecting = useSelector((state) => {
    return state.map.candidate_selecting;
  });
  const masterPolylineList = useSelector((state) => {
    return state.map.masterPolylineList;
  });

  useEffect(() => {
    if (
      marker_origin &&
      marker_origin.lat &&
      marker_origin.lng &&
      marker_desc &&
      marker_desc.lat &&
      marker_desc.lng
    ) {
      setBbox(
        getBbox([
          [marker_origin.lat, marker_origin.lng],
          [marker_desc.lat, marker_desc.lng],
        ])
      );
    }
  }, [marker_origin, marker_desc]);
  // console.log('candidate_selecting', candidate_selecting);
  // console.log('show_master_or_candidate', show_master_or_candidate);
  const { isRouteLoading } = useSelector(hmapSelector);

  useEffect(() => {
    if (candidate_selecting && show_master_or_candidate === 1) {
      setMasterPolyline({
        polyline: candidate_selecting.MergeRoute,
        waypoints: candidate_selecting.waypoints,
      });
      setCandidatePolyline(null);
    }
    if (candidate_selecting && show_master_or_candidate === 2) {
      setMasterPolyline(null);
      setCandidatePolyline(candidate_selecting.MergeRoute);
    }
  }, [candidate_selecting, show_master_or_candidate]);
  return (
    <>
      {isRouteLoading && <Loading />}
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={bbox}
      >
        {marker_origin && marker_origin.lat && marker_origin.lng && (
          <HMapDrawMarker
            marker={marker_origin}
            options={{
              icon: markerPinNumberSvgIcon('A', 30, 40, '#4c00b0', '#ffffff'),
            }}
          />
        )}
        {marker_desc && marker_desc.lat && marker_desc.lng && (
          <HMapDrawMarker
            marker={marker_desc}
            options={{
              icon: markerPinNumberSvgIcon('B', 30, 40, '#ff0000', '#ffffff'),
            }}
          />
        )}
        {show_master_or_candidate === 1 &&
          masterPolylineList &&
          masterPolylineList.map((p, idx) => {
            return (
              <HMapPolyline
                key={idx}
                polyline={p.polyline}
                options={{ color: '#a0a0a0' }}
              />
            );
          })}
        {candidatePolyline && (
          <HMapPolyline
            polyline={candidatePolyline}
            options={{ isArrowStyle: true, color: '#880ED4' }}
          />
        )}
        {masterPolyline?.polyline && (
          <HMapPolyline
            polyline={masterPolyline.polyline}
            options={{ isArrowStyle: true }}
          />
        )}
        {masterPolyline?.waypoints &&
          masterPolyline?.waypoints.map((p, wpIdx) => {
            return (
              <HMapDrawMarker
                key={wpIdx}
                marker={{ lat: p[0], lng: p[1] }}
                options={{
                  icon: markerCircleSvgIcon(14, 14, '#404040'),
                  anchor: { x: 7, y: 7 },
                }}
              />
            );
          })}
        <HMapPlaceSearch />
      </Mapview>
    </>
  );
};
export default RouteMasterMapList;
