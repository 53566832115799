export function showError(enqueueSnackbar, err) {
  if (!enqueueSnackbar) {
    return;
  }
  if (!err.message) {
    return enqueueSnackbar(JSON.stringify(err), {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      persist: true,
    });
  }
  let errorMsgs = err.message.split(':');
  if (errorMsgs.length >= 2) {
    errorMsgs.shift();
    let displayMsg = errorMsgs.join(':');

    return enqueueSnackbar(displayMsg, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      persist: true,
    });
  } else {
    return enqueueSnackbar('Somethings went wrong. Please try again.', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      persist: true,
    });
  }
}
