import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { searchOperation } from 'reducers/search';

const OptionsCheckbox = (props) => {
  const dispatch = useDispatch();
  const searchOperationState = useSelector((state) => {
    return state.search.search_operation;
  });
  const checkboxStatus = {
    isGrouped:
      searchOperationState && searchOperationState.isGrouped ? true : false,
    isPriority:
      searchOperationState && searchOperationState.isPriority ? true : false,
    isHistory:
      searchOperationState && searchOperationState.isHistory ? true : false,
  };

  const handleChangeCheckbox = (checkboxType, value) => {
    let newStatus = {
      ...searchOperationState,
      ...checkboxStatus,
      [checkboxType]: value,
    };

    if (value) {
      newStatus = {
        ...searchOperationState,
        isGrouped: false,
        isPriority: false,
        isHistory: false,
        [checkboxType]: value,
      };
    }

    dispatch(searchOperation(newStatus));
  };
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            className='checkBox'
            checked={checkboxStatus.isGrouped}
            onChange={(e) => {
              handleChangeCheckbox('isGrouped', e.target.checked);
            }}
            name='group'
            color='primary'
          />
        }
        label='GROUP'
      />
      <FormControlLabel
        control={
          <Checkbox
            className='checkBox'
            checked={checkboxStatus.isPriority}
            onChange={(e) => {
              handleChangeCheckbox('isPriority', e.target.checked);
            }}
            name='priority'
            color='primary'
          />
        }
        label='PRIORITY'
      />
      <FormControlLabel
        control={
          <Checkbox
            className='checkBox'
            checked={checkboxStatus.isHistory}
            onChange={(e) => {
              handleChangeCheckbox('isHistory', e.target.checked);
            }}
            color='primary'
            name='history'
          />
        }
        label='HISTORY'
      />
    </>
  );
};

export default OptionsCheckbox;
