/**
 * @type {string} Default version for the API
 */
const VERSION = 'v3/3.1';
const MAP_TYPE = 'normal.map';
const _test = true;
const mapTypes = {
  normal: [
    'xbase',
    'xbasenight',
    'base',
    'basenight',
    'map',
    'mapnight',
    'traffic',
    'trafficnight',
    'transit',
    'panorama',
    'panoramanight',
    'labels',
    'metaInfo',
  ],
  satellite: ['xbase', 'base', 'map', 'traffic', 'panorama', 'labels'],
  terrain: ['xbase', 'base', 'map', 'traffic', 'panorama', 'labels'],
  incidents: _test,
  venues: _test,
};

const mapOptions = {
  zoom: 14,
  center: {
    lat: 13.7196045,
    lng: 100.5241515,
  },
  pixelRatio: window.devicePixelRatio || 1,
};
const useEvents = false;
const interactive = true;
const includeUI = true;

const _mapEvents = [
  'pointerdown',
  'pointerup',
  'pointermove',
  'pointerenter',
  'pointerleave',
  'pointercancel',
  'dragstart',
  'drag',
  'dragend',
  'tab',
  'dbltap',
];

const includePlaces = false;

// Function that does really nothing, still it is a function, and has its right!
const noop = () => {};
let mapEvents = {};
_mapEvents.map((name) => (mapEvents[name] = noop));

const defaultOptions = {
  VERSION,
  mapTypes,
  mapEvents,
  MAP_TYPE,
  mapOptions,
  interactive,
  includeUI,
  includePlaces,
  useEvents,
};

export default defaultOptions;
