import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import green from '@material-ui/core/colors/green';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
//import AutoSelect from 'react-select';
import { connect } from 'react-redux';
import { searchOperation } from '../../reducers/search';

import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  formControl: {
    margin: theme.spacing.unit,
    //minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  padding5px: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    margin: '10px',
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
  },
  selectStyles: {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  },
});

class ETAMultiSelect extends Component {
  state = {
    etaList: ['supplier', 'plant', 'yard'],
    value: [],
  };
  renderMenuItem() {
    let self = this;
    var menuItem = this.state.etaList;
    return menuItem.map(function (value, index) {
      return (
        <MenuItem key={value} value={value}>
          <Checkbox
            checked={
              self.props.search_operation.etaType &&
              self.props.search_operation.etaType.indexOf(value) > -1
                ? true
                : false
            }
          />
          <ListItemText primary={value.toUpperCase()} />
        </MenuItem>
      );
    });
  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
    this.props.searchOperation({
      ...this.props.search_operation,
      etaType: event.target.value,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <FormControl className={classes.textField}>
        <InputLabel shrink htmlFor='etaSelect-required'>
          ETA
        </InputLabel>
        <Select
          multiple
          name='etaSelection'
          width={100}
          inputProps={{
            id: 'etaSelect-required',
          }}
          value={
            this.props.search_operation.etaType
              ? this.props.search_operation.etaType
              : []
          }
          onChange={this.handleChange}
          renderValue={(selected) =>
            selected.map((a) => a.toUpperCase()).join(',')
          }
        >
          {this.renderMenuItem()}
        </Select>
      </FormControl>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
    search_operation: state.search.search_operation,
  };
};
const mapActionsToProps = {
  searchOperation,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ETAMultiSelect));
