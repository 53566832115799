import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import green from '@material-ui/core/colors/green';
import { getLogisticPoint } from '../../reducers/map/api';
import { selectLocationGroup } from '../../reducers/ui/selectList';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  padding5px: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    margin: '10px',
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
  },
  selectStyles: {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  },
});

const LocationGroupSelect = (props) => {
  const { classes } = props;
  const location_group = useSelector((state) => {
    return state.map.location_group;
  });
  const locationGroup = useSelector((state) => {
    return state.selection.locationGroup;
  });
  const selectedValue = useMemo(() => {
    if (locationGroup && locationGroup.value) {
      let gValue = JSON.parse(locationGroup.value);

      return gValue.id;
    } else {
      return '';
    }
  }, [locationGroup]);

  const handleChange = (event) => {
    let selectedGroup = location_group.find((g) => {
      return g.id === event.target.value;
    });
    let payload = {
      value: JSON.stringify(selectedGroup),
      text: selectedGroup.name,
    };
    props.selectLocationGroup(payload);

    //Data for create Marker Group
    props.GET_LOGISTIC_POINTS({
      url: Configs[env].BACKEND_HOST + '/locationCond',
      filter: {
        logisticsPointGroup: selectedGroup.logisticsPointGroup,
        locationGroupId: selectedGroup.id,
      },
    });
  };

  const renderMenuItem = () => {
    if (location_group !== undefined) {
      return location_group.map(function (data, index) {
        return (
          <MenuItem key={index} value={data.id}>
            {data.name}
          </MenuItem>
        );
      });
    } else return null;
  };

  return (
    <FormControl required fullWidth>
      <InputLabel shrink htmlFor='location-required'>
        LOCATION GROUP
      </InputLabel>
      <Select
        testing='select-location-group'
        value={selectedValue}
        onChange={handleChange}
        styles={classes.selectStyles}
        name='location'
        disabled={props.disabled}
        fullWidth
        placeholder='Search Location Group'
        inputProps={{
          id: 'location-required',
        }}
      >
        {renderMenuItem()}
      </Select>
    </FormControl>
  );
};

const mapActionsToProps = {
  selectLocationGroup,
  GET_LOGISTIC_POINTS: getLogisticPoint,
};

export default connect(
  null,
  mapActionsToProps
)(withStyles(styles)(LocationGroupSelect));
