import { takeLatest, select, call, put } from 'redux-saga/effects';
import axios from 'axios';

import FileSaver from 'file-saver';
import { exportOperation, exportFrame } from '../reducers/dashboard/api';
import { exportHazardPoints, exportHazardLogs } from '../reducers/map/api';
import { exportEventLogs } from '../reducers/setting/api';

import Configs from '../config/config';
import { SET_SNACKBAR } from '../reducers/ui';
import { SET_DATA_LOADING } from '../reducers/map';
const env = process.env.NODE_ENV;
// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(exportOperation, exportWorkerSaga);
  yield takeLatest(exportFrame, exportWorkerSaga);
  yield takeLatest(exportHazardPoints, exportWorkerSaga);
  yield takeLatest(exportEventLogs, exportWorkerSaga);
  yield takeLatest(exportHazardLogs, exportWorkerSaga);
}

function paramsUrl(data) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURI(data[key])}`)
    .join('&');
}

function fetchDataExport(params) {
  let url = params.payload.url + '?' + paramsUrl(params.payload.filter);
  let headers = {
    Accept: 'application/json',
    // 'Accept-Encoding' : 'gzip',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem('token'),
  };
  return axios({
    url: url,
    method: 'GET',
    /*responseType: 'arraybuffer',
        headers: {
            'Accept': 'application/pdf'
        }*/
    headers: headers,
    responseType: 'blob', // important
  })
    .then((response) => {
      if (response.headers['content-disposition']) return response;
      else {
        return axios({
          url: url,
          method: 'GET',
          headers: headers,
        })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            // console.log('error2', error);
            return { catchError: error };
          });
      }
    })
    .catch((error) => {
      // console.log('error', error);
      return { catchError: error };
    });
}

function* exportWorkerSaga(action) {
  try {
    yield put({ type: SET_DATA_LOADING, payload: true });
    //if(state.ui.formManage.page=='list'){
    const resp = yield call(fetchDataExport, action);
    //console.log('resp',resp);
    const response = yield checkError(resp, action);

    //console.log('response',response);
    if (response !== false) {
      let fileName = '';
      if (action.type === exportOperation.toString())
        fileName = 'OperationDash';
      else if (action.type === exportOperation.toString())
        fileName = 'FrameDash';
      else if (action.type === exportHazardPoints.toString())
        fileName = 'HazardPoints';
      else if (action.type === exportEventLogs.toString())
        fileName = 'EventLogs';
      //const blob = new Blob([response.data], {type: 'application/pdf',});
      //response.data.filename = response.headers['content-disposition'].split('filename="')[1].split('";')[0]
      //FileSaver.saveAs(blob,response.data.filename);
      response.data.filename = response.headers['content-disposition']
        .split('filename="')[1]
        .split('";')[0];
      FileSaver.saveAs(response.data, response.data.filename);
      yield put({ type: SET_DATA_LOADING, payload: false });
    }
  } catch (error) {
    yield put({ type: SET_DATA_LOADING, payload: false });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

//===============================
function* checkError(response, action) {
  let params = action;
  let callback = params.payload.callback;
  let callback_error = params.payload.callback_error;

  if (response.hasOwnProperty('catchError')) {
    if (callback_error) callback_error(response.catchError.toString());
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: response.catchError.toString(),
        snackbarDuration: 20000,
      },
    });
    yield put({ type: SET_DATA_LOADING, payload: false });
    return false;
  }

  if (parseInt(response.status) < 200 || parseInt(response.status) > 200) {
    if (callback_error)
      callback_error('HTTP ERROR : status code = ' + response.status);
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'HTTP ERROR : status code = ' + response.status,
        snackbarDuration: 20000,
      },
    });
    yield put({ type: SET_DATA_LOADING, payload: false });
    return false;
  }
  if (response.data.hasOwnProperty('errorList')) {
    let errorShow = '';

    if (response.data.errorList.length > 0) {
      if (callback_error) callback_error(errorShow);

      errorShow = response.data.errorList[0].errorDetail;
      //console.info("errorDetail",response.data.errorList[0].errorDetail)

      if (parseInt(response.data.errorList[0].errorCode) === 4201) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
          },
        });
        //window.location.href = "/dashboard/operation_dash";
        yield put({ type: SET_DATA_LOADING, payload: false });
        return false;
      }

      if (
        parseInt(response.data.errorList[0].errorCode) === 2102 ||
        parseInt(response.data.errorList[0].errorCode) === 2103
      ) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
            forceLogout: true,
          },
        });
        yield put({ type: SET_DATA_LOADING, payload: false });
        return false;
      }

      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: errorShow,
          snackbarDuration: 20000,
        },
      });
      yield put({ type: SET_DATA_LOADING, payload: false });
      return false;
    }
  }

  //console.log('resp',response);
  if (response.hasOwnProperty('headers')) {
    if (callback) callback(response);
    // yield put({
    //     type: SET_SNACKBAR,
    //     payload: {
    //         snackbarOpen: true,
    //         snackbarVariant: "success",
    //         snackbarMessage: "Manual reload data success",
    //         snackbarDuration: 2000
    //     }
    // });
    yield put({ type: SET_DATA_LOADING, payload: false });
    return response;
  }

  yield put({
    type: SET_SNACKBAR,
    payload: {
      snackbarOpen: true,
      snackbarVariant: 'error',
      snackbarMessage: 'API error',
      snackbarDuration: 20000,
    },
  });
  yield put({ type: SET_DATA_LOADING, payload: false });

  return false;
}
