import React from 'react';
import { Avatar, Typography } from '@material-ui/core';

const TollRenderer = (props) => {
  const { value } = props;
  return (
    <div>
      {value === true && (
        <Avatar
          alt='Toll'
          sizes={20}
          src='/img/toll_use.png'
          style={{ width: 20, height: 20 }}
        />
      )}
      {value === false && (
        <Typography
         
          style={{ margin: 'auto', textAlign: 'center' }}
        >
          -
        </Typography>
      )}
    </div>
  );
};

export default TollRenderer;
