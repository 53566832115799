import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { uiSwitchForm } from '../../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ListIcon from '@material-ui/icons/List';
// import EditIcon from '@material-ui/icons/Create';
import { apiCallRequest } from '../../../reducers/api';
import { searchOperation } from '../../../reducers/search';
import { getOperation } from '../../../reducers/dashboard/api';
import {
  dashboardSubFrameOpen,
  dashboardFrameSelected,
} from '../../../reducers/dashboard';
//Config
import Configs from '../../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  gridButton: {
    padding: '0px 0px',
    minWidth: '50px',
    fontSize: '0.8em',
    minHeight: '20px',
  },
  iconStyle: {
    padding: 0,
  },
});
function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class ManageBtnRenderer extends Component {
  constructor(props) {
    super(props);

    this.invokeParentMethodOpen = this.invokeParentMethodOpen.bind(this);
    this.state = {
      open: false,
      name: this.props.data.jobDashboardId.value,
    };
  }
  handleCancel = () => {
    this.setState({ open: false });
  };
  invokeParentMethodOpen() {
    //this.setState({open : true});
    this.props.dashboardSubFrameOpen(
      this.props.dashboard_subframe_open ? false : true
    );
  }
  render() {
    const { classes } = this.props;
    let privilege = JSON.parse(localStorage.getItem('privilege'));
    return (
      <span>
        <IconButton
          className={classes.iconStyle}
          onClick={this.invokeParentMethodOpen}
          disabled={
            privilege && privilege.hasOwnProperty('configuration')
              ? !privilege.configuration.controllable
              : true
          }
        >
          <ListIcon />
        </IconButton>
      </span>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
    search_operation: state.search.search_operation,
    dashboard_subframe_open: state.dashboard.dashboard_subframe_open,
  };
};

const mapActionsToProps = {
  getOperation,
  searchOperation,
  apiCallRequest,
  dashboardFrameSelected,
  dashboardSubFrameOpen,
};
const enhance = compose(
  connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true }),
  withStyles(styles)
);

export default enhance(ManageBtnRenderer);
