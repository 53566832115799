import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

//Component
import CategoryIconRenderer from '../HazardPoints/Renderer/CategoryIconRenderer';
//Reducer
import { apiGetCallRequest } from '../../reducers/api';
import {
  mapSetLogisticPoint,
  mapSelectedMarker,
  MAP_LOG,
  SET_HAZARD_PHOTO,
  ADD_HAZARD_VDO,
  MAP_SELECT_SUGGESSION,
  mapSetSuggestionPolylines,
  mapSetBounds,
  SET_MARKER_ORIGIN_DESC,
  mapResetMap,
  MAP_SELECT_ROUTE,
  mapSetMarkers,
  mapSetPolygons,
  mapSetCircles,
} from '../../reducers/map';
import store from 'reducers/rootReducer';
import { decode } from '@liberty-rider/flexpolyline';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;
const gUtils = require('@googlemaps/google-maps-services-js/dist/util');

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin: 0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200,
  },
});
class Impacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pointList: this.props.hazard_impact,
      url_endpoint: {
        response: false,
        endpoint: Configs[env].BACKEND_HOST,
      },
      columnDefs: [
        {
          headerName: 'Plan Arrival Time',
          field: 'planArrivalTime',
          width: 60,
        },
        {
          headerName: 'Route',
          field: 'routeCode',
          width: 60,
        },
        {
          headerName: 'Run Seq.',
          field: 'runSeq',
          width: 50,
        },
        {
          headerName: 'ETA',
          field: 'eta',
          width: 60,
        },
        {
          headerName: 'Part Usage Time',
          field: 'partUsageTime',
          width: 80,
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 100,
        },
      ],
      rowData: null,
      context: { componentParent: this },
      defaultColDef: { sortable: true, resizable: true },
      frameworkComponents: {
        categoryIconRender: CategoryIconRenderer,
      },
    };
  }
  componentDidMount() {
    this.autoSizeAll();
  }
  componentWillUnmount() {}
  componentDidUpdate() {
    this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {
    this.focusRow(nextProps.marker_selecting);
  }
  focusRow = (marker_selecting) => {
    let gridApi = this.gridApi;
    if (gridApi) {
      gridApi.deselectAll();
      this.gridApi.forEachNode(function (node) {
        if (node.data.latitude === marker_selecting) {
          node.setSelected(true, true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' });
        }
      });
    }
  };

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function (column, index) {
        if (index !== 0) allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  renderRow() {
    if (Array.isArray(this.props.hazard_impact)) {
      //this.props.setLogisticPoint(this.props.hazard_impact);

      //filter text in location point
      if (this.props.textSearchLocation === '') return this.props.hazard_impact;
      else {
        let result = [];
        let findText = this.props.textSearchLocation;
        this.props.hazard_impact.forEach((v) => {
          if (
            v.name.toUpperCase().search(findText.toUpperCase()) > -1 ||
            v.logistic_point.toUpperCase().search(findText.toUpperCase()) > -1
          )
            result.push(v);
        });

        return result;
      }
    } else return [];
  }

  setPolyline = (routes) => {
    let bounds = [];
    if (routes) {
      let polygonList = [];
      let polygon = {};
      // var decodePath = gUtils.decodePath(decodeURIComponent(routes));
      var paths = [];
      let decodedResult = decode(decodeURIComponent(routes));
      let decodePath = decodedResult.polyline;
      decodePath.forEach(function (p) {
        paths.push({ lat: p[0], lng: p[1] });
        bounds.push({ lat: p[0], lng: p[1] });
      });
      polygon = {
        id: 1,
        path: decodePath,
        isGroup: false,
        editable: false,
      };
      polygonList.push(polygon);
      // this.props.MAP_SELECT_SUGGESSION(1)
      this.props.mapSetSuggestionPolylines(polygonList);
      this.props.SET_MARKER_ORIGIN_DESC({
        marker_origin: {
          lat: paths[0].lat,
          lng: paths[0].lng,
        },
        marker_desc: {
          lat: paths[paths.length - 1].lat,
          lng: paths[paths.length - 1].lng,
        },
      });

      this.props.MAP_SELECT_SUGGESSION(1);
      this.props.MAP_SELECT_ROUTE(routes.routeCode);
      this.props.mapSetBounds(bounds);
    }
  };

  setHazard = (route) => {
    let markerList = [];
    let marker = {};

    // routeCode: "KC09"
    // routes: null
    // runSeq: "01"
    route.hazard.forEach((point, p) => {
      //Marker
      marker = {
        ...point,
        routeCode: route.routeCode,
        hid: point.id,
        id: p + '_' + point.id,
        position: { lat: point.latitude, lng: point.longitude },
        info: {
          name: point.location,
          description: point.detail,
          logisticsPoint: point.category,
        },
        isGroup: false,
        category: point.category,
        editable: false,
      };
      //markerList.push(marker);
      markerList.push(marker);
    });
    this.props.mapSetMarkers(markerList);
  };

  setPolygon = (route) => {
    let polygonList = [];
    let polygon = {};
    route.hazard.forEach((point) => {
      var decodePath = gUtils.decodePath(point.polygon);
      var paths = [];
      decodePath.forEach(function (p) {
        paths.push({ lat: p.lat, lng: p.lng });
      });
      polygon = {
        id: point.routeCode,
        path: decodePath,
        // info : {
        //   name : point.location,
        //   description : point.detail,
        //   logisticsPoint : point.category ,
        // },
        isGroup: false,
        editable: false,
      };
      //polygonList.push(polygon);
      polygonList.push(polygon);
    });
    this.props.mapSetPolygons(polygonList);
  };

  setCircle = (route) => {
    let circleList = [];
    let circle = {};
    route.hazard.forEach((point) => {
      //Circle
      if (point.type === 'circle') {
        circle = {
          id: point.id,
          center: { lat: point.circleLatitude, lng: point.circleLongitude },
          radius: point.radius,
          info: {
            name: point.location,
            description: point.detail,
            logisticsPoint: point.category,
          },
          isGroup: false,
          editable: false,
        };
        circleList.push(circle);
      }
    });
    this.props.mapSetCircles(circleList);
  };
  handleSelectionChanged = (node) => {
    try {
      this.props.mapResetMap();
      this.props.mapSetSuggestionPolylines([]);

      let selectedRows = node.api.getSelectedRows();

      this.setPolyline(selectedRows[0].routes);
      this.setHazard(selectedRows[0]);
      this.setPolygon(selectedRows[0]);
      this.setCircle(selectedRows[0]);
    } catch (e) {}
  };
  render() {
    return (
      <div style={{ width: '100%', height: 'calc(100vh - 500px)' }}>
        <div
          className='ag-theme-balham'
          style={{
            height: 'calc(100vh - 500px)',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <AgGridReact
            reactNext={true}
            reduxStore={store}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderRow()}
            rowSelection='single'
            onRowClicked={this.handleSelectionChanged}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    isLoading: state.api.fetching,
    logistic_point: state.map.logistic_point,
    hazard_impact: state.map.hazard_impact,
    textSearchLocation: state.search.search_location,
    marker_selecting: state.map.marker_selecting,
  };
};
const mapActionsToProps = {
  getImpacts: apiGetCallRequest,
  setLogisticPoint: mapSetLogisticPoint,
  onSelectedMarker: mapSelectedMarker,
  SELECT_MARKER: mapSelectedMarker,
  MAP_SELECT_SUGGESSION,
  mapSetSuggestionPolylines,
  SET_MARKER_ORIGIN_DESC,
  mapSetBounds,
  mapResetMap,
  MAP_LOG: MAP_LOG,
  SET_HAZARD_PHOTO,
  ADD_HAZARD_VDO,
  MAP_SELECT_ROUTE,
  mapSetMarkers,
  mapSetPolygons,
  mapSetCircles,
};

export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
)(Impacts);
