import {
  Dashboard,
  PieChart,
  ViewList,
  Directions,
  Place,
  EditLocation,
  Warning,
  Person,
  Settings,
  Ballot,
  NewReleases,
  Build,
  History,
} from '@material-ui/icons';

const menuRoutes = () => {
  let privilege = JSON.parse(localStorage.getItem('privilege'));
  let isMaster = false;
  let isDashboard = false;
  if (
    privilege &&
    !privilege.locationMaster?.visible &&
    !privilege.routeMaster?.visible &&
    !privilege.inputHazard?.visible &&
    !privilege.impactHazard?.visible
  ) {
    isMaster = true;
  }
  if (
    privilege &&
    !privilege.managementDash?.visible &&
    !privilege.operationDash?.visible &&
    !privilege.sequenceDash?.visible
  ) {
    isDashboard = true;
  }

  return {
    dashboardListItems: {
      itemName: 'Dashboard',
      itemIcon: Dashboard,
      itemLink: '/dashboard',
      disabled: isDashboard,
      nestedItems: [
        {
          itemName: 'Management Dashboard',
          itemIcon: PieChart,
          disabled:
            privilege && privilege.hasOwnProperty('managementDash')
              ? !privilege.managementDash.visible
              : false,
          itemLink: '/dashboard/management_dash',
        },
        {
          itemName: 'Operation Dashboard',
          itemIcon: ViewList,
          disabled:
            privilege && privilege.hasOwnProperty('operationDash')
              ? !privilege.operationDash.visible
              : false,
          itemLink: '/dashboard/operation_dash',
        },
        {
          itemName: 'Sequence Dashboard',
          itemIcon: ViewList,
          disabled:
            privilege && privilege.hasOwnProperty('sequenceDash')
              ? !privilege.sequenceDash.visible
              : false,
          itemLink: '/dashboard/frame_dash',
        },
      ],
    },
    maintenanceListItems: {
      itemName: 'Maintenance',
      itemIcon: EditLocation,
      itemLink: '/master',
      disabled: isMaster,
      nestedItems: [
        {
          itemName: 'Location Master',
          itemIcon: Place,
          disabled:
            privilege && privilege.hasOwnProperty('locationMaster')
              ? !privilege.locationMaster.visible
              : false,
          itemLink: '/master/location_master',
        },
        {
          itemName: 'Route Master',
          itemIcon: Directions,
          disabled:
            privilege && privilege.hasOwnProperty('routeMaster')
              ? !privilege.routeMaster.visible
              : false,
          itemLink: '/master/route_master',
        },
        {
          itemName: 'Hazard Points',
          itemIcon: Warning,
          disabled:
            privilege && privilege.hasOwnProperty('inputHazard')
              ? !privilege.inputHazard.visible
              : false,
          itemLink: '/master/hazard_point',
        },
        {
          itemName: 'Impact Hazard',
          itemIcon: NewReleases,
          disabled:
            privilege && privilege.hasOwnProperty('impactHazard')
              ? !privilege.impactHazard.visible
              : false,
          itemLink: '/master/impact_hazard',
        },
      ],
    },
    configurationListItems: {
      itemName: 'Configuration',
      itemIcon: Settings,
      itemLink: '/config',
      disabled:
        privilege && privilege.hasOwnProperty('configuration')
          ? !privilege.configuration.visible
          : false,
      nestedItems: [
        {
          itemName: 'Users',
          itemIcon: Person,
          disabled:
            privilege && privilege.hasOwnProperty('configuration')
              ? !privilege.configuration.visible
              : false,
          itemLink: '/config/users',
        },
        {
          itemName: 'System Configuration',
          itemIcon: Build,
          itemLink: '/config/settings',
          disabled:
            privilege && privilege.hasOwnProperty('configuration')
              ? !privilege.configuration.visible
              : false,
        },
        {
          itemName: 'System Master',
          itemIcon: Ballot,
          itemLink: '/config/system_master',
          disabled:
            privilege && privilege.hasOwnProperty('configuration')
              ? !privilege.configuration.visible
              : false,
        },
        {
          itemName: 'Event Logs',
          itemIcon: History,
          itemLink: '/config/event_logs',
          disabled:
            privilege && privilege.hasOwnProperty('configuration')
              ? !privilege.configuration.visible
              : false,
        },
      ],
    },
  };
};

export default menuRoutes;
