import React, { Component } from 'react';
import { setSidebarVisibility } from '../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';
//Component
import MapList from './MapList';
import MapManage from './MapManage';
import MapEditRoute from './MapEditRoute';

import SearchForm from './SearchForm';
import ManageForm from './ManageForm';
import MasterRouteForm from './MasterRouteForm';

//Reducer
import { searchReset } from '../../reducers/search';
import { mapSetLogisticRoutes } from '../../reducers/map';

const MIN_HEIGHT = 25;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  gridForm: {
    padding: '10px',
  },
  subheader: {
    width: '100%',
  },
});

class RouteMaster extends Component {
  constructor(props) {
    super(props);
    this.mapListRef = React.createRef();
    this.mapManageRef = React.createRef();
    this.mapEditRouteRef = React.createRef();

    this.paneSizeRef = React.createRef();

    this.state = {};
  }
  componentWillMount() {
    this.props.mapSetLogisticRoutes([]);
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    setSidebarVisibility(false);
  }
  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        '#locationMap .ag-header-cell-label'
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }
  renderManageForm() {
    switch (this.props.formManage.page) {
      case 'manage':
        return <ManageForm />;
      case 'master_route':
        return <MasterRouteForm />;
      default:
        return <SearchForm />;
    }
  }

  render() {
    return (
      <div style={{ display: 'flex', height: 'calc(100vh - 52px)' }}>
        <React.Fragment>
          <SplitPane
            split='vertical'
            minSize={50}
            defaultSize={100}
            onChange={(size) => {
              if (
                this.paneSizeRef.current &&
                size[0] === this.paneSizeRef.current
              )
                return;
              if (this.mapListRef.current) {
                this.mapListRef.current.resize();
              }
              if (this.mapManageRef.current) {
                this.mapManageRef.current.resize();
              }
              if (this.mapEditRouteRef.current) {
                this.mapEditRouteRef.current.resize();
              }
              this.paneSizeRef.current = size[0];
            }}
          >
            <Pane initialSize='450px'>
              <div style={{ height: 'calc(100vh - 40px)', overflow: 'auto' }}>
                {this.renderManageForm()}
              </div>
            </Pane>
            <Pane>
              <div style={{ height: 'calc(100vh - 52px)' }}>
                {this.props.formManage.page === 'list' && (
                  <MapList mapRef={this.mapListRef} />
                )}
                {this.props.formManage.page === 'manage' && (
                  <MapManage mapRef={this.mapManageRef} />
                )}
                {this.props.formManage.page === 'master_route' && (
                  <MapEditRoute mapRef={this.mapEditRouteRef} />
                )}
              </div>
            </Pane>
          </SplitPane>
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  formManage: state.ui.formManage,
  search_logistics_route: state.search.search_logistics_route,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    searchReset: (payload) => dispatch(searchReset(payload)),
    mapSetLogisticRoutes: (payload) => dispatch(mapSetLogisticRoutes(payload)),
    dispatch,
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(RouteMaster);
