import { getProperty } from 'dot-prop';
import validateMapType from './validateMapType';
const initMap = (platform, container, mapOptions, mapTypes, mapType) => {
  validateMapType(mapTypes, mapType);
  // Get all the default layers so we can set which to use based on the map type
  const defaultLayers = platform.createDefaultLayers();
  // Instantiate (and display) a map object:
  return new window.H.Map(
    container,
    getProperty(defaultLayers, `vector.${mapType}`),
    mapOptions
  );
};
export default initMap;
