import { select, takeLatest, call, put } from 'redux-saga/effects';
import {
  getSuggestionRoutes,
  getTruckLocation,
  getEditRoute,
  postConfirmRoute,
} from '../reducers/map/api';
import {
  mapSetSuggestionRoutes,
  mapSetTruckLocation,
  mapSetSuggestionPolylines,
  mapSetBounds,
  SET_MAP_LOADING,
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons,
  mapSetDropPoint,
  mapSetSubLegs,
} from '../reducers/map';
import axios from 'axios';

import { SET_SNACKBAR } from '../reducers/ui';
import { decode } from '@liberty-rider/flexpolyline';

import Configs from '../config/config';
const env = process.env.NODE_ENV;
const gUtils = require('@googlemaps/google-maps-services-js/dist/util');

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(getSuggestionRoutes, suggestionRoutesWorkerSaga);
  yield takeLatest(getTruckLocation, suggestionRoutesWorkerSaga);
  yield takeLatest(getEditRoute, suggestionRoutesWorkerSaga);
  yield takeLatest(postConfirmRoute, suggestionRoutesWorkerSaga);
}

function fetchForm(params) {
  let url = params.payload.url;
  let form = params.payload.form;
  let listData = params.payload.listData;
  let objData = params.payload.objData;

  let inputParams = {
    list: listData ? listData : [{ ...form }],
  };
  if (objData) inputParams = objData;
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem('token'),
  };

  return new Promise((resolve) => {
    axios({
      method: params.payload.method ? params.payload.method : 'POST',
      url: url,
      data: JSON.stringify(inputParams),
      headers: headers,
      timeout: 60000,
    })
      .then(function (resp) {
        resolve(resp);
      })
      .catch(function (error) {
        resolve({ catchError: error });
      });
  });
}

function fetchDataGet(params) {
  let url = params.payload.url + '?' + paramsUrl(params.payload.filter);
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem('token'),
  };

  return axios
    .get(url, { headers: headers, timeout: 60000 })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return { catchError: error };
    });
}

function paramsUrl(data) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
}

/////// POLYLINE /////////

function setDropPoint_array(data, editable) {
  let markerList = [];
  let marker = {};
  if (data) {
    data.routes[0].Legs.forEach((item, i) => {
      marker = {
        ...item,
        route_data: data,
        id: i,
        position: { lat: item.lat, lng: item.lng },
        isGroup: false,
        editable: editable,
      };
      markerList.push(marker);
    });
  }
  return markerList;
}

function setSubLegs(data, editable) {
  let subLegs = [];
  // let waypoint = []
  if (data) {
    data.routes.forEach((route, route_idx) => {
      route.Legs.forEach((leg, leg_idx) => {
        if (leg.hasOwnProperty('subLegs')) {
          leg.subLegs.forEach((subLeg, subleg_idx) => {
            // Seq: 1
            // subRoute:
            let decodedResult = subLeg.subRoute
              ? decode(decodeURIComponent(subLeg.subRoute))
              : { polyline: [] };
            let decodePath = decodedResult.polyline;
            var paths = [];
            decodePath.forEach(function (p) {
              paths.push({ lat: p[0], lng: p[1] });
              // paths.push({ lat: p.lat, lng: p.lng });
            });

            let tmp = {
              position: paths[0],
              route_idx: route_idx,
              leg_idx: leg_idx,
              subleg_idx: subleg_idx,
              all_idx: route_idx + '_' + leg_idx + '_' + subleg_idx,
            };
            if (subleg_idx > 0) subLegs.push(tmp);
          });
        }
      });
    });

    return subLegs;
  }
}

function setPolylines(data, editable) {
  let polylineList = [];
  let polyline = {};
  // let waypoint = []

  if (data) {
    data.routes.forEach((route, route_id) => {
      route.Legs.forEach((leg, leg_id) => {
        let count_sub_idx = 0;
        if (leg.hasOwnProperty('subLegs')) {
          leg.subLegs.forEach((subLeg) => {
            // Seq: 1
            // subRoute:
            let decodedResult = subLeg.subRoute
              ? decode(decodeURIComponent(subLeg.subRoute))
              : { polyline: [] };
            let decodePath = decodedResult.polyline;
            var paths = [];
            decodePath.forEach(function (p) {
              paths.push({ lat: p[0], lng: p[1] });
              // paths.push({ lat: p.lat, lng: p.lng });
            });
            polyline = {
              // ...subLeg,
              route_id: route_id,
              leg_id: leg_id,
              seq_id: subLeg.Seq,
              subleg_seq: subLeg.Seq,
              id: route_id,
              sub_idx: count_sub_idx,
              path: decodePath,
              editable: editable,
            };
            polylineList.push(polyline);
            // waypoint[count_wp+1].push(paths[0]);
            // count_wp++
            count_sub_idx++;
          });
        } else {
          let decodePath = [];
          if (leg.Route) {
            // var decodePath = gUtils.decodePath(decodeURIComponent(leg.Route));
            let decodedResult = leg.Route
              ? decode(decodeURIComponent(leg.Route))
              : { polyline: [] };
            decodePath = decodedResult.polyline;
          }
          //var decodePath = []
          var paths = [];
          decodePath.forEach(function (p) {
            // paths.push({ lat: p.lat, lng: p.lng });
            paths.push({ lat: p[0], lng: p[1] });
          });
          polyline = {
            ...leg,
            route_id: route_id,
            leg_id: leg_id,
            seq_id: leg.Seq,
            id: route_id,
            sub_idx: 0,
            path: decodePath,
            editable: editable,
          };
          polylineList.push(polyline);
          // waypoint[0].push(paths[0]);
          // waypoint[count_wp].push(paths[paths.length-1]);
        }
      });
    });

    return polylineList;
  }
}

function setBoundsWaypoints(polylines) {
  let bounds = [];
  if (polylines) {
    polylines.forEach((item) => {
      var decodedResult = item.Route
        ? decode(decodeURIComponent(item.Route))
        : { polyline: [] };
      // var decodePath = gUtils.decodePath(decodeURIComponent(item.Route));
      let decodePath = decodedResult.polyline;

      decodePath.forEach(function (p) {
        bounds.push({ lat: p[0], lng: p[1] });
        // bounds.push({ lat: p.lat, lng: p.lng });
      });
    });
  }
  return bounds;
}

function setHazardMarkers_array(data, editable) {
  let markerList = [];
  let marker = {};

  data.routes.forEach((route) => {
    if (route.hazard) {
      route.hazard.forEach((point) => {
        if (point) {
          //Marker
          marker = {
            ...point,
            id: point.id,
            position: { lat: point.latitude, lng: point.longitude },
            info: {
              name: point.location,
              description: point.detail,
              logisticsPoint: point.category,
            },
            isGroup: false,
            category: point.category,
            editable: editable,
          };
          let existMarker = markerList.find((i) => i.id === point.id);
          if (!existMarker) {
            markerList.push(marker);
          }
        }
      });
    }
  });
  return markerList;
}

function setCircles_array(data, editable) {
  let circleList = [];
  let circle = {};
  data.routes.forEach((route) => {
    if (route.hazard) {
      route.hazard.forEach((point) => {
        if (point) {
          //Circle
          if (point.type === 'circle') {
            circle = {
              id: point.id,
              center: { lat: point.circleLatitude, lng: point.circleLongitude },
              radius: point.radius,
              info: {
                name: point.location,
                description: point.detail,
                logisticsPoint: point.category,
              },
              isGroup: false,
              editable: editable,
            };
            let existCircle = circleList.find((i) => i.id === point.id);
            if (!existCircle) {
              circleList.push(circle);
            }
          }
        }
      });
    }
  });

  return circleList;
}

function setPolygons_array(data, editable) {
  let polygonList = [];
  let polygon = {};
  data.routes.forEach((route) => {
    if (route.hazard) {
      route.hazard.forEach((point) => {
        if (point?.polygon) {
          var decodePath = gUtils.decodePath(point.polygon);
          polygon = {
            id: point.routeCode,
            path: decodePath,
            isGroup: false,
            editable: editable,
          };
          let existPolygon = polygonList.find((i) => i.id === point.routeCode);
          if (!existPolygon) {
            polygonList.push(polygon);
          }
        }
      });
    }
  });
  return polygonList;
}
//////////////////////////////////////////////////

function* suggestionRoutesWorkerSaga(action) {
  try {
    let response;

    yield put({ type: SET_MAP_LOADING, payload: true });

    if (getEditRoute.toString() === action.type) {
      const state = yield select();

      let tmp = yield call(fetchForm, action);
      response = JSON.parse(JSON.stringify(tmp));

      let suggestion_routes = state.map.suggestion_routes;
      response.data.list[0] = JSON.parse(JSON.stringify(suggestion_routes));
      // "planDepartureTime": "2019-01-28 07:10:00",
      // tmp.data.list[0].Legs[0].planDepartureTime = "2019-01-28 :10:00"
      response.data.list[0].routes[action.payload.route_id] = JSON.parse(
        JSON.stringify(tmp.data.list[0])
      );
      // response.data.list[0].routes[0].Legs[0].planDepartureTime = "2019-01-28 10:10:00"
    } else if (postConfirmRoute.toString() === action.type) {
      response = yield call(fetchForm, action);
    } else {
      response = yield call(fetchDataGet, action);
    }

    let params = action;
    let callback = params.payload.callback;
    let callback_error = params.payload.callback_error;

    if (response.hasOwnProperty('catchError')) {
      if (callback_error) callback_error(response.catchError.toString());
      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: response.catchError.toString(),
          snackbarDuration: 20000,
        },
      });
      yield put({ type: SET_MAP_LOADING, payload: false });

      return false;
    }

    if (parseInt(response.status) < 200 || parseInt(response.status) > 200) {
      if (callback_error)
        callback_error('HTTP ERROR : status code = ' + response.status);
      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: 'HTTP ERROR : status code = ' + response.status,
          snackbarDuration: 20000,
        },
      });
      yield put({ type: SET_MAP_LOADING, payload: false });

      return false;
    }

    if (response.data.hasOwnProperty('errorList')) {
      let errorShow = '';
      if (response.data.errorList.length > 0) {
        if (callback_error) callback_error(errorShow);
        yield put({ type: SET_MAP_LOADING, payload: false });
        errorShow = response.data.errorList[0].errorMessage;
        if (parseInt(response.data.errorList[0].errorCode) === 2101) {
          yield put({
            type: SET_SNACKBAR,
            payload: {
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorShow,
              snackbarDuration: 3000,
            },
          });
          window.location.href = '/dashboard/operation_dash';
          return false;
        }

        if (
          parseInt(response.data.errorList[0].errorCode) === 2102 ||
          parseInt(response.data.errorList[0].errorCode) === 2103
        ) {
          yield put({
            type: SET_SNACKBAR,
            payload: {
              snackbarOpen: true,
              snackbarVariant: 'error',
              snackbarMessage: errorShow,
              snackbarDuration: 3000,
              forceLogout: true,
            },
          });
          return false;
        }

        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 20000,
          },
        });
        return false;
      }
    }

    if (callback) callback(response.data);

    //Truck Location
    if (getTruckLocation.toString() === action.type) {
      let truck_location = response.data.list[0];
      truck_location.position = {
        lat: response.data.list[0].lat,
        lng: response.data.list[0].lng,
      };
      truck_location.name =
        'Truck Location ( ' +
        response.data.list[0].lat +
        ',' +
        response.data.list[0].lng +
        ' )';
      yield put({ type: mapSetTruckLocation, payload: truck_location });
    } else if (postConfirmRoute.toString() === action.type) {
      // yield put({ type: SET_MAP_LOADING, payload: false });
    } else {
      let polylines = setPolylines(response.data.list[0], false);
      let hazardMarkers = setHazardMarkers_array(response.data.list[0], false);
      let circles = setCircles_array(response.data.list[0], false);
      let polygons = setPolygons_array(response.data.list[0], false);
      let dropPoints = setDropPoint_array(response.data.list[0], false);
      let subLegs = setSubLegs(response.data.list[0], false);

      yield put({ type: mapSetMarkers, payload: hazardMarkers });
      yield put({ type: mapSetCircles, payload: circles });
      yield put({ type: mapSetPolygons, payload: polygons });
      yield put({ type: mapSetDropPoint, payload: dropPoints });
      yield put({ type: mapSetSubLegs, payload: subLegs });

      yield put({ type: mapSetSuggestionPolylines, payload: polylines });
      yield put({
        type: mapSetSuggestionRoutes,
        payload: response.data.list[0],
      });

      if (getEditRoute.toString() !== action.type) {
        let bounds = setBoundsWaypoints(polylines);
        yield put({ type: mapSetBounds, payload: bounds });
      }
    }

    return true;
  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  } finally {
    yield put({ type: SET_MAP_LOADING, payload: false });
  }
}
