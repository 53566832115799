import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumericInput from 'react-numeric-input';
import CircularProgress from '@material-ui/core/CircularProgress';

//Reducer
import { setSidebarVisibility } from '../../reducers/ui';
import { getConfigs } from '../../reducers/setting/api';
import { apiCallRequest } from '../../reducers/api';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = () => ({
  center: {
    position: 'relative',
    left: '20px',
  },
});
class Setting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configs: null,
    };
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    setSidebarVisibility(false);
    this.handleReload();
  }
  renderNumberField = (value) => {
    return (
      <TextField
        value={value}
        type='number'
        className={styles.textField}
        InputLabelProps={{
          shrink: true,
        }}
        margin='normal'
      />
    );
  };
  handleReload = () => {
    this.props.getConfigs({
      url: Configs[env].BACKEND_HOST + '/setting',
      filter: {},
    });
  };
  componentWillReceiveProps(nextProps, props) {
    if (nextProps.setting_configs != this.props.setting_configs) {
      let configs = [];

      if (nextProps.setting_configs) {
        nextProps.setting_configs.map((row) => {
          configs.push(row);
        });
      }
      this.setState({ configs: configs });
    }
  }
  handleChangeValue = (value, rowChange) => {
    let configChange = [];
    if (this.state.configs) {
      this.state.configs.map((row) => {
        if (rowChange.id === row.id) {
          if (value < rowChange.min) value = rowChange.min;
          else if (value > rowChange.max) value = rowChange.max;

          configChange.push({ ...row, value: parseInt(value) });
        } else {
          configChange.push({ ...row });
        }
      });
      this.setState({ configs: configChange });
    }
  };
  handleSave = () => {
    this.props.apiCallRequest({
      url: Configs[env].BACKEND_HOST + '/setting',
      listData: this.state.configs,
      method: 'PUT',
      callback: this.handleReload,
    });
  };
  render() {
    const { classes } = this.props;
    const hideConfigIds = [1, 12, 18];
    return (
      <div style={{ height: 'calc(100vh - 40px)', overflow: 'auto' }}>
        <Card>
          <CardHeader title={this.props.name} />
          <CardContent>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align='right'>Value</TableCell>
                  <TableCell align='right'>Default</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.configs &&
                  this.state.configs.map((row) => {
                    if (!hideConfigIds.includes(row.id)) {
                      return (
                        <TableRow key={row.id}>
                          <TableCell component='th' scope='row'>
                            {row.name}
                          </TableCell>
                          <TableCell align='center'>
                            <NumericInput
                              min={row.min}
                              max={row.max}
                              defaultValue={row.value}
                              value={row.value}
                              onChange={(event) =>
                                this.handleChangeValue(event, row)
                              }
                            />
                          </TableCell>
                          <TableCell align='left'>{row.description}</TableCell>
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  })}
              </TableBody>
            </Table>

            <DialogActions>
              <Button
                testing='button-save-logistics-point'
                variant='contained'
                size='small'
                color='primary'
                aria-label='Save'
                className={styles.button}
                onClick={this.handleSave}
                disabled={
                  this.props.isLoading ||
                  !JSON.parse(localStorage.getItem('privilege')).configuration
                    .controllable
                }
                type='submit'
              >
                {this.props.isLoading && (
                  <CircularProgress
                    className={classes.center}
                    size={25}
                    thickness={2}
                  />
                )}
                Save
              </Button>
            </DialogActions>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    isLoading: state.ui.isLoading,
    setting_configs: state.setting.setting_configs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    getConfigs: (payload) => dispatch(getConfigs(payload)),
    apiCallRequest: (payload) => dispatch(apiCallRequest(payload)),
    dispatch,
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Setting);
