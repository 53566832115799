import React, { Component } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import DialogActions from '@material-ui/core/DialogActions';
import 'react-datepicker/dist/react-datepicker.css';
//import renderDatePicker from '../Ui/Renderer/DatePickerRenderer';
import {
  reduxForm,
  Form,
  Field,
} from 'redux-form';
//import AutoSelect from 'react-select';
//Lib
import {
  required,
  betweenDateTime,
  diffDate,
} from '../../libs/validation';
//Component
import CategorySelect from './RouteForm/CategorySelect';
import HistoryLogs from './HistoryLogs';
//Reducer
import {
  resetAPIResult,
} from '../../reducers/api';
import { connect } from 'react-redux';
import { uiPanelDialogOpen, uiSwitchForm } from '../../reducers/ui';
import {
  mapResetMap,
  mapDrawingOptions,
} from '../../reducers/map';
import { getHistoryLogs, exportHazardLogs } from '../../reducers/map/api';
import { searchHazardLogs } from '../../reducers/search';
//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    dateFormat='YYYY/MM/DD'
    {...inputProps}
    {...props}
  />
);
class LogForm extends Component {
  state = {};

  componentDidMount() {
    this.props.RESET_MAP(true);
    this.props.DRAWING_OPTION(this.state.drawingOptions);
    let defaultValue = {
      category: 'all',
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: '9999-12-31',
    };
    if (this.props.search_hazard_logs.category) {
      defaultValue = this.props.search_hazard_logs;
    }
    this.props.initialize({ ...defaultValue });
    this.props.searchHazardLogs({ ...defaultValue });
  }
  componentWillMount() {}
  componentWillReceiveProps(nextProps, props) {}
  handleChange = (event) => {
    if (event.target.name === 'category') {
      // console.log('#handleChange search_hazard_logs',this.props.search_hazard_logs);
      this.props.searchHazardLogs({
        ...this.props.search_hazard_logs,
        category: event.target.value,
      });
    } else {
      this.props.searchHazardLogs({
        ...this.props.search_hazard_logs,
        [event.target.name]: event.target.value,
      });
    }
  };
  handleSearch = () => {
    this.props.RESET_MAP(true);
    // console.log('#handleSearch search_hazard_logs',this.props.search_hazard_logs);
    let filter = { ...this.props.search_hazard_logs };
    if (filter.category === 'all') delete filter.category;
    this.props.getHistoryLogs({
      url: Configs[env].BACKEND_HOST + '/impactHazardHistoryCond',
      //url: "http://mapssh.leafte.ch/impactHazardHistoryCond",
      filter: filter,
    });
  };
  handleExport = () => {
    //const category = selector(state, category');

    let filter = { ...this.props.search_hazard_logs };
    if (filter.category === 'all') delete filter.category;
    this.props.exportHazardLogs({
      url: Configs[env].BACKEND_HOST + '/impactHazardHistoryCondDownload',
      filter: filter,
    });
  };
  render() {
    const { handleSubmit } = this.props;

    return (
      <div style={{ padding: '10px' }}>
        <Card className={styles.card}>
          <CardContent>
            <Form
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.handleSearch.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CategorySelect isSearch={true} formImpact='history' />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='location'
                    label='LOCATION'
                    component={renderInput}
                    className={styles.textField}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    margin='dense'
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='detail'
                    label='DETAIL'
                    component={renderInput}
                    className={styles.textField}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    margin='dense'
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='dateFrom'
                    label='IMPACT FROM'
                    type='date'
                    component={renderInput}
                    className={styles.textField}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required, betweenDateTime]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='dateTo'
                    label='IMPACT TO'
                    type='date'
                    onChange={this.handleChange}
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required, betweenDateTime, diffDate]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      aria-label='Save'
                      className={styles.button}
                      type='submit'
                    >
                      SEARCH
                    </Button>
                    <Button
                      variant='contained'
                      size='small'
                      color='secondary'
                      aria-label='Save'
                      className={styles.button}
                      onClick={this.handleExport}
                      disabled={!JSON.parse(localStorage.getItem('privilege')).impactHazard.controllable}
                    >
                      DOWNLOAD REPORT
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
            <Typography variant='subtitle1'>Impact Detail : </Typography>
            <HistoryLogs />
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    apiFetchResult: state.api.result,
    dialogOpen: state.ui.dialogOpen,
    formManage: state.ui.formManage,
    manageForm: state.mapForm.manageForm,
    search_hazard_logs: state.search.search_hazard_logs,
  };
};
const mapActionsToProps = {
  DRAWING_OPTION: mapDrawingOptions,
  OPEN_DIALOG: uiPanelDialogOpen,
  SWITCH_FORM: uiSwitchForm,
  getHistoryLogs,
  RESET_MAP: mapResetMap,
  searchHazardLogs,
  exportHazardLogs,
  resetAPIResult,
};
const enhance = compose(
  reduxForm({
    form: 'searchHazardLogForm',
    initialValues: {
      category: '0',
    },
    // validate
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(LogForm);
