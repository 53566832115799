import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';

const styles = {
  avatar: {
    margin: 5,
    width: 20,
    height: 20,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
};

class IconRouteRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  handleOpenRoute = () => {
    window.location.href =
      '/route_suggestion/' + this.props.data.jobDashboardId.value;
  };
  handleOpenImpact = () => {
    //console.log('#handleOpenImpact data',this.props.data);
    window.location.href =
      '/master/impact_hazard/' +
      this.props.data.routeCode.value +
      '/' +
      this.props.data.runSeq.value;
  };
  render() {
    const { classes } = this.props;
    let shiftSeq =
      this.props.data.shiftSeq.value != ''
        ? '?shiftNo=' + this.props.data.shiftSeq.value
        : '';
    let toRoute =
      '/route_suggestion/' + this.props.data.jobDashboardId.value + shiftSeq;
    //let toRoute = '/route_suggestion/'+this.props.data.jobDashboardId.value;
    let toImpact =
      '/master/impact_hazard/' +
      this.props.data.routeCode.value +
      '/' +
      this.props.data.runSeq.value;
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {this.props.data.hasAlternativeRoute.value == 1 ? (
            <a href={toRoute}>
              <Avatar
                alt='Route'
                src='/img/4-2.png'
                className={classes.avatar}
              />
            </a>
          ) : (
            ''
          )}
          {this.props.data.hasAlternativeRoute.value == 2 ? (
            <a href={toRoute}>
              <Avatar
                alt='Route'
                src='/img/4-1.png'
                className={classes.avatar}
              />
            </a>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={6}>
          {this.props.data.hasHazard.value && (
            <a href={toImpact}>
              <Avatar
                alt='Route'
                src='/img/2-1.png'
                className={classes.avatar}
              />
            </a>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
    activeMenuListItem: state.ui.activeMenuListItem,
  };
};

const mapActionsToProps = {
  //setActiveMenuListItem
};
const enhance = compose(
  connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true }),
  withStyles(styles)
);

export default enhance(IconRouteRenderer);
