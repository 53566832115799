import React from 'react';
import { compose } from 'recompose';
import { Typography, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 48px)',
  },
  paper: {
    padding: '20px',
  },
  centerButton: {
    margin: 'auto',
    display: 'block',
    width: '100px',
  },
}));

const ErrorComponent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.center}>
      <Paper className={classes.paper}>
        <Typography variant='h3' gutterBottom>
          {props.title || `Error!`}
        </Typography>
        <Typography gutterBottom style={{ marginBottom: '20px' }}>
          {props.message || `Service is temporarily unavailable.`}
        </Typography>
        <Button
          className={classes.centerButton}
          variant='contained'
          size='small'
          color='primary'
          onClick={() => {
            dispatch(goBack());
          }}
        >
          Back
        </Button>
      </Paper>
    </div>
  );
};
export default compose()(ErrorComponent);
