import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

//Component
//Reducer
import { apiCallRequest, apiCallSuccess } from '../../reducers/api';
import { getMasterRoutes, getSuggestionRoutes } from '../../reducers/map/api';
import {
  MAP_SELECT_CANDIDATE,
  mapResetMap,
  mapSetSuggestionRoutes,
  mapSetTruckLocation,
  mapSetDropPoint,
  mapSetHoverSeq,
  SET_MARKER_ORIGIN_DESC,
  MAP_ROW_SUGGESSION,
  MAP_SELECT_SUGGESSION,
  MAP_EDIT_SUGGESSION,
  SET_MAP_LOADING,
} from '../../reducers/map';
import store from 'reducers/rootReducer';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin: 0,
    width: 200,
  },
});
class RouteSuggestGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToll: false,
      columnDefs: [],
      jsonData: '',
      rowData: [],
      defaultColumn: [
        {
          headerName: 'TRUCK LICENSE',
          field: 'truckLicense',
          headerCellClass: 'text-center',
          width: 90,
          rowSpan: function () {
            return 2;
          },
        },
        {
          headerName: 'ROUTE',
          field: 'routeCode',
          headerCellClass: 'text-center',
          width: 80,
          rowSpan: function () {
            return 2;
          },
        },
        {
          headerName: 'RUN SEQ',
          field: 'runSeq',
          headerCellClass: 'text-center',
          width: 50,
          rowSpan: function () {
            return 2;
          },
        },
        {
          headerName: 'JOB',
          field: 'jobNo',
          headerCellClass: 'text-center',
          width: 50,
          rowSpan: function () {
            return 2;
          },
        },
      ],
      context: { componentParent: this },
      defaultColDef: { sortable: true, resizable: true },
    };
  }
  componentDidMount() {}
  componentDidUpdate(prevProps, prevState) {
    this.props.SET_MAP_LOADING(false);
  }

  componentWillUnmount() {
    this.props.mapResetMap(true);
    this.props.mapSetSuggestionRoutes([]);
    this.props.mapSetTruckLocation([]);
    this.props.mapSetDropPoint([]);
    this.props.MAP_EDIT_SUGGESSION(-1);

    this.props.SET_MAP_LOADING(false);
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.suggestion_routes.routes &&
      (this.props.suggestion_routes !== nextProps.suggestion_routes ||
        this.props.suggestion_selecting !== nextProps.suggestion_selecting)
    ) {
      if (nextProps.suggestion_selecting > -1) {
        this.setState({ rowData: [] });
        let rowData = [];
        let _this = this;

        var item =
          nextProps.suggestion_routes.routes[nextProps.suggestion_selecting];
        let data = _this.renderData(
          nextProps.suggestion_routes,
          item,
          nextProps.suggestion_selecting
        );
        data.forEach(function (itemD) {
          rowData.push(itemD);
        });
        // console.log('#componentWillReceiveProps rowData', rowData);
        setTimeout(() => {
          this.setState({ rowData: rowData });
        }, 1000);

        this.focusRow(
          nextProps.suggestion_selecting !== 0
            ? nextProps.suggestion_selecting + 2
            : 0
        );
      }
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll();
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function (column, index) {
        if (index !== 0) allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  focusRow = (selecting) => {
    let gridApi = this.gridApi;
    if (gridApi) {
      gridApi.deselectAll();
      this.gridApi.forEachNode(function (node) {
        if (node.rowIndex === selecting) {
          node.setSelected(true, true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' });
        }
      });
    }
    gridApi.deselectAll();
  };
  renderData = (suggestion_routes, item) => {
    //console.log('#renderData suggestion_routes',suggestion_routes);
    if (item) {
      //console.log('#renderData item', item);
      let dropsHeader = JSON.parse(JSON.stringify(this.state.defaultColumn));
      let dropCount = 1;
      let routes = [];
      var data = [];
      data[0] = [];
      data[1] = [];

      //Set first row detail
      for (var key in suggestion_routes) {
        if (
          suggestion_routes.hasOwnProperty(key) &&
          !Array.isArray(suggestion_routes[key])
        ) {
          data[0][key] = suggestion_routes[key];
          data[1][key] = '';
        }
      }
      for (let leg of item.Legs) {
        dropsHeader.push({
          headerName: dropCount + ' : ' + leg.locationName,
          children: [
            {
              headerName: 'IN',
              headerCellClass: 'text-center',
              field: 'in' + dropCount,
              width: 70,
              cellStyle: function (params) {
                //console.log('#renderData params IN',params);
                let complete =
                  params.data[
                    'completed' + params.colDef.field.replace('in', '')
                  ];
                let arrived =
                  params.data[
                    'arrived' + params.colDef.field.replace('in', '')
                  ];
                //console.log('#renderData complete IN',complete);
                return {
                  color: 'black',
                  textAlign: 'center',
                  backgroundColor:
                    complete || arrived ? '#1976D2' : 'transparent',
                };
              },
            },
            {
              headerName: 'OUT',
              headerCellClass: 'text-center',
              field: 'out' + dropCount,
              width: 70,
              cellStyle: function (params) {
                //console.log('#renderData params OUT',params);
                let complete =
                  params.data[
                    'completed' + params.colDef.field.replace('out', '')
                  ];
                //console.log('#renderData complete IN',complete);
                return {
                  color: 'black',
                  textAlign: 'center',
                  backgroundColor: complete ? '#1976D2' : 'transparent',
                };
              },
            },
          ],
        });

        data[0]['in' + dropCount] =
          leg.planArrivalTime !== ''
            ? moment(leg.planArrivalTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
            : '-';
        data[0]['out' + dropCount] =
          leg.planDepartureTime !== ''
            ? moment(leg.planDepartureTime, 'YYYY-MM-DD HH:mm:ss').format(
                'HH:mm'
              )
            : '-';
        data[0]['completed' + dropCount] = leg.isCompleted;
        data[0]['arrived' + dropCount] = leg.isArrived;

        data[1]['in' + dropCount] =
          leg.actualArrivalTime !== ''
            ? moment(leg.actualArrivalTime, 'YYYY-MM-DD HH:mm:ss').format(
                'HH:mm'
              )
            : '-';
        data[1]['out' + dropCount] =
          leg.actualDepartureTime !== ''
            ? moment(leg.actualDepartureTime, 'YYYY-MM-DD HH:mm:ss').format(
                'HH:mm'
              )
            : '-';
        data[1]['completed' + dropCount] = leg.isCompleted;
        data[1]['arrived' + dropCount] = leg.isArrived;

        dropCount++;
      }
      routes.push(data[0]);
      routes.push(data[1]);

      this.updateCoulumns(dropsHeader);

      return routes;
    }
  };
  handleSelectionChanged = (node) => {
    var selectedRows = node.api.getSelectedRows();

    this.props.MAP_SELECT_CANDIDATE(selectedRows[0].id);
  };
  updateRouteData = (data) => {
    this.setState({ jsonData: data });
  };
  updateCoulumns = (data) => {
    //console.log('#updateCoulumns...data', data);
    this.setState({ columnDefs: data });
  };
  cellMouseOver = (data) => {
    //console.log('#cellMouseOver data', data);
    if (data.colDef === 'IN' || data.colDef === 'OUT') {
      let seq = data.colDef.field.replace('in', '').replace('out', '');
      this.props.mapSetHoverSeq(seq);
    } else {
    }
  };
  cellMouseOut = (data) => {
    if (data.colDef === 'IN' || data.colDef === 'OUT') {
    }
  };
  renderRow = () => {
    if (Array.isArray(this.props.suggestion_rowdata)) {
      return this.props.suggestion_rowdata;
    } else return [];
  };
  render() {
    return (
      <div style={{ width: '100%', height: '140px' }}>
        <div
          className='ag-theme-balham'
          style={{
            height: '140px',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <AgGridReact
            reactNext={true}
            reduxStore={store}
            suppressMovableColumns={true}
            suppressRowTransform={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.state.rowData}
            onCellMouseOver={this.cellMouseOver}
            onCellMouseOut={this.cellMouseOut}
            onGridReady={this.onGridReady.bind(this)}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    isLoading: state.api.fetching,
    suggestion_routes: state.map.suggestion_routes,
    suggestion_rowdata: state.map.suggestion_rowdata,
    suggestion_selecting: state.map.suggestion_selecting,
  };
};
const mapActionsToProps = {
  getRequest: apiCallRequest,
  success: apiCallSuccess,
  mapResetMap,
  GET_MASTER_ROUTE: getMasterRoutes,
  getSuggestionRoutes: getSuggestionRoutes,
  mapSetSuggestionRoutes,
  mapSetTruckLocation,
  mapSetHoverSeq,
  mapSetDropPoint,
  SET_MARKER_ORIGIN_DESC,
  MAP_SELECT_CANDIDATE,
  MAP_ROW_SUGGESSION,
  MAP_SELECT_SUGGESSION,
  MAP_EDIT_SUGGESSION,
  SET_MAP_LOADING,
};

export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
)(RouteSuggestGrid);
