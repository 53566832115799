import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import debounce from 'lodash/debounce';
import { reduxForm, Form, Field } from 'redux-form';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import TimerMachine from 'react-timer-machine';
import momentDurationFormatSetup from 'moment-duration-format';
//Component
import FrameGrid from './FrameGrid';
import SubFrameGrid from './SubFrameGrid';
import PlantSelect from './PlantSelect';
import RouteSelect from './RouteSelect';
//Reducer
import { getFrame, exportFrame } from '../../reducers/dashboard/api';
import {
  setSidebarVisibility,
  SET_SNACKBAR,
  STATUS_LOADING,
} from '../../reducers/ui';
import { searchFrame, searchFrameTxt } from '../../reducers/search';
import { dashboardSelected, dashboardSubFrame } from '../../reducers/dashboard';
//Lib
import { required, betweenRouteDate } from '../../libs/validation';

import io from 'socket.io-client';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import axios from 'axios';
import './inputStyle.css';

//Config
import Configs from '../../config/config';
import { InputLabel } from '@material-ui/core';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  card: {
    margin: theme.spacing.unit,
  },
  media: {
    height: 140,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: 200
  },
  txtBlack: {
    color: '#000000',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: '25%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headModal: {
    width: '100%',
    backgroundColor: '#3F51B5',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bodyModal: {
    padding: theme.spacing(2, 4, 3),
  },
  h2title: {
    margin: '0 !important',
    padding: '12px',
    color: 'white',
  },
  textError: {
    color: 'red',
  },
  textSucces: {
    color: 'green',
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
});

momentDurationFormatSetup(moment);

let socket;

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sq_respone: [],
      etaSelect: '',
      plantSelect: '',
      routeSelect: '',
      last_updated: '',
      operationUpdate: null,
      blinkColor: 0,
      started: true,
      timeStart: 180,
      fetching: false,
      manualReload: false,
      isLoading: false,
      routeDateFrom: new Date(),
      routeDateTo: new Date(),
      isDisableDownload: false,
    };
    this.onFilterTextBoxChanged = debounce(this.onFilterTextBoxChanged, 500);
  }
  componentDidMount() {
    var defaultValue = {
      routeDateFrom: moment().format('YYYY-MM-DD'),
      routeDateTo: moment().format('YYYY-MM-DD'),
    };
    //console.log('#componentDidMount search_frame', this.props.search_frame);
    if (
      this.props.search_frame.routeDateFrom ||
      this.props.search_frame.routeDateTo
    ) {
      defaultValue = this.props.search_frame;
    }
    this.props.initialize({ ...defaultValue });
    this.props.searchFrame({ ...defaultValue });

    const { setSidebarVisibility } = this.props;
    setSidebarVisibility(false);

    let search = this.props.search_frame;
    this.getData(search);

    // endpoint
    socket = io.connect(Configs[env].SOCKETIO_URL, {
      withCredentials: true,
    });

    socket.on('digitalMapEvent', (data) => {
      //console.info('Realtime Updated by socket.io msg: ', data);
      if (data === 'update') {
        this.setState({ operationUpdate: new Date().toISOString() });
      }
    });
    //this.props.initialize({...this.props.search_frame});
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log('#componentDidUpdate search_frame',this.props.search_frame);
    if (prevState.operationUpdate !== this.state.operationUpdate) {
      //this.getData(this.props.search_frame);
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.blinkColor);
    socket.disconnect();
  }
  componentWillReceiveProps(nextProps) {
    //console.log('#componentWillReceiveProps search_frame',nextProps.search_frame);
    if (nextProps.search_frame !== this.props.search_frame) {
      this.getData(nextProps.search_frame);
    }
  }

  timer = () => {
    this.setState({ blinkColor: this.state.blinkColor !== 1 ? 1 : 0 });
  };
  manualReload(search_frame) {
    this.getData(search_frame);
    this.setState({ manualReload: true });
  }
  handleInputChange = (e) => {
    //console.log('#handleInputChange e',e.target.value);
    this.onFilterTextBoxChanged(e.target.value);
  };
  onFilterTextBoxChanged = (value) => {
    //console.log('#onFilterTextBoxChanged searchTxt', value);
    this.props.searchFrameTxt(value);
    this.props.dashboardSelected(null);
    this.props.dashboardSubFrame([]);
  };
  changeDateForm = (event) => {
    //console.log('#changeDateForm',event.target.value);
    this.setState({ routeDateFrom: event.target.value });
    this.props.searchFrame({
      ...this.props.search_frame,
      routeDateFrom: event.target.value,
    });
  };
  changeDateTo = (event) => {
    //console.log('#changeDateTo',event.target.value);
    this.setState({ routeDateTo: event.target.value });
    this.props.searchFrame({
      ...this.props.search_frame,
      routeDateTo: event.target.value,
    });
  };
  handleTimerOnComplete = () => {
    this.getData(this.props.search_frame);
  };
  getData = (search) => {
    //console.log('#getData search', search);
    this.setState({ fetching: true, started: false, isLoading: true });
    if (search.routeDateFrom && search.routeDateTo)
      this.setState({ isDisableDownload: false });
    else this.setState({ isDisableDownload: true });
    //const selector = formValueSelector('frameForm')

    this.props.STATUS_LOADING(true);
    let filter = search;
    this.props.getFrame({
      url: Configs[env].BACKEND_HOST + '/frameDashboard',
      //url: endpoint + "/dashboard/frame",
      method: 'GET',
      filter: { ...filter },
      callback: this.updateTime,
      callback_error: this.updateTime_error,
    });
  };
  updateTime = () => {
    //console.log('#updateTime.....');
    this.setState({ last_updated: moment().format('YYYY/MM/DD HH:mm:ss') });
    this.setState({ started: true, fetching: false, isLoading: false });
    this.props.STATUS_LOADING(false);
    if (this.state.manualReload) {
      this.props.SET_SNACKBAR({
        snackbarOpen: true,
        snackbarVariant: 'success',
        snackbarMessage: 'Manual reload data success',
        snackbarDuration: 2000,
        forceLogout: false,
      });
    }
    this.setState({ manualReload: false });
  };

  updateTime_error = () => {
    //console.log('#updateTime_error.....');
    this.props.STATUS_LOADING(false);
    this.setState({
      started: true,
      fetching: false,
      isLoading: false,
      manualReload: false,
    });
  };
  handleSearch = () => {};
  handleClear = () => {
    this.props.searchFrame({});
    this.props.initialize({});
    this.props.change({});
    this.props.searchFrameTxt('');
  };
  handleExport = () => {
    let filter = { ...this.props.search_frame };
    filter.routeDateFrom = moment(filter.routeDateFrom, 'YYYY-MM-DD').format(
      'YYYY/MM/DD'
    );
    filter.routeDateTo = moment(filter.routeDateTo, 'YYYY-MM-DD').format(
      'YYYY/MM/DD'
    );

    this.props.exportFrame({
      url: Configs[env].BACKEND_HOST + '/frameDashboard/report',
      filter: filter,
    });
  };
  handleExportEXCEL = () => {
    let filter = this.props.search_frame;
    filter.routeDateFrom = moment(filter.routeDateFrom, 'YYYY-MM-DD').format(
      'YYYY/MM/DD'
    );
    filter.routeDateTo = moment(filter.routeDateTo, 'YYYY-MM-DD').format(
      'YYYY/MM/DD'
    );
    //console.log(this.props);
    this.props.exportFrame({
      url: Configs[env].BACKEND_HOST + '/frameDashboard/report',
      filter: filter,
    });
  };

  importFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    let url = Configs[env].BACKEND_HOST + '/frameDashboard/importfile';
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Cache: 'no-cache',
      Authorization: Configs[env].AUTHORIZATION,
      token: localStorage.getItem('token'),
    };
    axios({
      method: 'post',
      url: url,
      data: formData,
      headers: headers,
    })
      .then((res) => {
        this.setState({
          sq_respone: res.data.SQ,
        });
        if (res.status == '200') {
          this.handleOpenMadal();
        }
      })
      .catch(() => {
        this.setState({
          mr_respone: ['MR is empty'],
          sq_respone: ['SQ is empty'],
        });
        this.handleOpenMadal();
      })
      .finally(() => {
        document.getElementById('fileXlsx').value = '';
      });
  };

  handleOpenMadal = () => {
    this.setState({
      modalStatus: true,
    });
  };

  render() {
    const { handleSubmit, classes, invalid } = this.props;

    return (
      <div
        style={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 80px)',
        }}
        testing='div-operation-dashboard'
      >
        <div>
          <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            className={classes.modal}
            open={this.state.modalStatus}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={this.state.modalStatus}>
              <div className={classes.paper}>
                <div className={classes.headModal}>
                  <h2 className={classes.h2title}>Status Post</h2>
                  <svg
                    style={{ marginRight: '10px' }}
                    width='32'
                    height='32'
                    viewBox='0 0 32 32'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M16 13V18'
                      stroke='white'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M14.2748 4.99983L3.27479 23.9998C3.09955 24.3033 3.00715 24.6476 3.00684 24.9981C3.00653 25.3485 3.09832 25.693 3.27302 25.9968C3.44773 26.3006 3.6992 26.5532 4.00226 26.7292C4.30532 26.9053 4.64932 26.9986 4.99979 26.9998H26.9998C27.3503 26.9986 27.6943 26.9053 27.9973 26.7292C28.3004 26.5532 28.5519 26.3006 28.7266 25.9968C28.9013 25.693 28.9931 25.3485 28.9927 24.9981C28.9924 24.6476 28.9 24.3033 28.7248 23.9998L17.7248 4.99983C17.5509 4.69591 17.2997 4.44334 16.9968 4.26768C16.6939 4.09202 16.35 3.99951 15.9998 3.99951C15.6496 3.99951 15.3057 4.09202 15.0028 4.26768C14.6998 4.44334 14.4487 4.69591 14.2748 4.99983V4.99983Z'
                      stroke='white'
                      stroke-width='2'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    />
                    <path
                      d='M16 24C16.8284 24 17.5 23.3284 17.5 22.5C17.5 21.6716 16.8284 21 16 21C15.1716 21 14.5 21.6716 14.5 22.5C14.5 23.3284 15.1716 24 16 24Z'
                      fill='white'
                    />
                  </svg>
                </div>
                <div className={classes.bodyModal}>
                  {this.state.sq_respone[0] === 'SQ is empty' ? (
                    <h4 className={classes.textError}>
                      Error: Please contact admin
                    </h4>
                  ) : (
                    <p></p>
                  )}

                  {this.state.sq_respone[0] !== 'SQ is empty' &&
                  this.state.sq_respone[0] !== 'sheet is empty' ? (
                    <h4>
                      SQ_PART{' '}
                      {this.state.sq_respone.length === 0 ? (
                        <svg
                          width='14'
                          height='14'
                          viewBox='0 0 14 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4967 5.27711 12.8108 3.62573 11.5925 2.40746C10.3743 1.18918 8.72289 0.503304 7 0.5ZM10.0938 5.8625L6.43125 9.3625C6.33657 9.45157 6.21125 9.5008 6.08125 9.5C6.01771 9.50091 5.95463 9.48923 5.89563 9.46563C5.83663 9.44203 5.78289 9.40698 5.7375 9.3625L3.90625 7.6125C3.85546 7.56819 3.81415 7.51406 3.78481 7.45337C3.75547 7.39269 3.73871 7.3267 3.73552 7.25937C3.73233 7.19204 3.7428 7.12476 3.76628 7.06157C3.78975 6.99839 3.82577 6.9406 3.87215 6.89169C3.91853 6.84278 3.97432 6.80375 4.03617 6.77695C4.09802 6.75015 4.16465 6.73614 4.23206 6.73574C4.29946 6.73535 4.36625 6.7486 4.42841 6.77467C4.49056 6.80075 4.54681 6.83913 4.59375 6.8875L6.08125 8.30625L9.40625 5.1375C9.50347 5.05268 9.62972 5.00872 9.75859 5.01482C9.88747 5.02092 10.009 5.0766 10.0978 5.17022C10.1866 5.26384 10.2357 5.38815 10.235 5.51717C10.2342 5.64618 10.1836 5.76992 10.0938 5.8625Z'
                            fill='#27AE60'
                          />
                        </svg>
                      ) : (
                        <svg
                          width='14'
                          height='14'
                          viewBox='0 0 14 14'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4967 5.27711 12.8108 3.62573 11.5925 2.40746C10.3743 1.18918 8.72289 0.503304 7 0.5ZM9.35625 8.64375C9.45014 8.73855 9.50282 8.86658 9.50282 9C9.50282 9.13342 9.45014 9.26145 9.35625 9.35625C9.26068 9.44866 9.13294 9.50032 9 9.50032C8.86706 9.50032 8.73932 9.44866 8.64375 9.35625L7 7.70625L5.35625 9.35625C5.26068 9.44866 5.13294 9.50032 5 9.50032C4.86706 9.50032 4.73932 9.44866 4.64375 9.35625C4.54986 9.26145 4.49719 9.13342 4.49719 9C4.49719 8.86658 4.54986 8.73855 4.64375 8.64375L6.29375 7L4.64375 5.35625C4.56402 5.2591 4.52328 5.13577 4.52944 5.01024C4.53561 4.88472 4.58824 4.76597 4.67711 4.67711C4.76598 4.58824 4.88472 4.5356 5.01025 4.52944C5.13577 4.52327 5.2591 4.56402 5.35625 4.64375L7 6.29375L8.64375 4.64375C8.7409 4.56402 8.86423 4.52327 8.98976 4.52944C9.11528 4.5356 9.23403 4.58824 9.3229 4.67711C9.41177 4.76597 9.4644 4.88472 9.47057 5.01024C9.47673 5.13577 9.43598 5.2591 9.35625 5.35625L7.70625 7L9.35625 8.64375Z'
                            fill='#B20000'
                          />
                        </svg>
                      )}
                    </h4>
                  ) : (
                    <p></p>
                  )}
                  {this.state.sq_respone[0] === 'SQ is empty' ? (
                    <p></p>
                  ) : this.state.sq_respone.length === 0 ? (
                    <p className={classes.textSucces}>Success Post</p>
                  ) : this.state.sq_respone[0] === 'sheet is empty' ? (
                    <h4 className={classes.textError}>
                      Error: There is no "seq_part" sheet
                    </h4>
                  ) : (
                    this.state.sq_respone.map((sq) => (
                      <p className={classes.textError}>{sq}</p>
                    ))
                  )}
                  <div className={classes.buttonStyle}>
                    <Button
                      className='inlineButton'
                      onClick={() => {
                        this.setState({
                          modalStatus: false,
                        });
                        this.manualReload(this.props.search_frame);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
          <Form
            testing='form-add_edit-logistics-point'
            autoComplete={'off'}
            autoCorrect={'off'}
            spellCheck={'off'}
            onSubmit={handleSubmit(this.handleSearch.bind(this))}
          >
            <Grid container spacing={2}>
              <Grid container item xs={4}>
                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={6}>
                    <PlantSelect />
                  </Grid>
                  <Grid item xs={6}>
                    <RouteSelect />
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <FormControl required fullWidth noValidate autoComplete='off'>
                    <TextField
                      id='framedash-search'
                      label='SEARCH'
                      type='search'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //value={this.props.textSearchFrame}
                      margin='dense'
                      className={classes.textField}
                      onChange={this.handleInputChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment variant='filled' position='end'>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    width: '300px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    cursor: 'pointer',
                  }}
                >
                  <Button
                    disabled={this.props.isLoading}
                    variant='contained'
                    size='small'
                    color='primary'
                    className={styles.margin}
                    onClick={() => this.manualReload(this.props.search_frame)}
                  >
                    Update
                  </Button>
                  <Button
                    size='medium'
                    className={styles.margin}
                    disabled={this.props.isLoading}
                    onClick={() => this.manualReload(this.props.search_frame)}
                  >
                    {this.state.last_updated &&
                      moment(this.state.last_updated).format(
                        'YYYY/MM/DD HH:mm:ss'
                      )}
                    &nbsp;(
                    <TimerMachine
                      timeStart={this.state.timeStart * 1000} // start at 10 seconds
                      timeEnd={0} // end at 20 seconds
                      started={this.state.started}
                      paused={false}
                      countdown={true} // use as stopwatch
                      interval={1000} // tick every 1 second
                      formatTimer={(time, ms) =>
                        moment.duration(ms, 'milliseconds').format('h:mm:ss')
                      }
                      onComplete={() => {
                        this.handleTimerOnComplete(this.props.search_frame);
                      }}
                    />
                    )
                  </Button>
                </div>
              </Grid>
              <Grid container spacing={2} item xs={3}>
                <Grid item xs={5}>
                  <InputLabel
                    shrink
                    htmlFor='start-required'
                    className={classes.txtBlack}
                  >
                    Route Date From :
                  </InputLabel>
                </Grid>
                <Grid item xs={7}>
                  <Field
                    name='routeDateFrom'
                    type='date'
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.changeDateForm}
                    margin='dense'
                    validate={[required, betweenRouteDate]}
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputLabel
                    shrink
                    htmlFor='end-required'
                    className={classes.txtBlack}
                  >
                    Route Date To :
                  </InputLabel>
                </Grid>
                <Grid item xs={7}>
                  <Field
                    name='routeDateTo'
                    type='date'
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.changeDateTo}
                    margin='dense'
                    validate={[required, betweenRouteDate]}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={2}
                style={{ textAlign: 'center' }}
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {JSON.parse(localStorage.getItem('privilege')).sequenceDash
                    .controllable ? (
                    <div style={{ display: 'inline-flex' }} className='ipf'>
                      <label
                        className='label-file-input fixed-input'
                        for='fileXlsx'
                      >
                        UPLOAD
                        <div className='fixed-upload-file'>
                          <input
                            id='fileXlsx'
                            type='file'
                            accept='.xlsx'
                            onChange={(e) => this.importFile(e.target.files[0])}
                          />
                        </div>
                      </label>
                    </div>
                  ) : (
                    <div style={{ display: 'inline-flex' }} className='ipf'>
                      <label className='label-file-input-disible fixed-input'>
                        UPLOAD
                      </label>
                    </div>
                  )}
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={this.handleClear}
                    className={styles.button}
                  >
                    CLEAR
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    color='secondary'
                    disabled={
                      invalid ||
                      this.state.isDisableDownload ||
                      !JSON.parse(localStorage.getItem('privilege'))
                        .sequenceDash.controllable
                    }
                    onClick={this.handleExportEXCEL}
                    className={styles.button}
                  >
                    DOWNLOAD
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </div>
        <div
          style={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1em',
          }}
        >
          <Card className={styles.card} style={{ height: '100%' }}>
            <CardContent style={{ height: '100%' }}>
              <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={8} style={{ height: '100%' }}>
                  <FrameGrid blinkColor={this.state.blinkColor} />
                </Grid>
                <Grid item xs={4} style={{ height: '100%' }}>
                  <SubFrameGrid blinkColor={this.state.blinkColor} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  search_frame: state.search.search_frame,
  textSearchFrame: state.search.search_frame_txt,
  isLoading: state.ui.isLoading,
  dashboard_subframe_open: state.dashboard.dashboard_subframe_open,
});
const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    getFrame: (payload) => dispatch(getFrame(payload)),
    searchFrame: (payload) => dispatch(searchFrame(payload)),
    searchFrameTxt: (payload) => dispatch(searchFrameTxt(payload)),
    exportFrame: (payload) => dispatch(exportFrame(payload)),
    dashboardSubFrame: (payload) => dispatch(dashboardSubFrame(payload)),
    dashboardSelected: (payload) => dispatch(dashboardSelected(payload)),
    SET_SNACKBAR: (payload) => dispatch(SET_SNACKBAR(payload)),
    STATUS_LOADING: (payload) => dispatch(STATUS_LOADING(payload)),
    dispatch,
  };
};
const enhance = compose(
  reduxForm({
    form: 'frameForm',
    // validate
    initialValues: { min: '1', max: '10' },
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
);

export default enhance(Main);
