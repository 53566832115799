import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

//Component
import ProviderDialog from './Dialog/ProviderDialog';
import ProviderBtnRenderer from './Renderer/ProviderBtnRenderer';
//Reducer
import { getProvider } from '../../reducers/setting/api';
import { searchProvider } from '../../reducers/search';
import { settingOpenProvider } from '../../reducers/setting';
import { setSidebarVisibility, uiSwitchForm } from '../../reducers/ui';
import { formProvider } from '../../reducers/ui/mapForm';
import store from 'reducers/rootReducer';

import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({});
class Provider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'ADD PROVIDER',
      columnDefs: [
        { headerName: 'Provider', field: 'name' },
        { headerName: 'Last Update', field: 'lastUpdate' },
        {
          cellRenderer: 'providerBtnRenderer',
          width: 80,
        },
      ],
      rowData: null,
      defaultColDef: { sortable: true, resizable: true },
      frameworkComponents: {
        providerBtnRenderer: ProviderBtnRenderer,
      },
    };
  }
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate() {
    this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {}
  renderData = () => {
    if (Array.isArray(this.props.setting_provider)) {
      //this.props.setLogisticPoint(this.props.setting_provider);
      //filter text in location point
      if (this.props.textSearchProvider === '')
        return this.props.setting_provider;
      else {
        let result = [];
        let findText = this.props.textSearchProvider;
        this.props.setting_provider.forEach((point, i) => {
          if (point.name.toUpperCase().search(findText.toUpperCase()) > -1)
            result.push(point);
        });

        return result;
      }
    } else return [];
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      let columns = this.gridColumnApi.getAllColumns();
      columns.forEach(function (column, index) {
        if (index !== columns.length - 1) allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  getData = () => {
    this.props.getProvider({
      url: Configs[env].BACKEND_HOST + '/gpsProvider',
      filter: {},
    });
  };
  handleAdd = () => {
    this.props.formProvider({});
    //this.props.settingUserSelect(this.props.userForm.id);
    this.props.settingOpenProvider(true);
  };
  onFilterTextBoxChanged = (event) => {
    this.props.searchProvider(event.target.value);
    //this.props.filterResult({key:'LOCATION_PROVIDER_LIST',filter:event.target.value});
    //this.state.api.setQuickFilter(document.getElementById('location-search').value);
  };
  render() {
    return (
      <div style={{ width: '100%', height: '400px' }}>
        <div
          className='ag-theme-balham'
          style={{
            height: '98%',
            width: '100%',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl required fullWidth noValidate autoComplete='on'>
                <TextField
                  id='provider-search'
                  label='SEARCH PROVIDER'
                  type='search'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin='dense'
                  className={styles.textField}
                  onChange={this.onFilterTextBoxChanged.bind(this)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant='filled' position='end'>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DialogActions>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Add'
                  className={classNames(styles.button)}
                  onClick={this.getData}
                >
                  REFRESH
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Add'
                  className={classNames(styles.button)}
                  onClick={this.handleAdd}
                  disabled={!JSON.parse(localStorage.getItem('privilege')).configuration.controllable}
                >
                  ADD PROVIDER
                </Button>
              </DialogActions>
            </Grid>
          </Grid>

          <AgGridReact
            reactNext={true}
            reduxStore={store}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderData()}
            rowSelection='multiple'
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
          <ProviderDialog title={this.state.title} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    setting_provider: state.setting.setting_provider,
    textSearchProvider: state.search.search_provider,
  };
};
const mapActionsToProps = {
  searchProvider,
  getProvider,
  uiSwitchForm,
  formProvider,
  settingOpenProvider,
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(Provider);
