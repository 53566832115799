import React, { useEffect, useRef, useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
//Component
import TollRenderer from '../Ui/Renderer/TollRenderer';
import AddBtnRenderer from './Renderer/AddBtnRenderer';
import SecToTimeRenderer from '../Ui/Renderer/SecToTimeRenderer';
import DistanceRenderer from '../Ui/Renderer/DistanceRenderer';
import { waypointsByDistanceCond } from 'Components/HereMap/libs/geoLib';
import { makeStyles } from '@material-ui/core/styles';
import { hmapCandidateRoutes } from 'reducers/hmap/api';
import { hmapSelector, clearState } from 'reducers/hmap';
import { withSnackbar } from 'notistack';

import {
  MAP_SELECT_CANDIDATE,
  // mapResetMap,
  SELECT_MASTER_OR_CANDIDATE,
  SET_MARKER_ORIGIN_DESC,
} from '../../reducers/map';
import store from 'reducers/rootReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin: 0,
    width: 200,
  },
}));

const CandidateRoutes = (props) => {
  const { enqueueSnackbar } = props;
  const gridApiRef = useRef();
  const gridColumnApiRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);

  const logisticRouteForm = useSelector((state) => {
    return state.mapForm.logisticRouteForm;
  });

  const candidate_selecting = useSelector((state) => {
    return state.map.candidate_selecting;
  });

  const tableConfigs = {
    columnDefs: [
      {
        headerName: 'ADD',
        field: 'Vehicle',
        cellRenderer: 'addBtnRenderer',
        width: 35,
      },
      {
        headerName: 'ROUTE',
        field: 'Vehicle',
        width: 80,
        autoHeight: true,
      },
      {
        headerName: 'DISTANCE',
        field: 'TotalDistance',
        cellRenderer: 'distanceRenderer',
        width: 75,
        autoHeight: true,
      },
      {
        headerName: 'DURATION',
        field: 'TotalTime',
        cellRenderer: 'secToTimeRenderer',
        width: 120,
        autoHeight: true,
      },
      {
        headerName: 'TOLL',
        field: 'TollUsage',
        width: 50,
        autoHeight: true,
        cellRenderer: 'tollRenderer',
      },
    ],
    rowData: null,
    context: { componentParent: this },
    suppressRowClickSelection: true,
    rowSelection: 'single',
    defaultColDef: { sortable: true, resizable: true },
    frameworkComponents: {
      tollRenderer: TollRenderer,
      addBtnRenderer: AddBtnRenderer,
      secToTimeRenderer: SecToTimeRenderer,
      distanceRenderer: DistanceRenderer,
    },
  };
  const { routeError, candidateRouteResult } = useSelector(hmapSelector);

  useEffect(() => {
    if (routeError) {
      enqueueSnackbar('Failed to get route candidates', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 4000,
      });
    }
  }, [routeError, enqueueSnackbar]);

  useEffect(() => {
    if (candidateRouteResult) {
      let rows = candidateRouteResult.routes.map((r, idx) => {
        if (r.sections && r.sections.length > 0) {
          let linestring = window.H.geo.LineString.fromFlexiblePolyline(
            r.sections[0].polyline
          );
          let waypoints = waypointsByDistanceCond(
            linestring.toGeoJSON()?.coordinates
          );
          return {
            Id: r.sections[0].id,
            Vehicle: `Route${idx + 1}`,
            TotalTime: r.sections[0].summary.baseDuration / 60,
            TotalDistance: r.sections[0].summary['length'],
            TollUsage: r.sections[0].tolls ? true : false,
            MergeRoute: r.sections[0].polyline,
            waypoints: waypoints,
            sections: r.sections,
          };
        }
        return null;
      });
      setRowData(rows);
      if (rows.length > 0) {
        dispatch(SELECT_MASTER_OR_CANDIDATE(2));
        dispatch(MAP_SELECT_CANDIDATE(rows[0]));
        dispatch(clearState());
      }
    }
  }, [candidateRouteResult, dispatch]);

  useEffect(() => {
    dispatch(
      SET_MARKER_ORIGIN_DESC({
        marker_origin: {
          lat: logisticRouteForm.originLat,
          lng: logisticRouteForm.originLng,
        },
        marker_desc: {
          lat: logisticRouteForm.destinationLat,
          lng: logisticRouteForm.destinationLng,
        },
      })
    );
  }, [dispatch, logisticRouteForm]);

  //Grid
  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
  };

  const focusRow = useCallback((candidate_selecting) => {
    if (gridApiRef.current && candidate_selecting) {
      gridApiRef.current.deselectAll();
      gridApiRef.current.forEachNode(function (node) {
        if (node.data.Id === candidate_selecting.Id) {
          node.setSelected(true, true);
          gridApiRef.current.ensureIndexVisible(node.rowIndex, {
            position: 'top',
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (candidate_selecting) {
      focusRow(candidate_selecting);
    }
  }, [candidate_selecting, focusRow]);

  const getCandidateRoutes = useCallback(() => {
    dispatch(
      hmapCandidateRoutes({
        transportMode: 'truck',
        origin: `${logisticRouteForm.originLat},${logisticRouteForm.originLng}`,
        destination: `${logisticRouteForm.destinationLat},${logisticRouteForm.destinationLng}`,
      })
    );
  }, [dispatch, logisticRouteForm]);

  const handleSelectionChanged = (node) => {
    var selectedRows = node.api.getSelectedRows();

    dispatch(SELECT_MASTER_OR_CANDIDATE(2));
    dispatch(MAP_SELECT_CANDIDATE(selectedRows[0]));
  };

  return (
    <div style={{ width: '100%', height: '180px', padding: '5px' }}>
      <div
        className='ag-theme-balham'
        style={{
          height: '150px',
          width: '100%',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DialogActions>
              <Typography>CANDIDATE ROUTES : </Typography>
              <Button
                variant='contained'
                size='small'
                color='primary'
                aria-label='Add'
                className={classNames(classes.button)}
                onClick={getCandidateRoutes}
              >
                GET
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <AgGridReact
          reactNext={true}
          reduxStore={store}
          singleClickEdit={true}
          suppressMovableColumns={true}
          columnDefs={tableConfigs.columnDefs}
          defaultColDef={tableConfigs.defaultColDef}
          rowData={rowData}
          rowSelection='single'
          onGridReady={onGridReady}
          onRowClicked={handleSelectionChanged}
          frameworkComponents={tableConfigs.frameworkComponents}
        />
      </div>
    </div>
  );
};

export default compose(withSnackbar)(CandidateRoutes);
