import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootCircular: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 10000,
  },
}));

const ComponentLoading = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.rootCircular}>
      <CircularProgress size={80} thickness={4} />
    </div>
  );
};
export default ComponentLoading;
