import { createSlice } from '@reduxjs/toolkit';
import { deleteJobs } from './api';
const initialState = {
  showDeleteButton: false,
  selectedJobs: [],
  isDeleting: false,
  error: null,
  isSuccess: false,
};
export const operationDashboardSlice = createSlice({
  name: 'operationDashboard',
  initialState,
  reducers: {
    clearState: (state) => {
      state.showDeleteButton = false;
      state.selectedJobs = [];
      state.isDeleting = false;
      state.isSuccess = false;
      state.error = null;
    },
    setShowDeleteButton: (state, { payload }) => {
      state.showDeleteButton = payload;
    },
    setSelectedJobs: (state, { payload }) => {
      state.selectedJobs = payload;
    },
  },
  extraReducers: {
    [deleteJobs.fulfilled]: (state, { payload }) => {
      state.isDeleting = false;
      state.isSuccess = true;
    },
    [deleteJobs.pending]: (state, _) => {
      state.isDeleting = true;
    },
    [deleteJobs.rejected]: (state, { payload }) => {
      state.isDeleting = false;
      state.isSuccess = false;
      state.error = payload;
    },
  },
});

export const { clearState, setShowDeleteButton, setSelectedJobs } =
  operationDashboardSlice.actions;
export const operationDashboardSelector = (state) => state.operationDashboard;

export default operationDashboardSlice.reducer;
