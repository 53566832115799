import { createAsyncThunk } from '@reduxjs/toolkit';
import Configs from 'config/config';
const env = process.env.NODE_ENV;

export const deleteJobs = createAsyncThunk(
  'deleteJobs',
  async (payload, thunkAPI) => {
    try {
      const params = {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
          Cache: 'no-cache',
          Authorization: Configs[env].AUTHORIZATION,
          token: localStorage.getItem('token'),
        },
        body: JSON.stringify(payload), // We send data in JSON format
      };
      const url = `${Configs[env].BACKEND_HOST}/operationDashboard`;
      const reqUrl = `${url}`;

      const response = await fetch(reqUrl, params);
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);
