export const MAPS = {
  LOCATIONS: 'LOCATIONS',
  ROUTES: 'ROUTES',
};

export const AREA_TYPES = {
  ALLOWED: 'ALLOWED',
  RESTRICTED: 'RESTRICTED',
  SPEED_LIMIT: 'SPEED_LIMIT',
};

export const DRAWING_MODE = {
  MARKER: 'MARKER',
  CIRCLE: 'CIRCLE',
  POLYGON: 'POLYGON',
};
