import React, { useState, useEffect } from 'react';
import { makeStyles, Button, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    return {
      position: 'absolute',
      top: `${props.top - (90 + 40)}px`,
      left: `${props.left - 75}px`,
      borderRadius: '10px',
      height: '90px',
      width: '150px',
    };
  },
  header: {
    padding: '5px',
    display: 'flex',
  },
  closeIcon: {
    display: 'block',
    marginLeft: 'auto',
  },
  deleteButton: {
    display: 'block',
    backgroundColor: 'red',
    color: 'white',
    height: '30px',
    margin: 'auto',
  },
}));

const InfoWindowDelete = (props) => {
  const { onDeleteClick, content, disabled, onClose } = props;
  const classes = useStyles(content);
  const [isOpen, setIsOpen] = useState(content && content.top && content.left);

  useEffect(() => {
    setIsOpen(content && content.top && content.left);
  }, [content]);

  if (!isOpen || disabled) {
    return <div style={{ display: 'none' }} />;
  }

  return (
    <Paper className={classes.root}>
      <div>
        <div className={classes.header}>
          <Typography variant='subtitle2'>
            {(content && content.title) || ''}
          </Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              setIsOpen(false);
              if (onClose) {
                onClose();
              }
            }}
          />
        </div>
        <Button
          variant='contained'
          size='small'
          className={classes.deleteButton}
          onClick={() => {
            if (onDeleteClick) {
              onDeleteClick(content);
            }
          }}
        >
          Delete
        </Button>
      </div>
    </Paper>
  );
};

export default InfoWindowDelete;
