import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
//Lib
//Conponent
import LogisticRouteForm from './LogisticRouteForm';
import MasterRoutes from './MasterRoutes';
import CandidateRoutes from './CandidateRoutes';
//Reducer
import { uiSwitchForm } from '../../reducers/ui';
import { mapDrawingMode, SET_MARKER_ORIGIN_DESC } from '../../reducers/map';
import { getLocationGroupDetail } from '../../reducers/map/api';
import { formLogisticRoute } from '../../reducers/ui/mapForm';
//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

class ManageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawingMode: null,
      marker: {
        latitude: 0,
        longitude: 0,
      },
    };
  }

  componentDidMount() {
    this.props.SET_MARKER_ORIGIN_DESC({
      marker_origin: {
        lat: this.props.logisticRouteForm.originLat,
        lng: this.props.logisticRouteForm.originLng,
      },
      marker_desc: {
        lat: this.props.logisticRouteForm.destinationLat,
        lng: this.props.logisticRouteForm.destinationLng,
      },
    });
  }

  componentWillMount() {
    if (this.props.logisticRouteForm) {
      let formData = this.props.logisticRouteForm;
      this.props.initialize(formData);
      if (formData.logisticsRouteId) {
        this.setState({ activeManageRoute: true });
        if (formData.originId && formData.destinationId)
          this.getOriDest_latlng();
      }
    }
  }

  getOriDest_latlng = () => {
    let formData = this.props.logisticRouteForm;
    this.props.GET_LOCATIONGROUP({
      url: Configs[env].BACKEND_HOST + '/locationGroup/' + formData.originId,
      filter: {},
      callback: this.setOrigin,
    });
  };

  setOrigin = (response) => {
    if (response.list[0]) {
      let originLat = response.list[0].latitude;
      let originLng = response.list[0].longitude;
      this.props.ROUTE_FORM({
        ...this.props.logisticRouteForm,
        originLat,
        originLng,
      });
    }

    let formData = this.props.logisticRouteForm;
    this.props.GET_LOCATIONGROUP({
      url:
        Configs[env].BACKEND_HOST + '/locationGroup/' + formData.destinationId,
      filter: {},
      callback: this.setDestination,
    });
  };

  setDestination = (response) => {
    if (response.list[0]) {
      let destinationLat = response.list[0].latitude;
      let destinationLng = response.list[0].longitude;
      this.props.ROUTE_FORM({
        ...this.props.logisticRouteForm,
        destinationLat,
        destinationLng,
      });
    }
  };
  render() {
    return (
      <div style={{ padding: '10px' }}>
        <Toolbar variant='dense'>
          <Typography variant='h6' gutterBottom className={styles.titlePanel}>
            {this.props.mode} ROUTE MASTER
          </Typography>
        </Toolbar>
        <Card className={styles.card}>
          <CardContent>
            <LogisticRouteForm />
          </CardContent>
        </Card>

        {this.props.logisticRouteForm.logisticsRouteId && <MasterRoutes />}
        {this.props.logisticRouteForm.logisticsRouteId && <CandidateRoutes />}
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    drawingMode: state.map.drawingMode,
    marker: state.map.marker,
    circle: state.map.circle,
    polygon: state.map.polygon,
    manageForm: state.mapForm.manageForm,
    logisticRouteForm: state.mapForm.logisticRouteForm,
    mode: state.ui.formManage.mode || ownProps.mode === 'edit' ? 'EDIT' : 'ADD',
  };
};
const mapActionsToProps = {
  SWITCH_FORM: uiSwitchForm,
  DRAWING_MODE: mapDrawingMode,
  GET_LOCATIONGROUP: getLocationGroupDetail,
  ROUTE_FORM: formLogisticRoute,
  SET_MARKER_ORIGIN_DESC: SET_MARKER_ORIGIN_DESC,
};

const enhance = compose(
  reduxForm({
    form: 'manageForm',
    // validate
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(ManageForm);
