import { handleActions } from 'redux-actions';
// ACTION CREATORS
import { createAction } from 'redux-actions';

export const mapDrawingMode = createAction('MAP_DRAWING_MODE');
export const mapAreaType = createAction('MAP_AREA_TYPE');
export const mapDrawingSelected = createAction('MAP_DRAWING_SELECTED');
export const mapChangeMarker = createAction('MAP_CHANGE_MARKER');

export const mapDrawingOptions = createAction('MAP_DRAWING_OPTION');

export const mapChangeRectangle = createAction('MAP_CHANGE_RECTANGLE');
export const mapChangePolyline = createAction('MAP_CHANGE_POLYLINE');
export const mapResetMap = createAction('MAP_RESET_MAP');
export const mapCancelResetMap = createAction('MAP_CANCELRESET_MAP');
export const mapCenterChanged = createAction('MAP_CENTER_CHANGE');
export const mapZoomChanged = createAction('MAP_ZOOM_CHANGE');
export const mapSetBounds = createAction('MAP_SET_BOUNDS');

//Map Object
export const mapSetMarkerPlace = createAction('MAP_SET_PLACE');
export const mapSetMarkers = createAction('MAP_SET_MARKERS');
export const mapSetCircles = createAction('MAP_SET_CIRCLE');
export const mapSetPolygons = createAction('MAP_SET_POLYGON');
export const mapSetPolylines = createAction('MAP_SET_POLYLINES');
export const mapSetMarkerGroup = createAction('MAP_SET_MARKERGROUP');
export const mapSetCandidatePolylines = createAction(
  'MAP_SET_CANDIDATE_POLYLINES'
);
export const mapSetMasterPolylines = createAction('MAP_SET_MASTER_POLYLINES');
export const mapSetSuggestionPolylines = createAction(
  'MAP_SET_SUGGESTION_POLYLINES'
);
export const mapSetMasterLegs = createAction('MAP_SET_MASTER_WAYPOINTS');

//Selection
export const mapSelectedMarker = createAction('MAP_SELECT_MARKER');
export const mapSelectedMarkerSearch = createAction('MAP_SELECT_MARKER_SEARCH');
export const mapSelectedCircle = createAction('MAP_SELECT_CIRCLE');
export const mapSelectedPolygon = createAction('MAP_SELECT_POLYGON');
export const MAP_SELECT_CANDIDATE = createAction('MAP_SELECT_CANDIDATE');
export const MAP_SELECT_SUGGESSION = createAction('MAP_SELECT_SUGGESSION');

//Editing
export const MAP_EDIT_SUGGESSION = createAction('MAP_EDIT_SUGGESSION');

export const mapMoveMarker = createAction('MAP_MOVE_MARKER');
export const mapChangePolygon = createAction('MAP_CHANGE_POLYGON');
export const mapChangeCircle = createAction('MAP_CHANGE_CIRCLE');
export const mapMoveMarkerGroup = createAction('MAP_MOVE_MARKERGROUP');

export const mapClearMarker = createAction('MAP_CLEAR_MARKER');
export const mapClearCircle = createAction('MAP_CLEAR_CIRCLE');
export const mapClearPolygon = createAction('MAP_CLEAR_POLYGON');

//Location Master
export const mapSetLogisticPoint = createAction('MAP_SET_LOGISTICPOINT');
export const mapSetLocationGroup = createAction('MAP_SET_LOCATIONGROUP');
//HazardPoint
export const mapSetWariningTypes = createAction('MAP_SET_WARNING_TYPES');
export const mapSetHazardPoints = createAction('MAP_SET_HAZARD_POINTS');
//ImpactHazard
export const mapSetImpactHazard = createAction('MAP_SET_HAZARD_IMPACT');
export const mapSetHazardHistoryLogs = createAction('MAP_SET_HAZARD_LOGS');
export const mapSetImpactRoute = createAction('MAP_IMPACT_ROUTE');
export const mapSetRouteCode = createAction('MAP_ROUTE_CODE');
export const mapSetRunSeq = createAction('MAP_RUN_SEQ');
export const mapSetHazardSelectList = createAction('MAP_HAZARDS_SELECTLIST');
//Route Master
export const mapSetLogisticRoutes = createAction('MAP_SET_LOGISTIC_ROUTES');
export const mapSetCandidateRoutes = createAction('MAP_SET_CANDIDATE_ROUTES');
export const mapSetMasterRoutes = createAction('MAP_SET_MASTER_ROUTES');

//Route Suggestion
export const mapSetSuggestionRoutes = createAction('MAP_SET_SUGGESTION_ROUTES');
export const mapSetTruckLocation = createAction('MAP_TRACK_LOCATION');
export const MAP_ROW_SUGGESSION = createAction('MAP_ROW_SUGGESSION');
export const mapSetHoverSeq = createAction('MAP_HOVER_SEQ');

export const SET_MAP_LOADING = createAction('SET_MAP_LOADING');
export const SET_DATA_LOADING = createAction('SET_DATA_LOADING');

export const MAP_LOG = createAction('MAP_LOG');
export const ADD_WAYPOINT = createAction('ADD_WAYPOINT');
export const LOAD_MODIFIED_ROUTE_SUCCESS = createAction(
  'LOAD_MODIFIED_ROUTE_SUCCESS'
);
export const MARKER_MOVE = createAction('MARKER_MOVE');
export const MARKER_DRAGGING = createAction('MARKER_DRAGGING');
export const MARKER_SELECTING = createAction('MARKER_SELECTING');
export const CLEAR_SELECTING = createAction('CLEAR_SELECTING');
export const DEL_MARKER = createAction('DEL_MARKER');
export const SET_MODIFYING_ROUTE_LOADING = createAction(
  'SET_MODIFYING_ROUTE_LOADING'
);

export const SET_MARKER_ORIGIN_DESC = createAction('SET_MARKER_ORIGIN_DESC');

export const SELECT_MASTER_OR_CANDIDATE = createAction(
  'SELECT_MASTER_OR_CANDIDATE'
);

export const MAP_RESET_MASTER_CANDIDATE = createAction(
  'MAP_RESET_MASTER_CANDIDATE'
);
export const SET_USE_TOLL = createAction('SET_USE_TOLL');

export const ADD_HAZARD_PHOTO = createAction('ADD_HAZARD_PHOTO');
export const DEL_HAZARD_PHOTO = createAction('DEL_HAZARD_PHOTO');
export const ADD_HAZARD_VDO = createAction('ADD_HAZARD_VDO');
export const DEL_HAZARD_VIDEO = createAction('DEL_HAZARD_VIDEO');
export const SET_AS_FIRST = createAction('SET_AS_FIRST');
export const SET_HAZARD_PHOTO = createAction('SET_HAZARD_PHOTO');

export const mapSetDropPoint = createAction('mapSetDropPoint');
export const mapSetSubLegs = createAction('mapSetSubLegs');

export const SET_ROUTESVIEW = createAction('SET_ROUTESVIEW');

export const MAP_SELECT_HAZARD = createAction('MAP_SELECT_HAZARD');
export const MAP_SELECT_ROUTE = createAction('MAP_SELECT_ROUTE');

export const SET_DATA_FOR_EDIT_ROUTE = createAction('SET_DATA_FOR_EDIT_ROUTE');
export const SET_LEG_EDITING = createAction('SET_LEG_EDITING');

export const SET_MODIFIED_ROUTE = createAction('SET_MODIFIED_ROUTE');

const initialState = {
  show_master_or_candidate: 1,
  logs: null,
  markerList: [],
  circleList: [],
  polygonList: [],
  candidatePolylineList: [],
  masterPolylineList: [],
  suggestionPolylineList: [],
  waypoints: [],
  routesview: [],
  path_onMouseOver: -1,
  paths: [],
  legs: [],
  marker_selecting: '',
  marker_search_selecting: '',
  route_selecting: '',
  candidate_selecting: -1,
  circle_selecting: '',
  polygon_selecting: '',

  //Route Suggestion
  suggestion_routes: [],
  suggestion_selecting: 0,
  suggestion_editing: -1,
  suggestion_rowdata: [],
  leg_editing: '',

  truck_location: [],
  fetching: false,
  error: null,
  drawingMode: null,
  areaType: null,
  markerGroup: null,
  markerPlace: null,
  center: {
    lat: 13.95,
    lng: 101.33,
  },
  zoom: 11,
  bounds: [],
  boundsIsSet: false,
  location_group: [],
  logistic_point: [],
  warning_types: [],
  hazard_points: [],

  //Impact Hazard
  hazard_impact: [],
  hazard_route: [],
  hazard_history_logs: [],
  route_codes: [],
  run_seqs: [],
  hazard_seleciton_list: [],

  logistic_routes: [],
  candidate_routes: [],

  master_routes: [],
  routes: [],
  drawingOptions: {},

  map_loading: false,
  data_loading: false,

  //--- Route Modifying
  marker_Dragging: -1,
  marker_MovingAdding: -1,
  modified_route: [],
  modifying_route_loading: false,
  marker_origin: null,
  marker_desc: null,
  TollUsage: null,
  use_toll: 1,

  //---- Hazard Point
  hazard_photos: [],
  hazard_vdo: null,

  //---- Route Suggestion
  dropPointList: [],
  hazard_selecting: -1,
  dataForEditRoute: [],
  subLegs: [],
  hoverSeq: 0,
};

// REDUCERS
export default handleActions(
  {
    [SET_DATA_FOR_EDIT_ROUTE](state, { payload }) {
      return {
        ...state,
        dataForEditRoute: payload,
      };
    },
    [SET_ROUTESVIEW](state, { payload }) {
      return {
        ...state,
        routesview: payload,
      };
    },

    [DEL_HAZARD_VIDEO](state) {
      return {
        ...state,
        hazard_vdo: null,
      };
    },
    [ADD_HAZARD_VDO](state, { payload }) {
      return {
        ...state,
        hazard_vdo: payload,
      };
    },

    [SET_AS_FIRST](state, { payload }) {
      let hazard_photos = [...state.hazard_photos];
      let tmp = hazard_photos[payload];
      hazard_photos.splice(payload, 1);
      hazard_photos.unshift(tmp);

      return {
        ...state,
        hazard_photos: hazard_photos,
      };
    },

    [DEL_HAZARD_PHOTO](state, { payload }) {
      let hazard_photos = [...state.hazard_photos];
      hazard_photos.splice(payload, 1);
      return {
        ...state,
        hazard_photos: hazard_photos,
      };
    },

    [ADD_HAZARD_PHOTO](state, { payload }) {
      let hazard_photos = [...state.hazard_photos];
      hazard_photos.push(payload);
      return {
        ...state,
        hazard_photos: hazard_photos,
      };
    },
    [SET_HAZARD_PHOTO](state, { payload }) {
      return {
        ...state,
        hazard_photos: payload,
      };
    },

    [SET_USE_TOLL](state, { payload }) {
      return {
        ...state,
        use_toll: payload,
      };
    },

    [SELECT_MASTER_OR_CANDIDATE](state, { payload }) {
      return {
        ...state,
        show_master_or_candidate: payload,
      };
    },

    [SET_MARKER_ORIGIN_DESC](state, { payload }) {
      let this_bounds = [];
      this_bounds.push({
        lat: payload.marker_origin.lat,
        lng: payload.marker_origin.lng,
      });
      this_bounds.push({
        lat: payload.marker_desc.lat,
        lng: payload.marker_desc.lng,
      });

      return {
        ...state,
        bounds: this_bounds,
        marker_origin: payload.marker_origin,
        marker_desc: payload.marker_desc,
      };
    },

    [SET_MAP_LOADING](state, { payload }) {
      return {
        ...state,
        map_loading: payload,
      };
    },
    [SET_DATA_LOADING](state, { payload }) {
      return {
        ...state,
        data_loading: payload,
      };
    },

    //--- Route Modifying
    [SET_MODIFYING_ROUTE_LOADING](state, { payload }) {
      return {
        ...state,
        modifying_route_loading: payload,
      };
    },
    [DEL_MARKER](state, { payload }) {
      let id = payload;
      var newArray = state.waypoints.slice();
      newArray.splice(id, 1);

      return {
        ...state,
        waypoints: newArray,
        marker_selecting: -1,
      };
    },
    [CLEAR_SELECTING](state, { payload }) {
      return {
        ...state,
        marker_selecting: -1,
        marker_MovingAdding: -1,
        hazard_selecting: -1,
        marker_search_selecting: null,
      };
    },
    [MARKER_SELECTING](state, { payload }) {
      return {
        ...state,
        marker_selecting: payload,
        marker_MovingAdding: payload,
      };
    },
    [MARKER_DRAGGING](state, { payload }) {
      return {
        ...state,
        marker_Dragging: payload,
        marker_MovingAdding: payload,
      };
    },
    [MARKER_MOVE](state, { payload }) {
      const moving_marker = payload;

      return {
        ...state,
        marker_Dragging: -1,
        marker_MovingAdding: moving_marker.id,
      };
    },
    [SET_MODIFIED_ROUTE](state, { payload }) {
      return {
        ...state,
        modified_route: payload,
      };
    },
    [LOAD_MODIFIED_ROUTE_SUCCESS](state, { payload }) {
      return {
        ...state,
        legs: payload.legs,
        modified_route: payload.modified_route,
        TollUsage: payload.TollUsage,
      };
    },
    [ADD_WAYPOINT](state, { payload }) {
      let id = payload.id;
      let position = payload.position;

      var cpWaypoints = state.waypoints.slice();

      cpWaypoints.splice(id + 1, 0, { position: position });

      return {
        ...state,
        marker_MovingAdding: id + 1,
        marker_selecting: -1,
        waypoints: cpWaypoints,
        logs: cpWaypoints,
      };
    },
    [MAP_LOG](state, { payload }) {
      return { ...state, logs: payload };
    },
    //PLACE MARKER
    [mapSetMarkerPlace](state, { payload }) {
      return { ...state, markerPlace: payload };
    },
    [mapSetMarkerGroup](state, { payload }) {
      return { ...state, markerGroup: payload };
    },

    [mapSetSubLegs](state, { payload }) {
      return { ...state, subLegs: payload };
    },
    [mapSetDropPoint](state, { payload }) {
      return { ...state, dropPointList: payload };
    },
    [mapSetMarkers](state, { payload }) {
      return { ...state, markerList: payload };
    },
    [mapSetCircles](state, { payload }) {
      return { ...state, circleList: payload };
    },
    [mapSetPolygons](state, { payload }) {
      return { ...state, polygonList: payload };
    },
    [mapSetPolylines](state, { payload }) {
      return { ...state, polylineList: payload };
    },
    [mapSetCandidatePolylines](state, { payload }) {
      return { ...state, candidatePolylineList: payload };
    },
    [mapSetMasterPolylines](state, { payload }) {
      return { ...state, masterPolylineList: payload };
    },
    [mapSetSuggestionPolylines](state, { payload }) {
      return { ...state, suggestionPolylineList: payload };
    },
    [mapSetMasterLegs](state, { payload }) {
      return { ...state, legs: payload.legs, waypoints: payload.waypoints };
    },

    [mapSelectedMarker](state, { payload }) {
      return { ...state, marker_selecting: payload };
    },
    [mapSelectedMarkerSearch](state, { payload }) {
      return { ...state, marker_search_selecting: payload };
    },
    [MAP_SELECT_HAZARD](state, { payload }) {
      return { ...state, hazard_selecting: payload };
    },
    [mapMoveMarker](state, { payload }) {
      let markers = state.markerList.slice();
      markers.forEach((marker, i) => {
        if (marker.id === payload.id) markers[i] = payload;
      });
      //markers[payload.id] = payload;
      return { ...state, markerList: markers };
    },
    [mapMoveMarkerGroup](state, { payload }) {
      //markers[payload.id] = payload;
      return { ...state, markerGroup: state.markerGroup };
    },
    [mapSelectedCircle](state, { payload }) {
      return { ...state, circle_selecting: payload };
    },
    [mapSelectedPolygon](state, { payload }) {
      return { ...state, polygon_selecting: payload };
    },
    [MAP_SELECT_CANDIDATE](state, { payload }) {
      return { ...state, candidate_selecting: payload };
    },
    [MAP_SELECT_SUGGESSION](state, { payload }) {
      return { ...state, suggestion_selecting: payload };
    },
    [MAP_SELECT_ROUTE](state, { payload }) {
      return { ...state, route_selecting: payload };
    },
    [MAP_EDIT_SUGGESSION](state, { payload }) {
      return { ...state, suggestion_editing: payload };
    },
    [MAP_ROW_SUGGESSION](state, { payload }) {
      return { ...state, suggestion_rowdata: payload };
    },
    [mapChangePolygon](state, { payload }) {
      let polygons = state.polygonList.slice();
      polygons.forEach((polygon, i) => {
        if (polygon.id == payload.id) polygons[i] = payload;
      });
      return { ...state, polygonList: polygons };
    },
    [mapChangeCircle](state, { payload }) {
      let circles = state.circleList.slice();
      circles.forEach((circle, i) => {
        if (circle.id == payload.id) circles[i] = payload;
      });
      return { ...state, circleList: circles };
    },
    ////////////CLAR/////////////
    [mapClearMarker](state, { payload }) {
      return { ...state, markerList: [] };
    },
    [mapClearCircle](state, { payload }) {
      return { ...state, circleList: [] };
    },
    [mapClearPolygon](state, { payload }) {
      return { ...state, polygonList: [] };
    },
    [mapResetMap](state, { payload }) {
      return {
        ...state,
        markerList: [],
        circleList: [],
        polygonList: [],
        candidatePolylineList: [],
        candidate_routes: [],
        hazard_selecting: -1,
        // masterPolylineList: [],
        waypoints: [],
        paths: [],
        legs: [],
        markerGroup: null,
        marker_origin: null,
        marker_desc: null,
        markerPlace: null,
        suggestionPolylineList: [],
        marker_search_selecting: null,
      };
    },
    [MAP_RESET_MASTER_CANDIDATE](state, { payload }) {
      return {
        ...state,
        candidatePolylineList: [],
        candidate_routes: [],
        masterPolylineList: [],
        master_routes: [],
        marker_origin: {},
        marker_desc: {},
      };
    },
    /////////CENTER////////////
    [mapCenterChanged](state, { payload }) {
      return { ...state, center: payload };
    },
    [mapZoomChanged](state, { payload }) {
      return { ...state, zoom: payload, boundsIsSet: false };
    },
    [mapSetBounds](state, { payload }) {
      if (payload.length > 0)
        return { ...state, bounds: payload, boundsIsSet: true };
      else return { ...state };
    },
    ////////////DRAWING//////////////
    [mapDrawingMode](state, { payload }) {
      return { ...state, drawingMode: payload };
    },
    [mapDrawingOptions](state, { payload }) {
      return { ...state, drawingOptions: payload };
    },
    [mapAreaType](state, { payload }) {
      return { ...state, areaType: payload };
    },
    [mapSetLogisticPoint](state, { payload }) {
      return { ...state, logistic_point: payload };
    },
    //////////////////LOCATIONS/////////////
    [mapSetLocationGroup](state, { payload }) {
      return { ...state, location_group: payload };
    },
    //////////////////HAZARDS/////////////
    [mapSetWariningTypes](state, { payload }) {
      return { ...state, warning_types: payload };
    },
    [mapSetHazardPoints](state, { payload }) {
      return { ...state, hazard_points: payload };
    },
    //////////////////IMPORT HAZARDS/////////////
    [mapSetImpactHazard](state, { payload }) {
      return { ...state, hazard_impact: payload };
    },
    [mapSetImpactRoute](state, { payload }) {
      return { ...state, hazard_route: payload };
    },
    [mapSetHazardHistoryLogs](state, { payload }) {
      return { ...state, hazard_history_logs: payload };
    },
    [mapSetHazardSelectList](state, { payload }) {
      return { ...state, hazard_seleciton_list: payload };
    },
    [mapSetRouteCode](state, { payload }) {
      return { ...state, route_codes: payload };
    },
    [mapSetRunSeq](state, { payload }) {
      return { ...state, run_seqs: payload };
    },
    ////////////////ROUTE MASTER/////////////
    [mapSetLogisticRoutes](state, { payload }) {
      return { ...state, logistic_routes: payload };
    },
    [mapSetCandidateRoutes](state, { payload }) {
      return { ...state, candidate_routes: payload };
    },
    [mapSetMasterRoutes](state, { payload }) {
      return { ...state, master_routes: payload };
    },
    ////////////////ROUTE SUGGESTION/////////////
    [mapSetSuggestionRoutes](state, { payload }) {
      return { ...state, suggestion_routes: payload };
    },
    [mapSetTruckLocation](state, { payload }) {
      return { ...state, truck_location: payload };
    },
    [mapSetHoverSeq](state, { payload }) {
      return { ...state, hoverSeq: payload };
    },
    [SET_LEG_EDITING](state, { payload }) {
      return { ...state, leg_editing: payload };
    },
  },
  initialState
);
