import React, { useEffect, useMemo, useState } from 'react';
import Configs from 'config/config';
import { useSelector, useDispatch } from 'react-redux';
import Mapview, {
  HMapPlaceSearch,
  HMapDrawCircle,
  HMapDrawMarker,
  HMapDrawPolygon,
} from 'Components/HereMap';
import {
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons,
  MARKER_SELECTING,
} from 'reducers/map';
import { markerPinSvgIcon } from 'Components/Common/MarkerIconRenderer';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import { HMapInfoWindow } from 'Components/HereMap';
import ImgVdoPreview from './ImgVdoPreview';
import Loading from 'Components/HereMap/Components/Loading';

const env = process.env.NODE_ENV;

const HazardPointManageMap = (props) => {
  const { mapRef } = props;
  const [bbox, setBbox] = useState(null);
  const [isSetBound, setIsSetBound] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();

  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });
  const hazardForm = useSelector((state) => {
    return state.mapForm.hazardForm;
  });
  const drawingMode = useSelector((state) => {
    return state.map.drawingMode;
  });
  const markerList = useSelector((state) => {
    return state.map.markerList;
  });
  const circleList = useSelector((state) => {
    return state.map.circleList;
  });
  const polygonList = useSelector((state) => {
    return state.map.polygonList;
  });
  const hazard_photos = useSelector((state) => {
    return state.map.hazard_photos;
  });
  const hazard_vdo = useSelector((state) => {
    return state.map.hazard_vdo;
  });
  const marker_selecting = useSelector((state) => {
    return state.map.marker_selecting;
  });

  useEffect(() => {
    if (marker_selecting) {
      setIsOpen(true);
    }
  }, [marker_selecting]);

  let marker = useMemo(() => {
    if (
      markerList &&
      markerList.length > 0 &&
      markerList[0].position &&
      markerList[0].position.lat &&
      markerList[0].position.lng
    ) {
      return markerList[0].position;
    } else {
      return null;
    }
  }, [markerList]);

  let circle = useMemo(() => {
    if (
      circleList &&
      circleList.length > 0 &&
      circleList[0].center &&
      circleList[0].center.lat &&
      circleList[0].center.lng
    ) {
      return circleList[0];
    } else {
      return null;
    }
  }, [circleList]);

  let polygon = useMemo(() => {
    if (polygonList && polygonList.length > 0 && polygonList[0].path) {
      return polygonList[0].path;
    } else {
      return null;
    }
  }, [polygonList]);

  useEffect(() => {
    setIsOpen(false);
    if (drawingMode === 'circle') {
      dispatch(mapSetPolygons([]));
    }
    if (drawingMode === 'polygon') {
      dispatch(mapSetCircles([]));
    }
    if (!drawingMode) {
      setIsOpen(true);
    }
  }, [drawingMode, dispatch]);

  useEffect(() => {
    let tmpBbox = [];
    if (marker) {
      tmpBbox.push([marker.lat, marker.lng]);
    }

    if (tmpBbox.length > 0) {
      setBbox(getBbox(tmpBbox));
    }
  }, [marker]);

  const mapPageManage = (drawingMode) => {
    const selectedMarkerFunction = () => {
      return {
        pointerdown: (_) => {
          if (drawingMode !== 'marker') {
            setIsOpen(true);
          } else {
            dispatch(MARKER_SELECTING(null));
            setIsOpen(false);
          }
        },
      };
    };
    return (
      <>
        <HMapDrawMarker
          key='marker'
          marker={marker}
          isDraw={drawingMode === 'marker'}
          draggable={drawingMode === 'marker'}
          options={{
            icon: markerPinSvgIcon(30, 40, '#3B538B', '#ffffff'),
          }}
          objectEvents={selectedMarkerFunction()}
          onCallback={(d) => {
            const markerList = [
              {
                id: 'id',
                position: d,
                isGroup: false,
              },
            ];
            dispatch(mapSetMarkers(markerList));
            setIsSetBound(false);
          }}
        >
          <HMapInfoWindow
            isOpen={isOpen}
            size={
              hazard_vdo || hazard_photos.length > 0
                ? { width: 430, height: 320 }
                : { width: 380, height: 260 }
            }
            onClose={() => {
              dispatch(MARKER_SELECTING(null));
              setIsOpen(false);
            }}
          >
            <ImgVdoPreview
              inputPhotos={hazard_photos}
              inputVdo={hazard_vdo}
              info={{
                name: hazardForm?.warning || '',
                description: hazardForm?.detail || '',
              }}
            />
          </HMapInfoWindow>
        </HMapDrawMarker>
        <HMapDrawCircle
          key='circle'
          circle={circle}
          draggable={drawingMode === 'circle'}
          resizable={drawingMode === 'circle'}
          isDraw={drawingMode === 'circle'}
          onCallback={(d) => {
            dispatch(mapSetCircles([d]));
            setIsSetBound(false);
          }}
        />
        <HMapDrawPolygon
          key='polygon'
          polygon={polygon}
          draggable={drawingMode === 'polygon'}
          resizable={drawingMode === 'polygon'}
          isDraw={drawingMode === 'polygon'}
          options={{
            style: {
              fillColor: 'rgba(0, 85, 170, 0.4)',
              strokeColor: 'rgba(0, 85, 170, 0.6)',
            },
          }}
          onCallback={(p) => {
            let path = p?.geometry?.coordinates[0]?.map((c) => {
              return {
                lat: c[1],
                lng: c[0],
              };
            });
            dispatch(mapSetPolygons([{ path }]));
            setIsSetBound(false);
          }}
        />
      </>
    );
  };

  return (
    <>
      {map_loading && <Loading />}
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={isSetBound && bbox}
      >
        {mapPageManage(drawingMode)}

        <HMapPlaceSearch />
      </Mapview>
    </>
  );
};
export default HazardPointManageMap;
