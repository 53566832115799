import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

//Component
import CategoryIconRenderer from '../HazardPoints/Renderer/CategoryIconRenderer';
//Reducer
import { apiGetCallRequest } from '../../reducers/api';
import {
  mapSetLogisticPoint,
  mapSelectedMarker,
  MAP_LOG,
  SET_HAZARD_PHOTO,
  ADD_HAZARD_VDO,
  MAP_SELECT_HAZARD,
  mapSetMarkers,
  mapSetBounds,
  mapSetPolygons,
  mapResetMap,
  mapSetCircles,
  MAP_SELECT_SUGGESSION,
  SET_MARKER_ORIGIN_DESC,
  mapSetSuggestionPolylines,
  MAP_SELECT_ROUTE,
} from '../../reducers/map';
import store from 'reducers/rootReducer';
import { decode } from '@liberty-rider/flexpolyline';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;
const gUtils = require('@googlemaps/google-maps-services-js/dist/util');

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin: 0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200,
  },
});
class HistoryLogs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pointList: this.props.hazard_history_logs,
      url_endpoint: {
        response: false,
        endpoint: Configs[env].BACKEND_HOST,
      },
      columnDefs: [
        {
          headerName: 'ROUTE DATE',
          field: 'routeDate',
          width: 120,
        },
        {
          headerName: 'ROUTE',
          field: 'routeCode',
          width: 120,
        },
        {
          headerName: 'REQ',
          field: 'runSeq',
          width: 100,
        },
        {
          headerName: 'LOCATION',
          field: 'location',
          width: 100,
        },
        {
          headerName: 'STATUS',
          field: 'status',
          width: 100,
        },
      ],
      rowData: null,
      context: { componentParent: this },
      defaultColDef: { sortable: true, resizable: true },
      frameworkComponents: {
        categoryIconRender: CategoryIconRenderer,
      },
    };
  }
  componentDidMount() {}
  componentWillUnmount() {}
  componentWillReceiveProps(nextProps) {
    // if (
    //   nextProps.grouplist.statusText === "OK" &&
    //   (nextProps.grouplist.url ===
    //     Configs[env].BACKEND_HOST + "/location/add_group" ||
    //     nextProps.grouplist.url ===
    //       Configs[env].BACKEND_HOST + "/location/edit_group")
    // ) {
    //   this.props.onDialogOpen(false);
    // }
    this.focusRow(nextProps.marker_selecting);
  }
  focusRow = () => {
    // let gridApi = this.gridApi
    // if(gridApi){
    //   gridApi.deselectAll()
    //   this.gridApi.forEachNode(function(node) {
    //     if (node.data.latitude === marker_selecting) {
    //       node.setSelected(true,true);
    //       gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' });
    //     }
    //   });
    // }
  };
  componentDidUpdate() {
    this.autoSizeAll();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function (column, index) {
        if (index !== 0) allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  renderRow() {
    if (Array.isArray(this.props.hazard_history_logs)) {
      //this.props.setLogisticPoint(this.props.hazard_history_logs);

      //filter text in location point
      if (this.props.textSearchLocation === '')
        return this.props.hazard_history_logs;
      else {
        let result = [];
        let findText = this.props.textSearchLocation;
        this.props.hazard_history_logs.forEach((v) => {
          if (
            v.name.toUpperCase().search(findText.toUpperCase()) > -1 ||
            v.logistic_point.toUpperCase().search(findText.toUpperCase()) > -1
          )
            result.push(v);
        });

        return result;
      }
    } else return [];
  }

  setHazard = (hazard) => {
    let markerList = [];
    let marker = {};

    let bounds = [];

    marker = {
      ...hazard,
      // hazardCode: hazard.hazardCode,
      // hid : point.id,
      id: hazard.id,
      position: { lat: hazard.latitude, lng: hazard.longitude },
      info: {
        name: hazard.location,
        description: hazard.detail,
        logisticsPoint: hazard.category,
      },
      isGroup: false,
      category: hazard.category,
      editable: false,
    };
    //markerList.push(marker);
    markerList.push(marker);
    this.props.mapSetMarkers(markerList);

    bounds.push({ lat: hazard.latitude, lng: hazard.longitude });
    this.props.mapSetBounds(bounds);
  };

  setPolygon = (hazard) => {
    if (hazard.polygon) {
      let polygonList = [];
      let polygon = {};
      let bounds = [];

      var decodePath = gUtils.decodePath(hazard.polygon);
      var paths = [];
      decodePath.forEach(function (p) {
        paths.push({ lat: p.lat, lng: p.lng });
        bounds.push({ lat: p.lat, lng: p.lng });
      });
      polygon = {
        id: hazard.id,
        path: decodePath,
        isGroup: false,
        editable: false,
      };
      //polygonList.push(polygon);
      polygonList.push(polygon);
      this.props.mapSetPolygons(polygonList);

      // this.props.mapSetBounds(bounds)
    }
  };

  setCircle = (hazard) => {
    let circleList = [];
    let circle = {};
    //Circle
    if (hazard.type === 'circle') {
      circle = {
        id: hazard.id,
        center: { lat: hazard.circleLatitude, lng: hazard.circleLongitude },
        radius: hazard.radius,
        info: {
          name: hazard.location,
          description: hazard.detail,
          logisticsPoint: hazard.category,
        },
        isGroup: false,
        editable: false,
      };
      circleList.push(circle);
      this.props.mapSetCircles(circleList);
    }
  };

  setPolyline = (routes) => {
    let bounds = [];
    if (routes) {
      let polylineList = [];
      let polyline = {};
      //var decodePath = gUtils.decodePath(decodeURIComponent(routes));
      let decodePath = decode(decodeURIComponent(routes));
      decodePath = decodePath.polyline;
      var paths = [];
      decodePath.forEach(function (p) {
        paths.push({ lat: p[0], lng: p[1] });
        bounds.push({ lat: p[0], lng: p[1] });

        // paths.push({ lat: p.lat, lng: p.lng });
        // bounds.push({ lat: p.lat, lng: p.lng });
      });
      polyline = {
        id: 1,
        path: decodePath,
        // info : {
        //   name : point.location,
        //   description : point.detail,
        //   logisticsPoint : point.category ,
        // },
        isGroup: false,
        editable: false,
      };
      polylineList.push(polyline);
      // this.props.MAP_SELECT_SUGGESSION(1)
      this.props.mapSetSuggestionPolylines(polylineList);
      this.props.SET_MARKER_ORIGIN_DESC({
        marker_origin: {
          lat: paths[0].lat,
          lng: paths[0].lng,
        },
        marker_desc: {
          lat: paths[paths.length - 1].lat,
          lng: paths[paths.length - 1].lng,
        },
      });

      this.props.MAP_SELECT_SUGGESSION(1);
      this.props.MAP_SELECT_ROUTE(1);
      this.props.mapSetBounds(bounds);
    }
  };

  handleSelectionChanged = (node) => {
    this.props.mapResetMap();

    try {
      var selectedRows = node.api.getSelectedRows();
      // this.props.MAP_SELECT_HAZARD(selectedRows[0].id);
      if (selectedRows[0].mediaUrl && selectedRows[0].mediaUrl.length > 0) {
        let video = null;
        let photo = [];
        selectedRows[0].mediaUrl.forEach(function (row) {
          if (
            row.search(/mp4/i) > 0 ||
            row.search(/jpg/i) > 0 ||
            row.search(/jpeg/i) > 0
          ) {
            let files = row.split('.');
            if (files[files.length - 1].toLowerCase() === 'mp4') video = row;
            else photo.push(row);
          }
        });
        this.props.SET_HAZARD_PHOTO(photo);
        this.props.ADD_HAZARD_VDO(video);
      } else {
        this.props.SET_HAZARD_PHOTO([]);
        this.props.ADD_HAZARD_VDO(null);
      }
    } catch (e) {
      this.props.MAP_LOG({
        error: e,
        detail: 'Location.js - handleSelectionChanged',
      });
    }

    this.setHazard(selectedRows[0]);
    this.setCircle(selectedRows[0]);
    this.setPolygon(selectedRows[0]);
    this.setPolyline(selectedRows[0].routes);
  };
  render() {
    return (
      <div style={{ width: '100%', height: 'calc(100vh - 500px)' }}>
        <div
          className='ag-theme-balham'
          style={{
            height: 'calc(100vh - 500px)',
            width: '100%',
            marginTop: '10px',
          }}
        >
          <AgGridReact
            reactNext={true}
            reduxStore={store}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderRow()}
            rowSelection='single'
            onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    isLoading: state.api.fetching,
    logistic_point: state.map.logistic_point,
    hazard_history_logs: state.map.hazard_history_logs,
    textSearchLocation: state.search.search_location,
    marker_selecting: state.map.marker_selecting,
  };
};
const mapActionsToProps = {
  getHistoryLogs: apiGetCallRequest,
  setLogisticPoint: mapSetLogisticPoint,
  onSelectedMarker: mapSelectedMarker,
  SELECT_MARKER: mapSelectedMarker,
  MAP_LOG: MAP_LOG,
  SET_HAZARD_PHOTO,
  ADD_HAZARD_VDO,
  MAP_SELECT_HAZARD,
  mapSetMarkers,
  mapSetBounds,
  mapSetPolygons,
  mapSetCircles,
  mapResetMap,
  MAP_SELECT_SUGGESSION,
  MAP_SELECT_ROUTE,
  mapSetSuggestionPolylines,
  SET_MARKER_ORIGIN_DESC,
};

export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
)(HistoryLogs);
