import React, { useEffect } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: '180px',
    right: '20px',
  },
}));

const DeleteButton = (props) => {
  const { onDeleteClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton
        style={{
          backgroundColor: 'white',
          boxShadow: '0 0 2px rgba(0, 0, 0, 0.5)',
        }}
        onClick={() => {
          if (onDeleteClick) {
            onDeleteClick();
          }
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default DeleteButton;
