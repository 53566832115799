
export const upper = value => value && value.toUpperCase()

export const lower = value => value && value.toLowerCase()

export const lessThan = otherField => (value, previousValue, allValues) =>
  parseFloat(value) < parseFloat(allValues[otherField]) ? value : previousValue

export const greaterThan = otherField => (value, previousValue, allValues) =>
  parseFloat(value) > parseFloat(allValues[otherField]) ? value : previousValue
  

export const logisticPointFormat = value => {
    if (!value) {
      return value
    }
    const txtFormat = value.replace(/[^a-zA-Z0-9 ]/g, '')
    if (txtFormat.length <= 4) {
      return txtFormat.toUpperCase()
    }
    if (txtFormat.length <= 5) {
      return `${txtFormat.slice(0, 4).toUpperCase()}-${txtFormat.slice(4,5).toUpperCase()}`
    }
    return `${txtFormat.slice(0, 4).toUpperCase()}-${txtFormat.slice(4,5).toUpperCase()}-${txtFormat.slice(5,8).toUpperCase()}`
}