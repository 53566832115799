import React, { useEffect, useState } from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  root: (props) => {
    let height = props.height || 100;
    let width = props.width || 150;
    return {
      position: 'absolute',
      top: `${props.top - (height + 40)}px`,
      left: `${props.left - width / 2}px`,
      borderRadius: '10px',
      height: `${height}px`,
      width: `${width}px`,
      overflow: 'scroll',
    };
  },
  header: {
    padding: '5px',
    display: 'flex',
  },
  closeIcon: {
    display: 'block',
    marginLeft: 'auto',
  },
  deleteButton: {
    display: 'block',
    backgroundColor: 'red',
    color: 'white',
    height: '30px',
    margin: 'auto',
  },
}));

const InfoWindowHazardPoint = (props) => {
  const {
    title,
    disabled,
    marker,
    map,
    size,
    isOpen: propsIsOpen,
    onClose,
  } = props;

  let targetPosition = {};
  if (map && marker) {
    targetPosition = map.geoToScreen(marker);
  }

  const classes = useStyles({
    left: targetPosition.x || 0,
    top: targetPosition.y || 0,
    ...size,
  });

  const [isOpen, setIsOpen] = useState(propsIsOpen);

  useEffect(() => {
    setIsOpen(propsIsOpen);
  }, [propsIsOpen]);

  if (!marker || !map) {
    return <div style={{ display: 'none' }} />;
  }

  if (!isOpen || disabled) {
    return <div style={{ display: 'none' }} />;
  }

  return (
    <Paper className={classes.root}>
      <div>
        <div className={classes.header}>
          <Typography variant='subtitle2'>{title || ''}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => {
              setIsOpen(false);
              if (onClose) {
                onClose();
              }
            }}
          />
        </div>
        {props.children}
      </div>
    </Paper>
  );
};

export default InfoWindowHazardPoint;
