import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Link from '@material-ui/core/Link';
import { push } from 'connected-react-router';

const styles = {
  avatar: {
    margin: 5,
    width: 20,
    height: 20,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
};

class RouteRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  handleOpenRoute = () => {
    window.location.href =
      '/route_suggestion/' + this.props.data.jobDashboardId.value;
  };

  render() {
    let canEdit = false;
    let bgColor = '';
    if (this.props.data.routeCode.editable === 1) canEdit = true;
    let shiftSeq =
      this.props.data.shiftSeq.value !== ''
        ? '?shiftNo=' + this.props.data.shiftSeq.value
        : '';
    let link =
      '/route_suggestion/' + this.props.data.jobDashboardId.value + shiftSeq;

    return (
      <div
        className='something'
        styles={{
          width: '100%',
          height: '100%',
          backgroundColor: bgColor,
        }}
      >
        {canEdit && <div className='arrow-right'></div>}
        {canEdit ? (
          <Link
            style={{ textDecoration: 'none', color: 'black' }}
            href='#'
            onClick={(e) => {
              this.props.push(link);
              e.preventDefault();
            }}
          >
            {this.state.value}
          </Link>
        ) : (
          this.state.value
        )}
      </div>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
    activeMenuListItem: state.ui.activeMenuListItem,
  };
};

const mapActionsToProps = {
  push,
};
const enhance = compose(
  connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true }),
  withStyles(styles)
);

export default enhance(RouteRenderer);
