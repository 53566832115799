import React, { Component } from 'react';
import { setSidebarVisibility } from '../../reducers/ui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ConfirmBtnRenderer from './Renderer/ConfirmBtnRenderer';
import DateTimeFieldRenderer from './Renderer/DateTimeFieldRenderer';
import PlanETAFieldRenderer from './Renderer/PlanETAFieldRenderer';
import ProbActionFieldRenderer from './Renderer/ProbActionFieldRenderer';
import DateTimeDialog from './Dialog/DateTimeDialog';
import ProblemDialog from './Dialog/ProblemDialog';
import ActionDialog from './Dialog/ActionDialog';

import DateTimeEditor from './Renderer/DateTimeEditor';
import ManageBtnRenderer from './Renderer/ManageBtnRenderer';
import RouteRenderer from './Renderer/RouteRenderer';
import TrackingRenderer from './Renderer/TrackingRenderer';
import IconRouteRenderer from './Renderer/IconRouteRenderer';
import StatusOperationRenderer from '../Ui/Renderer/StatusOperationRenderer';
import SelectRenderer from '../Ui/Renderer/SelectRenderer';
import Avatar from '@material-ui/core/Avatar';
import $ from 'jquery';
import { getDataOperation } from '../../reducers/dashboard/operation';
import { dashboardSelected, dashboardSubFrame } from '../../reducers/dashboard';
import moment from 'moment';
import store from 'reducers/rootReducer';

import Configs from '../../config/config';
import './grid.css';

const env = process.env.NODE_ENV;
const MIN_HEIGHT = 25;

const styles = (theme) => ({
  avatar: {
    margin: 5,
    width: 20,
    height: 20,
  },
});

function getDatePicker() {
  function Datepicker() {}
  Datepicker.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({ dateFormat: 'dd/mm/yy' });
  };
  Datepicker.prototype.getGui = function () {
    return this.eInput;
  };
  Datepicker.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
  };
  Datepicker.prototype.getValue = function () {
    return this.eInput.value;
  };
  Datepicker.prototype.destroy = function () {};
  Datepicker.prototype.isPopup = function () {
    return false;
  };
  return Datepicker;
}
class FrameGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowSelecting: true,
      blinkColor: 0,
      columnDefs: [
        {
          headerName: 'Route Date',
          field: 'routeDate.value',
          headerClass: 'multiline',
          width: 100,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.routeDate.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.routeDate.color,
            };
          },
          cellClass: function (params) {
            return params.data.routeDate.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Shift Seq.',
          field: 'shiftSeq.value',
          headerClass: 'multiline',
          width: 50,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.shiftSeq.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.shiftSeq.color,
            };
          },
          cellClass: function (params) {
            return params.data.shiftSeq.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Route',
          field: 'routeCode.value',
          headerClass: 'multiline',
          width: 75,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.routeCode.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.routeCode.color,
            };
          },
          cellClass: function (params) {
            return params.data.routeCode.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Run Seq.',
          field: 'runSeq.value',
          headerClass: 'multiline',
          width: 50,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.runSeq.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.runSeq.color,
            };
          },
          cellClass: function (params) {
            return params.data.runSeq.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Truck License',
          field: 'truckLicense.value',
          headerClass: 'multiline',
          width: 80,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.truckLicense.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.truckLicense.color,
            };
          },
          cellClass: function (params) {
            return params.data.truckLicense.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Plan Arrival Time',
          field: 'planArrivalTime.value',
          cellRenderer: 'planETAFieldRenderer',
          headerClass: 'multiline',
          width: 100,
          cellStyle: function (params) {
            let color = params.data.planArrivalTime.color;
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor: color == '#FFFFFF' ? 'transparent' : color,
              //animation: 'blinker 1s step-start infinite',
            };
          },
          cellClass: function (params) {
            return params.data.planArrivalTime.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'ETA',
          field: 'ETA.value',
          headerClass: 'multiline',
          editable: false,
          cellRenderer: 'planETAFieldRenderer',
          //cellEditor: "datePicker",
          width: 60,
          cellStyle: function (params) {
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor:
                params.data.ETA.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.ETA.color,
            };
          },
          cellClass: function (params) {
            return params.data.ETA.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Status',
          field: 'status.value',
          headerClass: 'multiline',
          //cellRenderer: "statusOperationRenderer",
          width: 260,
          cellStyle: function (params) {
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor:
                params.data.status.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.status.color,
            };
          },
          cellClass: function (params) {
            return params.data.status.blink === 1 ? 'blink' : '';
          },
        } /*,{
          cellRenderer: "manageBtnRenderer",
          width: 50,
          cellStyle: function(params) {
            return {padding: '2px', lineHeight: '1em'};
          }
        }*/,
      ],
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            { field: 'callId' },
            { field: 'direction' },
            { field: 'number' },
            {
              field: 'duration',
              valueFormatter: "x.toLocaleString() + 's'",
            },
            { field: 'switchCode' },
          ],
          defaultColDef: {
            editable: true,
            resizable: true,
          },
          onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
          },
        },
        getDetailRowData: function (params) {
          params.successCallback(params.data.callRecords);
        },
      },
      detailRowHeight: 340,
      rowClassRules: {
        // "status-ontime": function(params) {
        //   var status = params.data.status;
        //   return status === "0";
        // },
        'status-delay': function (params) {
          var status = params.data.status;
          return status === '1';
        },
        'status-critical-delay': function (params) {
          var status = params.data.status;
          return status === '2';
        },
      },

      defaultColDef: { editable: false, sortable: true, resizable: true },
      context: { componentParent: this },
      frameworkComponents: {
        confirmBtnRenderer: ConfirmBtnRenderer,
        dateTimeFieldRenderer: DateTimeFieldRenderer,
        planETAFieldRenderer: PlanETAFieldRenderer,
        probActionFieldRenderer: ProbActionFieldRenderer,
        manageBtnRenderer: ManageBtnRenderer,
        routeRenderer: RouteRenderer,
        trackingRenderer: TrackingRenderer,
        iconRouteRenderer: IconRouteRenderer,
      },
      headerHeight: 60,
      rowData: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    //console.log('#componentWillReceiveProps textSearchFrame',nextProps.textSearchFrame);
    if (nextProps.textSearchFrame != this.props.textSearchFrame) {
      if (this.gridApi) {
        this.gridApi.deselectAll();
        this.gridApi.clearFocusedCell();
      }
    }
    //getDataOperation({});
    // if(this.props.blinkColor != nextProps.blinkColor){

    //   this.renderColumns(nextProps);
    // }
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    setSidebarVisibility(false);
    //this.autoSizeAll();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.dashboard_frame != this.props.dashboard_frame) {
      this.restoreSelect(prevProps);
    }
    //this.autoSizeAll();
  }
  componentWillUnmount() {}
  rowData() {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function (column, index) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  autosizeHeaders(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        '#jobDataGrid .ag-header-cell-label'
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }

  methodFromParent(cell) {
    alert('Parent Component Method from ' + cell + '!');
  }
  renderData = () => {
    let self = this;
    if (Array.isArray(this.props.dashboard_frame)) {
      //console.log('#renderData textSearchFrame',this.props.textSearchFrame);
      //console.log('#renderData dashboard_frame',this.props.dashboard_frame);
      if (this.props.textSearchFrame === '') {
        return this.props.dashboard_frame;
      } else {
        let result = [];
        let findText = this.props.textSearchFrame.toUpperCase();

        this.props.dashboard_frame.forEach((point, i) => {
          let planArrivalTime = point.planArrivalTime.value;
          let ETA = point.ETA.value;
          if (point.planArrivalTime.value && point.isYard.value === true) {
            planArrivalTime = planArrivalTime + '*';
          }
          if (point.ETA.value && point.isYard.value === true) {
            ETA = ETA + '*';
          }
          if (
            point.routeDate.value.toUpperCase().indexOf(findText) > -1 ||
            point.shiftSeq.value.toUpperCase().indexOf(findText) > -1 ||
            point.routeCode.value.toUpperCase().indexOf(findText) > -1 ||
            point.runSeq.value.toUpperCase().indexOf(findText) > -1 ||
            point.truckLicense.value.toUpperCase().indexOf(findText) > -1 ||
            planArrivalTime.toUpperCase().indexOf(findText) > -1 ||
            ETA.toUpperCase().indexOf(findText) > -1 ||
            point.status.value.toUpperCase().indexOf(findText) > -1
          ) {
            result.push(point);
          }
          //Check SubFrame
          if (point.parts.length > 0) {
            point.parts.forEach((subpoint, i) => {
              if (
                (subpoint.partUsage.toUpperCase().indexOf(findText) > -1 ||
                  subpoint.partInfo.toUpperCase().indexOf(findText) > -1) &&
                !self.containsObject(point, result)
              )
                result.push(point);
            });
          }
        });
        return result;
      }
    } else return [];
  };
  containsObject = (obj, list) => {
    var i;
    for (i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  };
  handleSelectionCell = (node) => {
    try {
      var selectedRCell = node.api.getCellSelection();

      //this.props.SELECT_MARKER(selectedRows[0].id);
    } catch (e) {
      //this.props.MAP_LOG({ error : e, detail : "Location.js - handleSelectionChanged"})
    }
  };
  handleSelectionChanged = (node) => {
    try {
      var selectedRows = node.api.getSelectedRows();
      var selectedCell = node.api.getFocusedCell();
      console.log('#handleSelectionChanged selectedRows', selectedRows[0]);
      if (
        selectedRows[0].jobDashboardId.value +
          selectedRows[0].shiftSeq.value ===
          this.props.dashboard_selected &&
        !(
          selectedCell.column.colId === 'problem.value' ||
          selectedCell.column.colId === 'action.value' ||
          selectedCell.column.colId === 'partUsageTime.value' ||
          selectedCell.column.colId === 'ETA.value' ||
          selectedCell.column.colId === 'params' ||
          selectedCell.column.colId === '0'
        )
      ) {
        let gridApi = this.gridApi;
        // this.setState({ rowSelecting : false })
        this.props.dashboardSelected(null);
        this.props.dashboardSubFrame([]);
        gridApi.deselectAll();
        selectedCell.clearFocusedCell();
      } else {
        //console.log('#handleSelectionChanged parts',selectedRows[0].parts);
        // this.setState({ rowSelecting : true })
        this.props.dashboardSelected(
          selectedRows[0].jobDashboardId.value + selectedRows[0].shiftSeq.value
        );
        this.props.dashboardSubFrame(selectedRows[0].parts);
      }
    } catch (e) {
      // this.props.MAP_LOG({ error : e, detail : "Location.js - handleSelectionChanged"})
    }
  };
  restoreSelect = (prevProps) => {
    let gridApi = this.gridApi;
    let self = this;
    let isSelected = false;
    self.props.dashboardSubFrame([]);
    if (gridApi) {
      gridApi.deselectAll();
      gridApi.clearFocusedCell();
      // console.log(
      //   '#restoreSelect dashboard_selected',
      //   prevProps.dashboard_selected
      // );
      this.gridApi.forEachNode(function (node) {
        if (
          node.data.jobDashboardId.value + node.data.shiftSeq.value ===
          prevProps.dashboard_selected
        ) {
          //console.log('#restoreSelect node.data',node.data);
          node.setSelected(true, true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' });
          self.props.dashboardSubFrame(node.data.parts);
          isSelected = true;
          return true;
        }
      });
      //console.log('#restoreSelect isSelected', isSelected);
      if (!isSelected) self.props.dashboardSubFrame([]);
    }
  };
  render() {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <div
          id='jobDataGrid'
          style={{
            boxSizing: 'border-box',
            height: '98%',
            width: '100%',
          }}
          className='ag-theme-balham'
        >
          <AgGridReact
            reactNext={true}
            reduxStore={store}
            pagination={true}
            paginationAutoPageSize={true}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderData()}
            rowSelection='single'
            onGridReady={this.autosizeHeaders.bind(this)}
            onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
          />

          <DateTimeDialog title={this.state.title} />
          <ProblemDialog title={this.state.title} />
          <ActionDialog title={this.state.title} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dashboard_selected: state.dashboard.dashboard_selected,
  dashboard_frame: state.dashboard.dashboard_frame,
  dashboard_subframe: state.dashboard.dashboard_subframe,
  search_frame: state.search.search_frame,
  textSearchFrame: state.search.search_frame_txt,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    dashboardSelected: (payload) => dispatch(dashboardSelected(payload)),
    dashboardSubFrame: (payload) => dispatch(dashboardSubFrame(payload)),
    dispatch,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(FrameGrid);
