import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import SnackbarMe from './Components/Ui/SnackbarMe';
import UserAuthen from './Components/User/UserAuthen';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

import moment from 'moment';

import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import MenuListItem from './Layout/MenuListItem';
import ContentRoutes from './routes';
import List from '@material-ui/core/List';
import { Redirect } from 'react-router-dom';
//Component
import NewPasswordDialog from './Components/Ui/NewPasswordDialog';
import Alert from './Components/Ui/AlertDialogSlide';
//Api
import { getAuthResponse } from './reducers/auth';
import { SET_SNACKBAR } from './reducers/ui';
import { setActiveMenuListItem, uiSwitchForm } from './reducers/ui';
import { selectReset } from './reducers/ui/selectList';
import { searchReset, searchOperation } from './reducers/search';
import menuRoutes from 'routes/menuRoutes';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: 'calc(100vh - 0px)',
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minWidth: 0,

    [theme.breakpoints.up('xs')]: {
      marginTop: '2em',
    },
  },
  overlay: {
    width: '100%',
    height: '100%',
    display: 'contents',
    backgroundColor: '#55555555',
    zIndex: 1000000000000,
  },
  toolbar: theme.mixins.toolbar,
  center: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px',
  },
});

class App extends Component {
  constructor() {
    super();

    const routes = menuRoutes();
    this.state = {
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: 'Success',
      snackbarDuration: 5000,
      newlogin: null,
      response: false,
      isLogout: false,
      current_id: null,
      ...routes,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setHeader(nextProps);
    }
  }
  setHeader(nextProps) {
    let menuName = '';
    for (let item of this.state.dashboardListItems.nestedItems) {
      if (nextProps.location.pathname === item.itemLink)
        menuName = item.itemName;
    }
    for (let item of this.state.maintenanceListItems.nestedItems) {
      if (nextProps.location.pathname === item.itemLink)
        menuName = item.itemName;
    }
    for (let item of this.state.configurationListItems.nestedItems) {
      if (nextProps.location.pathname === item.itemLink)
        menuName = item.itemName;
    }
    this.props.selectReset(true);
    this.props.searchReset(true);
    this.props.uiSwitchForm({ page: 'list' });
    this.props.dispatch(
      this.props.setActiveMenuListItem({
        ...this.props.activeMenuListItem,
        menuName: menuName,
      })
    );
  }

  componentDidMount() {
    this.setState({ current_id: parseInt(localStorage.getItem('id')) });

    this.handleNewLogin();

    this.props.setActiveMenuStart({
      ...this.props.activeMenuListItem,
      subLink: this.props.location.pathname,
    });
    this.setHeader(this.props);
  }

  handleNewLogin = () => {
    this.props.SET_SNACKBAR({
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: 'test',
      snackbarDuration: 1,
    });
    this.props.searchOperation({
      ...this.props.search_operation,
      routeType: 'all',
    });
  };

  render() {
    let user_authen = JSON.parse(localStorage.getItem('user_authen'));

    if (user_authen) {
      let stamp_datetime = moment(
        user_authen.stamp_datetime,
        'YYYY/MM/DD HH:mm:ss'
      );
      var diff = moment.duration(moment().diff(stamp_datetime));
      var d = moment.duration(diff);
      var time_diffs = parseInt(d.format('ss', { useGrouping: false }));

      if (time_diffs > 60 || time_diffs < 1) {
        window.localStorage.clear();
      }
    } else {
      window.localStorage.clear();
    }

    const { classes, auth, dispatch } = this.props;

    let authResult = localStorage.getItem('authResult');
    if (!auth) {
      return <CircularProgress className={classes.center} size={'100px'} />;
    } else if (auth && !authResult) {
      return <Redirect to='/login' />;
    } else {
      return (
        <div className={classes.root}>
          <Header />
          <Sidebar>
            <List>
              <MenuListItem
                name='Dashboard'
                listItems={this.state.dashboardListItems}
              />
              <MenuListItem
                name='Maintenance'
                listItems={this.state.maintenanceListItems}
              />
              <MenuListItem
                name='Configuration'
                listItems={this.state.configurationListItems}
              />
            </List>
          </Sidebar>
          <main className={classes.content}>
            {
              <LoadingOverlay
                active={this.props.loadingDialog.active}
                className={classes.overlay}
                spinner={<BounceLoader />}
                text={this.props.loadingDialog.msg}
              ></LoadingOverlay>
            }
            <Alert />
            <NewPasswordDialog title='CHANGE PASSWORD' />
            <ContentRoutes dispatch={dispatch} />
          </main>

          <SnackbarMe />
          <UserAuthen />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  activeMenuListItem: state.ui.activeMenuListItem,
  loadingDialog: state.ui.loadingDialog,
  auth: getAuthResponse(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectReset: (payload) => dispatch(selectReset(payload)),
    searchReset: (payload) => dispatch(searchReset(payload)),
    uiSwitchForm: (payload) => dispatch(uiSwitchForm(payload)),
    setActiveMenuStart: (location) => dispatch(setActiveMenuListItem(location)),
    SET_SNACKBAR: (payload) => dispatch(SET_SNACKBAR(payload)),
    setActiveMenuListItem: (payload) =>
      dispatch(setActiveMenuListItem(payload)),
    searchOperation: (payload) => dispatch(searchOperation(payload)),
    dispatch,
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
);

export default enhance(App);
