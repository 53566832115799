import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import classNames from 'classnames';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { reduxForm, Form, Field } from 'redux-form';
//Lib
//Component
import AutoSuggestInput from '../Ui/AutoSuggestInput';
import LogisticRoutes from './LogisticRoutes';
//Reducer
import { formLogisticRoute } from '../../reducers/ui/mapForm';
import { connect } from 'react-redux';
import { uiSwitchForm } from '../../reducers/ui';
import {
  mapDrawingMode,
  mapResetMap,
  MAP_RESET_MASTER_CANDIDATE,
  SET_ROUTESVIEW,
  MAP_SELECT_CANDIDATE,
  mapSetLogisticRoutes,
} from '../../reducers/map';
import { MAP_GET_LOGISTIC_ROUTES } from '../../reducers/map/api';
import { searchLogisticsRoute } from '../../reducers/search';
//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  checked: {},
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioValue: 1,
      activeAddLogistic: true,
      mode: 0,
      name: '',
      origin: {},
      destination: {},
    };
  }
  componentDidMount() {
    this.props.SET_ROUTESVIEW([]);
    this.props.MAP_SELECT_CANDIDATE(-1);
    this.props.MAP_RESET_MASTER_CANDIDATE();
    this.props.mapSetLogisticRoutes([]);
  }
  handleRadioChange = (event) => {
    this.props.reset();
    this.props.ROUTE_FORM({});
    this.props.mapSetLogisticRoutes([]);

    this.setState({
      name: '',
      origin: { id: 0, text: 'Select data' },
      destination: { id: 0, text: 'Select data' },
    });
    this.props.SEARCH_ROUTE({ mode: parseInt(event.target.value) });
    this.setState({
      activeAddLogistic: true,
      radioValue: parseInt(event.target.value),
    });
  };
  handleSearchChange = (event) => {
    this.props.SEARCH_ROUTE({
      ...this.props.search_logistics_route,
      name: event.target.value,
    });
  };
  handleSearch = (form) => {
    this.props.SET_ROUTESVIEW([]);
    this.props.MAP_SELECT_CANDIDATE(-1);
    this.props.MAP_RESET_MASTER_CANDIDATE();

    //By Origin Dest
    if (this.props.search_logistics_route.mode === 3) {
      if (form.originSuggest || form.destSuggest) {
        let origin = form.originSuggest ? JSON.parse(form.originSuggest) : {};
        let destination = form.destSuggest ? JSON.parse(form.destSuggest) : {};
        this.setState({
          origin: origin,
          destination: destination,
        });
      }
    }
    let filter = { ...this.props.search_logistics_route };
    delete filter.originName;
    delete filter.destinationName;
    if (!this.props.search_logistics_route.mode) filter.mode = 1;
    this.props.GET_LOGISTIC_ROUTES({
      url: Configs[env].BACKEND_HOST + '/logisticsRouteCond',
      filter: filter,
      callback: this.setLogisticAction,
    });
  };
  setLogisticAction = (data) => {
    this.setState(this.props.search_logistics_route);
    if (
      this.props.search_logistics_route.originId &&
      this.props.search_logistics_route.destinationId &&
      data.list.length === 0
    ) {
      this.setState({ activeAddLogistic: false });
    } else {
      this.setState({ activeAddLogistic: true });
    }
  };
  handleManageLocation = () => {
    this.props.RESET_MAP(true);
    this.props.ROUTE_FORM({
      //logisticsRouteId : 1,
      name: '', //this.state.name,
      originId: this.state.origin.id,
      destinationId: this.state.destination.id,
      originName: this.state.origin.name,
      destinationName: this.state.destination.name,
      originLat: this.state.origin.latitude,
      originLng: this.state.origin.longitude,
      destinationLat: this.state.destination.latitude,
      destinationLng: this.state.destination.longitude,
    });
    this.props.SWITCH_FORM({ page: 'manage', mode: 'add' });
  };
  render() {
    const { handleSubmit } = this.props;
    let privilege = JSON.parse(localStorage.getItem('privilege'));
    let controllable =
      privilege && privilege.hasOwnProperty('routeMaster')
        ? privilege.routeMaster.controllable
        : false;
    let toDisable = !controllable;

    return (
      <div style={{ padding: '10px' }}>
        <DialogActions>
          <Button
            disabled={this.state.activeAddLogistic || toDisable}
            variant='contained'
            size='small'
            color='secondary'
            aria-label='Add'
            className={classNames(styles.button, styles.cssGreen)}
            onClick={this.handleManageLocation.bind(this)}
          >
            NEW LOGISTICS ROUTE
          </Button>
        </DialogActions>
        <Card className={styles.card}>
          <CardContent>
            <Form
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.handleSearch.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Radio
                        value={1}
                        onChange={this.handleRadioChange}
                        checked={
                          !this.props.search_logistics_route.mode ||
                          this.props.search_logistics_route.mode === 1
                            ? true
                            : false
                        }
                        classes={{
                          root: styles.root,
                          checked: styles.checked,
                        }}
                      />
                    }
                    label='NO MASTER ROUTE'
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Radio
                        value={2}
                        onChange={this.handleRadioChange}
                        checked={
                          this.props.search_logistics_route.mode === 2
                            ? true
                            : false
                        }
                        classes={{
                          root: styles.root,
                          checked: styles.checked,
                        }}
                      />
                    }
                    label='NAME'
                  />
                  {this.props.search_logistics_route.mode === 2 && (
                    <Field
                      component={renderInput}
                      name='name'
                      value={this.props.search_logistics_route.name}
                      className={styles.textField}
                      onChange={this.handleSearchChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin='dense'
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Radio
                        value={3}
                        onChange={this.handleRadioChange}
                        checked={
                          this.props.search_logistics_route.mode === 3
                            ? true
                            : false
                        }
                        classes={{
                          root: styles.root,
                          checked: styles.checked,
                        }}
                      />
                    }
                    label='ORIGIN / DESTINATION'
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {this.props.search_logistics_route.mode === 3 && (
                        <AutoSuggestInput
                          value={this.props.search_logistics_route.originId}
                          suggestions={[this.state.origin]}
                          label='Origin'
                          ref='originSuggest'
                          name='originSuggest'
                          handleChange={(value) => {
                            this.props.mapSetLogisticRoutes([]);
                            this.setState({ activeAddLogistic: true });

                            let originData = JSON.parse(value);
                            this.props.change('originSuggest', value);

                            this.props.SEARCH_ROUTE({
                              ...this.props.search_logistics_route,
                              originId: originData.id,
                              originName: originData.name,
                            });
                          }}
                          handleSuggestionsFetchRequested={() => {
                            this.setState({ activeAddLogistic: true });
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {this.props.search_logistics_route.mode === 3 && (
                        <AutoSuggestInput
                          value={
                            this.props.search_logistics_route.destinationId
                          }
                          suggestions={[this.state.destination]}
                          label='Destination'
                          ref='destSuggest'
                          name='destSuggest'
                          handleChange={(value) => {
                            this.props.mapSetLogisticRoutes([]);

                            this.setState({ activeAddLogistic: true });

                            let destinationData = JSON.parse(value);

                            this.props.change('destSuggest', value);
                            this.props.SEARCH_ROUTE({
                              ...this.props.search_logistics_route,
                              destinationId: destinationData.id,
                              destinationName: destinationData.name,
                            });
                          }}
                          handleSuggestionsFetchRequested={() => {
                            this.setState({ activeAddLogistic: true });
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <DialogActions>
                      <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        aria-label='Save'
                        className={styles.button}
                        type='submit'
                      >
                        SEARCH
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <LogisticRoutes />
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    formManage: state.ui.formManage,
    search_logistics_route: state.search.search_logistics_route,
  };
};
const mapActionsToProps = {
  SWITCH_FORM: uiSwitchForm,
  DRAWING_MODE: mapDrawingMode,
  RESET_MAP: mapResetMap,
  ROUTE_FORM: formLogisticRoute,
  GET_LOGISTIC_ROUTES: MAP_GET_LOGISTIC_ROUTES,
  SEARCH_ROUTE: searchLogisticsRoute,
  MAP_RESET_MASTER_CANDIDATE: MAP_RESET_MASTER_CANDIDATE,
  SET_ROUTESVIEW: SET_ROUTESVIEW,
  MAP_SELECT_CANDIDATE: MAP_SELECT_CANDIDATE,
  mapSetLogisticRoutes: mapSetLogisticRoutes,
};
const enhance = compose(
  reduxForm({
    form: 'searchRouteMasterForm',
    initialValues: {
      category: '0',
    },
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(SearchForm);
