import { select, takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';

import {
  SET_SNACKBAR,
  SET_LOADING,
  STATUS_LOADING,
  uiSwitchForm,
} from '../reducers/ui';

import Configs from '../config/config';
const env = process.env.NODE_ENV;
//import axios from 'axios';
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  //yield call(delay, 5000)
  yield takeLatest('API_CALL_REQUEST', workerSaga);
}

// function that makes the api request and returns a Promise for response
function fetchForm(params) {
  //debugger
  //onsole.log('formSaga #fetchForm params',params);
  let url = params.payload.url;
  let form = params.payload.form;
  let listData = params.payload.listData;
  let objData = params.payload.objData;

  let inputParams = {
    list: listData ? listData : [{ ...form }],
  };
  if (objData) inputParams = objData;
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem('token'),
  };
  // console.log('#fetchForm headers',headers);
  //   console.log('#fetchForm inputParams',inputParams);
  let callback = params.payload.callback;
  return new Promise((resolve, reject) => {
    // const data = {
    //   Name: name,
    //   ID: id,
    // }
    axios({
      method: params.payload.method ? params.payload.method : 'POST',
      url: url,
      data: JSON.stringify(inputParams),
      headers: headers,
      timeout: 60000,
    })
      .then(function (resp) {
        resolve(resp);
      })
      .catch(function (error) {
        resolve({ catchError: error });
      });
  });
}

function* workerSaga(action) {
  try {
    const state = yield select();
    //   yield put({ type: SET_MAP_LOADING, payload: true });
    // console.log('#workerSaga location_master',window.location.href.search("/location_master"))
    // console.log('#workerSaga formManage',state.ui.formManage)
    //if(window.location.href.search("/management_dash") < 0
    //&& window.location.href.search("/dashboard/operation_dash") < 0 ){
    //yield put({ type: SET_LOADING, payload: {active : true,msg : 'Please Wait...'}});
    //}
    yield put({ type: STATUS_LOADING, payload: true });
    const resp = yield call(fetchForm, action);

    //   yield put({ type: SET_MAP_LOADING, payload: false });
    const response = yield checkError(resp, action);
    yield put({ type: 'API_CALL_SUCCESS', result: response.data });

    if (response !== false) {
      if (
        window.location.href.search('/route_master') > 0 &&
        (state.ui.formManage.page === 'manage' ||
          state.ui.formManage.page === 'master_route' ||
          state.ui.formManage.page === 'warning_type')
      ) {
      } else if (
        window.location.href.search('/hazard_point') > 0 &&
        state.ui.formManage.page === 'warning_type'
      ) {
      } else if (state.setting.newpass_dialog) {
      } else {
        yield put({ type: 'UI_FORM_MANAGE_RESET' });
      }
    }
    //   yield put({ type: SET_MAP_LOADING, payload: false });
  } catch (error) {
    // yield put({ type: SET_MAP_LOADING, payload: false });
    yield put({ type: 'UI_FORM_MANAGE_RESET' });
    yield put({ type: 'API_CALL_SUCCESS', result: error.toString() });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
    yield put({ type: STATUS_LOADING, payload: false });
    //if(window.location.href.search("/management_dash") < 0 && window.location.href.search("/dashboard/operation_dash") < 0){
    //yield put({ type: SET_LOADING, payload: {active : false}});
    //}
  }
}

//===============================
function* checkError(response, action) {
  let params = action;
  let callback = params.payload.callback;
  let callback_error = params.payload.callback_error;

  if (response.hasOwnProperty('catchError')) {
    if (callback_error) callback_error(response.catchError.toString());

    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: response.catchError.toString(),
        snackbarDuration: 20000,
      },
    });
    //if(window.location.href.search("/management_dash") < 0 && window.location.href.search("/dashboard/operation_dash") < 0){
    //yield put({ type: SET_LOADING, payload: {active : false}});
    //}
    yield put({ type: STATUS_LOADING, payload: false });
    return false;
  }

  if (parseInt(response.status) < 200 || parseInt(response.status) > 200) {
    if (callback_error)
      callback_error('HTTP ERROR : status code = ' + response.status);

    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'HTTP ERROR : status code = ' + response.status,
        snackbarDuration: 20000,
      },
    });
    //if(window.location.href.search("/management_dash") < 0 && window.location.href.search("/dashboard/operation_dash") < 0){
    //yield put({ type: SET_LOADING, payload: {active : false}});
    //}
    yield put({ type: STATUS_LOADING, payload: false });
    return false;
  }
  if (response.data.hasOwnProperty('errorList')) {
    if (response.data.errorList.length > 0) {
      let errorShow = '';
      errorShow = response.data.errorList[0].errorMessage;
      if (callback_error) callback_error(response.data.errorList[0]);

      if (
        parseInt(response.data.errorList[0].errorCode) === 1104 ||
        parseInt(response.data.errorList[0].errorCode) === 1105
      ) {
        return false;
      }

      if (parseInt(response.data.errorList[0].errorCode) === 2101) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
          },
        });
        window.location.href = '/dashboard/operation_dash';
        return false;
      }

      // if error is invalid token
      if (
        parseInt(response.data.errorList[0].errorCode) === 2102 ||
        parseInt(response.data.errorList[0].errorCode) === 2103
      ) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
            forceLogout: true,
          },
        });
        if (
          window.location.href.search('/dashboard/management_dash') < 0 &&
          window.location.href.search('/dashboard/operation_dash') < 0
        ) {
          //yield put({ type: SET_LOADING, payload: {active : false}});
        }
        return false;
      }

      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: errorShow,
          snackbarDuration: 20000,
        },
      });
      //if(window.location.href.search("/management_dash") < 0 && window.location.href.search("/dashboard/operation_dash") < 0){
      //yield put({ type: SET_LOADING, payload: {active : false}});
      //}
      yield put({ type: STATUS_LOADING, payload: false });
      return false;
    }
  }

  // if(response.data.hasOwnProperty("list") || response.data.hasOwnProperty("result")){
  if (callback) callback(response.data);

  let isSilent = params.payload.isSilent;

  if (!isSilent) {
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'success',
        snackbarMessage: 'Success',
        snackbarDuration: 2000,
      },
    });
  }
  //if(window.location.href.search("/management_dash") < 0 && window.location.href.search("/dashboard/operation_dash") < 0){
  //yield put({ type: SET_LOADING, payload: {active : false}});
  //}
  yield put({ type: STATUS_LOADING, payload: false });
  return response;
  // }/

  // yield put({
  //     type: SET_SNACKBAR,
  //     payload: {
  //         snackbarOpen: true,
  //         snackbarVariant: "error",
  //         snackbarMessage: "API error",
  //         snackbarDuration: 20000
  //     }
  // });

  // return response
}
