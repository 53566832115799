import { createAsyncThunk } from '@reduxjs/toolkit';
import Configs from 'config/config';
const env = process.env.NODE_ENV;

const getMapScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = false;
    document.body.appendChild(script);
    script.onload = () => resolve();
  });

const getMapStyles = (href) =>
  new Promise((resolve) => {
    const link = document.createElement('link');
    link.href = href;
    link.media = 'all';
    link.type = 'text/css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    link.onload = () => resolve();
  });

export const loadMap = createAsyncThunk('loadMap', async (_, thunkAPI) => {
  try {
    await getMapStyles('https://js.api.here.com/v3/3.1/mapsjs-ui.css');
    await getMapScript('https://js.api.here.com/v3/3.1/mapsjs-core.js');
    await getMapScript('https://js.api.here.com/v3/3.1/mapsjs-service.js');
    await getMapScript('https://js.api.here.com/v3/3.1/mapsjs-ui.js');
    await getMapScript('https://js.api.here.com/v3/3.1/mapsjs-mapevents.js');
    await getMapScript('https://js.api.here.com/v3/3.1/mapsjs-data.js');
    await getMapScript('https://js.api.here.com/v3/3.1/mapsjs-clustering.js');
  } catch (e) {
    thunkAPI.rejectWithValue(e);
  }
});

// https://developer.here.com/documentation/geocoder-autocomplete/dev_guide/topics/quick-start.html
// TODO: Migrate to search API
// https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/endpoint-autocomplete-brief.html
export const geocodeAutocomplete = createAsyncThunk(
  'geocodeAutocomplete',
  async (searchPlace, thunkAPI) => {
    try {
      const params = {
        apiKey: Configs[env].HERE_API_KEY,
        query: searchPlace,
        beginHighlight: '<mark>',
        endHighlight: '</mark>',
      };
      const url =
        'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';
      const searchParams = new URLSearchParams(params);
      const reqUrl = `${url}?${searchParams.toString()}`;

      const response = await fetch(reqUrl);
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const geocodeAutosuggest = createAsyncThunk(
  'geocodeAutosuggest',
  async (searchPlace, thunkAPI) => {
    try {
      const params = {
        apiKey: Configs[env].HERE_API_KEY,
        q: searchPlace,
        at: `${Configs[env].MAP_CENTER_DEFAULT.lat},${Configs[env].MAP_CENTER_DEFAULT.lng}`,
        //in: 'countryCode:MAL',
      };
      const url = 'https://autosuggest.search.hereapi.com/v1/autosuggest';
      const searchParams = new URLSearchParams(params);
      const reqUrl = `${url}?${searchParams.toString()}`;

      const response = await fetch(reqUrl);
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);
//https://developer.here.com/documentation/routing-api/api-reference-swagger.html
//https://developer.here.com/documentation/maps/3.1.35.0/dev_guide/topics/routing.html
export const hmapRouting = createAsyncThunk(
  'hmapRouting',
  async (
    {
      id,
      transportMode,
      routingMode,
      returnItems,
      origin,
      destination,
      waypoints,
      alternatives,
    },
    thunkAPI
  ) => {
    try {
      const params = {
        apiKey: Configs[env].HERE_API_KEY,
        transportMode: transportMode || 'car',
        routingMode: routingMode || 'fast',
        return: returnItems || 'polyline,summary,tolls',
        origin,
        destination,
        alternatives: alternatives || 1,
      };
      let waypointList = waypoints
        ? waypoints
            .map((w) => {
              return `via=${w[0]},${w[1]}!passThrough=true`;
            })
            .join('&')
        : '';
      const url = 'https://router.hereapi.com/v8/routes';
      const searchParams = new URLSearchParams(params);
      let reqUrl = `${url}?${searchParams.toString()}`;

      if (waypointList) {
        reqUrl = reqUrl + `&${waypointList}`;
      }

      const response = await fetch(reqUrl);
      let data = await response.json();

      if (response.status === 200) {
        data['id'] = id;
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      // console.log(e);
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const hmapCandidateRoutes = createAsyncThunk(
  'hmapCandidateRoutes',
  async (
    { transportMode, routingMode, returnItems, origin, destination },
    thunkAPI
  ) => {
    try {
      const params = {
        apiKey: Configs[env].HERE_API_KEY,
        transportMode: transportMode || 'car',
        routingMode: routingMode || 'fast',
        return: returnItems || 'polyline,summary,tolls',
        origin,
        destination,
      };

      const url = 'https://router.hereapi.com/v8/routes';
      const searchParams = new URLSearchParams(params);
      let reqUrl = `${url}?${searchParams.toString()}`;

      const response = await fetch(reqUrl);
      let data = await response.json();

      if (response.status === 200) {
        const paramsWithAvoid = {
          ...params,
          'avoid[features]': ['tollRoad', 'controlledAccessHighway'],
        };

        const url = 'https://router.hereapi.com/v8/routes';
        const searchParams = new URLSearchParams(paramsWithAvoid);
        let reqUrl = `${url}?${searchParams.toString()}`;

        const avoidResp = await fetch(reqUrl);
        let avoidData = await avoidResp.json();
        if (response.status === 200) {
          let combinedData = { routes: [] };
          if (data && data.routes) {
            for (let r of data.routes) {
              if (r.sections.length > 0) {
                if (r.sections[0].tolls && r.sections[0].tolls.length > 0) {
                  combinedData.routes.push(r);
                }
              }
            }
          }
          if (avoidData && avoidData.routes) {
            for (let r of avoidData.routes) {
              if (r.sections.length > 0) {
                combinedData.routes.push(r);
              }
            }
          }
          return combinedData;
        } else {
          return thunkAPI.rejectWithValue(avoidData);
        }
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      // console.log(e);
      thunkAPI.rejectWithValue(e);
    }
  }
);
