import React, { memo, useEffect, useCallback, useRef } from 'react';
import DeleteButton from './DeleteButton';
import initMapObjectEvents from '../libs/initMapObjectEvents';

function DrawMarker(props) {
  const {
    map,
    options,
    objectEvents,
    interaction,
    onCallback,
    disposeFunctions,
    draggable,
    builderOptions,
    marker,
    isDraw,
    ui,
  } = props;

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  if (!window.H || !window.H.map || !map) {
    throw new Error('HMap has to be initialized before adding Map Objects');
  }

  let _marker = useRef();

  const dispatchCallback = useCallback(
    (data) => {
      if (onCallback) {
        onCallback(data);
      }
    },
    [onCallback]
  );

  const mapDragStartHandler = useCallback(
    (ev) => {
      if (!map) return;

      var target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof window.H.map.Marker) {
        var targetPosition = map.geoToScreen(target.getGeometry());
        target['offset'] = new window.H.math.Point(
          pointer.viewportX - targetPosition.x,
          pointer.viewportY - targetPosition.y
        );
        interaction.disable();
      }
    },
    [map, interaction]
  );

  const mapDragEndHandler = useCallback(
    (ev) => {
      var target = ev.target;
      if (target instanceof window.H.map.Marker) {
        interaction.enable();
        dispatchCallback(_marker.current.getGeometry());
      }
    },
    [interaction, dispatchCallback]
  );

  const mapDragHandler = useCallback(
    (ev) => {
      if (!map) return;

      var target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof window.H.map.Marker) {
        target.setGeometry(
          map.screenToGeo(
            pointer.viewportX - target['offset'].x,
            pointer.viewportY - target['offset'].y
          )
        );
      }
    },
    [map]
  );

  const enableDrag = useCallback(() => {
    _marker.current.draggable = true;
    map.addEventListener('dragstart', mapDragStartHandler, false);
    map.addEventListener('dragend', mapDragEndHandler, false);
    map.addEventListener('drag', mapDragHandler, false);
  }, [map, mapDragStartHandler, mapDragEndHandler, mapDragHandler]);

  const createMarker = useCallback(
    (geoPoint, isDispatch = true) => {
      if (!map) return;

      if (!_marker.current) {
        let _options = options;
        if (!_options) _options = {};
        if (_options.icon) {
          let markerOptions = {};

          if (_options.anchor?.x && _options.anchor?.y) {
            markerOptions['anchor'] = new window.H.math.Point(
              _options.anchor?.x || 0,
              _options.anchor?.y || 0
            );
          }
          if (_options.size?.w && _options.size?.h) {
            markerOptions['size'] = { w: _options.size.w, h: _options.size.h };
          }
          if (_options.zIndex) {
            markerOptions['zIndex'] = _options.zIndex;
          }
          _options.icon = new window.H.map.Icon(_options.icon, markerOptions);
        }
        _marker.current = new window.H.map.Marker(geoPoint, {
          ..._options,
          volatility: true,
        });

        map.addObject(_marker.current);
        if (objectEvents) {
          initMapObjectEvents(_marker.current, objectEvents, builderOptions);
        }

        if (_options.info) {
          if (_options.isInfoOpen) {
            var xy = map.geoToScreen(geoPoint);
            let markerInfo = new window.H.ui.InfoBubble(
              map.screenToGeo(xy.x, xy.y - 30),
              {
                content: _options.info,
              }
            );
            markerInfo.setData({ isInfoOpen: _options.isInfoOpen });

            ui.getBubbles().forEach((b) => {
              if (!b.isInfoOpen) {
                ui.removeBubble(b);
              }
            });
            ui.addBubble(markerInfo);
          }

          _marker.current.addEventListener(
            'tap',
            function (evt) {
              var xy = map.geoToScreen(evt.target.getGeometry());
              var bubble = new window.H.ui.InfoBubble(
                map.screenToGeo(xy.x, xy.y - 30),
                {
                  content: _options.info,
                }
              );
              ui.getBubbles().forEach((b) => {
                ui.removeBubble(b);
              });
              ui.addBubble(bubble);
            },
            false
          );
        }

        if (draggable && isDraw) {
          enableDrag();
        }

        if (isDispatch) {
          setTimeout(() => {
            if (!_marker.current) return;
            dispatchCallback(_marker.current.getGeometry());
          }, 0);
        }
      }
    },
    [
      map,
      options,
      objectEvents,
      draggable,
      isDraw,
      builderOptions,
      ui,
      enableDrag,
      dispatchCallback,
    ]
  );

  const mapPointerDownHandler = useCallback(
    (e) => {
      if (!map) return;
      const pointer = e.currentPointer;
      let geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
      createMarker(geoPoint);
    },
    [map, createMarker]
  );

  const subscribeToMapEvents = useCallback(() => {
    if (!map) return;
    map.addEventListener('pointerdown', mapPointerDownHandler, false);
  }, [map, mapPointerDownHandler]);

  const unsubscribeToMapEvents = useCallback(() => {
    if (!map) return;
    map.removeEventListener('pointerdown', mapPointerDownHandler, false);
    map.removeEventListener('dragstart', mapDragStartHandler, false);
    map.removeEventListener('dragend', mapDragEndHandler, false);
    map.removeEventListener('drag', mapDragHandler, false);
  }, [
    map,
    mapPointerDownHandler,
    mapDragStartHandler,
    mapDragEndHandler,
    mapDragHandler,
  ]);

  const mapViewChangeHandler = useCallback(() => {
    forceUpdate();
  }, [forceUpdate]);

  useEffect(() => {
    if (marker && marker?.lat && marker?.lng) {
      createMarker({ lat: marker.lat, lng: marker.lng }, false);
    }
    if (isDraw) {
      subscribeToMapEvents();
    }

    if (map) {
      map.addEventListener('mapviewchange', mapViewChangeHandler, false);
    }

    const dispose = () => {
      unsubscribeToMapEvents();

      if (map) {
        map.removeEventListener('mapviewchange', mapViewChangeHandler, false);
      }

      _marker.current = null;
    };
    disposeFunctions.current.push(dispose);
  }, [
    mapViewChangeHandler,
    disposeFunctions,
    subscribeToMapEvents,
    unsubscribeToMapEvents,
    createMarker,
    marker,
    isDraw,
    map,
  ]);

  const clear = () => {
    _marker.current = null;
    map.removeEventListener('dragstart', mapDragStartHandler, false);
    map.removeEventListener('dragend', mapDragEndHandler, false);
    map.removeEventListener('drag', mapDragHandler, false);

    dispatchCallback({});
  };
  let newProps = { ...props };
  delete newProps.children;

  if (!_marker.current || !isDraw) {
    return (
      <>
        <div style={{ display: 'none' }}></div>
        {props.children && React.cloneElement(props.children, newProps)}
      </>
    );
  } else {
    return (
      <>
        <DeleteButton onDeleteClick={clear}></DeleteButton>
        {props.children && React.cloneElement(props.children, newProps)}
      </>
    );
  }
}

export default DrawMarker;
