import React, { Component } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Hazards from './Hazards';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import compose from 'recompose/compose';
import DialogActions from '@material-ui/core/DialogActions';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//import renderDatePicker from '../Ui/Renderer/DatePickerRenderer';
import { reduxForm, Form, Field } from 'redux-form';
//import AutoSelect from 'react-select';
//Lib
import { required, betweenDate } from '../../libs/validation';
//Component
import CategorySelect from './CategorySelect';
//Reducer
import { resetAPIResult } from '../../reducers/api';
import { formHazardPoint } from '../../reducers/ui/mapForm';
import { connect } from 'react-redux';
import { uiPanelDialogOpen, uiSwitchForm } from '../../reducers/ui';
import {
  mapDrawingMode,
  mapResetMap,
  mapDrawingOptions,
} from '../../reducers/map';
import { getHazardPoints, exportHazardPoints } from '../../reducers/map/api';
import { searchHazardPoint } from '../../reducers/search';
//Config
import Configs from '../../config/config';

import { SET_HAZARD_PHOTO, ADD_HAZARD_VDO } from '../../reducers/map';

const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    format='YYYY/MM/DD'
    {...inputProps}
    {...props}
  />
);
// const renderDatePicker = ({
//   input,
//   placeholder,
//   defaultValue,
//   meta: {touched, error} }) => (
//   <div>
//         <DatePicker {...input}
//         dateFormat="YYYY/MM/DD"
//         selected={input.value ? moment(input.value) : null} />
//         {touched && error && <span>{error}</span>}
//   </div>
// );

class SearchForm extends Component {
  state = {
    category: '0',
    categoryText: '',
    dateForm: moment().format('YYYY-MM-DD'),
    dateTo: '9999-12-31',
    name: 'hai',
    mode: '',
    actionsLocationPointGroup: false,
    actionsLocationGroup: false,
  };

  componentDidMount() {
    this.props.CHANGE_MAPMODE(null);
    this.props.RESET_MAP(true);

    var defaultValue = {
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment('9999-12-31').format('YYYY-MM-DD'),
      category: 'all',
    };

    if (this.props.search_hazard_point.dateFrom) {
      defaultValue = this.props.search_hazard_point;

      if (!this.props.search_hazard_point.category)
        defaultValue.category = 'all';
      this.loadHazardPoint();
    }
    this.props.SEARCH_HAZARD({ ...defaultValue });
    this.props.initialize({ ...defaultValue });
  }
  loadHazardPoint = () => {
    //if(this.props.logisticPoint.value && this.props.locationGroup.value){
    this.props.GET_HAZARDS({
      label: 'HAZARD_LIST',
      url: Configs[env].BACKEND_HOST + '/hazardCond',
      filter: {
        ...this.props.search_hazard_point,
        category:
          this.props.search_hazard_point.category !== 'all'
            ? this.props.search_hazard_point.category
            : '',
      },
    });
    //}
  };
  handleChange = (event) => {
    if (event.target.name === 'dateFrom') {
      this.props.SEARCH_HAZARD({
        ...this.props.search_hazard_point,
        dateFrom: event.target.value,
      });
    } else if (event.target.name === 'dateTo') {
      this.props.SEARCH_HAZARD({
        ...this.props.search_hazard_point,
        dateTo: event.target.value,
      });
    }
  };
  handleAdd = (event) => {
    this.props.SET_HAZARD_PHOTO([]);
    this.props.ADD_HAZARD_VDO(null);

    this.props.RESET_MAP(true);
    this.props.SETFORM_HAZARD({
      category_id: this.state.category,
      category: this.state.categoryText,
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: moment('9999-12-31').format('YYYY-MM-DD'),
      timeFrom: moment().format('HH:mm'),
      timeTo: '23:59',
    });

    this.props.SWITCH_FORM({ page: 'manage', mode: 'add' });
  };
  handleSearch = (form) => {
    let filter = {
      category: form.category,
      dateFrom: form.dateFrom,
      dateTo: form.dateTo,
    };
    this.props.SEARCH_HAZARD({ ...filter });
    if (form.category === 'all') delete filter.category;
    this.props.GET_HAZARDS({
      label: 'HAZARD_LIST',
      url: Configs[env].BACKEND_HOST + '/hazardCond',
      filter: filter,
    });
  };
  handleExport = () => {
    let filter = { ...this.props.search_hazard_point };
    if (filter.category === 'all') delete filter.category;
    this.props.exportHazardPoints({
      url: Configs[env].BACKEND_HOST + '/hazardCondReport',
      filter: filter,
    });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    let privilege = JSON.parse(localStorage.getItem('privilege'));
    let controllable =
      privilege && privilege.hasOwnProperty('inputHazard')
        ? privilege.inputHazard.controllable
        : false;
    let toDisable = !controllable;

    return (
      <div style={{ padding: '10px' }}>
        <DialogActions>
          <Button
            variant='contained'
            disabled={toDisable}
            size='small'
            color='primary'
            aria-label='Add'
            className={classNames(styles.button, styles.cssGreen)}
            onClick={this.handleAdd.bind(this)}
          >
            ADD
          </Button>
        </DialogActions>
        <Card className={styles.card}>
          <CardContent>
            <Form
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.handleSearch.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CategorySelect isSearch={true} />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='dateFrom'
                    label='DATE FROM'
                    type='date'
                    component={renderInput}
                    className={styles.textField}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[betweenDate]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='dateTo'
                    label='DATE TO'
                    type='date'
                    component={renderInput}
                    className={styles.textField}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[betweenDate]}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} align='right'>
                    <DialogActions>
                      <Button
                        variant='contained'
                        size='small'
                        color='primary'
                        aria-label='Save'
                        className={styles.button}
                        type='submit'
                      >
                        SEARCH
                      </Button>
                      <Button
                        variant='contained'
                        size='small'
                        color='secondary'
                        aria-label='Export'
                        onClick={this.handleExport}
                        className={styles.button}
                        disabled={!JSON.parse(localStorage.getItem('privilege')).inputHazard.controllable}
                      >
                        DOWNLOAD REPORT
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
        <br />
        <div>
          <Grid container spacing={2}>
            {/* <Grid item xs={6}>
                <Typography variant="h7">HAZARD POINTS</Typography>
              </Grid> */}

            <Grid item xs={12}>
              <Hazards />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    apiFetchResult: state.api.result,
    dialogOpen: state.ui.dialogOpen,
    formManage: state.ui.formManage,
    hazardForm: state.mapForm.hazardForm,
    search_hazard_point: state.search.search_hazard_point,
  };
};
const mapActionsToProps = {
  DRAWING_OPTION: mapDrawingOptions,
  OPEN_DIALOG: uiPanelDialogOpen,
  SWITCH_FORM: uiSwitchForm,
  GET_HAZARDS: getHazardPoints,
  CHANGE_MAPMODE: mapDrawingMode,
  RESET_MAP: mapResetMap,
  SETFORM_HAZARD: formHazardPoint,
  SEARCH_HAZARD: searchHazardPoint,
  resetAPIResult,
  exportHazardPoints,
  SET_HAZARD_PHOTO,
  ADD_HAZARD_VDO,
};
const enhance = compose(
  reduxForm({
    form: 'searchHazardForm',
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(SearchForm);
