import { combineReducers } from 'redux';
import ui from './ui';
import { reducer as formReducer } from 'redux-form';
import literals from './literals';
import api from './api';
import auth from './auth';
import map from './map';
import websocket from './websocket';
import setting from './setting';
import search from './search';
import mapForm from './ui/mapForm';
import selectList from './ui/selectList';
import autoSuggest from './ui/autoSuggest';
import upload from './upload';
import dashboard from './dashboard';

import { connectRouter, routerMiddleware } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { rootApi } from './root/api';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas/rootSaga';
import hmap from './hmap';
import operationDashboard from './operationDashboard';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  router: connectRouter(history),
  ui,
  literals,
  api,
  auth,
  websocket,
  map,
  mapForm,
  setting,
  search,
  form: formReducer,
  selection: selectList,
  autoSuggest,
  upload,
  dashboard,
  // Redux Toolkit
  hmap,
  operationDashboard,
  // RTK Query
  [rootApi.reducerPath]: rootApi.reducer,
});

const store = configureStore({
  reducer: (state, action) => {
    if (
      typeof action.type === 'string' &&
      action.type.includes('AUTH_LOGOUT_REQUEST')
    ) {
      return rootReducer({ router: state.router }, action);
    } else {
      return rootReducer(state, action);
    }
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      routerMiddleware(history),
      rootApi.middleware,
      sagaMiddleware,
    ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// run the rootSaga initially
store.runSagaTask = () => {
  store.sagaTask = sagaMiddleware.run(rootSaga);
};
store.runSagaTask();

export default store;
