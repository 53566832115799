import React, { Component } from 'react';
import moment from 'moment';
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TimeInput from 'material-ui-time-picker';
//import momentLocaliser from 'react-widgets/lib/localizers/moment'
import 'react-widgets/dist/css/react-widgets.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//Component
import Loading from '../../Ui/Loading';
//Lib
import { required, number, email, match } from '../../../libs/validation';
//Reducer
import { apiCallRequest } from '../../../reducers/api';
import { getOperation, getRecovery } from '../../../reducers/dashboard/api';
import { formOperation } from '../../../reducers/ui/mapForm';
import { dashboardOpenTimeDialog } from '../../../reducers/dashboard';
import { SET_SNACKBAR, STATUS_LOADING } from '../../../reducers/ui/index';

//Config
import Configs from '../../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: '200px',
  },
  formControl: {
    //margin: theme.spacing.unit
    marginTop: '8px',
    marginBottom: '4px',
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderSelect = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <Select
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderDatePicker = ({ input: { onChange, value }, label, showTime }) => (
  <DatePicker label={label} />
);


class DateTimeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      recovery_dialog: false,
      recovery_leadtime: 0,
      form_data: null,
      timeSelect: new Date(),
      isDisable: false,
    };
  }

  componentDidMount() {
    this.props.initialize({
      ...this.props.operationForm,
      dateField: moment().format('YYYY-MM-DD'),
      timeField: moment().format('HH:mm'),
    });
    this.setState({ recovery_leadtime: 0 });
  }
  componentWillReceiveProps(nextProps) {
    //this.renderGroupActionItem();
    if (this.props.dashboard_time_dialog != nextProps.dashboard_time_dialog) {
      if (
        nextProps.operationForm &&
        nextProps.operationForm.colDef.field == 'ETA.value' &&
        nextProps.operationForm.partUsageTime.value != '' &&
        nextProps.operationForm.action.value == 'Under Repairing' &&
        (nextProps.operationForm.problem.value == 'Truck Damage' ||
          nextProps.operationForm.problem.value == 'Truck Accident')
      ) {
        this.props.dashboardOpenTimeDialog(false);
        this.handleGetRecovery(nextProps.operationForm.jobDashboardId.value);
        this.setState({ recovery_dialog: true });
      } else {
        this.setState({ timeSelect: new Date() });
        this.props.change('timeField', moment().format('HH:mm'));
        //this.props.initialize({...nextProps.operationForm});
      }
    }
  }
  beforeSave = (form) => {
    /*if(this.props.operationForm.colDef.field=="partUsageTime.value" && 
      (this.props.operationForm.problem.value=="Truck Damage" || 
        this.props.operationForm.problem.value=="Truck Accident")
    ){
      this.props.dashboardOpenTimeDialog(false);
      this.setState({form_data : form,recovery_dialog : true});
    } else {
      this.handleSave(form);
    }*/
    this.handleSave(form);
  };
  handleCancelRecovery = () => {
    //this.handleSave(this.state.form_data);
  };
  handleSave = (form) => {
    this.props.STATUS_LOADING(true);
    this.setState({ isDisable: true });
    this.props.dashboardOpenTimeDialog(false);
    let filter = {};
    filter.jobId = this.props.operationForm.jobDashboardId.value;
    //let ETA = this.props.operationForm.ETA.value;
    //let partUsageTime = this.props.operationForm.partUsageTime.value;
    if (this.props.operationForm.colDef.field == 'ETA.value') {
      filter.ETA = moment(
        form.dateField + ' ' + form.timeField,
        'YYYY-MM-DD HH:mm'
      ).format('YYYY/MM/DD HH:mm');
    }
    if (this.props.operationForm.colDef.field == 'partUsageTime.value') {
      filter.partUsageTime = moment(
        form.dateField + ' ' + form.timeField,
        'YYYY-MM-DD HH:mm'
      ).format('YYYY/MM/DD HH:mm');
    }
    if (
      this.props.operationForm.colDef.field == 'partUsageTime.value' &&
      this.props.operationForm.action.value == '' &&
      (this.props.operationForm.problem.value == 'Truck Damage' ||
        this.props.operationForm.problem.value == 'Truck Accident')
    ) {
      filter.action = 'Under Repairing';
    }
    //if(form.recoveryLeadTime)filter.recoveryLeadTime = form.recoveryLeadTime;
    //filter.problem  = this.props.operationForm.problem.value;
    //filter.action   = this.props.operationForm.action.value;
    filter.version = this.props.operationForm.version + 1;

    if (filter.jobId) {
      this.props.apiCallRequest({
        url: Configs[env].BACKEND_HOST + '/operationDashboard',
        objData: filter,
        method: 'POST',
        callback: this.handleSaveComplete,
        callback_error: this.handleError,
      });
    }
  };
  handleGetRecovery = (jobId) => {
    if (jobId) {
      this.props.getRecovery({
        url:
          Configs[env].BACKEND_HOST +
          '/operationDashboard/recoveryLeadTime/' +
          jobId,
        method: 'GET',
        filter: {},
        callback: this.setRecovery,
      });
    }
  };
  setRecovery = (data) => {
    this.setState({ recovery_leadtime: data.result.recoveryLeadTime });
  };
  handleSaveRecovery = () => {
    this.setState({ isDisable: true });
    let filter = {};
    let jobId = this.props.operationForm.jobDashboardId.value;
    filter.recoveryLeadTime = parseInt(this.state.recovery_leadtime);
    filter.version = this.props.operationForm.version + 2;

    if (
      this.props.dashboard_recovery &&
      this.state.recovery_leadtime <=
        this.props.dashboard_recovery.recoveryLeadTime
    ) {
      this.props.SET_SNACKBAR({
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Please input Recovery Lead Time more than old data',
        snackbarDuration: 10000,
      });
      this.setState({ isDisable: false });
    } else {
      if (jobId) {
        this.props.apiCallRequest({
          url:
            Configs[env].BACKEND_HOST +
            '/operationDashboard/recoveryLeadTime/' +
            jobId,
          objData: filter,
          method: 'POST',
          callback: this.handleCancel,
          callback_error: this.handleErrorRecovery,
        });
      }
    }
  };
  handleError = () => {
    // console.log('#handleError...');
    this.setState({ isDisable: false });
    this.props.dashboardOpenTimeDialog(true);
  };
  handleErrorRecovery = () => {
    // console.log('#handleErrorRecovery...');
    this.setState({ isDisable: false });
    this.setState({ recovery_dialog: true });
  };
  handleSaveComplete = () => {
    if (
      this.props.operationForm.colDef.field == 'partUsageTime.value' &&
      (this.props.operationForm.problem.value == 'Truck Damage' ||
        this.props.operationForm.problem.value == 'Truck Accident') &&
      (this.props.operationForm.action.value == 'Under Repairing' ||
        this.props.operationForm.action.value == '')
    ) {
      this.props.dashboardOpenTimeDialog(false);
      this.setState({ recovery_dialog: true, isDisable: false });
      this.handleGetRecovery(this.props.operationForm.jobDashboardId.value);
      this.props.STATUS_LOADING(false);
    } else {
      this.handleCancel();
    }
  };
  handleChangeRecovery = (e) => {
    this.setState({ recovery_leadtime: e.target.value });
  };
  handleChange = (event) => {
    if (event.target.name == 'problemId') {
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  handleDateChange = (data) => {
    this.props.change('timeField', moment(data).format('HH:mm'));
    this.setState({ timeSelect: data });
  };
  handleCancel = () => {
    let filter = { ...this.props.search_operation };
    filter.isPriority = filter.isPriority ? 1 : 0;
    filter.isHistory = filter.isHistory ? 1 : 0;
    if (filter.etaType === 'all') delete filter.etaType;
    this.props.getOperation({
      url: Configs[env].BACKEND_HOST + '/operationDashboard',
      filter: filter,
    });
    this.setState({
      recovery_dialog: false,
      recovery_leadtime: 0,
      form_data: null,
      isDisable: false,
    });
    this.props.reset();
    this.props.formOperation(null);
    this.props.dashboardOpenTimeDialog(false);
    this.props.STATUS_LOADING(false);
  };
  render() {
    const { handleSubmit, submitting, classes } = this.props;
    return (
      <div style={{ padding: '10px' }}>
        {this.props.isLoading && <Loading />}

        <Dialog
          open={
            this.props.dashboard_time_dialog
              ? this.props.dashboard_time_dialog
              : false
          }
          keepMounted
          onClose={this.handleCancel}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {this.props.operationForm && this.props.operationForm.colDef
              ? this.props.operationForm.colDef.headerName
              : ''}
          </DialogTitle>
          <DialogContent>
            <Form
              ////style={{ overflowY:"scroll"}}
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.beforeSave.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    name='dateField'
                    label='DATE'
                    //defaultValue={moment().format('DD/MM/YYYY')}
                    type='date'
                    component={renderInput}
                    className={styles.textField}
                    //formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name='timeField'
                    //label="TIME"
                    type='hidden'
                    //defaultValue={moment().format('HH:mm')}
                    component={renderInput}
                    className={styles.textField}
                    props={{ format: '24hr' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required]}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink htmlFor='time-field'>
                      TIME
                    </InputLabel>
                    <TimeInput
                      mode='24h'
                      name='timeToSelect'
                      label='Time picker'
                      value={this.state.timeSelect}
                      InputLabelProps={{
                        shrink: true,
                        id: 'time-field',
                      }}
                      margin='dense'
                      // value={selectedDate}
                      onChange={this.handleDateChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <DialogActions>
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      aria-label='Save'
                      disabled={this.state.isDisable}
                      className={styles.button}
                      type='submit'
                    >
                      SAVE
                    </Button>
                    <Button
                      variant='outlined'
                      size='small'
                      color='secondary'
                      aria-label='Close'
                      className={classNames(styles.button, styles.cssGreen)}
                      onClick={this.handleCancel}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.recovery_dialog}
          keepMounted
          onClose={this.handleCancel}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            Recovery Lead Time
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name='recoveryLeadTime'
                  fullWidth
                  type='number'
                  label='Recovery Lead Time'
                  InputLabelProps={{
                    shrink: true,
                    id: 'recovery-field',
                  }}
                  defaultValue={0}
                  value={this.state.recovery_leadtime}
                  helperText='mins'
                  onChange={this.handleChangeRecovery}
                  margin='dense'
                  className={styles.textField}
                ></TextField>
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <DialogActions>
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    aria-label='Save'
                    disabled={this.state.isDisable}
                    className={styles.button}
                    onClick={this.handleSaveRecovery}
                    type='submit'
                  >
                    SAVE
                  </Button>
                  <Button
                    variant='outlined'
                    size='small'
                    color='secondary'
                    aria-label='Close'
                    className={classNames(styles.button, styles.cssGreen)}
                    onClick={this.handleCancel}
                  >
                    Close
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    action_selecting: state.setting.action_selecting,
    dashboard_time_dialog: state.dashboard.dashboard_time_dialog,
    operationForm: state.mapForm.operationForm,
    search_operation: state.search.search_operation,
    dashboard_recovery: state.dashboard.dashboard_recovery,
  };
};
const mapActionsToProps = {
  apiCallRequest,
  dashboardOpenTimeDialog,
  getOperation,
  getRecovery,
  STATUS_LOADING,
  SET_SNACKBAR,
  formOperation,
};

const enhance = compose(
  reduxForm({
    form: 'operationForm',
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(DateTimeDialog);
