import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import ManageBtnRenderer from './Renderer/ManageBtnRenderer';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

//Component
import GroupDialog from './Dialog/GroupDialog';
import GroupBtnRenderer from './Renderer/GroupBtnRenderer';
//Reducer
import { settingGroupSelect, settingOpenGroup } from '../../reducers/setting';
import { getGroupCompanies, getCompanies } from '../../reducers/setting/api';
import { searchGroupCompany } from '../../reducers/search';
import { formGroup } from '../../reducers/ui/mapForm';
import store from 'reducers/rootReducer';
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({});
class GroupCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'ADD GROUP',
      columnDefs: [
        { headerName: 'Group Company', field: 'name' },
        { headerName: 'Last Update', field: 'lastUpdate' },
        {
          cellRenderer: 'groupBtnRenderer',
          width: 80,
        },
      ],
      rowData: null,
      defaultColDef: { sortable: true, resizable: true },
      frameworkComponents: {
        groupBtnRenderer: GroupBtnRenderer,
      },
    };
  }
  componentDidMount() {
    this.getData();
  }
  componentDidUpdate() {
    this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {}
  renderData = () => {
    if (Array.isArray(this.props.setting_groupcompanies)) {
      //this.props.setLogisticPoint(this.props.setting_groupcompanies);
      //filter text in location point
      if (this.props.textSearchGroupCompany === '')
        return this.props.setting_groupcompanies;
      else {
        let result = [];
        let findText = this.props.textSearchGroupCompany;
        this.props.setting_groupcompanies.forEach((point, i) => {
          if (point.name.toUpperCase().search(findText.toUpperCase()) > -1)
            result.push(point);
        });

        return result;
      }
    } else return [];
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      let columns = this.gridColumnApi.getAllColumns();
      columns.forEach(function (column, index) {
        if (index !== columns.length - 1) allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  getData = () => {
    this.props.getGroupCompanies({
      url: Configs[env].BACKEND_HOST + '/companyGroup',
      filter: {},
    });
  };
  getCompany = (groupId) => {
    // console.log('#getCompany groupId',groupId);
    this.props.getCompanies({
      url: Configs[env].BACKEND_HOST + '/companyCond',
      filter: {
        groupId: groupId ? groupId : this.props.group_selecting.id,
      },
    });
  };
  handleAdd = () => {
    this.props.formGroup({});
    //this.props.settingUserSelect(this.props.userForm.id);
    this.props.settingOpenGroup(true);
  };
  onFilterTextBoxChanged = (event) => {
    this.props.searchGroupCompany(event.target.value);
    //this.props.filterResult({key:'LOCATION_GROUP_LIST',filter:event.target.value});
    //this.state.api.setQuickFilter(document.getElementById('location-search').value);
  };
  handleSelectionChanged = (node) => {
    var selectedRows = node.api.getSelectedRows();
    this.props.settingGroupSelect(selectedRows[0]);
    this.getCompany(selectedRows[0].id);
  };
  render() {
    return (
      <div style={{ width: '100%', height: '400px' }}>
        <div
          className='ag-theme-balham'
          style={{
            height: '98%',
            width: '100%',
          }}
        >
          <Typography
            variant='subtitle1'
            gutterBottom
            className={styles.titlePanel}
          >
            Group Company
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl required fullWidth noValidate autoComplete='on'>
                <TextField
                  id='groupcompany-search'
                  label='SEARCH GROUP'
                  type='search'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin='dense'
                  className={styles.textField}
                  onChange={this.onFilterTextBoxChanged.bind(this)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant='filled' position='end'>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DialogActions>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Add'
                  className={classNames(styles.button)}
                  onClick={this.getData}
                >
                  REFRESH
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Add'
                  className={classNames(styles.button)}
                  onClick={this.handleAdd}
                  disabled={!JSON.parse(localStorage.getItem('privilege')).configuration.controllable}
                >
                  ADD GROUP
                </Button>
              </DialogActions>
            </Grid>
          </Grid>

          <AgGridReact
            reactNext={true}
            reduxStore={store}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderData()}
            rowSelection='multiple'
            onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
          <GroupDialog title={this.state.title} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    setting_groupcompanies: state.setting.setting_groupcompanies,
    textSearchGroupCompany: state.search.search_group_company,
    group_selecting: state.setting.group_selecting,
  };
};
const mapActionsToProps = {
  searchGroupCompany,
  getGroupCompanies,
  getCompanies,
  settingGroupSelect,
  formGroup,
  settingOpenGroup,
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(GroupCompany);
