import React, { createElement } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';

import Popover from '@material-ui/core/Popover';

import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { NavLink } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import { setActiveMenuListItem } from '../reducers/ui';
import * as uiRedux from '../reducers/ui';

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '0px 5px 0',
    borderRadius: '3px',
    background: '#2c3747',
  },
  whiteColor: {
    color: 'white',
  },
  itemIcon: {
    width: '24px',
    height: '24px',
    float: 'left',
    marginLeft: '-5px',
    marginRight: '15px',
    minWidth: '24px',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'rgba(255, 255, 255, 0.8)',
  },
  itemLink: {
    height: '42px',
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '0px 5px 0',
    borderRadius: '3px',
    backgroundColor: 'transparent',
  },
  itemLinkOnClose: {},
  itemText: {
    marginLeft: '5px',
    fontSize: '14px',
    color: '#FFFFFF',
    transform: `translate3d(0px, 0, 0)`,
    transition: `transform 300ms ease 0s, opacity 300ms ease 0s`,
  },
  itemTextOnClose: {
    opacity: '0',
    transform: `translate3d(-25px, 0, 0)`,
  },
  blue: {
    backgroundColor: '#3f51b5 !important',
  },
});

class MyMenuListItem extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    selectedIndex: null,
  };
  handleRequestClose = () => {
    this.setState({ open: false });
  };
  handleClick = (e, index) => {
    this.setState({ open: !this.state.open, anchorEl: e.currentTarget });
  };
  handleHover = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };
  handleListItemClick = (event, index) => {
    this.props.dispatch(
      setActiveMenuListItem({
        name: this.props.listItems.itemName,
        idx: index,
        menuName: this.props.listItems.nestedItems[index].itemName,
        subLink: this.props.listItems.nestedItems[index].itemLink,
      })
    );

    this.setState({ selectedIndex: index });
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.sidebarOpen !== nextProps.sidebarOpen) {
      this.setState({ open: false });
    }

    // Clear active selected and close sub menu if user click on other menu list
    if (nextProps.activeMenuListItem.idx !== null) {
      if (nextProps.activeMenuListItem.name !== nextProps.listItems.itemName) {
        this.setState({ open: false, selectedIndex: null });
      }
    }
  }
  nestedActiveRoute = (arr, val) => {
    return arr.some(function (arrVal) {
      return val === arrVal.itemLink;
    });
  };
  activeRoute = (path, exact) => {
    if (exact) {
      return this.props.location.pathname === path ? true : false;
    } else {
      return this.props.location.pathname.includes(path);
    }
  };
  activeListItemClasses = (path, exact) => {
    return classNames({
      [' ' + this.props.classes['blue']]: this.activeRoute(path, exact),
    });
  };
  activeItemTextClasses = (path, exact) =>
    classNames({
      [' ' + this.props.classes.whiteColor]: this.activeRoute(path, exact),
    });
  render() {
    const { classes, listItems, sidebarOpen } = this.props;
    const { open, anchorEl, selectedIndex } = this.state;

    const RenderListItemWithoutNestedLinks = (listItems, exact) => (
      <div>
        <ListItem
          className={classNames(
            classes.itemLink +
              this.activeListItemClasses(listItems.itemLink, exact),
            !sidebarOpen && classes.itemLinkOnClose
          )}
          button
          onClick={this.handleListItemClick}
        >
          <ListItemIcon
            className={classNames(
              classes.itemIcon +
                this.activeItemTextClasses(listItems.itemLink, exact)
            )}
          >
            {createElement(listItems.itemIcon)}
          </ListItemIcon>
          <ListItemText
            style={{ padding: '0px' }}
            disableTypography
            primary={
              <Typography
                variant='button'
                className={classNames(
                  classes.itemText +
                    this.activeItemTextClasses(listItems.itemLink, exact),
                  !this.props.sidebarOpen && classes.itemTextOnClose
                )}
              >
                {listItems.itemName}
              </Typography>
            }
            inset
          />
        </ListItem>
      </div>
    );
    const RenderListItemWithNestedLinks = (listItems, exact) => (
      <ListItem
        className={classNames(
          classes.itemLink +
            this.activeListItemClasses(listItems.itemLink, exact),
          !this.props.sidebarOpen && classes.itemLinkOnClose
        )}
        button
        onClick={this.handleClick}
      >
        <ListItemIcon
          className={classNames(
            classes.itemIcon +
              this.activeItemTextClasses(listItems.itemLink, exact)
          )}
        >
          {createElement(listItems.itemIcon)}
        </ListItemIcon>
        <ListItemText
          style={{ padding: '0px' }}
          disableTypography
          primary={
            <Typography
              variant='button'
              className={classNames(
                classes.itemText +
                  this.activeItemTextClasses(listItems.itemLink, exact),
                !this.props.sidebarOpen && classes.itemTextOnClose
              )}
            >
              {listItems.itemName}
            </Typography>
          }
          inset
        />
        {this.state.open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
    );

    return (
      <div>
        {listItems.nestedItems === undefined ? (
          sidebarOpen === false ? (
            <Tooltip
              disableTouchListener={true}
              disableFocusListener={true}
              title={listItems.itemName}
              placement='right'
            >
              {RenderListItemWithoutNestedLinks(listItems, listItems.exact)}
            </Tooltip>
          ) : (
            <>{RenderListItemWithoutNestedLinks(listItems, listItems.exact)}</>
          )
        ) : (
          <div>
            {sidebarOpen === false ? (
              <Tooltip
                disableTouchListener={true}
                disableFocusListener={true}
                title={listItems.itemName}
                placement='right'
              >
                {RenderListItemWithNestedLinks(listItems)}
              </Tooltip>
            ) : (
              <>
                {RenderListItemWithNestedLinks(listItems, open ? true : false)}
              </>
            )}
            <Collapse in={open && sidebarOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {listItems.nestedItems.map((item, idx) => (
                  <ListItem
                    button
                    disabled={item.disabled ? item.disabled : false}
                    className={classNames(
                      classes.nested + this.activeListItemClasses(item.itemLink)
                    )}
                    component={NavLink}
                    to={item.itemLink}
                    key={item.itemName}
                    selected={selectedIndex === idx}
                    onClick={(event) => this.handleListItemClick(event, idx)}
                  >
                    <ListItemIcon
                      className={classNames(
                        classes.listItemIcon,
                        classes.whiteColor
                      )}
                    >
                      {createElement(item.itemIcon)}
                    </ListItemIcon>
                    <ListItemText
                      style={{ padding: '0px' }}
                      disableTypography
                      primary={
                        <Typography
                          variant='subtitle1'
                          className={classNames(classes.itemText)}
                        >
                          {item.itemName}
                        </Typography>
                      }
                      inset
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            {!sidebarOpen && (
              <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleRequestClose}>
                    <MenuList>
                      {listItems.nestedItems.map((item) => (
                        <MenuItem
                          onClick={this.handleRequestClose}
                          component={NavLink}
                          to={item.itemLink}
                          disabled={item.disabled ? item.disabled : false}
                          key={item.itemName}
                        >
                          {item.itemName}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Popover>
            )}
            <Divider />
          </div>
        )}
      </div>
    );
  }
}

MyMenuListItem.propTypes = {
  listItems: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sidebarOpen: uiRedux.getSidebarOpen(state),
  activeMenuListItem: uiRedux.getActiveMenuListItem(state),
});

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(MyMenuListItem))
);
