import React, { Component } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { uiSwitchForm } from '../../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

//Reducer
import { dashboardOpenTimeDialog } from '../../../reducers/dashboard';
import { formOperation } from '../../../reducers/ui/mapForm';

const styles = (theme) => ({
  gridButton: {
    padding: '0px 0px',
    minWidth: '50px',
    fontSize: '0.8em',
    minHeight: '20px',
  },
  iconStyle: {
    padding: 0,
  },
});

class PlanETAFieldRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      title: 'Test',
      isYard: props.data.isYard.value,
    };
  }

  formatDateTime(value) {
    return moment(value).format('HH:mm');
  }

  // noinspection JSUnusedGlobalSymbols
  refresh(params) {
    if (params.value !== this.state.value) {
      this.setState({
        value: params.value.toFixed(2),
        isYard: params.data.isYard,
      });
    }
    return true;
  }
  openDateTimeDialog = (event) => {
    // let partUsageTime_editable = this.props.data.partUsageTime.editable
    // if(this.props.colDef.field === "partUsageTime.value" && partUsageTime_editable === 0)
    //     return false
    // if(this.props.colDef.field === "ETA.value" && this.props.data.ETA.editable === 0)
    //     return false

    // if(this.props.colDef.field=="ETA.value" || this.props.colDef.field=="partUsageTime.value" ){

    this.props.dashboardOpenTimeDialog(true);
    this.props.formOperation({ ...this.props.data, colDef: this.props.colDef });
    // }
  };
  render() {
    let showValue = '';

    if (this.state.value) {
      showValue = this.state.value;
    }

    if (
      (this.props.colDef.field == 'planArrivalTime.value' ||
        this.props.colDef.field == 'ETA.value') &&
      this.state.value &&
      this.state.isYard === true
    ) {
      showValue = this.state.value + '*';
    }

    let canEdit = false;
    let bgColor = '';

    if (this.props.colDef.field === 'partUsageTime.value') {
      if (this.props.data.partUsageTime.editable === 1) canEdit = true;
      bgColor = this.props.data.partUsageTime.color;
    }
    if (this.props.colDef.field === 'ETA.value') {
      if (this.props.data.ETA.editable === 1) canEdit = true;
      bgColor = this.props.data.ETA.color;
    }
    return (
      <div
        className='something'
        onClick={canEdit ? this.openDateTimeDialog : null}
        styles={{
          width: '100%',
          height: '100%',
          backgroundColor: bgColor,
        }}
      >
        {canEdit && <div className='arrow-right'></div>}
        <span>{showValue ? showValue : '-'}</span>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    ...ownProps,
    dashboard_time_dialog: state.dashboard.dashboard_time_dialog,
  };
};

const mapActionsToProps = {
  dashboardOpenTimeDialog,
  formOperation,
};
const enhance = compose(
  connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true }),
  withStyles(styles)
);

export default enhance(PlanETAFieldRenderer);
