import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
//Component
import CompanyDialog from './Dialog/CompanyDialog';
import CompanyBtnRenderer from './Renderer/CompanyBtnRenderer';
//Reducer
import { getCompanies, getGroupCompanies } from '../../reducers/setting/api';
import { searchCompany } from '../../reducers/search';
import { settingOpenCompany } from '../../reducers/setting';
import { formCompany } from '../../reducers/ui/mapForm';
import store from 'reducers/rootReducer';

import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({});
class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'ADD COMPANY',
      columnDefs: [
        { headerName: 'Company', field: 'name' },
        { headerName: 'Last Update', field: 'lastUpdate' },
        {
          cellRenderer: 'companyBtnRenderer',
          width: 80,
        },
      ],
      rowData: null,
      defaultColDef: { sortable: true, resizable: true },
      frameworkComponents: {
        companyBtnRenderer: CompanyBtnRenderer,
      },
    };
  }
  componentDidMount() {
    //this.getData();
  }
  componentDidUpdate() {
    this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {}
  renderData = () => {
    if (Array.isArray(this.props.setting_companies)) {
      //this.props.setLogisticPoint(this.props.setting_companies);
      //filter text in location point
      if (this.props.textSearchCompany === '')
        return this.props.setting_companies;
      else {
        let result = [];
        let findText = this.props.textSearchCompany;
        this.props.setting_companies.forEach((point, i) => {
          if (point.name.toUpperCase().search(findText.toUpperCase()) > -1)
            result.push(point);
        });

        return result;
      }
    } else return [];
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      let columns = this.gridColumnApi.getAllColumns();
      columns.forEach(function (column, index) {
        if (index !== columns.length - 1) allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  getData = () => {
    if (
      this.props.group_selecting &&
      this.props.group_selecting.hasOwnProperty('id')
    )
      this.props.getCompanies({
        url: Configs[env].BACKEND_HOST + '/companyCond',
        filter: {
          groupId: this.props.group_selecting.id,
        },
      });
  };

  handleAdd = () => {
    if (this.props.group_selecting) {
      // console.log('#handleAdd group_selecting',this.props.group_selecting.id);
      this.props.formCompany({
        //groupId : this.props.group_selecting.id
      });
      //this.props.settingUserSelect(this.props.userForm.id);
      this.props.settingOpenCompany(true);
    }
  };
  onFilterTextBoxChanged = (event) => {
    this.props.searchCompany(event.target.value);
    //this.props.filterResult({key:'LOCATION_COMPANY_LIST',filter:event.target.value});
    //this.state.api.setQuickFilter(document.getElementById('location-search').value);
  };
  render() {
    return (
      <div style={{ width: '100%', height: '400px' }}>
        <div
          className='ag-theme-balham'
          style={{
            height: '98%',
            width: '100%',
          }}
        >
          <Typography
            variant='subtitle1'
            gutterBottom
            className={styles.titlePanel}
          >
            Company :{' '}
            {this.props.group_selecting ? this.props.group_selecting.name : ''}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl required fullWidth noValidate autoComplete='on'>
                <TextField
                  id='groupcompany-search'
                  label='SEARCH COMPANY'
                  type='search'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  margin='dense'
                  className={styles.textField}
                  onChange={this.onFilterTextBoxChanged.bind(this)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant='filled' position='end'>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DialogActions>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Add'
                  className={classNames(styles.button)}
                  onClick={this.getData}
                  disabled={this.props.group_selecting ? false : true}
                >
                  REFRESH
                </Button>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Add'
                  className={classNames(styles.button)}
                  onClick={this.handleAdd}
                  disabled={this.props.group_selecting ? false : true || !JSON.parse(localStorage.getItem('privilege')).configuration.controllable }
                >
                  ADD COMPANY
                </Button>
              </DialogActions>
            </Grid>
          </Grid>

          <AgGridReact
            reactNext={true}
            reduxStore={store}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderData()}
            rowSelection='multiple'
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
          <CompanyDialog title={this.state.title} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    setting_companies: state.setting.setting_companies,
    setting_groupcompanies: state.setting.setting_groupcompanies,
    textSearchCompany: state.search.search_company,
    group_selecting: state.setting.group_selecting,
  };
};
const mapActionsToProps = {
  searchCompany,
  getCompanies,
  settingOpenCompany,
  formCompany,
  getGroupCompanies,
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(Company);
