import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import DeleteButton from './DeleteButton';

function DrawCircle(props) {
  const {
    map,
    disposeFunctions,
    interaction,
    builderOptions,
    onCallback,
    circle,
    draggable,
    resizable,
    isDraw,
    options,
  } = props;

  // if (!window.H || !window.H.map || !map) {
  //   throw new Error('HMap has to be initialized before adding Map Objects');
  // }
  let _circle = useRef();
  let circleOutline = useRef();
  let circleGroup = useRef();
  let circleTimeout = useRef();

  const dispatchCallback = useCallback(
    (circleData) => {
      if (onCallback) {
        onCallback(circleData);
      }
    },
    [onCallback]
  );

  const disableInteraction = useCallback(
    (ev) => {
      const target = ev.target;
      if (target instanceof window.H.map.Circle) {
        interaction.disable();
        ev.stopPropagation();
      }
    },
    [interaction]
  );

  const enableInteraction = useCallback(
    (ev) => {
      const target = ev.target;
      if (target instanceof window.H.map.Circle) {
        interaction.enable();
        ev.stopPropagation();

        setTimeout(() => {
          if (!_circle.current) return;
          dispatchCallback({
            center: _circle.current.getCenter(),
            radius: _circle.current.getRadius(),
          });
        }, [0]);
      }
    },
    [interaction, dispatchCallback]
  );

  const onDragCircle = useCallback(
    (ev) => {
      const target = ev.target,
        pointer = ev.currentPointer;
      if (target instanceof window.H.map.Circle) {
        target.setCenter(map.screenToGeo(pointer.viewportX, pointer.viewportY));

        // use circle's updated geometry for outline polyline
        var outlineLinestring = target.getGeometry().getExterior();

        // extract first point of the outline LineString and push it to the end, so the outline has a closed geometry
        outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
        circleOutline.current.setGeometry(outlineLinestring);
        ev.stopPropagation();
      }
    },
    [map, circleOutline]
  );

  const enableDrag = useCallback(() => {
    _circle.current.draggable = true;

    map.addEventListener('dragstart', disableInteraction, false);

    map.addEventListener('dragend', enableInteraction, false);

    map.addEventListener('drag', onDragCircle, false);
  }, [map, disableInteraction, enableInteraction, onDragCircle]);

  const enableResize = useCallback(() => {
    if (!circleOutline.current) return;
    circleOutline.current.draggable = true;

    // extract first point of the circle outline polyline's LineString and
    // push it to the end, so the outline has a closed geometry
    circleOutline.current
      .getGeometry()
      .pushPoint(circleOutline.current.getGeometry().extractPoint(0));

    // event listener for circle group to show outline (polyline) if moved in with mouse (or touched on touch devices)
    circleGroup.current.addEventListener(
      'pointerenter',
      function (_) {
        if (!circleOutline.current) return;
        var currentStyle = circleOutline.current.getStyle(),
          newStyle = currentStyle.getCopy({
            strokeColor: 'rgb(255, 0, 0)',
          });
        if (circleTimeout.current) {
          clearTimeout(circleTimeout.current);
          circleTimeout.current = null;
        }
        // show outline
        circleOutline.current.setStyle(newStyle);
      },
      true
    );

    // event listener for circle group to hide outline if moved out with mouse (or released finger on touch devices)
    // the outline is hidden on touch devices after specific timeout
    circleGroup.current.addEventListener(
      'pointerleave',
      function (evt) {
        if (!circleOutline.current) return;

        var currentStyle = circleOutline.current.getStyle(),
          newStyle = currentStyle.getCopy({
            strokeColor: 'rgba(255, 0, 0, 0)',
          }),
          timeout = evt.currentPointer.type === 'touch' ? 1000 : 0;

        circleTimeout.current = setTimeout(function () {
          if (!circleOutline.current) return;
          circleOutline.current.setStyle(newStyle);
        }, timeout);
        document.body.style.cursor = 'default';
      },
      true
    );

    // event listener for circle group to change the cursor if mouse position is over the outline polyline (resizing is allowed)
    circleGroup.current.addEventListener(
      'pointermove',
      function (evt) {
        if (evt.target instanceof window.H.map.Polyline) {
          document.body.style.cursor = 'pointer';
        } else {
          document.body.style.cursor = 'default';
        }
      },
      true
    );

    // event listener for circle group to resize the geo circle object if dragging over outline polyline
    circleGroup.current.addEventListener(
      'drag',
      function (evt) {
        if (!_circle.current) return;
        var pointer = evt.currentPointer,
          distanceFromCenterInMeters = _circle.current
            .getCenter()
            .distance(map.screenToGeo(pointer.viewportX, pointer.viewportY));

        // if resizing is allowed, set the circle's radius
        if (evt.target instanceof window.H.map.Polyline) {
          _circle.current.setRadius(distanceFromCenterInMeters);

          // use circle's updated geometry for outline polyline
          var outlineLinestring = _circle.current.getGeometry().getExterior();

          // extract first point of the outline LineString and push it to the end, so the outline has a closed geometry
          outlineLinestring.pushPoint(outlineLinestring.extractPoint(0));
          circleOutline.current.setGeometry(outlineLinestring);

          // prevent event from bubling, so map doesn't receive this event and doesn't pan
          evt.stopPropagation();
        }
      },
      true
    );

    circleGroup.current.addEventListener('dragend', function (_) {
      setTimeout(() => {
        if (!_circle.current) return;
        dispatchCallback({
          center: _circle.current.getCenter(),
          radius: _circle.current.getRadius(),
        });
      }, [0]);
    });
  }, [map, dispatchCallback]);

  const createCircle = useCallback(
    (geoPoint, radius = 300, isDispatch = true) => {
      if (!map) return;

      if (!_circle.current) {
        let _options = options;
        if (!_options) _options = {};

        _circle.current = new window.H.map.Circle(geoPoint, radius || 300, {
          ..._options,
          volatility: true,
        });
        circleOutline.current = new window.H.map.Polyline(
          _circle.current.getGeometry().getExterior(),
          {
            style: { lineWidth: 8, strokeColor: 'rgba(255, 0, 0, 0)' },
          }
        );
        circleGroup.current = new window.H.map.Group({
          volatility: true, // mark the group as volatile for smooth dragging of all it's objects
          objects: [_circle.current, circleOutline.current],
        });

        map.addObject(circleGroup.current);

        if (resizable && isDraw) {
          enableResize();
        }

        if (draggable && isDraw) {
          enableDrag();
        }

        if (isDispatch) {
          setTimeout(() => {
            if (!_circle.current) return;
            dispatchCallback({
              center: _circle.current.getCenter(),
              radius: _circle.current.getRadius(),
            });
          }, [0]);
        }
      }
    },
    [
      options,
      map,
      resizable,
      isDraw,
      draggable,
      enableResize,
      enableDrag,
      dispatchCallback,
    ]
  );

  const mapPointerDownHandler = useCallback(
    (e) => {
      if (!map) return;
      const pointer = e.currentPointer;
      let geoPoint = map.screenToGeo(pointer.viewportX, pointer.viewportY);
      createCircle(geoPoint);
    },
    [map, createCircle]
  );

  const subscribeToMapEvents = useCallback(() => {
    if (!map) return;
    map.addEventListener('pointerdown', mapPointerDownHandler, false);
  }, [map, mapPointerDownHandler]);

  const unsubscribeToMapEvents = useCallback(() => {
    if (!map) return;
    map.removeEventListener('dragstart', disableInteraction, false);
    map.removeEventListener('dragend', enableInteraction, false);
    map.removeEventListener('drag', onDragCircle, false);
    map.removeEventListener('pointerdown', mapPointerDownHandler, false);
  }, [
    map,
    disableInteraction,
    enableInteraction,
    onDragCircle,
    mapPointerDownHandler,
  ]);

  // Unsubscribe event
  useEffect(() => {
    if (circle && circle.center) {
      createCircle(circle.center, circle.radius, false);
    }
    if (isDraw) subscribeToMapEvents();

    const dispose = () => {
      _circle.current = null;
      circleOutline.current = null;
      circleGroup.current = null;
      unsubscribeToMapEvents();
    };
    disposeFunctions.current.push(dispose);
  }, [
    map,
    circle,
    createCircle,
    disposeFunctions,
    subscribeToMapEvents,
    unsubscribeToMapEvents,
    isDraw,
  ]);

  const clear = () => {
    if (circleGroup.current) {
      circleGroup.current.removeAll();
    }

    _circle.current = null;
    circleOutline.current = null;
    circleGroup.current = null;

    map.removeEventListener('dragstart', disableInteraction, false);
    map.removeEventListener('dragend', enableInteraction, false);
    map.removeEventListener('drag', onDragCircle, false);

    dispatchCallback({});
  };

  if (!_circle.current || !isDraw) {
    return <div style={{ display: 'none' }} />;
  } else {
    return <DeleteButton onDeleteClick={clear} />;
  }
}

export default DrawCircle;
