import { select, takeLatest, call, put } from 'redux-saga/effects';
import {
  getLocationGroup,
  getLogisticPoint,
  getLocationGroupDetail,
  getLogisticDetail,
} from '../reducers/map/api';
import {
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons,
  mapSetLocationGroup,
  mapSetLogisticPoint,
  mapCenterChanged,
  mapSetBounds,
  mapSetMarkerGroup,
  SET_MAP_LOADING,
} from '../reducers/map';

import { SET_SNACKBAR } from '../reducers/ui';

import { addLocation } from '../reducers/ui/mapForm';
import axios from 'axios';
import Configs from '../config/config';
const env = process.env.NODE_ENV;
const gUtils = require('@googlemaps/google-maps-services-js/dist/util');

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(getLocationGroup, locationGroupWorkerSaga);
  yield takeLatest(getLocationGroupDetail, locationGroupDetailWorkerSaga);
  yield takeLatest(getLogisticPoint, logisticPointWorkerSaga);
  yield takeLatest(getLogisticDetail, locationDetailWorkerSaga);
  //yield takeLatest(addGroupLocation, locationGroupSelectWorkerSaga);
}

function fetchDataGet_NEW(params) {
  //http://mapssh.leafte.ch/managementTest
  let url = params.payload.url + '?' + paramsUrl(params.payload.filter);
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem('token'),
  };

  return axios
    .get(url, { headers: headers, timeout: 20000 })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return { catchError: error };
    });
}

function paramsUrl(data) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function* logisticPointWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });
    const state = yield select();
    if (state.ui.formManage.page === 'list') {
      const resp = yield call(fetchDataGet_NEW, action);
      yield put({ type: SET_MAP_LOADING, payload: false });
      const response = yield checkError(resp, action);

      if (response !== false) {
        let groupDetail = JSON.parse(state.selection.locationGroup.value);
        let dataGroup = [];
        dataGroup.push(groupDetail);

        // dispatch a response.data action
        let markerGroup = setMarkerGroup(groupDetail, false);
        let markers = setMarkers(response.data.list, false);
        let circles = setCircles(response.data.list, false);
        let polygons = setPolygons(response.data.list, false);
        let dataAll = [];
        if (response.data.list) dataAll = [...response.data.list, ...dataGroup];
        else dataAll = dataGroup;

        let bounds = setBounds(dataAll);

        //yield put({ type: mapSetLogisticPoint, data });

        yield put({ type: mapSetMarkerGroup, payload: markerGroup });
        yield put({ type: mapSetMarkers, payload: markers });
        yield put({ type: mapSetCircles, payload: circles });
        yield put({ type: mapSetPolygons, payload: polygons });
        yield put({ type: mapSetLogisticPoint, payload: response.data.list });
        yield put({ type: mapSetBounds, payload: bounds });
      }

      yield put({ type: SET_MAP_LOADING, payload: false });
    }
    yield put({ type: SET_MAP_LOADING, payload: false });
  } catch (error) {
    // console.log(error);
    yield put({ type: SET_MAP_LOADING, payload: false });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

function* locationGroupDetailWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });
    const resp = yield call(fetchDataGet_NEW, action);

    yield put({ type: SET_MAP_LOADING, payload: false });
    const response = yield checkError(resp, action);

    if (response !== false) {
    }
    yield put({ type: SET_MAP_LOADING, payload: false });
  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

// function* locationGroupDetailWorkerSaga(action) {
//     try {
//       yield put({ type: SET_MAP_LOADING, payload: true });

//       const state = yield select();
//       const response = yield call(fetchDataGet_OLD, action);
//       if(response !== null) {

//         // dispatch a response action
//       }
//       yield put({ type: SET_MAP_LOADING, payload: false });
//     } catch (error) {
//       yield put({ type: SET_MAP_LOADING, payload: false });
//       // dispatch a failure action to the store with the error
//       //yield put({ type: mapAction.authLoginError, error });

//     }
//   }

function* locationDetailWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });
    const resp = yield call(fetchDataGet_NEW, action);

    yield put({ type: SET_MAP_LOADING, payload: false });
    const response = yield checkError(resp, action);

    if (response !== false) {
      let markers = setMarkers(response.data.list, true);
      let circles = setCircles(response.data.list, true);
      let polygons = setPolygons(response.data.list, true);
      let bounds = setBounds(response.data.list);

      //yield put({ type: mapSetLogisticPoint, response.data.list });
      yield put({ type: mapSetMarkers, payload: markers });
      yield put({ type: mapSetCircles, payload: circles });
      yield put({ type: mapSetPolygons, payload: polygons });
      yield put({ type: mapSetLogisticPoint, payload: response.data.list });
      //add to location form
      yield put({ type: addLocation, payload: response.data.list[0] });
      yield put({ type: mapSetBounds, payload: bounds });
    }
    yield put({ type: SET_MAP_LOADING, payload: false });
  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

// function* locationDetailWorkerSaga_(action) {
//   try {
//     yield put({ type: SET_MAP_LOADING, payload: true });
//     const state = yield select();
//     const response = yield call(fetchDataGet, action);

//     if(response !== null) {

//       // dispatch a response action

//       //response.editble = true;
//       let markers = setMarkers(response.list,true);
//       let circles = setCircles(response.list,true);
//       let polygons = setPolygons(response.list,true);
//       let bounds = setBounds(response.list);

//       //yield put({ type: mapSetLogisticPoint, response.list });
//       yield put({ type: mapSetMarkers,  payload:markers } );
//       yield put({ type: mapSetCircles, payload:circles });
//       yield put({ type: mapSetPolygons, payload:polygons });
//       yield put({ type: mapSetLogisticPoint, payload:response.list });
//       //add to location form
//       yield put({ type: addLocation, payload:response.list[0] });
//       yield put({ type: mapSetBounds, payload:bounds });
//     }
//     yield put({ type: SET_MAP_LOADING, payload: false });

//   } catch (error) {
//     yield put({ type: SET_MAP_LOADING, payload: false });
//     // dispatch a failure action to the store with the error
//     //yield put({ type: mapAction.authLoginError, error });

//   }
// }

function* locationGroupWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });
    const resp = yield call(fetchDataGet_NEW, action);

    yield put({ type: SET_MAP_LOADING, payload: false });
    const response = yield checkError(resp, action);

    if (response !== false) {
      yield put({ type: mapSetLocationGroup, payload: response.data.list });
    }
    yield put({ type: SET_MAP_LOADING, payload: false });
  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

// function* locationGroupWorkerSaga(action) {
//   try {
//     // let callback = action.callback;

//     yield put({ type: SET_MAP_LOADING, payload: true });
//     const state = yield select();
//     const response = yield call(fetchDataGet, action);

//     // if(callback) callback(response);

//     if(response !== null)
//       yield put({ type: mapSetLocationGroup, payload:response.list });

//     yield put({ type: SET_MAP_LOADING, payload: false });
//   } catch (error) {
//     yield put({ type: SET_MAP_LOADING, payload: false });
//     // dispatch a failure action to the store with the error
//     //yield put({ type: mapAction.authLoginError, error });

//   }
// }

function setMarkerGroup(point, editable) {
  let marker = {
    id: point.id,
    position: { lat: point.latitude, lng: point.longitude },
    info: {
      name: point.name,
      description: '',
      logisticsPoint: point.logisticsPointGroup,
    },
    isGroup: false,
    editable: editable,
  };

  return marker;
}
function setMarkers(data, editable) {
  let markerList = [];
  let marker = {};

  if (data) {
    data.forEach((point, i) => {
      //Marker
      marker = {
        id: point.id,
        position: { lat: point.latitude, lng: point.longitude },
        info: {
          name: point.name,
          description: point.detail,
          logisticsPoint: point.logisticsPoint,
        },
        isGroup: false,
        editable: editable,
      };
      //markerList.push(marker);
      markerList.push(marker);
    });
  }
  return markerList;
}
function setCircles(data, editable) {
  let circleList = [];
  let circle = {};
  if (data) {
    data.forEach((point, i) => {
      //Circle
      if (point.type == 'circle') {
        circle = {
          id: point.id,
          center: { lat: point.circleLatitude, lng: point.circleLongitude },
          radius: point.radius,
          info: {
            name: point.name,
            description: point.detail,
            logisticsPoint: point.logisticsPoint,
          },
          isGroup: false,
          editable: editable,
        };
        circleList.push(circle);
      }
    });
  }

  return circleList;
}
function setPolygons(data, editable) {
  let polygonList = [];
  let polygon = {};
  if (data) {
    data.forEach((point, i) => {
      if (point.type === 'polygon') {
        var decodePath = gUtils.decodePath(point.polygon);
        var paths = [];
        decodePath.forEach(function (p) {
          paths.push({ lat: p.lat, lng: p.lng });
        });
        polygon = {
          id: point.id,
          path: decodePath,
          info: {
            name: point.name,
            description: point.detail,
            logisticsPoint: point.logisticsPoint,
          },
          isGroup: false,
          editable: editable,
        };
        //polygonList.push(polygon);
        polygonList.push(polygon);
      }
    });
    return polygonList;
  }
}
function setBounds(data) {
  let bounds = [];
  if (data) {
    data.forEach((point, i) => {
      bounds.push({ lat: point.latitude, lng: point.longitude });
      if (point.type === 'circle') {
        bounds.push({ lat: point.circleLatitude, lng: point.circleLongitude });
      } else if (point.type === 'polygon') {
        var decodePath = gUtils.decodePath(point.polygon);
        decodePath.forEach(function (p) {
          bounds.push({ lat: p.lat, lng: p.lng });
        });
      }
    });
  }
  return bounds;
}

//===============================
function* checkError(response, action) {
  let params = action;
  let callback = params.payload.callback;
  let callback_error = params.payload.callback_error;

  if (response.hasOwnProperty('catchError')) {
    if (callback_error) callback_error(response.catchError.toString());
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: response.catchError.toString(),
        snackbarDuration: 20000,
      },
    });
    return false;
  }

  if (parseInt(response.status) < 200 || parseInt(response.status) > 200) {
    if (callback_error)
      callback_error('HTTP ERROR : status code = ' + response.status);
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'HTTP ERROR : status code = ' + response.status,
        snackbarDuration: 20000,
      },
    });
    return false;
  }
  if (response.data.hasOwnProperty('errorList')) {
    if (response.data.errorList.length > 0) {
      let errorShow = '';
      if (callback_error) callback_error(errorShow);

      errorShow = response.data.errorList[0].errorMessage;
      console.info('errorDetail', response.data.errorList[0].errorDetail);

      if (parseInt(response.data.errorList[0].errorCode) === 2101) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
          },
        });
        window.location.href = '/dashboard/operation_dash';
        return false;
      }

      if (
        parseInt(response.data.errorList[0].errorCode) === 2102 ||
        parseInt(response.data.errorList[0].errorCode) === 2103
      ) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
            forceLogout: true,
          },
        });
        return false;
      }

      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: errorShow,
          snackbarDuration: 20000,
        },
      });
      return false;
    }
  }

  if (
    response.data.hasOwnProperty('list') ||
    response.data.hasOwnProperty('result')
  ) {
    if (callback) callback(response.data);
    // yield put({
    //     type: SET_SNACKBAR,
    //     payload: {
    //         snackbarOpen: true,
    //         snackbarVariant: "success",
    //         snackbarMessage: "Manual reload data success",
    //         snackbarDuration: 2000
    //     }
    // });

    return response;
  }

  yield put({
    type: SET_SNACKBAR,
    payload: {
      snackbarOpen: true,
      snackbarVariant: 'error',
      snackbarMessage: 'API error',
      snackbarDuration: 20000,
    },
  });

  return false;
}
