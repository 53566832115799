import { takeLatest, call, put } from 'redux-saga/effects';
import { getHistoryLogs } from '../reducers/map/api';
import { mapSetHazardHistoryLogs, SET_MAP_LOADING } from '../reducers/map';

import { SET_SNACKBAR } from '../reducers/ui';

import axios from 'axios';
import Configs from '../config/config';
const env = process.env.NODE_ENV;

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(getHistoryLogs, hazardHistoryLogWorkerSaga);
}

function paramsUrl(data) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function fetchDataGet_NEW(params) {
  //http://mapssh.leafte.ch/managementTest
  let url = params.payload.url + '?' + paramsUrl(params.payload.filter);
  // let callback = params.payload.callback;
  // let callback_error = params.payload.callback_error;
  // let callback_errorList = params.payload.callback_errorList;
  let headers = {
    Accept: 'application/json',
    // 'Accept-Encoding' : 'gzip',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem('token'),
  };

  return axios
    .get(url, { headers: headers, timeout: 60000 })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return { catchError: error };
    });
}

// function* impactHazardWorkerSagaxx(action) {
//   try {
//     yield put({ type: SET_MAP_LOADING, payload: true });
//     const state = yield select();

//     //if(state.ui.formManage.page=='list'){
//       const response = yield call(fetchDataGet, action);

//       if(response !== null) {
//         // dispatch a response action
//         yield put({ type: mapSetHazardHistoryLogs, payload:response.list });

//         //Render Map

//         if(response.list.length>0){
//         //   let hazard = response.list[0].hazard;

//           let markers = setMarkers_array(response.list,false);
//           let circles = setCircles(response.list,false);
//           let polygons = setPolygons(response.list,false);
//           let bounds = setBounds(response.list);

//           yield put({ type: mapSetMarkers,  payload:markers } );
//           yield put({ type: mapSetCircles, payload:circles });
//           yield put({ type: mapSetPolygons, payload:polygons });
//           yield put({ type: mapSetBounds, payload:bounds });
//         }
//         yield put({ type: SET_MAP_LOADING, payload: false });
//       }
//   } catch (error) {
//     yield put({ type: SET_MAP_LOADING, payload: false });
//     // dispatch a failure action to the store with the error
//     //yield put({ type: mapAction.authLoginError, error });

//   }
// }

// wor
// worker saga: makes the api call when watcher saga sees the action
function* hazardHistoryLogWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });

    // const response = yield call(fetchDataGet, action);

    const resp = yield call(fetchDataGet_NEW, action);
    const response = yield checkError(resp, action);
    // const response = yield call(fetchDataGet, action);
    if (response !== false) {
      yield put({ type: mapSetHazardHistoryLogs, payload: response.data.list });
      //Render Map

      if (response.data.list.length > 0) {
      }
    }

    // if(response !== null) {
    //   // dispatch a response action
    //   yield put({ type: mapSetHazardHistoryLogs, payload:response.list });

    //   //Render Map

    //   if(response.list.length>0){
    //     let markers = setMarkers(response.list,false);
    //     let circles = setCircles(response.list,false);
    //     let polygons = setPolygons(response.list,false);
    //     let bounds = setBounds(response.list);
    //   }
    //   yield put({ type: SET_MAP_LOADING, payload: false });
    // }

    yield put({ type: SET_MAP_LOADING, payload: false });
  } catch (error) {
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
    yield put({ type: SET_MAP_LOADING, payload: false });
  }
}

//===============================
function* checkError(response, action) {
  let params = action;
  let callback = params.payload.callback;
  let callback_error = params.payload.callback_error;

  if (response.hasOwnProperty('catchError')) {
    if (callback_error) callback_error(response.catchError.toString());
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: response.catchError.toString(),
        snackbarDuration: 20000,
      },
    });
    return false;
  }

  if (parseInt(response.status) < 200 || parseInt(response.status) > 200) {
    if (callback_error)
      callback_error('HTTP ERROR : status code = ' + response.status);
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'HTTP ERROR : status code = ' + response.status,
        snackbarDuration: 20000,
      },
    });
    return false;
  }
  if (response.data.hasOwnProperty('errorList')) {
    let errorShow = '';
    if (response.data.errorList.length > 0) {
      if (callback_error) callback_error(errorShow);

      errorShow = response.data.errorList[0].errorMessage;
      //console.info('errorDetail', response.data.errorList[0].errorDetail);

      if (parseInt(response.data.errorList[0].errorCode) === 2101) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
          },
        });
        window.location.href = '/dashboard/operation_dash';
        return false;
      }

      if (
        parseInt(response.data.errorList[0].errorCode) === 2102 ||
        parseInt(response.data.errorList[0].errorCode) === 2103
      ) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
            forceLogout: true,
          },
        });
        return false;
      }

      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: errorShow,
          snackbarDuration: 20000,
        },
      });
      return false;
    }
  }

  if (
    response.data.hasOwnProperty('list') ||
    response.data.hasOwnProperty('result')
  ) {
    if (callback) callback(response.data);
    // yield put({
    //     type: SET_SNACKBAR,
    //     payload: {
    //         snackbarOpen: true,
    //         snackbarVariant: "success",
    //         snackbarMessage: "Manual reload data success",
    //         snackbarDuration: 2000
    //     }
    // });

    return response;
  }

  yield put({
    type: SET_SNACKBAR,
    payload: {
      snackbarOpen: true,
      snackbarVariant: 'error',
      snackbarMessage: 'API error',
      snackbarDuration: 20000,
    },
  });

  return false;
}
