import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import EditBtnRenderer from './Renderer/EditBtnRenderer';
import DetailExRenderer from './Renderer/DetailExRenderer';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { mapSelectedMarker, MAP_LOG } from '../../reducers/map';
import store from 'reducers/rootReducer';

import Configs from '../../config/config';

const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // padding: "10px"
  },
  textField: {
    margin: 0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200,
  },
});
class CriticalRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marker_selecting: this.props.marker_selecting,
      pointList: this.props.dashboard_management,
      url_endpoint: {
        response: false,
        endpoint: Configs[env].BACKEND_HOST,
      },
      columnDefs: [
        {
          headerName: 'Problem',
          field: 'problem',
          width: 100,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              whiteSpace: 'normal',
              color: 'white',
              textAlign: 'left',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'Partner',
          field: 'logisticsPartner',
          width: 50,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'Route',
          field: 'routeCode',
          width: 50,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'Truck License',
          //headerClass: 'multiline',
          field: 'truckLicense',
          width: 60,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'Action',
          field: 'action',
          width: 100,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              whiteSpace: 'normal',
              color: 'white',
              textAlign: 'left',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'Plan Arrival Time',
          // headerClass: "multiline",
          field: 'planArrivalTime',
          width: 50,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'ETA',
          field: 'eta',
          width: 50,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'Part Usage Time',
          // headerClass: "multiline",
          field: 'partUsageTime',
          width: 50,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'Total Delay (Min)',
          //headerClass: 'multiline',
          field: 'totalDelay',
          width: 90,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              color: 'white',
              textAlign: 'center',
              backgroundColor: '#FF0000',
            };
          },
        },
        {
          headerName: 'Detail Explanation',
          // headerClass: "multiline",
          field: 'detailExplanation',
          cellRenderer: 'detailExRenderer',
          // width: 150,
          autoHeight: true,
          cellStyle: function (params) {
            return {
              padding: '2px',
              lineHeight: '1.5em',
              whiteSpace: 'normal',
            };
          },
        },
        {
          cellRenderer: 'editBtnRenderer',
          width: 50,
          cellStyle: function (params) {
            return { padding: '2px', lineHeight: '1em' };
          },
        },
      ],
      context: { componentParent: this },
      defaultColDef: { sortable: true, resizable: true },
      // suppressRowClickSelection: true,
      rowSelection: 'single',
      frameworkComponents: {
        editBtnRenderer: EditBtnRenderer,
        detailExRenderer: DetailExRenderer,
      },
      headerHeight: 100,
      rowData: [],
    };
  }
  componentDidMount() {
    // this.sizeToFit();
  }
  componentWillUnmount() {}
  componentDidUpdate() {
    // this.gridApi.sizeColumnsToFit();
    // this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {
    // if (
    //   nextProps.grouplist.statusText === "OK" &&
    //   (nextProps.grouplist.url ===
    //     Configs[env].BACKEND_HOST + "/location/add_group" ||
    //     nextProps.grouplist.url ===
    //       Configs[env].BACKEND_HOST + "/location/edit_group")
    // ) {
    //   this.props.onDialogOpen(false);
    // }
    this.testSelect(nextProps.marker_selecting);
  }

  renderData() {
    if (this.props.dashboard_management) {
      if (Array.isArray(this.props.dashboard_management.crticalDelay)) {
        return this.props.dashboard_management.crticalDelay;
      }
    }
    return [];
  }
  sizeToFit = () => {
    // sizeToFit() {
    this.gridApi.sizeColumnsToFit();
    // }
  };
  autoSizeAll = () => {
    // this.gridApi.sizeColumnsToFit();
    // var allColumnIds = [];
    // if(this.gridColumnApi){
    //   this.gridColumnApi.getAllColumns().forEach(function(column,index) {
    //     allColumnIds.push(column.colId);
    //   });
    //   this.gridColumnApi.autoSizeColumns(allColumnIds);
    // }
  };
  
  handleSelectionChanged = (node) => {
    try {
      var selectedRows = node.api.getSelectedRows();

      this.props.SELECT_MARKER(selectedRows[0].id);
    } catch (e) {
      this.props.MAP_LOG({
        error: e,
        detail: 'Location.js - handleSelectionChanged',
      });
    }
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(38);
    this.sizeToFit();
  };

  onColumnResized = (event) => {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  };

  testSelect = (marker_selecting) => {
    let gridApi = this.gridApi;
    if (gridApi) {
      gridApi.deselectAll();
      this.gridApi.forEachNode(function (node) {
        if (node.data.id === marker_selecting) {
          node.setSelected(true, true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' });

          // and ensureNodeVisible(event.node, { position: 'top' });
        }
      });
    }
  };

  render() {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <div
          className='ag-theme-balham'
          style={{
            height: '100%',
            width: '100%',
            marginTop: '-1px',
          }}
        >
          <AgGridReact
            reactNext={true}
            reduxStore={store}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderData()}
            rowSelection='multiple'
            onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady}
            onColumnResized={this.onColumnResized}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    isLoading: state.api.fetching,
    pointLocationList: state.api.resultPointList || [],
    marker_selecting: state.map.marker_selecting,
    textSearchLocation: state.search.search_location,
    dashboard_management: state.dashboard.dashboard_management,
  };
};
const mapActionsToProps = {
  SELECT_MARKER: mapSelectedMarker,
  MAP_LOG: MAP_LOG,
};

export default compose(
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
)(CriticalRoutes);
