import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Refresh, ViewList, PinDrop } from '@material-ui/icons';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
//Reducer
import {
  apiGetCallRequest,
  apiGetCallSuccess,
  apiCallRequest,
} from '../../reducers/api';
import { connect } from 'react-redux';
import { uiPanelDialogOpen, uiSwitchForm } from '../../reducers/ui';
import {
  mapDrawingMode,
  mapResetMap,
  mapCenterChanged,
  mapZoomChanged,
  mapSetSuggestionRoutes,
  MAP_SELECT_SUGGESSION,
  MAP_EDIT_SUGGESSION,
  SET_DATA_FOR_EDIT_ROUTE,
  CLEAR_SELECTING,
  SET_MAP_LOADING,
} from '../../reducers/map';
import {
  getSuggestionRoutes,
  getTruckLocation,
  getEditRoute,
  postConfirmRoute,
} from '../../reducers/map/api';
import { decode } from '@liberty-rider/flexpolyline';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  titlePanel: {
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: 0,
    padding: 5,
  },
  card: {
    minWidth: 275,
  },
  card_active: {
    minWidth: 275,
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 10,
  },
  pos: {
    marginBottom: 12,
  },
  rootCircular: {
    display: 'table',
    height: 'calc(100vh - 120px)',
    margin: 'auto',
  },
  centerSpin: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  center: {
    position: 'relative',
    left: '20px',
  },
  centerButton: {
    margin: 'auto',
  },
});

class RouteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      route_editing: null,
      masterData: '',
      routesData: '',
      open: false,
      truck_location: null,
      allRouteResponse: null,
      isLoading: false,
    };
  }
  componentDidMount() {
    setTimeout(() => this.handleLoadData(), 1000);
  }
  componentWillUnmount() {}
  componentWillReceiveProps(nextProps) {
    //getDataOperation({});
    if (
      this.props.suggestion_routes !== nextProps.suggestion_routes ||
      this.props.suggestion_selecting !== nextProps.suggestion_selecting ||
      this.props.suggestion_editing !== nextProps.suggestion_editing
    ) {
      this.renderMasterData(nextProps.suggestion_routes);
      this.renderRouteMaster(nextProps);
    }
  }

  handleLoadData = () => {
    if (this.props.jobDashboardId) {
      this.setState({ isLoading: true });
      this.props.SET_MAP_LOADING(true);

      this.props.mapSetSuggestionRoutes([]);

      this.props.getSuggestionRoutes({
        url:
          Configs[env].BACKEND_HOST +
          '/routeSuggestion/' +
          this.props.jobDashboardId,
        filter: {},
        callback: this.loadDataSuccess,
        callback_error: this.loadDataError,
      });
      this.props.getTruckLocation({
        url:
          Configs[env].BACKEND_HOST +
          '/truckLocation/' +
          this.props.jobDashboardId,
        filter: {},
        callback: this.setTruckLocation,
        callback_error: this.loadDataError,
      });
    }
  };

  loadDataError = () => {
    this.setState({ isLoading: false });
    this.props.SET_MAP_LOADING(false);
  };

  setTruckLocation = (response) => {
    let data = response.list[0];
    if (data) {
      this.setState({ truck_location: data });
    }
  };

  loadDataSuccess = (response) => {
    this.props.MAP_SELECT_SUGGESSION(0);
    this.setState({ allRouteResponse: response, isLoading: false });
    this.props.SET_MAP_LOADING(false);

    let dataForEditRoute = [];

    response.list[0].routes.map((route, route_idx) => {
      dataForEditRoute[route_idx] = {
        jobDashboardId: this.props.jobDashboardId,
        routeName: response.list[0].routes[route_idx].routeName,
        Legs: [],
      };
      route.Legs.map((Leg) => {
        let decodedResult = Leg.Route
          ? decode(decodeURIComponent(Leg.Route))
          : { polyline: [] };
        let decodePath = decodedResult.polyline;
        var paths = [];
        decodePath.forEach(function (p) {
          paths.push({ lat: p[0], lng: p[1] });
          // paths.push({ lat: p.lat, lng: p.lng });
        });

        let waypoints = [];
        waypoints.push(paths[0]);

        if (Leg.hasOwnProperty('subLegs')) {
          Leg.subLegs.forEach((subLeg) => {
            var decodedResultSub = subLeg.subRoute
              ? decode(decodeURIComponent(subLeg.subRoute))
              : { polyline: [] };
            let decodePath_sub = decodedResultSub.polyline;
            var paths_sub = [];
            decodePath_sub.forEach(function (p) {
              paths_sub.push({ lat: p[0], lng: p[1] });
            });

            waypoints.push(paths_sub[paths_sub.length - 1]);
          });
        } else {
          waypoints.push(paths[paths.length - 1]);
        }

        dataForEditRoute[route_idx].Legs.push({
          Seq: Leg.Seq,
          locationId: Leg.locationId,
          routeId: Leg.routeId,
          // Route: Leg.Route,
          waypoints: waypoints,
          isChangeRoute: false,
        });
        return null;
      });
      return null;
    });

    this.props.SET_DATA_FOR_EDIT_ROUTE(dataForEditRoute);
  };

  handleOpenConfirm = () => {
    this.setState({ open: true });
  };

  handleConfirm = () => {
    const route_id = this.props.suggestion_selecting;

    let post_data = JSON.parse(
      JSON.stringify(this.props.dataForEditRoute[route_id])
    );

    post_data.Legs.map((leg, idx) => {
      delete post_data.Legs[idx].Seq;
      delete post_data.Legs[idx].isChangeRoute;
      delete post_data.Legs[idx].polyline;
      delete post_data.Legs[idx].waypoints;
      return null;
    });

    if (this.props.shiftSeq !== null && this.props.shiftSeq !== '')
      post_data.shiftNo = this.props.shiftSeq;
    else post_data.shiftNo = null;

    // console.log('KAI post_data', post_data);

    this.props.postConfirmRoute({
      url: Configs[env].BACKEND_HOST + '/confirmRoute',
      objData: post_data,
      method: 'POST',
      callback: this.handleConfirmComplete,
    });

    this.setState({ open: false });

    //this.handleConfirmComplete();
  };
  handleConfirmComplete = () => {
    this.setState({ open: false });
    this.props.history.push('/dashboard/operation_dash');
  };
  handleCancel = () => {
    this.setState({ open: false });
  };
  handleClickRoute = (id) => {
    this.props.MAP_SELECT_SUGGESSION(id);
    if (id !== this.props.suggestion_selecting)
      this.props.MAP_EDIT_SUGGESSION(-1);
  };
  handleCenterTruck = () => {
    if (
      this.state.truck_location &&
      this.state.truck_location.hasOwnProperty('position')
    ) {
      this.props.CLEAR_SELECTING();
      let position = JSON.parse(
        JSON.stringify(this.state.truck_location.position)
      );
      this.props.mapCenterChanged(position);
    }
  };
  handleEditRoute = (event, id) => {
    if (
      event.target.value === 'route_editing' &&
      id !== this.props.suggestion_editing
    )
      this.props.MAP_EDIT_SUGGESSION(id);
    else this.props.MAP_EDIT_SUGGESSION(-1);
  };
  renderMenuItem() {
    if (this.props.grouplist !== undefined && this.props.grouplist !== null) {
      var menuItem = this.props.grouplist.result;
      return menuItem.map(function (data, index) {
        return (
          <MenuItem key={index} value={data.id}>
            {data.name}
          </MenuItem>
        );
      });
    } else return null;
  }
  handleCenterMap = () => {};
  renderMasterData = (suggestion_routes) => {
    // console.log('#renderMasterData suggestion_routes',suggestion_routes);
    let self = this;
    let origin =
      'FROM : ' +
      (suggestion_routes.originLocationName
        ? suggestion_routes.originLocationName
        : '-');
    let destination =
      'TO : ' +
      (suggestion_routes.destinationLocationName
        ? suggestion_routes.destinationLocationName
        : '-');

    let component = (
      <Grid item xs={12}>
        <List component='nav'>
          <ListItem button onClick={self.handleCenterMap()}>
            <ListItemIcon>
              <PinDrop />
            </ListItemIcon>
            <ListItemText inset primary={origin} />
          </ListItem>
          <ListItem button onClick={self.handleCenterMap()}>
            <ListItemIcon>
              <PinDrop />
            </ListItemIcon>
            <ListItemText inset primary={destination} />
          </ListItem>
        </List>
      </Grid>
    );
    this.setState({ masterData: component });
  };

  renderRouteMaster = (props) => {
    let component = (
      <div>
        {props.suggestion_routes.routes &&
          props.suggestion_routes.routes.length > 0 &&
          props.suggestion_routes.routes.map((route, index) => {
            return (
              <div key={'route_' + index}>
                {this.renderRouteCard(route, index, props)}
              </div>
            );
          })}
      </div>
    );
    this.setState({ routesData: component });
  };
  renderRouteCard = (route, index, props) => {
    const { classes } = props;
    let allIsCompleted = true;

    route.Legs.map((Leg) => {
      if (Leg.isCompleted === false) {
        allIsCompleted = false;
      }
      return null;
    });

    return (
      <div style={{ marginTop: '5px' }}>
        <Card
          style={
            props.suggestion_selecting === index
              ? { border: '2px solid red' }
              : {}
          }
          key={route.routeName}
          onClick={() => this.handleClickRoute(index)}
        >
          <CardContent>
            <Typography
              variant='subtitle1'
              className={styles.title}
              color={index !== 0 ? '' : 'secondary'}
              gutterBottom
            >
              Route {index + 1} : {route.routeName}{' '}
              {index !== 0 ? '' : '( Active )'}
            </Typography>
            <Typography>
              Duration Time :{' '}
              {this.renderermins(parseInt(route.durationTime / 60))}
            </Typography>
            <Typography>
              {this.rendererETA(route.ETA, route.diffTime)}
            </Typography>
            <Typography>
              {this.rendererDistance(
                route.durationDistance,
                route.diffDistance
              )}
            </Typography>
            <div style={{ border: '1px solid #CCC', padding: '5px' }}>
              {this.rendererHazard(route.hazard)}
            </div>
          </CardContent>
          <CardActions>
            {/* <FormControlLabel
              control={
                <Checkbox
                  onClick={(event) => this.handleEditRoute(event, index)}
                  checked={
                    props.suggestion_editing !== -1 &&
                    index === props.suggestion_editing
                      ? true
                      : false
                  }
                  value='route_editing'
                  color='secondary'
                  disabled={allIsCompleted}=
                />
              }
              label='Adjust'
            /> */}
              {JSON.parse(localStorage.getItem("privilege")).routeSuggestion
                    .controllable  && JSON.parse(localStorage.getItem("privilege")).routeSuggestion
                    .controllable ? (
                      <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      aria-label='Confirm'
                      className={classNames(classes.centerButton)}
                      onClick={this.handleOpenConfirm}
                      disabled={allIsCompleted}
                    >
                      CONFIRM
                    </Button>
                  ) : (
                    <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    aria-label='Confirm'
                    className={classNames(classes.centerButton)}
                    disabled
                  >
                    CONFIRM
                  </Button>
                  )}
            {/* <Button
              variant='contained'
              size='small'
              color='primary'
              aria-label='Confirm'
              className={classNames(classes.centerButton)}
              onClick={this.handleOpenConfirm}
              disabled={allIsCompleted}
            >
              CONFIRM
            </Button> */}
          </CardActions>
        </Card>
      </div>
    );
  };
  renderermins = (mins) => {
    let hour = parseInt(mins / 60) > 0 ? parseInt(mins / 60) + ' hour' : '';
    let min = (mins % 60) + ' mins';
    return hour + ' ' + min;
  };
  rendererETA = (eta, diff) => {
    let data = '-';
    if (eta !== 0) {
      let diffTxt = (diff > 0 ? '+' : '') + parseInt(diff / 60);
      data =
        moment(eta, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') +
        ' ' +
        (diff !== 0 ? '( ' + diffTxt + ' mins )' : '');
    }
    if (diff > 0)
      return (
        <Typography color='secondary'>
          Estimate Time Arrival : {data}
        </Typography>
      );
    else
      return (
        <Typography color='primary'>Estimate Time Arrival : {data}</Typography>
      );
  };
  rendererDistance = (distance, diff) => {
    let data = '-';
    if (distance !== 0) {
      let diffTxt = (diff > 0 ? '+' : '') + diff / 1000;
      data =
        distance / 1000 + ' km. ' + (diff !== 0 ? '(' + diffTxt + ' km.)' : '');
    }
    if (diff > 0)
      return (
        <Typography color='secondary'>Duration Distance : {data}</Typography>
      );
    else
      return (
        <Typography color='primary'>Duration Distance : {data}</Typography>
      );
  };
  rendererHazard = (hazards) => {
    if (hazards) {
      return hazards.map((hazard) => {
        if (!hazard) {
          return null;
        }
        return (
          <div>
            <img
              alt='harzard_category'
              width='20px'
              src={'/img/hz_' + hazard.category + '.png'}
            />
            {hazard.detail}
          </div>
        );
      });
    } else return '';
  };
  handleGotoDash = () => {
    window.location.href = '/dashboard/operation_dash';
  };
  render() {
    const { classes } = this.props;

    return (
      <div style={{ padding: '10px', overflowX: 'auto', height: 'auto' }}>
        <List component='nav' className={styles.titlePanel}>
          <ListItem style={{ paddingTop: '0px', paddingBottom: '0px' }}>
            <ListItemIcon>
              <Tooltip title='Panto truck location' placement='top'>
                <img
                  alt='truck_marker'
                  src='/img/truck_marker.png'
                  height='30'
                  onClick={this.handleCenterTruck}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary='ALTERNATIVE ROUTE ' />
            <ListItemSecondaryAction>
              <Link
                to={'/dashboard/operation_dash'}
                style={{ textDecoration: 'none' }}
              >
                <Tooltip title='Operation Dasboard' placement='top'>
                  <IconButton
                    variant='contained'
                    size='small'
                    color='secondary'
                    aria-label='Dashboard'
                    className={classNames(classes.button)}
                  >
                    <ViewList fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Link>
              <Tooltip title='Refresh' placement='top'>
                <IconButton
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Refresh'
                  disabled={this.state.isLoading}
                  className={classNames(classes.button)}
                  onClick={this.handleLoadData}
                >
                  <Refresh fontSize='small' />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        {this.state.isLoading && (
          <div className={classes.rootCircular}>
            <CircularProgress
              className={classes.centerSpin}
              size={60}
              thickness={4}
            />
          </div>
        )}
        {!this.state.isLoading && this.state.masterData}
        {!this.state.isLoading && this.state.routesData}

        <Dialog
          open={this.state.open}
          //TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>Set Route</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Confirm to set this route
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleConfirm.bind(this)}
              disabled={this.props.isLoading}
              color='primary'
              variant='contained'
            >
              {this.props.isLoading && (
                <CircularProgress
                  className={classes.center}
                  size={25}
                  thickness={2}
                />
              )}
              Confirm
            </Button>
            <Button
              onClick={this.handleCancel.bind(this)}
              disabled={this.props.isLoading}
              color='default'
              variant='outlined'
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
RouteForm.propTypes = {
  match: PropTypes.object.isRequired,
};
const mapStateToProps = function (state) {
  return {
    isLoading: state.ui.isLoading,
    formManage: state.ui.formManage,
    suggestion_routes: state.map.suggestion_routes,
    suggestion_selecting: state.map.suggestion_selecting,
    suggestion_editing: state.map.suggestion_editing,
    jobDashboardId: state.selection.jobDashboardId,
    shiftSeq: state.selection.shiftSeq,
    dataForEditRoute: state.map.dataForEditRoute,
  };
};
const mapActionsToProps = {
  DIALOG_OPEN: uiPanelDialogOpen,
  SWITCH_FORM: uiSwitchForm,
  getGroupList: apiGetCallRequest,
  successGroupList: apiGetCallSuccess,
  DRAWING_MODE: mapDrawingMode,
  RESET_MAP: mapResetMap,
  getSuggestionRoutes: getSuggestionRoutes,
  API_REQUEST: apiCallRequest,
  MAP_SELECT_SUGGESSION,
  MAP_EDIT_SUGGESSION,
  getTruckLocation,
  mapCenterChanged,
  mapSetSuggestionRoutes,
  mapZoomChanged,
  getEditRoute,
  SET_DATA_FOR_EDIT_ROUTE,
  postConfirmRoute,
  CLEAR_SELECTING,
  SET_MAP_LOADING,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(withRouter(RouteForm)));
