import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import { reduxForm, Form, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import PlaceIcon from '@material-ui/icons/Place';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

//Component
import LogisticPointSelect from './LogisticPointSelect';
import DeleteDialog from '../Ui/DeleteDialog';

//Reducer
import { uiPanelDialogOpen, STATUS_LOADING } from '../../reducers/ui';
import { required, number, maxLength100 } from '../../libs/validation';
import { apiCallRequest } from '../../reducers/api';
import { uiSwitchForm } from '../../reducers/ui';
import { addGroupLocation } from '../../reducers/ui/mapForm';
import { selectLocationGroup } from '../../reducers/ui/selectList';
import {
  mapDrawingMode,
  mapAreaType,
  mapResetMap,
  mapSetMarkers,
  mapCenterChanged,
  mapSetMarkerGroup,
} from '../../reducers/map';
//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 120,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  titlePanel: {},
  center: {
    position: 'relative',
    left: '20px',
  },
});
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

class LocationGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDialog: false,
      deleteDetail: '',
      isRoute: false,
      name: '',
      logisticsPointGroup: '',
    };
  }

  componentWillMount() {
    this.props.initialize({ ...this.props.groupForm });
  }
  componentDidMount() {
    if (this.props.type.toLowerCase() === 'edit' && this.props.groupForm) {
      let markers = this.setMarker(this.props.groupForm, true);
      this.props.SET_MARKERS(markers);
      this.props.CENTER_CHANGE(markers[0].position);
    }
  }
  componentWillUnmount() {}
  componentWillReceiveProps(nextProps) {
    //SET MARKER
    if (nextProps.markerList && nextProps.markerList.length > 0) {
      this.props.change('latitude', nextProps.markerList[0].position.lat);
      this.props.change('longitude', nextProps.markerList[0].position.lng);
    }
  }
  setMarker = (point, editable) => {
    let markerList = [];

    //Marker
    let marker = {
      id: point.id,
      position: { lat: point.latitude, lng: point.longitude },
      info: {
        name: point.name,
        logisticsPoint: point.logisticsPointGroup,
      },
      isGroup: true,
      editable: editable,
    };
    markerList.push(marker);
    return markerList;
  };
  handleSave = (form) => {
    let formData = {};
    let url = '';
    let method = 'POST';
    if (this.props.type.toLowerCase() === 'edit') {
      url = Configs[env].BACKEND_HOST + '/locationGroup/' + form.id;
      method = 'PUT';
      formData = {
        ...form,
        //id : form.id,
        logisticsPointGroup: form.logisticsPointGroup.trim(),
        latitude: parseFloat(this.props.markerList[0].position.lat),
        longitude: parseFloat(this.props.markerList[0].position.lng),
      };
    } else {
      url = Configs[env].BACKEND_HOST + '/locationGroup';
      formData = {
        ...form,
        logisticsPointGroup: form.logisticsPointGroup.trim(),
        latitude: parseFloat(this.props.markerList[0].position.lat),
        longitude: parseFloat(this.props.markerList[0].position.lng),
      };
      // this.setState({
      //   name: form.name,
      //   logisticsPointGroup: form.logisticsPointGroup.trim(),
      // });
    }
    this.setState({
      name: form.name,
      logisticsPointGroup: form.logisticsPointGroup.trim(),
    });
    delete formData.id;

    this.props.API_REQUEST({
      url: url,
      form: formData,
      method: method,
      callback: this.handleSaveComplete,
    });
  };

  handleOpenDelete = () => {
    this.setState({ deleteDialog: true });
  };
  handleComfirmDelete = () => {
    this.props.API_REQUEST({
      url:
        Configs[env].BACKEND_HOST + '/locationGroup/' + this.props.groupForm.id,
      method: 'DELETE',
      callback_error: this.handleBeforeDelete,
      callback: this.handleDeleteComplete,
    });
  };
  handleComfirmDeleteRoute = () => {
    this.props.API_REQUEST({
      url:
        Configs[env].BACKEND_HOST +
        '/locationGroup/' +
        this.props.groupForm.id +
        '?confirmed=1',
      method: 'DELETE',
      callback: this.handleDeleteComplete,
    });
  };
  handleBeforeDelete = (response) => {
    if (response.errorCode === 1104 || response.errorCode === 1105) {
      this.props.STATUS_LOADING(false);
      this.setState({
        deleteDialog: true,
        deleteDetail: response.errorMessage,
        isRoute: true,
      });
    } else {
      this.handleDeleteComplete();
    }
  };
  handleDeleteComplete = () => {
    let payload = { value: '', text: '' };
    this.props.selectLocationGroup(payload);
    this.handleReset();
  };
  handleSaveComplete = (response) => {
    if (this.props.type.toLowerCase() !== 'edit') {
      let payload = {
        value: JSON.stringify({
          id: response.id,
          name: this.state.name,
          latitude: parseFloat(this.props.markerList[0].position.lat),
          longitude: parseFloat(this.props.markerList[0].position.lng),
          logisticsPointGroup: this.state.logisticsPointGroup,
        }),
        text: this.state.name,
      };
      this.props.selectLocationGroup(payload);
    } else {
      let payload = {
        value: JSON.stringify({
          ...this.props.groupForm,
          latitude: parseFloat(this.props.markerList[0].position.lat),
          longitude: parseFloat(this.props.markerList[0].position.lng),
          name: this.state.name,
        }),
        text: this.state.name,
      };

      this.props.selectLocationGroup(payload);
    }

    this.props.reset();
    this.props.SWITCH_FORM({ page: 'list' });
    this.props.RESET_MAP(true);
  };
  handleReset = () => {
    this.props.reset();
    this.props.SWITCH_FORM({ page: 'list' });
    this.props.RESET_MAP(true);
  };

  handleComfirmCancel = () => {
    this.setState({ deleteDialog: false, deleteDetail: '', isRoute: false });
  };
  handleDrawingMode(mode, event) {
    if (mode !== 'marker') this.props.CHANGE_AREATYPE(mode);
    this.props.DRAWING_MODE(mode);
  }
  handleMarkerIconClicked() {
    if (
      this.props.markerList &&
      this.props.markerList.length > 0 &&
      this.props.markerList[0].position &&
      this.props.markerList[0].position.lat &&
      this.props.markerList[0].position.lng
    ) {
      this.props.CENTER_CHANGE(this.props.markerList[0].position);
    }
  }
  renderMenuItem() {
    if (this.props.grouplist !== null) {
      if (
        Array.isArray(this.props.grouplist.result) &&
        this.props.grouplist.result.length > 0
      ) {
        return this.props.grouplist.result.forEach((data) => {
          //debugger
          return (
            <MenuItem key={data.id} value={data.id}>
              {data.name}
            </MenuItem>
          );
        });
      } else return null;
    } else return null;
  }

  render() {
    const { handleSubmit, submitting, classes } = this.props;
    return (
      <div style={{ padding: '10px' }}>
        <Toolbar variant='dense'>
          <Typography variant='h6' gutterBottom className={classes.titlePanel}>
            {this.props.mode} LOCATION GROUP
          </Typography>
        </Toolbar>
        <Card className={classes.card}>
          <CardContent>
            <Form
              testing='form-add_edit-location-group'
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.handleSave.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <LogisticPointSelect disabled={this.props.mode === 'EDIT'} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    testing='field-location-group'
                    name='name'
                    component={renderInput}
                    label='Location Group'
                    validate={[required, maxLength100]}
                    disabled={this.props.isLoading}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color={
                      this.props.markerList &&
                      this.props.markerList.length > 0 &&
                      this.props.markerList[0].position &&
                      this.props.markerList[0].position.lat &&
                      this.props.markerList[0].position.lng
                        ? 'primary'
                        : 'default'
                    }
                    aria-label='Create Marker'
                    className={classes.button}
                    onClick={this.handleMarkerIconClicked.bind(this)}
                  >
                    <PlaceIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    testing='field-lat'
                    disabled
                    name='latitude'
                    label='Latitude'
                    component={renderInput}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required, number]}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                  <Field
                    testing='field-lng'
                    disabled
                    name='longitude'
                    label='Longitude'
                    component={renderInput}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required, number]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button
                      testing='button-save-location-group'
                      variant='contained'
                      size='small'
                      color='primary'
                      aria-label='Save'
                      disabled={this.props.isLoading}
                      type='submit'
                    >
                      {this.props.isLoading && (
                        <CircularProgress
                          className={classes.center}
                          size={25}
                          thickness={2}
                        />
                      )}
                      Save
                    </Button>
                    {this.props.groupForm.id && (
                      <Button
                        variant='contained'
                        size='small'
                        color='secondary'
                        aria-label='Delete'
                        disabled={this.props.isLoading}
                        onClick={this.handleOpenDelete}
                      >
                        {this.props.isLoading && (
                          <CircularProgress
                            className={classes.center}
                            size={25}
                            thickness={2}
                          />
                        )}
                        Delete
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      size='small'
                      aria-label='Cancel'
                      disabled={this.props.isLoading}
                      onClick={this.handleReset}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
        {this.state.deleteDialog && (
          <DeleteDialog
            title='Location Group'
            detail={
              this.state.isRoute
                ? this.state.deleteDetail
                : this.props.form.name
            }
            handleComfirmDelete={
              this.state.isRoute
                ? this.handleComfirmDeleteRoute
                : this.handleComfirmDelete
            }
            handleComfirmCancel={this.handleComfirmCancel}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = function (state, ownProps) {
  return {
    mode: state.ui.formManage.mode || ownProps.mode === 'edit' ? 'EDIT' : 'ADD',
    isLoading: state.ui.isLoading,
    location: ownProps.location,
    groupForm: state.mapForm.groupForm,
    grouplist: state.api.result,
    drawingMode: state.map.drawingMode,
    markerList: state.map.markerList,
    type: ownProps.type,
    logisticPoint: state.selection.logisticPoint,
    locationGroup: state.selection.locationGroup,
  };
};
const mapActionsToProps = {
  OPEN_DIALOG: uiPanelDialogOpen,
  API_REQUEST: apiCallRequest,
  DRAWING_MODE: mapDrawingMode,
  CHANGE_AREATYPE: mapAreaType,
  SWITCH_FORM: uiSwitchForm,
  SET_GROUP_LOCATION: addGroupLocation,
  RESET_MAP: mapResetMap,
  CENTER_CHANGE: mapCenterChanged,
  SET_MARKERS: mapSetMarkers,
  SET_MARKER_GROUP: mapSetMarkerGroup,
  STATUS_LOADING,
  selectLocationGroup,
};

const enhance = compose(
  //translate,
  reduxForm({
    form: 'locationGroupForm',
    // validate
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(LocationGroupForm);
