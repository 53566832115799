import HMap from './Components/HMap';
import DrawMarker from './Components/DrawMarker.js';
import DrawCircle from './Components/DrawCircle.js';
import DrawPolygon from './Components/DrawPolygon';
import PlaceSearch from './Components/PlaceSearch';
import Polyline from './Components/Polyline';
import InfoWindow from './Components/InfoWindow';

import Route from './Components/Route';

export const HMapDrawMarker = DrawMarker;
export const HMapDrawCircle = DrawCircle;
export const HMapDrawPolygon = DrawPolygon;
export const HMapPlaceSearch = PlaceSearch;
export const HMapPolyline = Polyline;
export const HMapInfoWindow = InfoWindow;

export const HMapRoute = Route;

export default HMap;
