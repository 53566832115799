import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'utils/token';
import Configs from 'config/config';
const env = process.env.NODE_ENV;

// Define a service using a base URL and expected endpoints
export const rootApi = createApi({
  reducerPath: 'rootApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${Configs[env].BACKEND_HOST}`,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${getToken()}`);
      return headers;
    },
  }),
  endpoints: () => ({}),
});
