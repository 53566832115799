import moment from "moment";

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = value =>
  value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

export const required = value =>
  value || typeof value === 'number' ? undefined : 'Required';

export const logisticLength = value =>
  value && (value.length <5 || value.length>11) ? `Text format is wrong` : undefined;

export const match =  (value, allValues, props) =>
  value !== allValues["password"]
    ? `This field must match with password field`
    : undefined;

export const maxLength200 = value =>
  value && value.length > 200 ? `Must be 200 characters or less` : undefined;
export const maxLength100 = value =>
  value && value.length > 100 ? `Must be 100 characters or less` : undefined;

export const betweenDate =  (value, allValues, props) =>
  moment(allValues["dateTo"]).isBefore(moment(allValues["dateFrom"]))
    ? `Date Form > Date To`
    : undefined;

export const betweenDateTime =  (value, allValues, props) =>{
  // console.log('DateTo',moment(allValues["dateTo"]+' '+allValues["timeTo"],'YYYY-MM-DD HH:mm'));
  // console.log('DateFrom',moment(allValues["dateFrom"]+' '+allValues["timeFrom"],'YYYY-MM-DD HH:mm'));
  return moment(allValues["dateTo"]+' '+allValues["timeTo"],'YYYY-MM-DD HH:mm').isBefore(moment(allValues["dateFrom"]+' '+allValues["timeFrom"],'YYYY-MM-DD HH:mm'))
    ? `Datetime Form > Datetime To`
    : undefined;
}
export const betweenRouteDate =  (value, allValues, props) =>{
  // console.log('DateTo',moment(allValues["dateTo"]+' '+allValues["timeTo"],'YYYY-MM-DD HH:mm'));
  // console.log('DateFrom',moment(allValues["dateFrom"]+' '+allValues["timeFrom"],'YYYY-MM-DD HH:mm'));
  return moment(allValues["routeDateTo"],'YYYY-MM-DD HH:mm').isBefore(moment(allValues["routeDateFrom"],'YYYY-MM-DD HH:mm'))
    ? `Datetime Form > Datetime To`
    : undefined;
}
export const diffDate =  (value, allValues, props) =>{
  return moment(allValues["dateTo"]).diff(moment(allValues["dateFrom"]),'months') > 0 
    ? `Range exceed more than 1 month `
    : undefined;
}