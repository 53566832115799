import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Map from './Map';
import MenuTabs from './MenuTabs';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';

//Reducer
import { setActiveMenuListItem } from '../../reducers/ui';
import { setSidebarVisibility } from '../../reducers/ui';
import { searchHazardRoute } from '../../reducers/search';
import { mapSetImpactHazard, mapSetRunSeq } from '../../reducers/map';

const MIN_HEIGHT = 25;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  gridForm: {
    padding: '0px',
  },
  subheader: {
    width: '100%',
  },
});

class ImpactHazard extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.paneSizeRef = React.createRef();

    this.state = {};
  }
  componentWillMount() {
    this.props.mapSetImpactHazard([]);
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    const { routeCode } = this.props.match.params;
    const { runSeq } = this.props.match.params;
    if (routeCode && mapSetRunSeq && this.props.search_hazard_route) {
      this.props.searchHazardRoute({
        ...this.props.search_hazard_route,
        routeCode: routeCode,
        runSeq: runSeq,
      });
    }

    //this.props.selectJobDashboardId(jobDashboardId);
    //this.props.setActiveMenuListItem({...this.props.activeMenuListItem,subLink:'/route_suggestion'});
    setSidebarVisibility(false);
  }
  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        '#locationMap .ag-header-cell-label'
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }

  methodFromParent() {}
  render() {
    const { routeCode } = this.props.match.params;
    const { runSeq } = this.props.match.params;

    return (
      <div
        style={{
          boxSizing: 'border-box',
          height: 'calc(100vh - 52px)',
          width: '100%',
        }}
      >
        <SplitPane
          split='vertical'
          minSize={50}
          defaultSize={400}
          onChange={(size) => {
            if (
              this.paneSizeRef.current &&
              size[0] === this.paneSizeRef.current
            ) {
              return;
            }
            if (this.mapRef.current) {
              this.mapRef.current.resize();
            }
            this.paneSizeRef.current = size[0];
          }}
        >
          <Pane size={{ width: 500 }}>
            <MenuTabs firstOpen={routeCode && runSeq ? 1 : 0} />
          </Pane>
          <Pane>
            <div style={{ height: 'calc(100vh - 52px)' }}>
              <Map mapRef={this.mapRef} />
              {/* <BaseMap isReset={this.props.isReset} /> */}
            </div>
          </Pane>
        </SplitPane>
      </div>
      // </div>
    );
  }
}
ImpactHazard.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isReset: state.map.isReset,
  formManage: state.ui.formManage,
  search_hazard_route: state.search.search_hazard_route,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveMenuListItem: (payload) =>
      dispatch(setActiveMenuListItem(payload)),
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    searchHazardRoute: (payload) => dispatch(searchHazardRoute(payload)),
    mapSetImpactHazard: (payload) => dispatch(mapSetImpactHazard(payload)),
    dispatch,
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(withRouter(ImpactHazard));
