import React, { useEffect, useState } from 'react';
import Configs from 'config/config';
import { useSelector } from 'react-redux';
import Mapview, { HMapPolyline, HMapDrawMarker } from 'Components/HereMap';
import {
  markerPinNumberSvgIcon,
  markerCircleSvgIcon,
  waypointPinNumberSvgIcon,
} from 'Components/Common/MarkerIconRenderer';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import RoutesViewBoxes from './RoutesViewBoxes';
import Loading from 'Components/HereMap/Components/Loading';

const env = process.env.NODE_ENV;

const RouteMasterMapList = (props) => {
  const { mapRef } = props;
  const [bbox, setBbox] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);

  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });

  const marker_origin = useSelector((state) => {
    return state.map.marker_origin;
  });
  const marker_desc = useSelector((state) => {
    return state.map.marker_desc;
  });
  const master_routes = useSelector((state) => {
    return state.map.master_routes;
  });

  const candidate_selecting = useSelector((state) => {
    return state.map.candidate_selecting;
  });

  const masterPolylineList = useSelector((state) => {
    return state.map.masterPolylineList;
  });

  useEffect(() => {
    if (candidate_selecting) {
      setSelectedRoute({
        polyline: candidate_selecting?.routes?.MergeRoute,
        waypoints: candidate_selecting?.routes?.waypoints,
      });
    }
  }, [candidate_selecting]);

  useEffect(() => {
    if (
      marker_origin &&
      marker_origin.lat &&
      marker_origin.lng &&
      marker_desc &&
      marker_desc.lat &&
      marker_desc.lng
    ) {
      setBbox(
        getBbox([
          [marker_origin.lat, marker_origin.lng],
          [marker_desc.lat, marker_desc.lng],
        ])
      );
    }
  }, [marker_origin, marker_desc]);
  return (
    <>
      {map_loading && <Loading />}
      <RoutesViewBoxes routesview={master_routes} />
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={bbox}
      >
        {marker_origin && marker_origin.lat && marker_origin.lng && (
          <HMapDrawMarker
            marker={marker_origin}
            options={{
              icon: markerPinNumberSvgIcon('A', 30, 40, '#4c00b0', '#ffffff'),
            }}
          />
        )}
        {marker_desc && marker_desc.lat && marker_desc.lng && (
          <HMapDrawMarker
            marker={marker_desc}
            options={{
              icon: markerPinNumberSvgIcon('B', 30, 40, '#ff0000', '#ffffff'),
            }}
          />
        )}
        {masterPolylineList &&
          masterPolylineList.map((p, idx) => {
            return (
              <HMapPolyline
                key={idx}
                polyline={p.polyline}
                options={{ color: '#a0a0a0' }}
              />
            );
          })}

        {selectedRoute?.polyline && (
          <HMapPolyline
            polyline={selectedRoute.polyline}
            options={{ isArrowStyle: true }}
          />
        )}
        {selectedRoute?.waypoints &&
          selectedRoute?.waypoints.map((p, wpIdx) => {
            return (
              <HMapDrawMarker
                key={wpIdx}
                marker={{ lat: p[0], lng: p[1] }}
                options={{
                  icon: markerCircleSvgIcon(14, 14, '#404040'),
                  anchor: { x: 7, y: 7 },
                }}
              />
            );
          })}
      </Mapview>
    </>
  );
};
export default RouteMasterMapList;
