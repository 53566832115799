import React, { useRef, useState, useEffect, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { useSelector, useDispatch } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

//Component
import MasterRouteBtnRenderer from './Renderer/MasterRouteBtnRenderer';
import SecToTimeRenderer from '../Ui/Renderer/SecToTimeRenderer';
import DistanceRenderer from '../Ui/Renderer/DistanceRenderer';
import TollRenderer from '../Ui/Renderer/TollRenderer';
//Reducer
import { apiCallRequest } from '../../reducers/api';
import { getMasterRoutes } from '../../reducers/map/api';
import {
  MAP_SELECT_CANDIDATE,
  SELECT_MASTER_OR_CANDIDATE,
} from '../../reducers/map';
import store from 'reducers/rootReducer';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin: 0,
    width: 200,
  },
});
const MasterRoutes = (props) => {
  const gridApiRef = useRef();
  const gridColumnApiRef = useRef();
  const [rowData, setRowData] = useState([]);
  const dispatch = useDispatch();

  const logisticRouteForm = useSelector((state) => {
    return state.mapForm.logisticRouteForm;
  });

  const master_routes = useSelector((state) => {
    return state.map.master_routes;
  });

  const candidate_selecting = useSelector((state) => {
    return state.map.candidate_selecting;
  });

  const tableConfigs = {
    isToll: false,
    columnDefs: [
      {
        headerName: '',
        field: 'id',
        cellRenderer: 'masterBtnRenderer',
        width: 65,
        autoHeight: true,
      },
      {
        width: 100,
        headerName: 'ROUTE',
        field: 'name',
        rowDrag: true,
      },
      {
        headerName: 'DISTANCE',
        field: 'routes.TotalDistance',
        cellRenderer: 'distanceRenderer',
        width: 75,
        autoHeight: true,
      },
      {
        headerName: 'DURATION',
        field: 'routes.TotalTime',
        cellRenderer: 'secToTimeRenderer',
        width: 100,
        autoHeight: true,
      },
      {
        headerName: 'TOLL',
        field: 'routes.TollUsage',
        width: 50,
        autoHeight: true,
        cellRenderer: 'tollRenderer',
      },
      {
        headerName: 'WAYPOINTS',
        field: 'routes.waypoints',
        width: 50,
        autoHeight: true,
        valueFormatter: (params) => {
          return params && params?.value && Array.isArray(params?.value)
            ? params?.value.length
            : 0;
        },
      },
    ],
    rowData: null,
    context: { componentParent: this },
    defaultColDef: { sortable: true, resizable: true },
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    frameworkComponents: {
      tollRenderer: TollRenderer,
      masterBtnRenderer: MasterRouteBtnRenderer,
      secToTimeRenderer: SecToTimeRenderer,
      distanceRenderer: DistanceRenderer,
    },
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading...</span>',
  };

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;
  };

  const loadingComplete = () => {
    if (gridApiRef.current) gridApiRef.current.hideOverlay();
  };
  const handleLoadData = useCallback(() => {
    if (gridApiRef.current) gridApiRef.current.showLoadingOverlay();

    if (logisticRouteForm.logisticsRouteId) {
      dispatch(
        getMasterRoutes({
          url: Configs[env].BACKEND_HOST + '/routeCond',
          filter: {
            logisticsRouteId: logisticRouteForm.logisticsRouteId,
          },
          callback: loadingComplete,
          callback_error: loadingComplete,
        })
      );
    }
  }, [dispatch, logisticRouteForm]);

  useEffect(() => {
    handleLoadData();
  }, [handleLoadData]);

  useEffect(() => {
    if (master_routes) {
      setRowData(master_routes);
    }
  }, [master_routes]);

  const focusRow = useCallback((candidate_selecting) => {
    if (gridApiRef.current) {
      gridApiRef.current.deselectAll();
      gridApiRef.current.forEachNode(function (node) {
        if (node.data.id === candidate_selecting.id) {
          node.setSelected(true, true);
          gridApiRef.current.ensureIndexVisible(node.rowIndex, {
            position: 'top',
          });
        }
      });
    }
  }, []);

  useEffect(() => {}, [candidate_selecting, focusRow]);

  const updatePriority = (id, priority) => {
    dispatch(
      apiCallRequest({
        url: Configs[env].BACKEND_HOST + '/routePriority/' + id,
        form: { priority: priority },
        method: 'PUT',
        callback: handleLoadData,
      })
    );
  };

  const handleSelectionChanged = (node) => {
    try {
      var selectedRows = node.api.getSelectedRows();
      dispatch(MAP_SELECT_CANDIDATE(selectedRows[0]?.routes));
      dispatch(SELECT_MASTER_OR_CANDIDATE(1));
    } catch (e) {}
  };
  const onRowDragEnter = (e) => {};
  const onRowDragEnd = (e) => {
    if (gridApiRef.current) gridApiRef.current.showLoadingOverlay();

    gridApiRef.current.deselectAll();
    gridApiRef.current.forEachNode(function (node) {
      updatePriority(node.data.id, node.childIndex + 1);
    });
  };

  const onRowDragMove = (e) => {};
  const onRowDragLeave = (e) => {};

  return (
    <div style={{ width: '100%', height: '180px', padding: '5px' }}>
      <DialogActions>
        <Typography variant='subheading'>MASTER ROUTES : </Typography>
      </DialogActions>
      <div
        className='ag-theme-balham'
        style={{
          height: '150px',
          width: '100%',
        }}
      >
        <AgGridReact
          reactNext={true}
          reduxStore={store}
          singleClickEdit={true}
          columnDefs={tableConfigs.columnDefs}
          defaultColDef={tableConfigs.defaultColDef}
          rowDragManaged={true}
          animateRows={true}
          enableFilter={true}
          rowData={rowData}
          rowSelection='single'
          overlayLoadingTemplate={tableConfigs.overlayLoadingTemplate}
          onRowClicked={handleSelectionChanged}
          frameworkComponents={tableConfigs.frameworkComponents}
          onGridReady={onGridReady}
          onRowDragEnter={onRowDragEnter}
          onRowDragEnd={onRowDragEnd}
          onRowDragMove={onRowDragMove}
          onRowDragLeave={onRowDragLeave}
        />
      </div>
    </div>
  );
};

export default compose(withStyles(styles))(MasterRoutes);
