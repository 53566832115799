import React, { useEffect, useState } from 'react';
import Configs from 'config/config';
import { useSelector } from 'react-redux';
import Mapview, {
  HMapPlaceSearch,
  HMapDrawCircle,
  HMapDrawMarker,
  HMapDrawPolygon,
} from 'Components/HereMap';
import { markerPinSvgIcon } from 'Components/Common/MarkerIconRenderer';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import Loading from 'Components/HereMap/Components/Loading';

const gUtils = require('@googlemaps/google-maps-services-js/dist/util');
const env = process.env.NODE_ENV;

const LocationMasterMap = (props) => {
  const { mapRef } = props;
  const [bbox, setBbox] = useState(null);

  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });

  const markerGroup = useSelector((state) => {
    return state.map.markerGroup;
  });

  const locationPoints = useSelector((state) => {
    return state.map.logistic_point;
  });

  const markerSelecting = useSelector((state) => {
    return state.map.marker_selecting;
  });

  useEffect(() => {
    let bounds = [];
    if (
      markerGroup &&
      markerGroup?.position &&
      markerGroup?.position?.lat &&
      markerGroup?.position?.lng
    ) {
      bounds.push([markerGroup.position.lat, markerGroup.position.lng]);
    }

    if (locationPoints && locationPoints.length > 0) {
      locationPoints.forEach((p) => {
        if (p) {
          bounds.push([p.latitude, p.longitude]);
        }
      });
    }

    if (bounds.length > 0) {
      setBbox(getBbox(bounds));
    }
  }, [markerGroup, locationPoints]);

  const renderCircle = (c) => {
    return (
      <>
        {renderMarkerPoint(c)}
        <HMapDrawCircle
          circle={{
            center: { lat: c.circleLatitude, lng: c.circleLongitude },
            radius: c.radius,
          }}
          options={{ style: { fillColor: 'rgba(0, 85, 170, 0.4)' } }}
        />
      </>
    );
  };
  const renderMarkerPoint = (m) => {
    return (
      <HMapDrawMarker
        marker={{ lat: m.latitude, lng: m.longitude }}
        options={{
          icon: markerPinSvgIcon(30, 40, '#3B538B', '#ffffff'),
          info: `<div style="font-size:12px;width:150px"><b>Information:</b><br/>Logistics Point: ${m.logisticsPoint}<br/><b>Name:</b><br/>${m.name}</div>`,
          isInfoOpen: markerSelecting === m.id,
        }}
      />
    );
  };
  const renderPolygon = (p) => {
    let paths = gUtils.decodePath(p.polygon);
    return (
      <>
        {renderMarkerPoint(p)}
        <HMapDrawPolygon
          polygon={paths}
          options={{
            style: {
              fillColor: 'rgba(0, 85, 170, 0.4)',
              strokeColor: 'rgba(0, 85, 170, 0.6)',
            },
          }}
        />
      </>
    );
  };
  return (
    <>
      {map_loading && <Loading />}
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={bbox}
      >
        {locationPoints.map((p) => {
          if (p.type === 'circle') {
            return renderCircle(p);
          }
          if (p.type === 'polygon') {
            return renderPolygon(p);
          }
          return <div />;
        })}
        {markerGroup &&
          markerGroup.position &&
          markerGroup.position.lat &&
          markerGroup.position.lng && (
            <HMapDrawMarker
              marker={{
                lat: markerGroup.position.lat,
                lng: markerGroup.position.lng,
              }}
              options={{
                icon: markerPinSvgIcon(30, 40, '#019875', '#ffffff'),
                info: `<div style="font-size:12px;width:150px"><b>Logistics Point Group:</b><br/>${markerGroup?.info?.logisticsPoint}<br/><b>Location Group:</b><br/>${markerGroup?.info?.name}</div>`,
              }}
            />
          )}
        <HMapPlaceSearch />
      </Mapview>
    </>
  );
};
export default LocationMasterMap;
