import React, { Component } from 'react';
import moment from 'moment';
import { reduxForm, Form, Field } from 'redux-form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PlaceIcon from '@material-ui/icons/Place';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import Select from '@material-ui/core/Select';
import PanoramaHorizontalIcon from '@material-ui/icons/PanoramaHorizontal';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Loading from '../Ui/Loading';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Toolbar from '@material-ui/core/Toolbar';
import compose from 'recompose/compose';
import DialogActions from '@material-ui/core/DialogActions';
import TimeInput from 'material-ui-time-picker';
import CircularProgress from '@material-ui/core/CircularProgress';

import 'rc-time-picker/assets/index.css';
//Component
import UploadFileInput from '../Ui/UploadFileInput';
import MenuItem from '@material-ui/core/MenuItem';
import CategorySelect from './CategorySelect';

import 'react-widgets/dist/css/react-widgets.css';
//Lib
import { required, number, betweenDateTime } from '../../libs/validation';
//Reducer
import {
  apiCallRequest,
  apiGetCallRequest,
  resetAPIResult,
} from '../../reducers/api';
import { uploadFile } from '../../reducers/upload';
import {
  uiPanelDialogOpen,
  uiSwitchForm,
  SET_SNACKBAR,
} from '../../reducers/ui';
import { mapDrawingMode, mapResetMap, mapAreaType } from '../../reducers/map';
import { getWarningTypes } from '../../reducers/map/api';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;
const gUtils = require('@googlemaps/google-maps-services-js/dist/util');

const classes = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {},
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
  },
  center: {
    position: 'relative',
    left: '20px',
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderSelect = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <Select
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

class ManageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningTypeId: this.props.hazardForm.warningTypeId,
      warningMouseOver: false,
      drawingMode: 'marker',
      marker: null,
      now: new Date(),
      timeFromSelect: new Date(),
      timeToSelect: new Date('2015-03-25T23:59:59'),
      category: 0,
      time: moment(),
    };
  }

  componentWillMount() {
    this.getWarningItem();

    if (this.props.hazardForm) {
      this.props.initialize({ ...this.props.hazardForm });
      //Set time for edit
      if (this.props.hazardForm.id) {
        this.setState({
          timeFromSelect: new Date(
            this.props.hazardForm.dateFrom +
              'T' +
              this.props.hazardForm.timeFrom
          ),
          timeToSelect: new Date(
            this.props.hazardForm.dateTo + 'T' + this.props.hazardForm.timeTo
          ),
        });
      }
    }
  }
  componentDidMount() {
    this.props.DRAWING_MODE('marker');
    //this.props.RESET_MAP(true);
    //this.props.DRAWING_OPTION(this.state.drawingOptions);
  }
  componentWillReceiveProps(nextProps, props) {
    if (
      nextProps.hazardForm &&
      nextProps.hazardForm !== this.props.hazardForm
    ) {
      this.props.initialize({ ...nextProps.hazardForm });
      this.props.change({ ...nextProps.hazardForm });
    }

    //SET MARKER
    if (nextProps.markerList && nextProps.markerList.length > 0) {
      this.props.change('latitude', nextProps.markerList[0].position.lat);
      this.props.change('longitude', nextProps.markerList[0].position.lng);
    }
  }
  getType = () => {
    if (this.props.polygonList[0]) return 'polygon';
    else if (this.props.circleList[0]) return 'circle';
    else return '';
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleWarningTypeChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSave = (form) => {
    if (
      (this.getType() === 'circle' && this.props.circleList[0]) ||
      (this.getType() === 'polygon' && this.props.polygonList[0])
    ) {
      let mediaFile = [];
      mediaFile = [...this.props.hazard_photos];
      if (this.props.hazard_vdo) mediaFile.push(this.props.hazard_vdo);

      let formData = {
        ...form,
        radius: this.props.circleList[0]
          ? parseFloat(this.props.circleList[0].radius.toFixed(3))
          : null,
        circleLatitude: this.props.circleList[0]
          ? parseFloat(this.props.circleList[0].center.lat.toFixed(6))
          : null,
        circleLongitude: this.props.circleList[0]
          ? parseFloat(this.props.circleList[0].center.lng.toFixed(6))
          : null,
        polygon: this.props.polygonList[0]
          ? gUtils.encodePath(this.props.polygonList[0].path)
          : '',
        type: this.getType(),
        dateFrom: moment(
          form.dateFrom + ' ' + form.timeFrom,
          'YYYY-MM-DD HH:mm'
        ).format('YYYY/MM/DD HH:mm:ss'),
        dateTo: moment(
          form.dateTo + ' ' + form.timeTo,
          'YYYY-MM-DD HH:mm'
        ).format('YYYY/MM/DD HH:mm:ss'),
        mediaUrl: mediaFile,
      };
      delete formData.category_id;
      delete formData.timeFrom;
      delete formData.timeTo;

      if (formData.warningTypeId === '') delete formData.warningTypeId;

      if (this.props.mode.toUpperCase() === 'EDIT') {
        let id = formData.id;
        delete formData.id;

        this.props.API_REQUEST({
          url: Configs[env].BACKEND_HOST + '/hazard/' + id,
          form: { ...formData },
          method: 'PUT',
          callback: this.handleReset.bind(this),
        });
      } else {
        this.props.API_REQUEST({
          url: Configs[env].BACKEND_HOST + '/hazard',
          form: { ...formData },
          method: 'POST',
          callback: this.handleReset.bind(this),
        });
      }
    } else {
      this.props.SET_SNACKBAR({
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'Require area Circle or Polygon',
        snackbarDuration: 5000,
      });
    }
  };
  renderWarningEdit() {
    return (
      <Button
        variant='contained'
        size='small'
        color='primary'
        aria-label='Edit'
        className={classNames(this.props.classes.button)}
        onClick={this.handleWarningEdit.bind(this)}
      >
        EDIT
      </Button>
    );
  }

  getWarningItem() {
    this.props.GET_WARNING_TYPES({
      url: Configs[env].BACKEND_HOST + '/warningType',
      method: 'GET',
      filter: { status: true },
    });
  }
  renderWarningItem() {
    if (this.props.warning_types) {
      var menuItem = [];
      for (let index in this.props.warning_types) {
        let item = this.props.warning_types[index];
        menuItem.push({ id: item.id, description: item.description });
      }
      return menuItem.map(function (data, index) {
        return (
          <MenuItem key={index} value={data.id} detail={data.description}>
            {data.description}
          </MenuItem>
        );
      });
    } else return null;
  }
  handleWarningEdit = (event) => {
    this.props.SWITCH_FORM({ page: 'warning_type', mode: this.props.mode });
  };
  handleTimeFromChange = (data) => {
    this.props.change('timeFrom', moment(data).format('HH:mm'));
    this.setState({ timeFromSelect: data });
  };
  handleTimeToChange = (data) => {
    this.props.change('timeTo', moment(data).format('HH:mm'));
    this.setState({ timeToSelect: data });
  };
  renderGroupButton() {
    return (
      <Grid item xs={12}>
        <Button
          variant='contained'
          size={'small'}
          color={this.props.drawingMode === 'circle' ? 'secondary' : 'default'}
          aria-label='Create Circle'
          className={this.props.classes.button}
          onClick={this.handleDrawingMode.bind(this, 'circle')}
        >
          <PanoramaFishEyeIcon />
        </Button>
        <Button
          variant='contained'
          size={'small'}
          color={this.props.drawingMode === 'polygon' ? 'secondary' : 'default'}
          aria-label='Create Polygon'
          className={this.props.classes.button}
          onClick={this.handleDrawingMode.bind(this, 'polygon')}
        >
          <PanoramaHorizontalIcon />
        </Button>
      </Grid>
    );
  }
  handleChangeDateFrom = (e) => {
    this.setState({ dateForm: e.target.value });
  };
  handleChangeDateTo = (e) => {
    this.setState({
      //dateTo: moment(date).format("YYYY-MM-DD"),
    });
  };
  handleDateChangeTimeTo = (e) => {
    this.props.change('timeTo', moment(e).format('HH:mm:ss'));
  };
  handleClickBack(event) {
    this.handleReset();
  }
  handleReset() {
    this.props.SWITCH_FORM({ page: 'list' });
    this.props.reset();
    //reset map here
    //...
    this.props.RESET_MAP(true);
  }

  handleDrawingMode(mode, event) {
    if (mode === this.state.drawingMode) {
      this.setState({ drawingMode: null }, this.setMode);
    } else {
      this.setState({ drawingMode: mode }, this.setMode);
    }
  }
  setMode() {
    if (this.state.drawingMode !== 'marker')
      this.props.CHANGE_MAP_TYPE(this.state.drawingMode);
    this.props.DRAWING_MODE(this.state.drawingMode);
  }
  handleTimeChange = (time) => {
    this.setState({ time: time });
  };
  handleClearWarningType = () => {
    this.setState({ warningTypeId: '' });
    this.props.change('warningTypeId', '');
  };
  warningMouseOver = () => {
    this.setState({ warningMouseOver: true });
  };

  warningMouseOut = () => {
    this.setState({ warningMouseOver: false });
  };

  render() {
    const { handleSubmit, submitting, classes } = this.props;

    return (
      <div style={{ padding: '10px' }}>
        {this.props.isLoading && <Loading />}
        <Toolbar variant='dense'>
          <Typography variant='h6' gutterBottom>
            {this.props.mode.toUpperCase()} HAZARD POINT
          </Typography>
        </Toolbar>
        <Card className={classes.card}>
          <CardContent>
            <Form
              //style={{ overflowY:"scroll"}}
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.handleSave.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CategorySelect isSearch={false} />
                </Grid>

                <Grid item xs={8}>
                  <FormControl
                    fullWidth
                    className={classes.formControl}
                    onChange={this.handleChange}
                    onMouseOver={this.warningMouseOver}
                    // onMouseOut={this.warningMouseOut}
                  >
                    <InputLabel shrink htmlFor='warning-field'>
                      WARNING TYPE
                    </InputLabel>
                    <Field
                      name='warningTypeId'
                      fullWidth
                      component={renderSelect}
                      label='WARNING TYPE'
                      InputLabelProps={{
                        shrink: true,
                        id: 'warning-field',
                      }}
                      margin='dense'
                      className={classes.textField}
                      onChange={this.handleWarningTypeChange}
                      validate={
                        this.props.category_select.value === 'warning'
                          ? [required]
                          : []
                      }
                      /*endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClearWarningType}
                          >
                            <Close fontSize="small"/> 
                          </IconButton>
                        </InputAdornment>
                      }*/
                    >
                      {this.renderWarningItem()}
                    </Field>
                    {/* warning: "02 ถนนเลนสวน"
                    warningTypeId: 2 */}
                    {this.state.warningTypeId && (
                      <img
                        alt='clear-img'
                        onClick={this.handleClearWarningType}
                        style={{
                          cursor: 'pointer',
                          position: 'absolute',
                          right: '23px',
                          top: '23px',
                        }}
                        height='15px'
                        width='15px'
                        src='/img/clear_x.png'
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  {this.renderWarningEdit()}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label='LOCATION'
                    name='location'
                    fullWidth
                    component={renderInput}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required]}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Field
                    name='dateFrom'
                    label='DATE FROM'
                    type='date'
                    component={renderInput}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required, betweenDateTime]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='timeFrom'
                    type='hidden'
                    component={renderInput}
                    className={classes.textField}
                    props={{ format: '24hr' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required]}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink htmlFor='timefrom-field'>
                      TIME FROM
                    </InputLabel>
                    <TimeInput
                      mode='24h'
                      name='timeFromSelect'
                      label='Time picker'
                      value={this.state.timeFromSelect}
                      InputLabelProps={{
                        shrink: true,
                        id: 'timefrom-field',
                      }}
                      margin='dense'
                      onChange={this.handleTimeFromChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='dateTo'
                    label='DATE TO'
                    type='date'
                    component={renderInput}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required, betweenDateTime]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='timeTo'
                    type='hidden'
                    component={renderInput}
                    className={classes.textField}
                    props={{ format: '24hr' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required]}
                  />
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink htmlFor='timeto-field'>
                      TIME TO
                    </InputLabel>
                    <TimeInput
                      mode='24h'
                      name='timeToSelect'
                      label='Time picker'
                      value={this.state.timeToSelect}
                      InputLabelProps={{
                        shrink: true,
                        id: 'timeto-field',
                      }}
                      margin='dense'
                      onChange={this.handleTimeToChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='detail'
                    label='DETAIL'
                    component={renderInput}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    margin='dense'
                    validate={[required]}
                  />
                </Grid>

                <Grid item xs={2}>
                  <IconButton
                    color={
                      this.props.drawingMode == 'marker'
                        ? 'secondary'
                        : 'primary'
                    }
                    aria-label='Create Marker'
                    className={classes.button}
                    onClick={this.handleDrawingMode.bind(this, 'marker')}
                  >
                    <PlaceIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    testing='field-logistics-point-lat'
                    disabled
                    name='latitude'
                    label='Latitude'
                    component={renderInput}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required, number]}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                  <Field
                    testing='field-logistics-point-lng'
                    disabled
                    name='longitude'
                    label='lng'
                    component={renderInput}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    validate={[required, number]}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Button
                    variant='contained'
                    size={'small'}
                    color={
                      this.props.drawingMode === 'circle'
                        ? 'secondary'
                        : 'default'
                    }
                    aria-label='Create Circle'
                    className={classes.button}
                    onClick={this.handleDrawingMode.bind(this, 'circle')}
                  >
                    <PanoramaFishEyeIcon />
                  </Button>
                  <Button
                    variant='contained'
                    size={'small'}
                    color={
                      this.props.drawingMode === 'polygon'
                        ? 'secondary'
                        : 'default'
                    }
                    aria-label='Create Polygon'
                    className={classes.button}
                    onClick={this.handleDrawingMode.bind(this, 'polygon')}
                  >
                    <PanoramaHorizontalIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <UploadFileInput maxFiles={4} fileType={'video'} />
                </Grid>
              </Grid>
              <DialogActions>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Save'
                  className={classes.button}
                  disabled={this.props.isLoading}
                  type='submit'
                >
                  {this.props.isLoading && (
                    <CircularProgress
                      className={classes.center}
                      size={25}
                      thickness={2}
                    />
                  )}
                  SAVE
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  color='secondary'
                  aria-label='Add'
                  disabled={this.props.isLoading}
                  className={classNames(classes.button)}
                  onClick={this.handleClickBack.bind(this)}
                >
                  BACK
                </Button>
              </DialogActions>
            </Form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function (state, ownProps) {
  return {
    isLoading: state.ui.isLoading,
    apiFetchResult: state.api.result,
    dialogOpen: state.ui.dialogOpen,
    drawingMode: state.map.drawingMode,
    areaType: state.map.areaType,
    hazardForm: state.mapForm.hazardForm,
    markerList: state.map.markerList,
    circleList: state.map.circleList,
    polygonList: state.map.polygonList,
    warning_types: state.map.warning_types,
    manageForm: state.mapForm.manageForm,
    category_select: state.selection.category,

    hazard_photos: state.map.hazard_photos,
    hazard_vdo: state.map.hazard_vdo,

    mode: state.ui.formManage.mode,
    pointDeatailID: state.ui.formManage.editID || '',

    logistic: ownProps.logistic,
    location: ownProps.location,

    getDataPointDetail: state.api.mapResult.get('POINT_DETAIL'),
    uploadURL: state.upload.url,
    imageFiles: state.upload.imageFiles,
    videoFiles: state.upload.videoFiles,
  };
};
const mapActionsToProps = {
  GET_WARNING_TYPES: getWarningTypes,
  API_REQUEST: apiCallRequest,
  OPEN_DIALOG: uiPanelDialogOpen,
  SWITCH_FORM: uiSwitchForm,
  DRAWING_MODE: mapDrawingMode,
  CHANGE_MAP_TYPE: mapAreaType,
  RESET_MAP: mapResetMap,
  GET_POINT_DETAIL: apiGetCallRequest,
  resetAPIResult,
  uploadFile,
  SET_SNACKBAR,
};

const enhance = compose(
  reduxForm({
    form: 'manageHazardForm',
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(classes)
);
export default enhance(ManageForm);
