import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form';
import { searchRequest } from '../../reducers/ui/autoSuggest';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

const renderHiddenField = (field) => {
  return (
    <div className='input-row'>
      <input {...field.input} type='hidden' value={field.value} />
    </div>
  );
};
function renderSuggestion(suggestion, { query, isHighlighted }) {
  // const matches = match(suggestion.name, query);
  // const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component='div'>
      {/* <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div> */}
      <strong
        style={{ fontWeight: 300 }}
      >{`${suggestion.logisticsPointGroup}: ${suggestion.name}`}</strong>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

class AutoSuggestInput extends React.Component {
  state = {
    single: '',
    popper: '',
    suggestions: [],
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.props.searchRequest(value);
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };
  onSuggestionSelected = (event, { suggestion }) => {
    this.props.handleChange(JSON.stringify(suggestion));
  };
  handleAutoChange =
    (name) =>
    (event, { newValue }) => {
      this.setState({
        [name]: newValue,
      });
    };

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.props.suggest,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.onSuggestionSelected,
      getSuggestionValue,
      renderSuggestion,
    };

    return (
      <div className={classes.root}>
        <div className={classes.divider} />
        <Field name={this.props.name} component={renderHiddenField} />
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            label: this.props.label,
            placeholder: '',
            value: this.state.single,
            onChange: this.handleAutoChange('single'),
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={(options) => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    suggest: state.autoSuggest.suggest || [],
  };
};
const mapActionsToProps = {
  searchRequest,
};
AutoSuggestInput.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};
const enhance = compose(
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(AutoSuggestInput);
