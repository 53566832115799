import React, { useEffect, useState } from 'react';
import Configs from 'config/config';
import { useSelector, useDispatch } from 'react-redux';
import Mapview, {
  HMapPlaceSearch,
  HMapDrawCircle,
  HMapDrawMarker,
  HMapPolyline,
  HMapInfoWindow,
  HMapDrawPolygon,
  HMapRoute,
} from 'Components/HereMap';
import {
  markerPinNumberSvgIcon,
  truckIcon,
  hazardIcon,
} from 'Components/Common/MarkerIconRenderer';
import { SET_DATA_FOR_EDIT_ROUTE } from 'reducers/map';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import Loading from 'Components/HereMap/Components/Loading';
import { encode } from '@liberty-rider/flexpolyline';
import ImgVdoPreview from '../HazardPoints/ImgVdoPreview';
import { apiCallRequest } from 'reducers/api';

const env = process.env.NODE_ENV;

const RouteSuggestionMap = (props) => {
  const { mapRef, jobDashboardId } = props;
  const [selectedHazardId, setSelectedHazardId] = useState(null);
  const [selectedPolylines, setSelectedPolylines] = useState([]);
  const [editingPolylines, setEditingPolylines] = useState(null);
  const [truckLicense, setTruckLicense] = useState('');
  const [bbox, setBbox] = useState(null);
  const dispatch = useDispatch();

  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });

  const harzardPoints = useSelector((state) => {
    return state.map.markerList;
  });

  const hazardCircleList = useSelector((state) => {
    return state.map.circleList;
  });

  const hazardPolygonList = useSelector((state) => {
    return state.map.polygonList;
  });

  const suggestionPolylineList = useSelector((state) => {
    return state.map.suggestionPolylineList;
  });

  const truck_location = useSelector((state) => {
    return state.map.truck_location;
  });

  const dropPointList = useSelector((state) => {
    return state.map.dropPointList;
  });

  const center = useSelector((state) => {
    return state.map.center;
  });

  const suggestion_selecting = useSelector((state) => {
    return state.map.suggestion_selecting;
  });
  const dataForEditRoute = useSelector((state) => {
    return state.map.dataForEditRoute;
  });

  const suggestion_editing = useSelector((state) => {
    return state.map.suggestion_editing;
  });
  const suggestion_routes = useSelector((state) => {
    return state.map.suggestion_routes;
  });

  useEffect(() => {
    if (suggestion_routes?.truckLicense) {
      setTruckLicense(suggestion_routes?.truckLicense);
    }
  }, [suggestion_routes]);

  useEffect(() => {
    if (suggestionPolylineList == null || suggestionPolylineList.length === 0) {
      setSelectedPolylines([]);
      setEditingPolylines(null);
      return;
    }
    if (suggestion_editing >= 0) {
      let polylines = suggestionPolylineList.filter(
        (polyline) => polyline.id === suggestion_editing
      );
      let routes = [];
      polylines.forEach((r, idx) => {
        if (r.Route) {
          let hereRoute = {
            id: idx + 1,
            polyline: r.Route,
            waypoints: r.waypoints,
            section: {
              polyline: r.Route,
            },
          };
          routes.push(hereRoute);
        }
      });
      setSelectedPolylines([]);
      setEditingPolylines(routes);
    } else if (suggestion_selecting >= 0) {
      let polylines = suggestionPolylineList.filter(
        (polyline) => polyline.id === suggestion_selecting
      );
      setSelectedPolylines(polylines);
      setEditingPolylines(null);
    }
  }, [suggestion_selecting, suggestionPolylineList, suggestion_editing]);

  useEffect(() => {
    if (center && mapRef.current) {
      mapRef.current.center(center);
    }
  }, [center, mapRef]);

  useEffect(() => {
    let bounds = [];
    if (truck_location && truck_location.lat && truck_location.lng) {
      bounds.push([truck_location.lat, truck_location.lng]);
    }

    if (dropPointList && dropPointList.length > 0) {
      dropPointList.forEach((p) => {
        bounds.push([p.position.lat, p.position.lng]);
      });
    }
    if (bounds.length > 0) {
      setBbox(getBbox(bounds));
    }
  }, [truck_location, dropPointList]);

  const renderHazardPolygon = (p) => {
    if (!p.id) return null;
    return (
      <HMapDrawPolygon
        polygon={p.path}
        options={{
          style: {
            fillColor: 'rgba(193, 36, 0, 0.4)',
            strokeColor: '#e06666',
          },
        }}
      />
    );
  };
  const renderHazardCircle = (c, idx) => {
    return (
      <HMapDrawCircle
        key={`hazard-circle-${idx}`}
        circle={{
          center: { lat: c.center.lat, lng: c.center.lng },
          radius: c.radius,
        }}
        options={{
          style: { fillColor: 'rgba(193, 36, 0, 0.4)', strokeColor: '#e06666' },
        }}
      />
    );
  };
  const renderHazardPoint = (m, idx) => {
    let isOpen = selectedHazardId === m.id;
    let video = null;
    let photo = [];

    if (isOpen) {
      if (m && m.mediaUrl) {
        m.mediaUrl.forEach(function (row) {
          if (row !== null) {
            let files = row.split('.');
            if (files[files.length - 1].toLowerCase().includes('mp4'))
              video = row;
            else photo.push(row);
          }
        });
      }
    }
    const selectedMarkerFunction = (d) => {
      return {
        pointerdown: (_) => {
          setSelectedHazardId(d.id);
        },
      };
    };
    return (
      <HMapDrawMarker
        key={`hazard-${idx}`}
        marker={{ lat: m.latitude, lng: m.longitude }}
        options={{
          zIndex: 100,
          icon: hazardIcon(),
          size: { w: 40, h: 40 },
        }}
        objectEvents={selectedMarkerFunction(m)}
      >
        <HMapInfoWindow
          size={
            video || photo.length > 0
              ? { width: 430, height: 320 }
              : { width: 380, height: 260 }
          }
          isOpen={isOpen}
          onClose={() => {
            setSelectedHazardId(null);
          }}
        >
          <ImgVdoPreview
            inputPhotos={photo}
            inputVdo={video}
            info={{
              name: m?.warning || '',
              description: m?.detail || '',
            }}
          />
        </HMapInfoWindow>
      </HMapDrawMarker>
    );
  };
  const handlePostComplete = (resp) => {
    let copyOfDataEditRoutes = [...dataForEditRoute];
    let editingRoute = copyOfDataEditRoutes[suggestion_selecting];
    let newLegs = editingRoute.Legs.map((leg) => {
      if (leg.Seq === resp.seqNo) {
        return { ...leg, routeId: resp.id };
      } else {
        return leg;
      }
    });
    editingRoute = { ...editingRoute, Legs: newLegs, isChangeRoute: true };
    copyOfDataEditRoutes[suggestion_selecting] = editingRoute;
    dispatch(SET_DATA_FOR_EDIT_ROUTE(copyOfDataEditRoutes));
  };
  return (
    <>
      {map_loading && <Loading />}
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={bbox}
      >
        <HMapDrawMarker
          marker={{
            lat: truck_location.lat,
            lng: truck_location.lng,
          }}
          options={{
            zIndex: 1000,
            icon: truckIcon(),
            size: { w: 40, h: 40 },
            info: `<div style="font-size:12px;width:100px">
            <b>Truck License :</b> ${truckLicense || '-'}<br/>
            </div>`,
          }}
        />
        {dropPointList.map((p, idx) => {
          return (
            <HMapDrawMarker
              key={`drop-${idx}`}
              marker={{
                lat: p.position.lat,
                lng: p.position.lng,
              }}
              options={{
                icon: markerPinNumberSvgIcon(
                  `${idx + 1}`,
                  30,
                  40,
                  p.isCompleted ? '#3B538B' : '#D2D2D2',
                  p.isCompleted ? '#ffffff' : '#000000'
                ),
                info: `<div style="font-size:12px;width:300px">
                <b>Location Name :</b> ${p.locationName || '-'}<br/>
                <b>Actual Arrival Time :</b> ${p.actualArrivalTime || '-'}<br/>
                <b>Plan Arrival Time :</b> ${p.planArrivalTime || '-'}<br/>
                <b>Actual Departure Time :</b> ${
                  p.actualDepartureTime || '-'
                }<br/>
                <b>Plan Departure Time :</b> ${p.planDepartureTime || '-'}<br/>
                </div>`,
              }}
            />
          );
        })}
        {selectedPolylines &&
          selectedPolylines.map((p, idx) => {
            if (p.path.length === 0) {
              return null;
            }
            let polyline = encode({
              polyline: p.path,
            });

            return [<HMapPolyline key={`leg-${idx}`} polyline={polyline} />];
          })}

        {harzardPoints.map((p, idx) => {
          return renderHazardPoint(p, idx);
        })}
        {hazardCircleList.map((c, idx) => {
          return renderHazardCircle(c, idx);
        })}
        {hazardPolygonList.map((p, idx) => {
          return renderHazardPolygon(p, idx);
        })}
        {editingPolylines &&
          editingPolylines.map((route, idx) => {
            return (
              <HMapRoute
                key={`route-${idx}`}
                route={route}
                options={{
                  isShowStartEndMarker: false,
                  enableDelete: false,
                  enableAutoWaypoints: false,
                  isWaypointOnEnter: true,
                }}
                onCallback={(newRoute) => {
                  let systemRoute = {
                    jobDashboardId: jobDashboardId,
                    seqNo: newRoute.id,
                    routes: {
                      Id: newRoute.section.id,
                      Vehicle: null,
                      TotalTime: newRoute?.section?.summary?.baseDuration
                        ? newRoute.section.summary.baseDuration / 60
                        : 0,
                      TotalDistance:
                        newRoute?.section?.summary &&
                        newRoute?.section?.summary['length']
                          ? newRoute.section.summary['length']
                          : 0,
                      TollUsage: newRoute?.section?.tolls ? true : false,
                      MergeRoute: newRoute.polyline,
                      waypoints: newRoute.waypoints,
                      sections: newRoute.section,
                    },
                  };
                  dispatch(
                    apiCallRequest({
                      url: Configs[env].BACKEND_HOST + '/system_route',
                      method: 'POST',
                      form: systemRoute,
                      callback: handlePostComplete,
                      isSilent: true,
                    })
                  );
                  let newPolylines = editingPolylines.map((route) => {
                    if (newRoute.id === route.id) {
                      return { ...newRoute };
                    } else {
                      return { ...route };
                    }
                  });
                  setTimeout(() => {
                    setEditingPolylines(newPolylines);
                  }, 200);
                }}
              />
            );
          })}
        <HMapPlaceSearch />
      </Mapview>
    </>
  );
};
export default RouteSuggestionMap;
