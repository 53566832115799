import { takeLatest, select, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  getUsers,
  getGroupCompanies,
  getCompanies,
  getLevel,
  getConfigs,
  getProvider,
  getProblem,
  getAction,
} from '../reducers/setting/api';
import {
  settingUsers,
  settingGroupCompanies,
  settingCompanies,
  settingUserLevel,
  settingConfigs,
  settingProvider,
  settingProblem,
  settingAction,
} from '../reducers/setting';

import Configs from '../config/config';
import { SET_SNACKBAR } from '../reducers/ui';

const env = process.env.NODE_ENV;
// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(getUsers, usersWorkerSaga);
  yield takeLatest(getGroupCompanies, usersWorkerSaga);
  yield takeLatest(getCompanies, usersWorkerSaga);
  yield takeLatest(getLevel, usersWorkerSaga);
  yield takeLatest(getConfigs, usersWorkerSaga);
  yield takeLatest(getProvider, usersWorkerSaga);
  yield takeLatest(getProblem, usersWorkerSaga);
  yield takeLatest(getAction, usersWorkerSaga);
}

function paramsUrl(data) {
  return Object.keys(data)
    .map((key) => `${key}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function fetchDataGet_NEW(params) {
  let url = params.payload.url + '?' + paramsUrl(params.payload.filter);

  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Cache: 'no-cache',
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem('token'),
  };

  return axios
    .get(url, { headers: headers, timeout: 60000 })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return { catchError: error };
    });
}

function* usersWorkerSaga(action) {
  try {
    const resp = yield call(fetchDataGet_NEW, action);
    const response = yield checkError(resp, action);

    if (response !== false) {
      if (getUsers.toString() === action.type)
        yield put({ type: settingUsers, payload: response.data.list });
      else if (getGroupCompanies.toString() === action.type)
        yield put({ type: settingGroupCompanies, payload: response.data.list });
      else if (getCompanies.toString() === action.type)
        yield put({ type: settingCompanies, payload: response.data.list });
      else if (getLevel.toString() === action.type)
        yield put({ type: settingUserLevel, payload: response.data.list });
      else if (getConfigs.toString() === action.type)
        yield put({ type: settingConfigs, payload: response.data.list });
      else if (getProvider.toString() === action.type)
        yield put({ type: settingProvider, payload: response.data.list });
      else if (getProblem.toString() === action.type)
        yield put({ type: settingProblem, payload: response.data.list });
      else if (getAction.toString() === action.type)
        yield put({ type: settingAction, payload: response.data.list });
    }
  } catch (error) {
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

//===============================
function* checkError(response, action) {
  let params = action;
  let callback = params.payload.callback;
  let callback_error = params.payload.callback_error;

  if (response.hasOwnProperty('catchError')) {
    if (callback_error) callback_error(response.catchError.toString());
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: response.catchError.toString(),
        snackbarDuration: 20000,
      },
    });
    return false;
  }

  if (parseInt(response.status) < 200 || parseInt(response.status) > 200) {
    if (callback_error)
      callback_error('HTTP ERROR : status code = ' + response.status);
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: 'error',
        snackbarMessage: 'HTTP ERROR : status code = ' + response.status,
        snackbarDuration: 20000,
      },
    });
    return false;
  }
  if (response.data.hasOwnProperty('errorList')) {
    if (response.data.errorList.length > 0) {
      let errorShow = '';
      if (callback_error) callback_error(errorShow);

      errorShow = response.data.errorList[0].errorMessage;
      console.info('errorDetail', response.data.errorList[0].errorDetail);

      if (parseInt(response.data.errorList[0].errorCode) === 2101) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
          },
        });
        window.location.href = '/dashboard/operation_dash';
        return false;
      }

      if (
        parseInt(response.data.errorList[0].errorCode) === 2102 ||
        parseInt(response.data.errorList[0].errorCode) === 2103
      ) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: 'error',
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
            forceLogout: true,
          },
        });
        return false;
      }

      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: 'error',
          snackbarMessage: errorShow,
          snackbarDuration: 20000,
        },
      });
      return false;
    }
  }

  if (
    response.data.hasOwnProperty('list') ||
    response.data.hasOwnProperty('result')
  ) {
    if (callback) callback(response.data);

    return response;
  }

  yield put({
    type: SET_SNACKBAR,
    payload: {
      snackbarOpen: true,
      snackbarVariant: 'error',
      snackbarMessage: 'API error',
      snackbarDuration: 20000,
    },
  });

  return false;
}
