import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";

//Component
import Action from './Action';
import Problem from './Problem';
import Level from './Level';
import Provider from './Provider';
import Company from './Company';
import GroupCompany from './GroupCompany';
//Reducer
import { setSidebarVisibility,uiSwitchForm,uiActiveTabSystemMaster } from "../../reducers/ui";


function TabContainer({ children, dir }) {
  return (
    <Typography component="div" dir={dir} style={{ padding: 0 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
  }
});

class SystemMaster extends React.Component {
  state = {
    value: 0,
  };

  componentDidMount() {
    const { setSidebarVisibility } = this.props;

    setSidebarVisibility(false);
  }
  handleChange = (event, value) => {
    this.setState({ value });
    this.props.uiActiveTabSystemMaster(value);
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
    this.props.uiActiveTabSystemMaster(index);
  };


  render() {
    const { classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.props.activeTabSystemMaster}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            
          >
            <Tab label="Group Company / Company" />
            <Tab label="Level" />
            <Tab label="GPS Provider" />
            <Tab label="Problem / Action" />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={this.props.activeTabSystemMaster}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer dir={theme.direction}>
            <div style={{ padding:'10px',display: 'flex',marginTop:'10px', height: 'calc(100vh)' ,overflow:'auto'}}>
              <Grid container spacing={2}>
                <Grid item xs={6}><GroupCompany/></Grid>
                <Grid item xs={6}><Company/></Grid>
              </Grid>
            </div>
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <div style={{ padding:'10px',display: 'flex', height: 'calc(100vh' ,overflow:'auto'}}>
              <Level/>
            </div>
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <div style={{ padding:'10px',display: 'flex', height: 'calc(100vh)' ,overflow:'auto'}}>
              <Provider/>
            </div>
          </TabContainer>
          <TabContainer dir={theme.direction}>
            <div style={{ padding:'10px',display: 'flex', height: 'calc(100vh)' ,overflow:'auto'}}>
              <Grid container spacing={2}>
                <Grid item xs={6}><Problem/></Grid>
                <Grid item xs={6}><Action/></Grid>
              </Grid>
            </div>
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

SystemMaster.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  formManage : state.ui.formManage,
  activeTabSystemMaster : state.ui.activeTabSystemMaster
});

const mapDispatchToProps = dispatch => {
  return {
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    uiActiveTabSystemMaster: payload => dispatch(uiActiveTabSystemMaster(payload)),
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles, { withTheme: true })
)(SystemMaster);
