import React, { useEffect, useState } from 'react';
import Configs from 'config/config';
import { useSelector, useDispatch } from 'react-redux';
import Mapview, { HMapPlaceSearch, HMapRoute } from 'Components/HereMap';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import { SET_MODIFIED_ROUTE } from 'reducers/map';
import Loading from 'Components/HereMap/Components/Loading';

const env = process.env.NODE_ENV;

const RouteMasterMapList = (props) => {
  const { mapRef } = props;
  const [bbox, setBbox] = useState(null);
  const [route, setRoute] = useState(null);
  const dispatch = useDispatch();

  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });
  const marker_origin = useSelector((state) => {
    return state.map.marker_origin;
  });
  const marker_desc = useSelector((state) => {
    return state.map.marker_desc;
  });
  const modified_route = useSelector((state) => {
    return state.map.modified_route;
  });
  useEffect(() => {
    if (modified_route) {
      setRoute({
        polyline: modified_route.MergeRoute,
        waypoints: modified_route.waypoints,
        section: modified_route.sections[0],
      });
    }
  }, [modified_route]);

  useEffect(() => {
    if (
      marker_origin &&
      marker_origin.lat &&
      marker_origin.lng &&
      marker_desc &&
      marker_desc.lat &&
      marker_desc.lng
    ) {
      setBbox(
        getBbox([
          [marker_origin.lat, marker_origin.lng],
          [marker_desc.lat, marker_desc.lng],
        ])
      );
    }
  }, [marker_origin, marker_desc]);

  return (
    <>
      {map_loading && <Loading />}
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={bbox}
      >
        <HMapRoute
          route={route}
          options={{ enableDelete: false, enableAutoWaypoints: true }}
          onCallback={(r) => {
            setRoute(r);
            dispatch(
              SET_MODIFIED_ROUTE({
                ...modified_route,
                MergeRoute: r.polyline,
                TotalTime: r.section.summary.baseDuration / 60,
                TotalDistance: r.section.summary['length'],
                TollUsage: r.section.tolls ? true : false,
                sections: [r.section],
                waypoints: r.waypoints,
              })
            );
          }}
        />
        <HMapPlaceSearch />
      </Mapview>
    </>
  );
};
export default RouteMasterMapList;
