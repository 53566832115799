import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Locations from './Locations';

import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
//Component
import LogisticPointSelect from './LogisticPointSelect';
import LocationGroupSelect from './LocationGroupSelect';
//Reducer
import { searchLocation } from '../../reducers/search';
import { resetAPIResult, apiCallRequest } from '../../reducers/api';
import { addLocation, addGroupLocation } from '../../reducers/ui/mapForm';
import { connect } from 'react-redux';
import { uiPanelDialogOpen, uiSwitchForm } from '../../reducers/ui';
import {
  mapDrawingMode,
  mapResetMap,
  mapChangeMarker,
  mapDrawingOptions,
  mapSetLogisticPoint,
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons,
  mapSetMarkerGroup,
} from '../../reducers/map';
import { getLocationGroup, getLogisticPoint } from '../../reducers/map/api';
import {
  selectLocationGroup,
  selectLogisticPoint,
} from '../../reducers/ui/selectList';
//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: '4px',
  },
  center: {
    position: 'relative',
    left: '20px',
  },
});
function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class SearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedItems: [],
    };
  }
  componentDidMount() {
    this.props.CHANGE_MAPMODE(null);
    this.props.RESET_MAP(true);

    if (this.props.logisticPoint.value) {
      this.props.GET_LOCATION_GROUP({
        url: Configs[env].BACKEND_HOST + '/locationGroupCond',
        filter: {
          logisticsPointGroup: this.props.logisticPoint.value.trim(),
        },
        callback: this.loadLocationGroup,
      });
    }
  }
  loadLocationGroup = () => {
    if (this.props.logisticPoint.value && this.props.locationGroup.value) {
      let valueSelect = JSON.parse(this.props.locationGroup.value);
      let groupName = '';
      if (valueSelect.logisticsPointGroup.toUpperCase().trim() === 'SUPPLIER')
        groupName = 'Supplier';
      if (valueSelect.logisticsPointGroup.toUpperCase().trim() === 'YARD')
        groupName = 'Yard';
      if (valueSelect.logisticsPointGroup.toUpperCase().trim() === 'PLANT')
        groupName = 'Plant';

      let payload = { value: groupName, text: groupName };

      this.props.selectLogisticPoint(payload);
      this.loadLogisticsPoint();
      this.props.GET_LOCATION_GROUP({
        url: Configs[env].BACKEND_HOST + '/locationGroupCond',
        filter: {
          logisticsPointGroup: groupName,
        },
        callback: this.loadLogisticsPoint,
      });
    } else {
      this.props.SET_LOGISTICPOINT([]);
    }
  };
  loadLogisticsPoint = () => {
    setTimeout(() => {
      if (this.props.locationGroup.value && this.props.locationGroup.value) {
        let valueSelect = JSON.parse(this.props.locationGroup.value);
        let payload = {
          value: this.props.locationGroup.value,
          text: valueSelect.name,
        };
        this.props.selectLocationGroup(payload);

        //Data for create Marker Group
        this.props.GET_LOGISTIC_POINTS({
          url: Configs[env].BACKEND_HOST + '/locationCond',
          filter: {
            logisticsPointGroup: valueSelect.logisticsPointGroup,
            locationGroupId: valueSelect.id,
          },
        });
      } else {
        this.props.SET_LOGISTICPOINT([]);
      }
    }, 500);
  };

  componentWillMount() {
    if (this.props.manageForm) {
      this.setState({
        logisticText: this.props.manageForm.logisticsPointGroup || '',
        logistic: this.props.manageForm.logisticsPointGroup || '',
        location: this.props.manageForm.locationGroupId || '',
        locationText: this.props.manageForm.location_group || '',
      });
    }
  }
  componentWillReceiveProps(nextProps) {}

  onFilterTextBoxChanged = (event) => {
    this.props.SEARCH_LOCATION(event.target.value);
  };
  handleAdd = (event) => {
    this.props.RESET_MAP(true);
    this.props.SET_GROUP_LOCATION({
      logisticsPointGroup: this.props.logisticPoint.text,
      logisticsPointGroupId: this.props.logisticPoint.value,
    });
    this.props.SWITCH_FORM({ page: 'group', mode: 'add' });
  };
  handleEdit = (event) => {
    let detail = JSON.parse(this.props.locationGroup.value);
    this.props.RESET_MAP(true);
    this.props.SET_GROUP_LOCATION({ ...detail });
    this.props.SWITCH_FORM({ page: 'group', mode: 'edit' });
  };
  handleClick(event) {
    this.props.OPEN_DIALOG(true);
  }
  formLocationGroupForm() {}
  handleManageLocation = (event) => {
    this.props.SWITCH_FORM({ page: 'manage' });
  };
  ///////////Multi Delete//////////////
  handleDeleteOpen = () => {
    if (this.child.getSelections().length > 0) this.setState({ open: true });
  };
  handleDeleteConfirm = (event) => {
    this.props.apiCallRequest({
      url: Configs[env].BACKEND_HOST + '/location',
      method: 'DELETE',
      listData: [{ id: this.child.getSelections() }],
      callback: this.handleDeleteComplete,
    });
    //}
  };
  handleDeleteComplete = () => {
    let valueSelect = JSON.parse(this.props.locationGroup.value);
    this.props.GET_LOGISTIC_POINTS({
      url: Configs[env].BACKEND_HOST + '/locationCond',
      filter: {
        logisticsPointGroup: this.props.logisticPoint.value,
        locationGroupId: valueSelect.id,
      },
    });
    this.handleDeleteCancel();
  };
  handleDeleteCancel = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    let privilege = JSON.parse(localStorage.getItem('privilege'));
    let controllable =
      privilege && privilege.hasOwnProperty('locationMaster')
        ? privilege.locationMaster.controllable
        : false;
    let toDisable = !controllable;
    return (
      <div style={{ padding: '10px' }} testing='div-location-master'>
        <Card className={styles.card}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LogisticPointSelect />
              </Grid>
              <Grid item xs={7}>
                <LocationGroupSelect />
              </Grid>
              <Grid item xs={5}>
                <br />
                <Button
                  testing='button-edit-location-group'
                  disabled={this.props.locationGroup.value === '' || toDisable}
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Edit'
                  className={classes.button}
                  onClick={this.handleEdit}
                >
                  EDIT
                </Button>
                <Button
                  testing='button-add-location-group'
                  disabled={this.props.logisticPoint.value === '' || toDisable}
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Add'
                  className={classes.button}
                  onClick={this.handleAdd}
                >
                  ADD
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card className={styles.card}>
          <CardContent style={{ height: 'calc(100vh - 280px)' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant='subtitle1'>LOGISTICS POINT</Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  testing='button-add-logistics-point'
                  disabled={this.props.locationGroup.value === '' || toDisable}
                  variant='contained'
                  size='small'
                  color='primary'
                  aria-label='Add'
                  className={classes.button}
                  onClick={this.handleManageLocation}
                >
                  ADD
                </Button>
                <Button
                  testing='button-delete-logistics-point'
                  disabled={
                    this.props.locationGroup.value === '' ||
                    toDisable ||
                    this.child === null ||
                    this.child?.getSelections().length === 0
                  }
                  variant='contained'
                  size='small'
                  color='secondary'
                  aria-label='Delete '
                  className={classes.button}
                  onClick={this.handleDeleteOpen}
                >
                  DELETE
                </Button>
              </Grid>
              <Grid item xs={7}>
                <FormControl required fullWidth noValidate autoComplete='on'>
                  <TextField
                    id='location-search'
                    label='SEARCH LOCATION'
                    type='search'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin='dense'
                    className={styles.textField}
                    onChange={this.onFilterTextBoxChanged.bind(this)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment variant='filled' position='end'>
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Locations
                  onRef={(ref) => (this.child = ref)}
                  onSelectionChanged={(d) => {
                    this.setState({ selectedItems: d });
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Dialog
          open={this.state.open}
          //TransitionComponent={Transition}
          keepMounted
          onClose={this.handleDeleteCancel}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            Delete location point
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              Confirm to delete
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='secondary'
              onClick={this.handleDeleteConfirm}
              disabled={this.props.isLoading}
            >
              {this.props.isLoading && (
                <CircularProgress
                  className={classes.center}
                  size={25}
                  thickness={2}
                />
              )}
              Confirm
            </Button>
            <Button
              onClick={this.handleDeleteCancel}
              disabled={this.props.isLoading}
              color='primary'
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    isLoading: state.ui.isLoading,
    dialogOpen: state.ui.dialogOpen,
    formManage: state.ui.formManage,
    location_group: state.map.location_group,
    grouplist: state.api.resultGroupList,
    manageForm: state.mapForm.manageForm,
    logisticPoint: state.selection.logisticPoint,
    locationGroup: state.selection.locationGroup,
  };
};
const mapActionsToProps = {
  DRAWING_OPTION: mapDrawingOptions,
  OPEN_DIALOG: uiPanelDialogOpen,
  ADD_LOCATION: addLocation,
  CHANGE_MAPMODE: mapDrawingMode,
  RESET_MAP: mapResetMap,
  onChangeMarker: mapChangeMarker,
  SEARCH_LOCATION: searchLocation,
  SET_LOGISTICPOINT: mapSetLogisticPoint,
  SET_MARKER: mapSetMarkers,
  SET_CIRCLE: mapSetCircles,
  SET_POLYGON: mapSetPolygons,
  SWITCH_FORM: uiSwitchForm,
  SET_GROUP_LOCATION: addGroupLocation,
  GET_LOCATION_GROUP: getLocationGroup,
  GET_LOGISTIC_POINTS: getLogisticPoint,
  SET_MARKER_GROUP: mapSetMarkerGroup,
  resetAPIResult,
  selectLocationGroup,
  selectLogisticPoint,
  apiCallRequest,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(SearchForm));
