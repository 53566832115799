import { createSlice } from '@reduxjs/toolkit';
import {
  loadMap,
  geocodeAutocomplete,
  geocodeAutosuggest,
  hmapRouting,
  hmapCandidateRoutes,
} from './api';
const initialState = {
  // Main HMap
  isLoading: false,
  error: null,
  isSuccess: false,
  //Auto complete
  searchPlaceResult: null,
  isSearchPlaceLoading: false,
  searchPlaceError: false,
  // Routing
  routeResult: null,
  candidateRouteResult: null,
  isRouteLoading: false,
  routeError: false,
};
export const hereMapSlice = createSlice({
  name: 'hMap',
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.isLoading = false;
      state.routeResult = null;
      state.candidateRouteResult = null;
      state.isRouteLoading = false;
      state.routeError = false;
    },
  },
  extraReducers: {
    [loadMap.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [loadMap.pending]: (state, _) => {
      state.isLoading = true;
    },
    [loadMap.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [geocodeAutocomplete.fulfilled]: (state, { payload }) => {
      state.isSearchPlaceLoading = false;
      state.searchPlaceResult = payload;
    },
    [geocodeAutocomplete.pending]: (state, _) => {
      state.isSearchPlaceLoading = true;
    },
    [geocodeAutocomplete.rejected]: (state, { payload }) => {
      state.isSearchPlaceLoading = false;
      state.searchPlaceError = payload;
    },
    [geocodeAutosuggest.fulfilled]: (state, { payload }) => {
      state.isSearchPlaceLoading = false;
      state.searchPlaceResult = payload;
    },
    [geocodeAutosuggest.pending]: (state, _) => {
      state.isSearchPlaceLoading = true;
    },
    [geocodeAutosuggest.rejected]: (state, { payload }) => {
      state.isSearchPlaceLoading = false;
      state.searchPlaceError = payload;
    },
    [hmapRouting.fulfilled]: (state, { payload }) => {
      state.isRouteLoading = false;
      state.routeResult = payload;
    },
    [hmapRouting.pending]: (state, _) => {
      state.isRouteLoading = true;
    },
    [hmapRouting.rejected]: (state, { payload }) => {
      state.isRouteLoading = false;
      state.routeError = payload;
    },
    [hmapCandidateRoutes.fulfilled]: (state, { payload }) => {
      state.isRouteLoading = false;
      state.candidateRouteResult = payload;
    },
    [hmapCandidateRoutes.pending]: (state, _) => {
      state.isRouteLoading = true;
    },
    [hmapCandidateRoutes.rejected]: (state, { payload }) => {
      state.isRouteLoading = false;
      state.routeError = payload;
    },
  },
});

export const { clearState } = hereMapSlice.actions;
export const hmapSelector = (state) => state.hmap;

export default hereMapSlice.reducer;
