import React, {
  memo,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { loadMap } from 'reducers/hmap/api';
import { hmapSelector, clearState } from 'reducers/hmap';
import initPlatform from '../libs/initPlatform';
import mapBuilder from '../libs/mapBuilder';
import removeEventListener from '../libs/removeEvent';
import defaults from '../libs/defaults';
import { goBack } from 'connected-react-router';
import { CircularProgress, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((_) => ({
  rootCircular: {
    display: 'table',
    height: 'calc(100%)',
    margin: 'auto',
  },
  centerText: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
}));

const Map = forwardRef((props, ref) => {
  const { style, setBound } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const [builder, setBuilder] = useState();
  const mapRef = useRef(null);
  const disposeFunctions = useRef([]);
  const { isLoading, error, isSuccess } = useSelector(hmapSelector);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  // The component instance will be extended
  useImperativeHandle(ref, () => ({
    resize() {
      if (builder) {
        const { map } = builder;
        setTimeout(() => {
          map.getViewPort().resize();
        }, 500);
      }
    },
    center(coords) {
      if (builder) {
        const { map } = builder;
        map.setCenter(coords);
      }
    },
    removeAllObjects() {
      if (builder) {
        const { map } = builder;
        map.removeObjects(map.getObjects());
      }
    },
  }));

  useEffect(() => {
    dispatch(clearState());
    if (!isSuccess) {
      dispatch(loadMap());
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(clearState());
    }
  }, [dispatch, error]);

  useLayoutEffect(() => {
    if (builder) {
      const { map, options, ui } = builder;
      if (map) {
        map.removeObjects(map.getObjects());
      }
      if (ui) {
        ui.getBubbles().forEach((b) => {
          ui.removeBubble(b);
        });
      }
      if (options.useEvents && options.mapEvents) {
        removeEventListener(map, options.mapEvents);
      }

      for (let dispose of disposeFunctions.current) {
        dispose();
      }

      forceUpdate();
    }
    if (isSuccess && mapRef.current && !builder) {
      const platform = initPlatform(props);
      let createdBuilder = mapBuilder(platform, {
        ...defaults,
        ...props,
        container: mapRef.current,
        build: true,
      });
      setBuilder(createdBuilder);
    }
  }, [mapRef, props, isSuccess, builder, forceUpdate]);

  const resize = useCallback(() => {
    if (builder) {
      const { map } = builder;
      setTimeout(() => {
        map.getViewPort().resize();
      }, 500);
    }
  }, [builder]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  useEffect(() => {
    if (setBound && builder) {
      const { map } = builder;
      if (setBound.length === 4) {
        var bbox = new window.H.geo.Rect(
          setBound[0],
          setBound[1],
          setBound[2],
          setBound[3]
        );
        setTimeout(() => {
          map.getViewModel().setLookAtData(
            {
              // zoom: 13,
              bounds: bbox,
            },
            false
          );
          forceUpdate();
          setTimeout(() => {
            if(!map) return;
            let currentZoom = map.getZoom();
            map.setZoom(currentZoom - 1);
          }, 10);
        }, 0);
      } else {
        setTimeout(() => {
          map.setCenter({ lat: setBound[0][0], lng: setBound[0][1] });
          forceUpdate();
        }, 0);
      }
    }
  }, [setBound, builder, forceUpdate]);

  if (isLoading) {
    return (
      <div className={classes.rootCircular}>
        <CircularProgress
          className={classes.centerText}
          size={50}
          thickness={4}
        />
      </div>
    );
  }

  const cloneThroughFragments = (children, extProps) => {
    return React.Children.map(children, (c) => {
      if (!c) {
        return <div style={{ display: 'none' }} />;
      }
      if (React.isValidElement(c)) {
        if (c.type === React.Fragment) {
          return cloneThroughFragments(c.props.children, extProps);
        }
        return React.cloneElement(c, { ...c.props, ...extProps });
      }
      return c;
    });
  };
  const displayChildren = () => {
    const { children } = props;
    const { map, platform, ui, options, interaction } = builder;
    const extProps = {
      map,
      platform,
      ui,
      interaction,
      builderOptions: options,
      disposeFunctions,
    };
    return cloneThroughFragments(children, extProps);
  };

  if (isSuccess) {
    return (
      <div style={style} ref={mapRef}>
        {typeof window.H === 'object' &&
          builder &&
          builder.options &&
          builder.map &&
          displayChildren()}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Typography style={{ textAlign: 'center', marginBottom: '10px' }}>
          Failed to initialize map. Please retry again.
        </Typography>
        <Button
          variant='contained'
          size='small'
          color='primary'
          onClick={() => {
            dispatch(goBack());
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
});

export default memo(Map);
