const initPlatform = (options) => {
  const { apiKey } = options;
  if (!apiKey) {
    throw new Error('Options must include apiKey');
  }
  if (typeof window.H === 'undefined' || !window.H.service) {
    throw new Error('Here Map JavaScripts is not loaded.');
  }
  return new window.H.service.Platform(options);
};
export default initPlatform;
