import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import * as uiRedux from '../reducers/ui';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

//Api
import { authLogoutRequest, forceLogout } from '../reducers/auth';

import { settingUserSelect, settingOpenNewPass } from '../reducers/setting';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});

class MenuAppBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: true,
      anchorEl: null,
    };
  }

  handleChange = (event) => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleNewPassword = (event) => {
    this.props.settingUserSelect(localStorage.getItem('id'));
    this.props.settingOpenNewPass(true);
  };
  handleLogout = () => {
    this.props.authLogoutRequest({
      callback: this.setLogout,
    });
  };
  setLogout = () => {
    this.props.forceLogout(true);

    // this.setState({ auth: false });
    // window.localStorage.clear();
  };

  render() {
    const { classes, onAppbarToggle, sidebarOpen } = this.props;
    const { auth, anchorEl } = this.state;
    const open = Boolean(anchorEl);

    if (!auth) {
      return <Redirect to='/login' />;
    }
    return (
      <div>
        <AppBar position='absolute'>
          <Toolbar variant='dense'>
            <IconButton
              className={classes.menuButton}
              color='inherit'
              aria-label='Menu'
              onClick={onAppbarToggle}
            >
              <MenuIcon
                classes={{
                  root: sidebarOpen
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>

            <Typography variant='h6' color='inherit'>
              {this.props.activeMenuListItem.menuName}
            </Typography>
            <Typography variant='h6' color='inherit' className={classes.grow} />
            {auth && (
              <div>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : null}
                  aria-haspopup='true'
                  onClick={this.handleMenu}
                  color='inherit'
                >
                  <AccountCircle />
                </IconButton>
                <Button variant='outlined' color='inherit'>
                  {localStorage.getItem('firstName') +
                    ' ' +
                    localStorage.getItem('lastName')}
                </Button>

                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleNewPassword}>
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    sidebarOpen: uiRedux.getSidebarOpen(state),
    pageManage: state.ui.pageManage,
    activeMenuListItem: state.ui.activeMenuListItem,
  };
};

const mapDispatchToProps = {
  onAppbarToggle: uiRedux.uiAppbarToggle,
  settingUserSelect,
  settingOpenNewPass,
  forceLogout: forceLogout,
  authLogoutRequest,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MenuAppBar);
