import React, { useState, useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import AspectRatio from 'react-aspect-ratio';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import VideocamIcon from '@material-ui/icons/Videocam';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ImageIcon from '@material-ui/icons/ImageOutlined';

import { DEL_HAZARD_PHOTO, DEL_HAZARD_VIDEO, SET_AS_FIRST } from 'reducers/map';

import { useDispatch, useSelector } from 'react-redux';

import Configs from 'config/config';
const env = process.env.NODE_ENV;

const useStyles = makeStyles((theme) => ({
  button: {
    // margin: theme.spacing.unit,
  },
  input: {
    // display: 'none',
  },
  image: {
    // height: '100px',
  },
}));

const ImgVdoPreviewComponent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const galleryRef = useRef();
  const [previewState, setPreviewState] = useState({
    show_photo: true,
    selecting_photo: 0,
    is_selectingPhoto: true,
    photoIndex: 0,
    isOpen: false,
  });
  const { inputPhotos, inputVdo } = props;

  const page = useSelector((state) => {
    return state.ui.formManage.page;
  });

  let imageX = [];

  let images = [];
  let vdo = null;
  if (inputPhotos && inputPhotos.length > 0)
    inputPhotos.forEach((photo_path, index) => {
      imageX.push(Configs[env].BACKEND_HOST + '/getMedia?path=' + photo_path);

      images.push({
        original: Configs[env].BACKEND_HOST + '/getMedia?path=' + photo_path,
        thumbnail: Configs[env].BACKEND_HOST + '/getMedia?path=' + photo_path,
        sizes: '(max-height: 50px)',
        originalHeight: '150px',
      });
    });

  if (inputVdo !== null)
    vdo = Configs[env].BACKEND_HOST + '/getMedia?path=' + inputVdo;

  const onPhotoClick = (e, index) => {
    setPreviewState({
      ...previewState,
      selecting_photo: index,
    });
  };
  const renderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <button
        type='button'
        className={`image-gallery-fullscreen-button${
          isFullscreen ? ' active' : ''
        }`}
        onClick={() =>
          setPreviewState({
            ...previewState,
            isOpen: true,
          })
        }
      />
    );
  };
  const deletePhotoVDO = () => {
    if (previewState.is_selectingPhoto) {
      let selecting_photo = previewState.selecting_photo;
      setPreviewState({
        ...previewState,
        show_photo: null,
      });
      setTimeout(() => {
        dispatch(DEL_HAZARD_PHOTO(selecting_photo));
        setPreviewState({
          ...previewState,
          show_photo: true,
          selecting_photo: 0,
        });
      }, 100);
    }

    if (!previewState.is_selectingPhoto) {
      dispatch(DEL_HAZARD_VIDEO());
      setPreviewState({
        ...previewState,
        show_photo: true,
        is_selectingPhoto: true,
        selecting_photo: 0,
      });
    }
  };
  const setAsFirst = () => {
    let selecting_photo = previewState.selecting_photo;
    setPreviewState({
      ...previewState,
      show_photo: null,
    });
    setTimeout(() => {
      dispatch(SET_AS_FIRST(selecting_photo));
      setPreviewState({
        ...previewState,
        show_photo: true,
        selecting_photo: 0,
      });
    }, 100);
  };

  const slideToIndex = () => {
    if (galleryRef.current) {
      galleryRef.current.slideToIndex(previewState.selecting_photo);
    }
  };
  return (
    <>
      <div>
        {previewState.isOpen && (
          <Lightbox
            mainSrc={imageX[previewState.selecting_photo]}
            nextSrc={imageX[(previewState.selecting_photo + 1) % imageX.length]}
            prevSrc={
              imageX[
                (previewState.selecting_photo + imageX.length - 1) %
                  imageX.length
              ]
            }
            onCloseRequest={() =>
              setPreviewState({ ...previewState, isOpen: false })
            }
            onMovePrevRequest={() => {
              setPreviewState({
                ...previewState,
                selecting_photo:
                  (previewState.selecting_photo + imageX.length - 1) %
                  imageX.length,
              });
              setTimeout(() => slideToIndex(), 100);
            }}
            onMoveNextRequest={() => {
              setPreviewState({
                ...previewState,
                selecting_photo:
                  (previewState.selecting_photo + 1) % imageX.length,
              });
              setTimeout(() => slideToIndex(), 100);
            }}
          />
        )}
      </div>
      <table border={0}>
        <tbody>
          <tr valign='top'>
            {(images.length > 0 || vdo !== null) && (
              <td rowSpan='2'>
                <AspectRatio ratio='3/4' style={{ maxWidth: '300px' }}>
                  {previewState.show_photo === true && (
                    <ImageGallery
                      showIndex={true}
                      lazyLoad={false}
                      showPlayButton={false}
                      items={images}
                      showNav={false}
                      onThumbnailClick={onPhotoClick}
                      renderFullscreenButton={renderFullscreenButton}
                      ref={(input) => (galleryRef.current = input)}
                    />
                  )}
                  {previewState.show_photo === false && (
                    <Video
                      autoPlay
                      loop
                      muted
                      controls={[
                        'PlayPause',
                        'Seek',
                        'Time',
                        'Volume',
                        'Fullscreen',
                      ]}
                      onCanPlayThrough={() => {
                        // Do stuff
                      }}
                    >
                      <source src={vdo} type='video/mp4' />
                    </Video>
                  )}
                  {page !== 'list' &&
                    ((previewState.is_selectingPhoto &&
                      inputPhotos &&
                      inputPhotos.length > 0) ||
                      (!previewState.is_selectingPhoto &&
                        inputVdo !== null)) && (
                      <div
                        style={{
                          position: 'absolute',
                          left: '-6px',
                          top: '-6px',
                        }}
                      >
                        <IconButton
                          color='secondary'
                          className={classes.button}
                          aria-label='a'
                          onClick={deletePhotoVDO}
                        >
                          <CancelIcon />
                        </IconButton>
                      </div>
                    )}
                  {page !== 'list' &&
                    inputPhotos &&
                    inputPhotos.length > 0 &&
                    previewState.selecting_photo > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          left: '30px',
                          top: '8px',
                        }}
                      >
                        <button onClick={setAsFirst}>Set as first</button>
                      </div>
                    )}
                </AspectRatio>
              </td>
            )}
            {images.length === 0 && vdo === null && (
              <td rowSpan='2'>
                <ImageIcon style={{ fontSize: 200 }} />
              </td>
            )}
            <td>
              <div style={{ width: '150px' }}>
                <b>Location :</b>
                <br />
                {props?.info?.name || ''}
                <br />
                <br />
                <b>Detail :</b>
                <br />
                {props?.info?.description || ''}
              </div>
            </td>
          </tr>
          <tr align='center' height='50' valign='center'>
            <td>
              <IconButton
                disabled={inputPhotos && inputPhotos.length > 0 ? false : true}
                color={previewState.show_photo ? 'secondary' : 'primary'}
                className={classes.button}
                aria-label='a'
                onClick={() =>
                  setPreviewState({
                    show_photo: true,
                    selecting_photo: 0,
                    is_selectingPhoto: true,
                  })
                }
              >
                <PhotoLibraryIcon />
              </IconButton>
              |
              <IconButton
                disabled={inputVdo !== null ? false : true}
                color={!previewState.show_photo ? 'secondary' : 'primary'}
                className={classes.button}
                aria-label='a'
                onClick={() =>
                  setPreviewState({
                    show_photo: false,
                    selecting_photo: 0,
                    is_selectingPhoto: false,
                  })
                }
              >
                <VideocamIcon />
              </IconButton>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ImgVdoPreviewComponent;
