import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Select } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import {
  selectLogisticPoint,
  selectLocationGroup,
} from '../../reducers/ui/selectList';
import { getLocationGroup } from '../../reducers/map/api';
import { mapSetLogisticPoint, mapSetMarkerGroup } from '../../reducers/map';
import { connect } from 'react-redux';

import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  padding5px: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    margin: '10px',
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500]),
  },
  selectStyles: {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  },
});

class LogisticPointSelect extends Component {
  handleChange = (event) => {
    let payload = {
      value: event.target.value,
      text: event.currentTarget.innerText,
    };
    this.props.selectLogisticPoint(payload);
    this.props.GET_LOCATION_GROUP({
      url: Configs[env].BACKEND_HOST + '/locationGroupCond',
      filter: {
        logisticsPointGroup: event.target.value,
      },
    });

    // Clear previous displayed value
    this.props.selectLocationGroup({ value: '', text: '', idSelect: '' });
    this.props.SET_LOGISTICPOINT([]);
    this.props.SET_MARKER_GROUP(null);
  };
  render() {
    return (
      <FormControl required fullWidth>
        <InputLabel shrink htmlFor='logistic-required'>
          LOGISTICS POINT GROUP
        </InputLabel>
        <Select
          testing='select-logistics-point-group'
          value={this.props.logisticPoint.value}
          onChange={this.handleChange}
          name='logistic'
          fullWidth
          disabled={this.props.disabled}
          inputProps={{
            id: 'logistic-required',
          }}
        >
          <MenuItem key={0} value={'Supplier'}>
            Supplier
          </MenuItem>
          <MenuItem key={1} value={'Plant'}>
            Plant
          </MenuItem>
          <MenuItem key={2} value={'Yard'}>
            Yard
          </MenuItem>
        </Select>
      </FormControl>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    logisticPoint: state.selection.logisticPoint,
  };
};
const mapActionsToProps = {
  selectLogisticPoint,
  selectLocationGroup,
  GET_LOCATION_GROUP: getLocationGroup,
  SET_LOGISTICPOINT: mapSetLogisticPoint,
  SET_MARKER_GROUP: mapSetMarkerGroup,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(LogisticPointSelect));
