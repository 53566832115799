export const getLatRad = (lat) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

export const calculateZoom = (mapPx, worldPx, fraction) =>
  Math.log(mapPx / worldPx / fraction) / Math.LN2;

export const getAngleBetweenPoints = (point1, point2) => {
  if (!point1 || !point2) {
    return undefined;
  }

  const arePointsEqual =
    point1.latitude === point2.latitude &&
    point1.longitude === point2.longitude;

  if (arePointsEqual) {
    return undefined;
  }

  const { latitude: lat1, longitude: lng1 } = transformPointToRadian(point1);
  const { latitude: lat2, longitude: lng2 } = transformPointToRadian(point2);

  const lngDelta = lng2 - lng1;

  const y = Math.sin(lngDelta) * Math.cos(lat2);
  const x =
    Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lngDelta);
  const value = Math.atan2(y, x);
  const bearing = ((value * 180) / Math.PI + 360) % 360;

  return bearing;
};

const transformPointToRadian = (point) => ({
  ...point,
  latitude: (point.latitude * Math.PI) / 180,
  longitude: (point.longitude * Math.PI) / 180,
});

export const getDistance = (point1, point2) => {
  const distance =
    ((point1.latitude - point2.latitude) ** 2 +
      (point1.longitude - point2.longitude) ** 2) **
    0.5;

  return distance;
};
