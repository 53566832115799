import React, { Component } from 'react';
import { setSidebarVisibility } from '../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';
//import Pane from 'react-split-pane/lib/Pane';
//Component
import MapGroup from './MapGroup';
import MapPoint from './MapPoint';
import MapList from './MapList';

import SearchForm from './SearchForm';
import ManageForm from './ManageForm';
import LocationGroupForm from './LocationGroupForm';
//Reducer
import { selectReset } from '../../reducers/ui/selectList';
import { mapSetLogisticPoint } from '../../reducers/map';

const MIN_HEIGHT = 25;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  scrollHeight: {},
  gridList: {
    width: '100%',
    height: 'auto',
  },
  gridForm: {
    padding: '0px',
  },
  subheader: {
    width: '100%',
  },
});

class LocationMaster extends Component {
  constructor(props) {
    super(props);
    this.mapListRef = React.createRef();
    this.mapGroupRef = React.createRef();
    this.mapPointRef = React.createRef();

    this.paneSizeRef = React.createRef();

    this.state = {};
  }
  componentWillMount() {
    this.props.mapSetLogisticPoint([]);
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;

    setSidebarVisibility(false);
  }
  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        '#locationMap .ag-header-cell-label'
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }
  renderManageForm() {
    switch (this.props.formManage.page) {
      case 'manage':
        return <ManageForm />;
      case 'group':
        return <LocationGroupForm type={this.props.formManage.mode} />;
      default:
        return <SearchForm />;
    }
  }
  methodFromParent(cell) {}
  render() {
    return (
      <div style={{ display: 'flex', height: 'calc(100vh - 52px)' }}>
        <React.Fragment>
          <SplitPane
            split='vertical'
            defaultSize={100}
            onChange={(size) => {
              if (
                this.paneSizeRef.current &&
                size[0] === this.paneSizeRef.current
              ) {
                return;
              }
              if (this.mapListRef.current) {
                this.mapListRef.current.resize();
              }
              if (this.mapGroupRef.current) {
                this.mapGroupRef.current.resize();
              }
              if (this.mapPointRef.current) {
                this.mapPointRef.current.resize();
              }
              this.paneSizeRef.current = size[0];
            }}
          >
            <Pane initialSize='420px'>
              <div style={{ height: 'calc(100vh - 40px)', overflow: 'auto' }}>
                {this.renderManageForm()}
              </div>
            </Pane>
            <Pane>
              <div style={{ height: 'calc(100vh - 52px)' }}>
                {this.props.formManage.page === 'list' && (
                  <MapList
                    mapRef={this.mapListRef}
                    page={this.props.formManage.page}
                  />
                )}
                {this.props.formManage.page === 'group' && (
                  <MapGroup
                    mapRef={this.mapGroupRef}
                    page={this.props.formManage.page}
                  />
                )}
                {this.props.formManage.page === 'manage' && (
                  <MapPoint
                    mapRef={this.mapPointRef}
                    page={this.props.formManage.page}
                  />
                )}
              </div>
            </Pane>
          </SplitPane>
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isReset: state.map.isReset,
  formManage: state.ui.formManage,
});

const mapDispatchToProps = (dispatch) => {
  return {
    selectReset: (payload) => dispatch(selectReset(payload)),
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    mapSetLogisticPoint: (payload) => dispatch(mapSetLogisticPoint(payload)),
    dispatch,
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(LocationMaster);
