import React, { Component } from 'react';
import {
  reduxForm,
  Form,
  Field,
} from 'redux-form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Loading from '../Ui/Loading';
import compose from 'recompose/compose';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
//import momentLocaliser from 'react-widgets/lib/localizers/moment'
import 'react-widgets/dist/css/react-widgets.css';
//Component
//Lib
import { required, match } from '../../libs/validation';
//Reducer
import {
  apiCallRequest,
} from '../../reducers/api';
import { settingOpenNewPass, settingUserSelect } from '../../reducers/setting';

//Config
import Configs from '../../config/config';
const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth: '200px',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

class NewPasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentWillMount() {}
  componentDidMount() {}
  handleSave = (form) => {
    //debugger
    this.props.settingOpenNewPass(false);

    let formData = { ...form };
    delete formData.confirmPassword;

    this.props.API_REQUEST({
      url: Configs[env].BACKEND_HOST + '/password/' + this.props.user_selecting,
      form: { ...formData },
      method: 'PUT',
      callback: this.handleCancel,
    });
  };
  handleCancel = () => {
    this.props.reset();
    this.props.settingUserSelect(null);
    this.props.settingOpenNewPass(false);
  };
  render() {
    const { handleSubmit } = this.props;

    return (
      <div style={{ padding: '10px' }}>
        {this.props.isLoading && <Loading />}

        <Dialog
          open={this.props.newpass_dialog}
          keepMounted
          onClose={this.handleCancel}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {this.props.title}
          </DialogTitle>
          <DialogContent>
            <Form
              //style={{ overflowY:"scroll"}}
              autoComplete={'off'}
              autoCorrect={'off'}
              spellCheck={'off'}
              onSubmit={handleSubmit(this.handleSave.bind(this))}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name='currentPassword'
                    type='password'
                    component={renderInput}
                    className={styles.textField}
                    label='Current Password'
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name='password'
                    type='password'
                    component={renderInput}
                    className={styles.textField}
                    label='New Password'
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name='confirmPassword'
                    type='password'
                    component={renderInput}
                    className={styles.textField}
                    label='Confirm Password'
                    validate={[required, match]}
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <DialogActions>
                    <Button
                      variant='contained'
                      size='small'
                      color='primary'
                      aria-label='Save'
                      className={styles.button}
                      type='submit'
                    >
                      SAVE
                    </Button>
                    <Button
                      variant='outlined'
                      size='small'
                      color='secondary'
                      aria-label='Close'
                      className={classNames(styles.button, styles.cssGreen)}
                      onClick={this.handleCancel}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    userForm: state.mapForm.userForm,
    user_selecting: state.setting.user_selecting,
    newpass_dialog: state.setting.newpass_dialog,
  };
};
const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  settingOpenNewPass,
  settingUserSelect,
};

const enhance = compose(
  reduxForm({
    form: 'passwordForm',
  }),
  connect(mapStateToProps, mapActionsToProps),
  withStyles(styles)
);
export default enhance(NewPasswordDialog);
