import React, { Component } from 'react';
import { render } from 'react-dom';
import { connect } from 'react-redux';
import ImPolyline from './ImPolyline';
import WayPoints from './RouteMasterEditWP/WayPoints';
import WPFloatingBox from './RouteMasterEditWP/WPFloatingBox';
import RoutesViewBoxes from './RouteMasterEditWP/RoutesViewBoxes';

import uuidv4 from 'uuid/v4';
import { Marker, InfoWindow } from 'react-google-maps';

class RouteMaster extends Component {
  render() {
    let size = new window.google.maps.Size(22, 36);

    let positionA = { lat: 14.1643, lng: 100.581 };
    let positionB = { lat: 14.1669, lng: 100.6495 };

    let icon_urlA = 'http://www.google.com/mapfiles/dd-start.png';
    let icon_urlB = 'http://www.google.com/mapfiles/dd-end.png';

    return (
      // https://developers.google.com/maps/documentation/javascript/reference/polyline#PolylineOptions
      // visible
      // defaultVisible

      <React.Fragment key={uuidv4()}>
        {this.props.show_master_or_candidate !== 2 &&
          this.props.masterPolylineList &&
          this.props.masterPolylineList.length > 0 &&
          this.props.masterPolylineList.map((polyline, index) => {
            return (
              <ImPolyline
                key={polyline.id}
                id={polyline.id}
                path={polyline.path}
                editable={polyline.editable}
                info={polyline.info}
              />
            );
          })}

        {this.props.show_master_or_candidate == 2 &&
          this.props.candidatePolylineList &&
          this.props.candidatePolylineList.length > 0 &&
          this.props.candidatePolylineList.map((polyline, index) => {
            return (
              <ImPolyline
                key={polyline.id}
                id={polyline.id}
                path={polyline.path}
                editable={polyline.editable}
                info={polyline.info}
              />
            );
          })}

        <WayPoints legs={this.props.legs} waypoints={this.props.waypoints} />

        {this.props.waypoints.constructor === Array &&
          this.props.waypoints.length > 1 && <WPFloatingBox></WPFloatingBox>}

        {this.props.formManage.page === 'list' &&
          this.props.routesview.constructor === Array &&
          this.props.routesview.length > 0 && (
            <RoutesViewBoxes
              routesview={this.props.routesview}
            ></RoutesViewBoxes>
          )}

        {
          //------ Origin - Desc Marker ------//
        }
        <div>
          <Marker
            position={this.props.marker_origin}
            icon={{
              url: 'http://www.google.com/mapfiles/dd-start.png',
              labelOrigin: new window.google.maps.Point(19, 14),
              scaledSize: new window.google.maps.Size(22, 36),
              size: new window.google.maps.Size(22, 36),
            }}
          />
          <Marker
            position={this.props.marker_desc}
            icon={{
              url: 'http://www.google.com/mapfiles/dd-end.png',
              labelOrigin: new window.google.maps.Point(19, 14),
              scaledSize: new window.google.maps.Size(22, 36),
              size: new window.google.maps.Size(22, 36),
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    formManage: state.ui.formManage,
    markerGroup: state.map.markerGroup,
    markerList: state.map.markerList,
    circleList: state.map.circleList,
    candidatePolylineList: state.map.candidatePolylineList,
    masterPolylineList: state.map.masterPolylineList,
    legs: state.map.legs,
    waypoints: state.map.waypoints,
    routesview: state.map.routesview,
    marker_origin: state.map.marker_origin,
    marker_desc: state.map.marker_desc,
    show_master_or_candidate: state.map.show_master_or_candidate,
  };
};

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(RouteMaster);
