import React, { Component } from 'react';
import { setSidebarVisibility } from '../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ConfirmBtnRenderer from './Renderer/ConfirmBtnRenderer';
import DateTimeFieldRenderer from './Renderer/DateTimeFieldRenderer';
import PlanETAFieldRenderer from './Renderer/PlanETAFieldRenderer';
import ProbActionFieldRenderer from './Renderer/ProbActionFieldRenderer';
import DateTimeDialog from './Dialog/DateTimeDialog';
import ProblemDialog from './Dialog/ProblemDialog';
import ActionDialog from './Dialog/ActionDialog';

import ManageBtnRenderer from './Renderer/ManageBtnRenderer';
import RouteRenderer from './Renderer/RouteRenderer';
import TrackingRenderer from './Renderer/TrackingRenderer';
import IconRouteRenderer from './Renderer/IconRouteRenderer';
import DestinationPointRenderer from './Renderer/DestinationPointRenderer';
import { operDashboardSelected } from 'reducers/dashboard';
import {
  setShowDeleteButton,
  setSelectedJobs,
} from 'reducers/operationDashboard';
import store from 'reducers/rootReducer';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';
import './grid.css';
import ProblemRemarkDialog from './Dialog/ProblemRemarkDialog';
const MIN_HEIGHT = 25;

const styles = () => ({
  avatar: {
    margin: 5,
    width: 20,
    height: 20,
  },
  rootCircular: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 10000,
  },
});

class OperationsGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowSelecting: true,
      blinkColor: 0,
      columnDefs: [
        {
          headerName: '',
          suppressMovable: true,
          sortable: false,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          width: 40,
          headerClass: 'header-checkbox',
        },
        {
          headerName: 'Route Date',
          field: 'routeDate.value',
          width: 100,
          tooltipField: 'jobDashboardId.value',
          cellStyle: function (params) {
            let color = params.data.routeDate.color;
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor: color === '#FFFFFF' ? 'transparent' : color,
            };
          },
          cellClass: function (params) {
            return params.data.routeDate.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Plan Arrival Time',
          field: 'planArrivalTime.value',
          cellRenderer: 'planETAFieldRenderer',
          headerClass: 'multiline',
          width: 65,
          tooltipField: 'jobDashboardId.value',
          //tooltipComponentParams: { color: "#ececec" },
          cellStyle: function (params) {
            let color = params.data.planArrivalTime.color;
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor: color === '#FFFFFF' ? 'transparent' : color,
              //animation: 'blinker 1s step-start infinite',
            };
          },
          cellClass: function (params) {
            return params.data.planArrivalTime.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Route',
          field: 'routeCode.value',
          headerClass: 'multiline',
          cellRenderer: 'routeRenderer',
          width: 70,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.routeCode.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.routeCode.color,
            };
          },
          cellClass: function (params) {
            return params.data.routeCode.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Shift Seq.',
          field: 'shiftSeq.value',
          headerClass: 'multiline',
          width: 60,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.shiftSeq.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.shiftSeq.color,
            };
          },
          cellClass: function (params) {
            return params.data.shiftSeq.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Tracking by',
          field: 'trackBy.value',
          headerClass: 'multiline',
          width: 80,
          // cellRenderer : "trackingRenderer",
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.trackBy.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.trackBy.color,
            };
          },
          cellClass: function (params) {
            return params.data.trackBy.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Run Seq.',
          field: 'runSeq.value',
          headerClass: 'multiline',
          width: 50,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.runSeq.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.runSeq.color,
            };
          },
          cellClass: function (params) {
            return params.data.runSeq.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Truck License',
          field: 'truckLicense.value',
          headerClass: 'multiline',
          width: 95,
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.truckLicense.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.truckLicense.color,
            };
          },
          cellClass: function (params) {
            return params.data.truckLicense.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'ETA',
          field: 'ETA.value',
          headerClass: 'multiline',
          editable: false,
          cellRenderer: 'planETAFieldRenderer',
          //cellEditor: "datePicker",
          width: 70,
          cellStyle: function (params) {
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor:
                params.data.ETA.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.ETA.color,
            };
          },
          cellClass: function (params) {
            return params.data.ETA.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Part Usage Time',
          field: 'partUsageTime.value',
          headerClass: 'multiline',
          cellRenderer: 'planETAFieldRenderer',
          //cellRenderer: "dateTimeFieldRenderer",
          width: 70,
          cellStyle: function (params) {
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor:
                params.data.partUsageTime.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.partUsageTime.color,
            };
          },
          cellClass: function (params) {
            return params.data.partUsageTime.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Actual Arrival Time',
          field: 'actualArrivalTime.value',
          headerClass: 'multiline',
          cellRenderer: 'planETAFieldRenderer',
          width: 70,
          cellStyle: function (params) {
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor:
                params.data.actualArrivalTime.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.actualArrivalTime.color,
            };
          },
          cellClass: function (params) {
            return params.data.actualArrivalTime.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Destination Point',
          field: 'destinationPoint.value',
          headerClass: 'multiline',
          cellRenderer: 'destinationPointRenderer',
          width: 75,
          cellStyle: function (params) {
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor:
                params.data.destinationPoint.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.destinationPoint.color,
            };
          },
          cellClass: function (params) {
            return params.data.destinationPoint.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Status',
          field: 'status.value',
          headerClass: 'multiline',
          //cellRenderer: "statusOperationRenderer",
          width: 130,
          cellStyle: function (params) {
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor:
                params.data.status.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.status.color,
            };
          },
          cellClass: function (params) {
            return params.data.status.blink === 1 ? 'blink' : '';
          },
        },
        {
          headerName: 'Alternative Route/New Hazard Point',
          field: 'hasHazard.value',
          headerClass: 'multiline',
          width: 100,
          //editable: false,
          //cellEditor: "agSelectCellEditor",
          //cellEditorParams: {
          //values: ["YYY", "BBB", "CCC","AAA"]
          //},
          cellStyle: function (params) {
            return {
              color: 'black',
              textAlign: 'center',
              backgroundColor:
                params.data.hasHazard.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.hasHazard.color,
            };
          },
          cellRenderer: 'iconRouteRenderer',
          cellClass: function (params) {
            return params.data.hasHazard.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Problem',
          field: 'problem.value',
          headerClass: 'multiline',
          width: 110,
          editable: false,
          cellRenderer: 'probActionFieldRenderer',
          cellEditor: 'agSelectCellEditor',
          // cellEditorParams: {
          //   values: ["YYY", "BBB", "CCC","AAA"]
          // },
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.problem.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.problem.color,
            };
          },
          cellClass: function (params) {
            return params.data.problem.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Problem Remark',
          field: 'problemRemark.value',
          headerClass: 'multiline',
          width: 110,
          editable: false,
          cellRenderer: 'probActionFieldRenderer',
          cellEditor: 'agSelectCellEditor',
          // cellEditorParams: {
          //   values: ["YYY", "BBB", "CCC","AAA"]
          // },
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.problemRemark.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.problemRemark.color,
            };
          },
          cellClass: function (params) {
            return params.data.problemRemark.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Action',
          field: 'action.value',
          headerClass: 'multiline',
          width: 110,
          editable: false,
          cellRenderer: 'probActionFieldRenderer',
          cellEditor: 'agSelectCellEditor',
          // cellEditorParams: {
          //   values: ["YYY", "BBB", "CCC","AAA"]
          // },
          cellStyle: function (params) {
            return {
              color: 'black',
              backgroundColor:
                params.data.action.color === '#FFFFFF'
                  ? 'transparent'
                  : params.data.action.color,
            };
          },
          cellClass: function (params) {
            return params.data.action.blink === 1 ? '' : '';
          },
        },
        {
          headerName: 'Confirm',
          headerClass: 'multiline',
          sortable: false,
          field: 'confirmEnabled.value',
          cellRenderer: 'confirmBtnRenderer',
          colId: 'params',
          width: 80,
        },
        {
          headerClass: 'multiline',
          cellRenderer: 'manageBtnRenderer',
          width: 80,
        },
      ],
      rowClassRules: {
        // "status-ontime": function(params) {
        //   var status = params.data.status;
        //   return status === "0";
        // },
        'status-delay': function (params) {
          var status = params.data.status;
          return status === '1';
        },
        'status-critical-delay': function (params) {
          var status = params.data.status;
          return status === '2';
        },
      },
      defaultColDef: { editable: false, sortable: true, resizable: true },
      context: { componentParent: this },
      frameworkComponents: {
        confirmBtnRenderer: ConfirmBtnRenderer,
        dateTimeFieldRenderer: DateTimeFieldRenderer,
        planETAFieldRenderer: PlanETAFieldRenderer,
        probActionFieldRenderer: ProbActionFieldRenderer,
        //statusOperationRenderer : StatusOperationRenderer,
        //selectRenderer : SelectRenderer,
        manageBtnRenderer: ManageBtnRenderer,
        routeRenderer: RouteRenderer,
        trackingRenderer: TrackingRenderer,
        iconRouteRenderer: IconRouteRenderer,
        destinationPointRenderer: DestinationPointRenderer,
        //datePicker: getDatePicker()
      },
      headerHeight: 60,
      rowData: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    //getDataOperation({});
    // if(this.props.blinkColor != nextProps.blinkColor){
    //   this.renderColumns(nextProps);
    // }
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    setSidebarVisibility(false);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.dashboard_operation !== this.props.dashboard_operation) {
      //this.restoreSelect(prevProps);
    }
  }
  componentWillUnmount() {}

  rowData() {}

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = () => {
    var allColumnIds = [];
    if (this.gridColumnApi) {
      this.gridColumnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  };
  autosizeHeaders(event) {
    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        '#jobDataGrid .ag-header-cell-label'
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }

  methodFromParent(cell) {
    alert('Parent Component Method from ' + cell + '!');
  }
  renderData = () => {
    if (Array.isArray(this.props.dashboard_operation)) {
      if (this.props.textSearchOperation === '') {
        return this.props.dashboard_operation;
      } else {
        let result = [];
        let findText = this.props.textSearchOperation.toUpperCase();
        this.props.dashboard_operation.forEach((point) => {
          let planArrivalTime = point.planArrivalTime.value;
          let ETA = point.ETA.value;
          if (point.planArrivalTime.value && point.isYard.value === true) {
            planArrivalTime = planArrivalTime + '*';
          }
          if (point.ETA.value && point.isYard.value === true) {
            ETA = ETA + '*';
          }
          if (
            point.truckLicense.value.toUpperCase().indexOf(findText) > -1 ||
            point.routeCode.value.toUpperCase().indexOf(findText) > -1 ||
            point.shiftSeq.value.toUpperCase().indexOf(findText) > -1 ||
            point.trackBy.value.toUpperCase().indexOf(findText) > -1 ||
            point.runSeq.value.toUpperCase().indexOf(findText) > -1 ||
            point.partUsageTime.value.toUpperCase().indexOf(findText) > -1 ||
            point.actualArrivalTime.value.toUpperCase().indexOf(findText) >
              -1 ||
            point.destinationPoint.value.toUpperCase().indexOf(findText) > -1 ||
            //point.hasHazard.value.toUpperCase().indexOf(findText) > -1 ||
            point.problem.value.toUpperCase().indexOf(findText) > -1 ||
            point.action.value.toUpperCase().indexOf(findText) > -1 ||
            planArrivalTime.toUpperCase().indexOf(findText) > -1 ||
            ETA.toUpperCase().indexOf(findText) > -1 ||
            point.status.value.toUpperCase().indexOf(findText) > -1
          ) {
            result.push(point);
          }
        });
        return result;
      }
    } else return [];
  };
  handleSelectionCell = (node) => {
    try {
      //this.props.SELECT_MARKER(selectedRows[0].id);
    } catch (e) {
      //this.props.MAP_LOG({ error : e, detail : "Location.js - handleSelectionChanged"})
    }
  };
  onSelectionChanged = (node) => {
    let selectedRows = node.api.getSelectedRows();
    this.props.setShowDeleteButton(selectedRows.length > 0 ? true : false);
    this.props.setSelectedJobs(selectedRows);
  };
  handleSelectionChanged = (node) => {
    try {
      var selectedRows = node.api.getSelectedRows();
      var selectedCell = node.api.getFocusedCell();

      if (
        selectedRows[0].jobDashboardId.value +
          selectedRows[0].destinationPoint.value +
          selectedRows[0].planArrivalTime.value ===
          this.props.oper_dashboard_selected &&
        !(
          selectedCell.column.colId === 'problem.value' ||
          selectedCell.column.colId === 'problemRemark.value' ||
          selectedCell.column.colId === 'action.value' ||
          selectedCell.column.colId === 'partUsageTime.value' ||
          selectedCell.column.colId === 'ETA.value' ||
          selectedCell.column.colId === 'params' ||
          selectedCell.column.colId === '0'
        )
      ) {
        let gridApi = this.gridApi;
        // this.setState({ rowSelecting : false })
        this.props.operDashboardSelected(null);
        gridApi.deselectAll();
        selectedCell.clearFocusedCell();
      } else {
        // this.setState({ rowSelecting : true })
        this.props.operDashboardSelected(
          selectedRows[0].jobDashboardId.value +
            selectedRows[0].destinationPoint.value +
            selectedRows[0].planArrivalTime.value
        );
      }
    } catch (e) {
      // this.props.MAP_LOG({ error : e, detail : "Location.js - handleSelectionChanged"})
    }
  };
  restoreSelect = (prevProps) => {
    let gridApi = this.gridApi;

    if (gridApi) {
      gridApi.deselectAll();
      gridApi.clearFocusedCell();
      this.gridApi.forEachNode(function (node) {
        if (
          node.data.jobDashboardId.value +
            node.data.destinationPoint.value +
            node.data.planArrivalTime.value ===
          prevProps.oper_dashboard_selected
        ) {
          node.setSelected(true, true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' });
          return true;
        }
      });
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <div
          id='jobDataGrid'
          style={{
            boxSizing: 'border-box',
            height: '98%',
            width: '100%',
          }}
          className='ag-theme-balham'
        >
          {(this.props.isLoading || this.props.isDeleting) && (
            <div className={classes.rootCircular}>
              <CircularProgress size={80} thickness={4} />
            </div>
          )}
          <AgGridReact
            reactNext={true}
            reduxStore={store}
            rowDataChangeDetectionStrategy={
              ChangeDetectionStrategyType.IdentityCheck
            }
            pagination={true}
            paginationAutoPageSize={true}
            singleClickEdit={true}
            rowSelection='multiple'
            onSelectionChanged={this.onSelectionChanged}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={this.renderData()}
            onGridReady={this.autosizeHeaders.bind(this)}
            onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
          />
          <DateTimeDialog title={this.state.title} />
          <ProblemDialog title={this.state.title} />
          <ProblemRemarkDialog title={this.state.title} />
          <ActionDialog title={this.state.title} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  oper_dashboard_selected: state.dashboard.oper_dashboard_selected,
  dashboard_operation: state.dashboard.dashboard_operation,
  textSearchOperation: state.search.search_operation_txt,
  isLoading: state.ui.isLoading,
  isDeleting: state.operationDashboard.isDeleting,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    operDashboardSelected: (payload) =>
      dispatch(operDashboardSelected(payload)),
    setShowDeleteButton: (payload) => dispatch(setShowDeleteButton(payload)),
    setSelectedJobs: (payload) => dispatch(setSelectedJobs(payload)),
    dispatch,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(OperationsGrid);
