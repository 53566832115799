import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setSidebarVisibility } from '../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

import { withRouter } from 'react-router';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';
import queryString from 'query-string';
//Component
import Map from './Map';
import RouteForm from './RouteForm';
import RouteSuggestGrid from './RouteSuggestGrid';
//Reducer
import { setActiveMenuListItem } from '../../reducers/ui';
import {
  selectJobDashboardId,
  selectShiftSeq,
} from '../../reducers/ui/selectList';

const MIN_HEIGHT = 25;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  gridForm: {
    padding: '10px',
  },
  subheader: {
    width: '100%',
  },
});

class RouteSuggestion extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.paneSizeRef = React.createRef();

    this.state = {};
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    const { jobDashboardId } = this.props.match.params;

    const params = queryString.parse(this.props.location.search);

    if (jobDashboardId) this.props.selectJobDashboardId(jobDashboardId);
    if (params.shiftNo && params.shiftNo !== '')
      this.props.selectShiftSeq(params.shiftNo);
    else this.props.selectShiftSeq(null);
    setSidebarVisibility(false);
  }

  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        '#locationMap .ag-header-cell-label'
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }
  renderManageForm() {
    return <RouteForm />;
  }
  render() {
    const { jobDashboardId } = this.props.match.params;
    return (
      <div style={{ display: 'flex', height: 'calc(100vh - 50px)' }}>
        <SplitPane
          split='vertical'
          minSize={50}
          defaultSize={100}
          onChange={(size) => {
            if (
              this.paneSizeRef.current &&
              size[0] === this.paneSizeRef.current
            ) {
              return;
            }
            if (this.mapRef.current) {
              this.mapRef.current.resize();
            }
            this.paneSizeRef.current = size[0];
          }}
        >
          <Pane>
            <div
              style={{
                padding: '0px',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: 'calc(100vh - 50px)',
              }}
              testing='div-route-suggession'
            >
              <div
                style={{
                  width: '100%',
                  flex: '1',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div style={{ width: '100%', marginTop: '0em' }}>
                  <Map mapRef={this.mapRef} jobDashboardId={jobDashboardId} />
                </div>
              </div>
              <div
                style={{ width: '100', display: 'flex', flexDirection: 'row' }}
              >
                <div style={{ width: '100%', marginTop: '-0.6em' }}>
                  <RouteSuggestGrid />
                </div>
              </div>
            </div>
          </Pane>
          <Pane initialSize='350px' minSize='200px'>
            <div style={{ height: 'calc(100vh - 40px)', overflow: 'auto' }}>
              {this.renderManageForm()}
            </div>
          </Pane>
        </SplitPane>
      </div>
    );
  }
}
RouteSuggestion.propTypes = {
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  formManage: state.ui.formManage,
  activeMenuListItem: state.ui.activeMenuListItem,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    setActiveMenuListItem: (payload) =>
      dispatch(setActiveMenuListItem(payload)),
    selectJobDashboardId: (payload) => dispatch(selectJobDashboardId(payload)),
    selectShiftSeq: (payload) => dispatch(selectShiftSeq(payload)),
    dispatch,
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(withRouter(RouteSuggestion));
