import React, { Component } from 'react';
import { setSidebarVisibility, uiSwitchForm } from '../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import uuidv4 from 'uuid/v4';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';
//Component
import MapManage from './MapManage';
import MapList from './MapList';
import SearchForm from './SearchForm';
import ManageForm from './ManageForm';
import AddGroupForm from './AddGroupForm';
import WarningTypes from './WarningTypes';

//Reducer
import { mapSetHazardPoints } from '../../reducers/map';
//import { searchReset } from '../../reducers/search';

const env = process.env.NODE_ENV;
const MIN_HEIGHT = 25;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  gridForm: {
    padding: '0px',
  },
  subheader: {
    width: '100%',
  },
});

class HazardPoints extends Component {
  constructor(props) {
    super(props);
    this.mapListRef = React.createRef();
    this.mapManageRef = React.createRef();

    this.paneSizeRef = React.createRef();
    this.state = {};
  }
  componentWillMount() {
    this.props.mapSetHazardPoints([]);
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;

    setSidebarVisibility(false);
  }

  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        '#locationMap .ag-header-cell-label'
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach((cell) => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }
  renderManageForm() {
    switch (this.props.formManage.page) {
      case 'manage':
        return <ManageForm />;
      case 'warning_type':
        return <WarningTypes />;
      case 'group':
        return (
          <AddGroupForm
            type={this.state.mode}
            logistic={{
              value: this.state.logistic,
              text: this.state.logisticText,
            }}
            location={{
              detail: this.state.locationDetail,
              value: this.state.location,
              text: this.state.locationText,
            }}
          />
        );
      default:
        return <SearchForm />;
    }
  }
  methodFromParent(cell) {
    alert('Parent Component Method from ' + cell + '!');
  }
  render() {
    return (
      <div style={{ display: 'flex', height: 'calc(100vh - 50px)' }}>
        <SplitPane
          split='vertical'
          minSize={50}
          defaultSize={100}
          onChange={(size) => {
            if (
              this.paneSizeRef.current &&
              size[0] === this.paneSizeRef.current
            )
              return;
            if (this.mapListRef.current) {
              this.mapListRef.current.resize();
            }

            if (this.mapManageRef.current) {
              this.mapManageRef.current.resize();
            }
            this.paneSizeRef.current = size[0];
          }}
        >
          <Pane initialSize='450px' minSize='200px'>
            <div style={{ height: 'calc(100vh - 40px)', overflow: 'auto' }}>
              {this.renderManageForm()}
            </div>
          </Pane>
          <Pane>
            <div style={{ height: 'calc(100vh - 52px)' }}>
              {this.props.formManage.page === 'list' && (
                <MapList mapRef={this.mapListRef} />
              )}
              {this.props.formManage.page === 'manage' && (
                <MapManage mapRef={this.mapManageRef} />
              )}
            </div>
          </Pane>
        </SplitPane>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    isReset: state.map.isReset,
    formManage: state.ui.formManage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarVisibility: (status) => dispatch(setSidebarVisibility(status)),
    mapSetHazardPoints: (payload) => dispatch(mapSetHazardPoints(payload)),
    dispatch,
  };
};
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
);
export default enhance(HazardPoints);
