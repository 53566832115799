import React, { useRef, useEffect } from 'react';

function Polyline(props) {
  const { map, disposeFunctions, polyline, options } = props;

  if (!window.H || !window.H.map || !map) {
    throw new Error('HMap has to be initialized before adding Map Objects');
  }

  let polylineGroup = useRef();

  if (!polylineGroup.current) {
    polylineGroup.current = new window.H.map.Group({
      visibility: true,
    });
  }

  map.addObjects([polylineGroup.current]);
  if (polyline) {
    // Create a linestring to use as a point source for the Polyline line
    let linestring = window.H.geo.LineString.fromFlexiblePolyline(polyline);

    if (options?.isArrowStyle) {
      // Create a polyline to display the Polyline:
      let PolylineOutline = new window.H.map.Polyline(linestring, {
        style: {
          lineWidth: 8,
          strokeColor: options?.color
            ? options?.color
            : 'rgba(0, 128, 255, 0.9)',
          lineTailCap: 'arrow-tail',
          lineHeadCap: 'arrow-head',
        },
      });
      // Create a patterned polyline:
      let PolylineArrows = new window.H.map.Polyline(linestring, {
        style: {
          lineWidth: 8,
          fillColor: 'white',
          strokeColor: 'rgba(255, 255, 255, 1)',
          lineDash: [0, 2],
          lineTailCap: 'arrow-tail',
          lineHeadCap: 'arrow-head',
        },
      });

      if (polylineGroup.current) {
        // Add the Polyline polyline and the two markers to the map:
        polylineGroup.current.addObjects([PolylineOutline, PolylineArrows]);
      }
    } else {
      let PolylineInner = new window.H.map.Polyline(linestring, {
        style: {
          lineWidth: 6,
          strokeColor: options?.color
            ? options?.color
            : 'rgba(0, 128, 255, 0.9)',
        },
      });
      let PolylineOutline = new window.H.map.Polyline(linestring, {
        style: {
          lineWidth: 9,
          strokeColor: 'rgba(255, 255, 255, 1)',
        },
      });
      if (polylineGroup.current) {
        // Add the Polyline polyline and the two markers to the map:
        polylineGroup.current.addObjects([PolylineOutline, PolylineInner]);
      }
    }
  }
  useEffect(() => {
    const dispose = () => {
      if (polylineGroup.current) {
        polylineGroup.current.removeAll();
      }

      polylineGroup.current = null;
    };
    disposeFunctions.current.push(dispose);
  }, [map, disposeFunctions, polyline]);

  return <div style={{ display: 'none' }} />;
}

export default Polyline;
