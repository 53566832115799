import React, { useEffect, useMemo, useState } from 'react';
import Configs from 'config/config';
import { useSelector, useDispatch } from 'react-redux';
import Mapview, {
  HMapPlaceSearch,
  HMapDrawCircle,
  HMapDrawMarker,
  HMapDrawPolygon,
} from 'Components/HereMap';
import { mapSetMarkers, mapSetCircles, mapSetPolygons } from 'reducers/map';
import { markerPinSvgIcon } from 'Components/Common/MarkerIconRenderer';
import { getBbox } from 'Components/HereMap/libs/geoLib';
import Loading from 'Components/HereMap/Components/Loading';

const env = process.env.NODE_ENV;

const LocationMasterMap = (props) => {
  const { mapRef } = props;
  const [bbox, setBbox] = useState(null);
  const [isSetBound, setIsSetBound] = useState(true);
  const [markerGroup, setMarkerGroup] = useState(null);
  const dispatch = useDispatch();
  const map_loading = useSelector((state) => {
    return state.map.map_loading;
  });
  const locationGroup = useSelector((state) => {
    return state.selection.locationGroup;
  });

  const drawingMode = useSelector((state) => {
    return state.map.drawingMode;
  });
  const markerList = useSelector((state) => {
    return state.map.markerList;
  });
  const circleList = useSelector((state) => {
    return state.map.circleList;
  });
  const polygonList = useSelector((state) => {
    return state.map.polygonList;
  });

  let marker = useMemo(() => {
    if (
      markerList &&
      markerList.length > 0 &&
      markerList[0].position &&
      markerList[0].position.lat &&
      markerList[0].position.lng
    ) {
      return markerList[0].position;
    } else {
      return null;
    }
  }, [markerList]);

  let circle = useMemo(() => {
    if (
      circleList &&
      circleList.length > 0 &&
      circleList[0].center &&
      circleList[0].center.lat &&
      circleList[0].center.lng
    ) {
      return circleList[0];
    } else {
      return null;
    }
  }, [circleList]);

  let polygon = useMemo(() => {
    if (polygonList && polygonList.length > 0 && polygonList[0].path) {
      return polygonList[0].path;
    } else {
      return null;
    }
  }, [polygonList]);

  useEffect(() => {
    if (drawingMode === 'circle') {
      dispatch(mapSetPolygons([]));
    }
    if (drawingMode === 'polygon') {
      dispatch(mapSetCircles([]));
    }
  }, [drawingMode, dispatch]);

  useEffect(() => {
    if (locationGroup && locationGroup.value) {
      let group = JSON.parse(locationGroup.value);
      setMarkerGroup(group);
    }
  }, [locationGroup]);

  useEffect(() => {
    let tmpBbox = [];
    if (marker) {
      tmpBbox.push([marker.lat, marker.lng]);
    }
    if (markerGroup) {
      tmpBbox.push([markerGroup.latitude, markerGroup.longitude]);
    }
    if (tmpBbox.length > 0) {
      setBbox(getBbox(tmpBbox));
    }
  }, [marker, markerGroup]);

  const mapPageManage = (drawingMode) => {
    return (
      <>
        {map_loading && <Loading />}
        <HMapDrawMarker
          key='marker'
          marker={marker}
          isDraw={drawingMode === 'marker'}
          draggable={drawingMode === 'marker'}
          options={{
            icon: markerPinSvgIcon(30, 40, '#3B538B', '#ffffff'),
          }}
          onCallback={(d) => {
            const markerList = [
              {
                position: d,
                isGroup: false,
              },
            ];
            dispatch(mapSetMarkers(markerList));
            setIsSetBound(false);
          }}
        />
        <HMapDrawCircle
          key='circle'
          circle={circle}
          draggable={drawingMode === 'circle'}
          resizable={drawingMode === 'circle'}
          isDraw={drawingMode === 'circle'}
          onCallback={(d) => {
            dispatch(mapSetCircles([d]));
            setIsSetBound(false);
          }}
        />
        <HMapDrawPolygon
          key='polygon'
          polygon={polygon}
          draggable={drawingMode === 'polygon'}
          resizable={drawingMode === 'polygon'}
          isDraw={drawingMode === 'polygon'}
          options={{
            style: {
              fillColor: 'rgba(0, 85, 170, 0.4)',
              strokeColor: 'rgba(0, 85, 170, 0.6)',
            },
          }}
          onCallback={(p) => {
            let path = p?.geometry?.coordinates[0]?.map((c) => {
              return {
                lat: c[1],
                lng: c[0],
              };
            });
            dispatch(mapSetPolygons([{ path }]));
            setIsSetBound(false);
          }}
        />
      </>
    );
  };

  return (
    <>
      <Mapview
        ref={mapRef}
        apiKey={Configs[env].HERE_API_KEY}
        includeUI
        style={{
          height: '100%',
          width: '100%',
        }}
        mapOptions={{
          zoom: 14,
          center: Configs[env].MAP_CENTER_DEFAULT,
          pixelRatio: window.devicePixelRatio || 1,
        }}
        useEvents={true}
        setBound={isSetBound && bbox}
      >
        {mapPageManage(drawingMode)}
        {markerGroup && (
          <HMapDrawMarker
            marker={{
              lat: markerGroup.latitude,
              lng: markerGroup.longitude,
            }}
            options={{
              icon: markerPinSvgIcon(30, 40, '#019875', '#ffffff'),
              info: `<div style="font-size:12px;width:150px"><b>Logistics Point Group:</b><br/>${markerGroup.logisticsPointGroup}<br/><b>Location Group:</b><br/>${markerGroup.name}</div>`,
            }}
          />
        )}
        <HMapPlaceSearch />
      </Mapview>
    </>
  );
};
export default LocationMasterMap;
