import { all, call } from 'redux-saga/effects';
import form from './formSaga';
import auth from './authSaga';
import get from './getSaga';
import upload from './uploadSaga';
import location from './locationSaga';
import hazardHistory from './impactHazardSaga';
import hazardHistoryLog from './impactHazardLogSaga';
import hazard from './hazardSaga';
import routemaster from './routemasterSaga';
import routesuggestion from './routesuggestionSaga';
import autoSuggest from './autoSuggestSaga';
import setting from './settingSaga';
import dashboard from './dashboardSaga';
import exportFile from './exportSaga';

function* rootSaga() {
  yield all([
    call(get),
    call(form), 
    call(auth),
    call(upload),
    //call(map),
    call(hazard),
    call(hazardHistory),
    call(hazardHistoryLog),
    call(location),
    call(routemaster),
    call(routesuggestion),
    call(autoSuggest),
    call(setting),
    call(dashboard),
    call(exportFile),
  ]);
}

export default rootSaga;
