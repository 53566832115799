// eslint-disable-next-line import/no-anonymous-default-export
export default {
  development: {
    BACKEND_HOST: 'http://localhost:8000/api/v1',
    SOCKETIO_URL: 'http://localhost:8000',
    IDLE_TIMEOUT: 2 * 60 * 60 * 1000, // 2Hr
    AUTHORIZATION: '1234567890-12345-12345678',
    HERE_API_KEY: '1Z3Qen2k327mzzHDBNkT5j9TQN2xdvrjGLIlrqjBDLM',
    MAP_KEY: 'AIzaSyAR8tDtV_F2TuYSqZHAkdAzG7g8Hif8Cz8',
    MAP_CENTER_DEFAULT: {
      lat: 3.08098,
      lng: 101.47217,
    },
  },
  production: {
    BACKEND_HOST: 'https://api.dmtls.dev.mobility-daas.com/api/v1',
    SOCKETIO_URL: 'https://api.dmtls.dev.mobility-daas.com',
    IDLE_TIMEOUT: 2 * 60 * 60 * 1000, // 2Hr
    AUTHORIZATION: '1234567890-12345-12345678',
    HERE_API_KEY: '1Z3Qen2k327mzzHDBNkT5j9TQN2xdvrjGLIlrqjBDLM',
    MAP_KEY: 'AIzaSyAR8tDtV_F2TuYSqZHAkdAzG7g8Hif8Cz8',
    MAP_CENTER_DEFAULT: {
      lat: 3.08098,
      lng: 101.47217,
    },
  },
  production_uat: {
    BACKEND_HOST: 'https://api.dmtls.uat.mobility-daas.com/api/v1',
    SOCKETIO_URL: 'https://api.dmtls.uat.mobility-daas.com',
    IDLE_TIMEOUT: 2 * 60 * 60 * 1000, // 2Hr
    AUTHORIZATION: '1234567890-12345-12345678',
    HERE_API_KEY: '1Z3Qen2k327mzzHDBNkT5j9TQN2xdvrjGLIlrqjBDLM',
    MAP_KEY: 'AIzaSyAR8tDtV_F2TuYSqZHAkdAzG7g8Hif8Cz8',
    MAP_CENTER_DEFAULT: {
      lat: 3.08098,
      lng: 101.47217,
    },
  },
  production_prod: {
    BACKEND_HOST: 'https://api.dmtls.mobility-daas.com/api/v1',
    SOCKETIO_URL: 'https://api.dmtls.mobility-daas.com',
    IDLE_TIMEOUT: 2 * 60 * 60 * 1000, // 2Hr
    AUTHORIZATION: '1234567890-12345-12345678',
    HERE_API_KEY: '1Z3Qen2k327mzzHDBNkT5j9TQN2xdvrjGLIlrqjBDLM',
    MAP_KEY: 'AIzaSyAR8tDtV_F2TuYSqZHAkdAzG7g8Hif8Cz8',
    MAP_CENTER_DEFAULT: {
      lat: 3.08098,
      lng: 101.47217,
    },
  },
};
