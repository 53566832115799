// Remove event listener
const removeEvent = (map, mapEvents) => {
  for (const type in mapEvents) {
    if (mapEvents.hasOwnProperty(type)) {
      const callback = mapEvents[type];
      if (callback && typeof callback === 'function') {
        map.removeEventListener(type, callback)
      }
    }
  }

  return;
};

export default removeEvent;
