import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from './Snackbar';

import { SET_SNACKBAR } from '../../reducers/ui';
import { forceLogout } from '../../reducers/auth';

// import { setActiveMenuListItem } from './reducers/ui';

// import Configs from "./config/config";
// const env = process.env.NODE_ENV;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: 'calc(100vh - 0px)',
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works

    [theme.breakpoints.up('xs')]: {
      marginTop: '2em',
    },
  },
  toolbar: theme.mixins.toolbar,
  center: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px',
  },
});

class SnackbarMe extends Component {
  constructor() {
    super();

    let privilege = JSON.parse(localStorage.getItem('privilege'));

    this.state = {
      snackbarOpen: false,
      snackbarVariant: 'success',
      snackbarMessage: 'Success',
      snackbarDuration: 5000,
      forceLogout: false,
    };
  }

  handleSnackbarClose = (event, reason) => {
    if (
      this.props.snackbar.forceLogout === true &&
      this.props.snackbar.snackbarOpen === true
    ) {
      this.props.forceLogout(true);
    }
    this.props.SET_SNACKBAR({
      snackbarOpen: false,
      forceLogout: false,
    });
  };
  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={this.props.snackbar.snackbarOpen}
        onClose={this.handleSnackbarClose}
        autoHideDuration={this.props.snackbar.snackbarDuration}
      >
        <SnackbarContentWrapper
          variant={this.props.snackbar.snackbarVariant}
          style={{ margin: 'theme.spacing.unit' }}
          message={this.props.snackbar.snackbarMessage}
          onClose={this.handleSnackbarClose}
        />
      </Snackbar>
    );
  }
}

const mapStateToProps = (state) => ({
  //   isLoading: state.auth.fetching,
  //   // bodySize: state.ui.bodySize,
  //   activeMenuListItem : state.ui.activeMenuListItem,
  snackbar: state.ui.snackbar,
  //   auth: getAuthResponse(state),
  // privilege: state.auth.privilege
});

const mapDispatchToProps = (dispatch) => {
  return {
    // userLogin: auth => dispatch(authLoginRequest(auth)),
    // setActiveMenuStart: location => dispatch(setActiveMenuListItem(location)),
    SET_SNACKBAR: (payload) => dispatch(SET_SNACKBAR(payload)),
    forceLogout: (payload) => dispatch(forceLogout(payload)),
    // setActiveMenuListItem :payload => dispatch(setActiveMenuListItem(payload)),
    // authLogoutRequest,
    // uiAlertOpen,
    // dispatch
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
);

export default enhance(SnackbarMe);
