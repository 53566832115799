import React, { useState, useRef } from 'react';

import {
  InputBase,
  IconButton,
  Paper,
  makeStyles,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/CloseOutlined';
import LocationIcon from '@material-ui/icons/LocationOn';
import DeleteIcon from '@material-ui/icons/Delete';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';
import { pointsAlongLine, waypointsByBearingCond } from '../libs/geoLib';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    width: 188,
    marginTop: '10px',
    minHeight: '44px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  delButton: { color: 'red' },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function AutoWayPoints(props) {
  const { enqueueSnackbar, onCallback, lineJson, onDeleteCallback } = props;
  const inputRef = useRef();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [isAuto, setIsAuto] = useState(true);

  const onInputSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const clearSearch = () => {
    setSearchTerm('');
    inputRef.current.value = '';
  };
  const onSubmit = () => {
    if (!lineJson) {
      return;
    }

    let points = [];
    if (isAuto) {
      points = waypointsByBearingCond(lineJson.coordinates);
    } else {
      var re = /^\d+(\.\d+)?$/;
      var match = searchTerm.match(re);
      if (!match) {
        return enqueueSnackbar('Invalid input value', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          autoHideDuration: 2000,
        });
      }
      points = pointsAlongLine(lineJson.coordinates, searchTerm);
    }
    if (onCallback) {
      onCallback(points);
    }
  };

  const onDelete = () => {
    if (onDeleteCallback) {
      onDeleteCallback();
    }
  };
  return (
    <>
      <div className={classes.root}>
        <Paper style={{ textAlign: 'center' }}>
          <Typography style={{ textAlign: 'left', fontWeight: 600 }}>
            Waypoints
          </Typography>
          <FormControlLabel
            style={{ marginLeft: '5px' }}
            control={
              <Checkbox
                checked={isAuto}
                onChange={(e) => {
                  setIsAuto(e.target.checked);
                }}
                color='primary'
              />
            }
            label='Auto'
            labelPlacement='end'
          />
          <IconButton
            color='primary'
            className={classes.iconButton}
            aria-label='submit'
            onClick={onSubmit}
          >
            <LocationIcon />
          </IconButton>
          <IconButton
            className={classes.delButton}
            aria-label='delete'
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Paper>
        {!isAuto && (
          <Paper component='form' className={classes.searchInput}>
            <InputBase
              inputRef={inputRef}
              className={classes.input}
              placeholder='Interval (km)'
              inputProps={{ 'aria-label': 'place search' }}
              onChange={onInputSearchChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9?.]/g, '');
              }}
            />
            {searchTerm && (
              <>
                <Divider className={classes.divider} orientation='vertical' />
                <IconButton
                  className={classes.iconButton}
                  aria-label='clear'
                  onClick={clearSearch}
                >
                  <CloseIcon />
                </IconButton>
              </>
            )}
          </Paper>
        )}
      </div>
    </>
  );
}

export default compose(withSnackbar)(AutoWayPoints);
