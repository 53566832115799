import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputBase,
  IconButton,
  Paper,
  makeStyles,
  MenuList,
  MenuItem,
  Typography,
  Divider,
  ListItemIcon,
  CircularProgress,
  ClickAwayListener,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { useDebounce } from 'hooks/debounce';
import { hmapSelector } from 'reducers/hmap';
import { geocodeAutosuggest } from 'reducers/hmap/api';
import { compose } from 'recompose';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import parseToHtml from 'html-react-parser';
import { showError } from 'libs/errorHandler';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '10px',
    left: '10px',
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paperAutocomplete: {
    maxHeight: '300px',
    overflow: 'auto',
    width: 400,
    marginTop: '5px',
  },
  notFound: {
    width: 300,
  },
}));

function PlaceSearch(props) {
  const { map, enqueueSnackbar } = props;
  const inputRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [isShowResult, setIsShowResult] = useState(true);
  const [isOnFocus, setIsOnFocus] = useState(false);
  if (!window.H || !window.H.map || !map) {
    throw new Error('HMap has to be initialized before adding Map Objects');
  }
  var group = useMemo(() => {
    return new window.H.map.Group();
  }, []);

  group.addEventListener(
    'tap',
    function (evt) {
      map.setCenter(evt.target.getGeometry());
    },
    false
  );

  if (map) {
    map.addObject(group);
  }

  const { isSearchPlaceLoading, searchPlaceError, searchPlaceResult } =
    useSelector(hmapSelector);

  useEffect(() => {
    if (searchPlaceError) {
      showError(enqueueSnackbar, searchPlaceError);
    }
  }, [searchPlaceError, enqueueSnackbar]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      clearSearchResult();
      dispatch(geocodeAutosuggest(debouncedSearchTerm));
    }
  }, [debouncedSearchTerm, dispatch]);

  useEffect(() => {
    if (searchPlaceResult) {
      if (searchPlaceResult.items && searchPlaceResult.items.length === 0) {
        setIsNotFound(true);
      } else {
        setSearchResults(
          searchPlaceResult.items.map((item) => ({
            label: item.title,
            locationId: item.id,
            position: item.position,
          }))
        );
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
      setIsShowResult(true);
    }
  }, [searchPlaceResult]);

  const clearSearchResult = () => {
    setIsNotFound(false);
    setSearchResults([]);
  };

  const clearSearch = () => {
    if (group) {
      group.removeAll();
    }
    clearSearchResult();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setSearchTerm('');
  };

  const onInputSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setSearchResults([]);
  };

  const plotSearchLocation = useCallback(
    (position) => {
      if (!position) {
        return;
      }
      let marker = new window.H.map.Marker({
        lat: position.lat,
        lng: position.lng,
      });
      // marker.setData(locations[i].Location.Address.Label);
      group.removeAll();
      group.addObject(marker);

      map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox(),
      });
      if (group.getObjects().length < 2) {
        map.setZoom(14);
      }
    },
    [group, map]
  );

  return (
    <>
      <div className={classes.root}>
        <Paper component='form' className={classes.searchInput}>
          <InputBase
            inputRef={inputRef}
            className={classes.input}
            placeholder='Place search'
            inputProps={{ 'aria-label': 'place search' }}
            onChange={onInputSearchChange}
            disabled={isSearchPlaceLoading}
            onFocus={() => {
              setIsOnFocus(true);
              setIsShowResult(true);
            }}
            onBlur={() => {
              setIsOnFocus(false);
            }}
          />
          {!isSearchPlaceLoading && (
            <IconButton className={classes.iconButton} aria-label='search'>
              <SearchIcon />
            </IconButton>
          )}
          {isSearchPlaceLoading && <CircularProgress size={24} thickness={2} />}
          {debouncedSearchTerm && (
            <>
              <Divider className={classes.divider} orientation='vertical' />
              <IconButton
                className={classes.iconButton}
                aria-label='clear'
                onClick={clearSearch}
              >
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Paper>
        {isNotFound && (
          <Paper
            className={classNames(classes.paperAutocomplete, classes.notFound)}
          >
            <MenuList>
              <MenuItem style={{ whiteSpace: 'normal' }}>
                <ListItemIcon>
                  <PriorityHighIcon fontSize='small' />
                </ListItemIcon>
                <Typography variant='inherit'>
                  No search results found
                </Typography>
              </MenuItem>
            </MenuList>
          </Paper>
        )}

        {searchTerm &&
          searchResults &&
          searchResults.length > 0 &&
          isShowResult && (
            <ClickAwayListener
              onClickAway={() => {
                if (!isOnFocus) {
                  setIsShowResult(false);
                }
              }}
            >
              <Paper className={classes.paperAutocomplete}>
                <MenuList>
                  {searchResults.map((item, idx) => [
                    <MenuItem
                      key={idx}
                      style={{ whiteSpace: 'normal' }}
                      onClick={() => {
                        plotSearchLocation(item.position);
                      }}
                    >
                      <Typography
                        variant='inherit'
                        style={{
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {parseToHtml(item.label)}
                      </Typography>
                    </MenuItem>,
                    <Divider />,
                  ])}
                </MenuList>
              </Paper>
            </ClickAwayListener>
          )}
      </div>
    </>
  );
}

export default compose(withSnackbar)(PlaceSearch);
